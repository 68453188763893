(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('TimezoneService', TimezoneService);

    TimezoneService.$inject = ['Enum'];

    function TimezoneService (Enum) {
        var service = {
            getOffset: getOffset
        };

        return service;

        function getOffset (timezone) {
            Enum.getTimeCard2({name:"Timezone"},function(result){
                angular.forEach(result,function(data,index,array){
                    if (data.code === timezone) {
                        console.log(data.name);
                        return data.name;
                    }
                });
            });
            return "+0900";
        }
    }
})();
