(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('RoleManagementCreateController',RoleManagementCreateController);

    RoleManagementCreateController.$inject = ['$stateParams', 'param', 'AlertService', '$uibModalInstance', 'Role', 'JhiLanguageService','Group', 'MessageService', '$timeout', '$rootScope','OptionUser','Principal', 'Outlets'];

    function RoleManagementCreateController ($stateParams, param, AlertService, $uibModalInstance, Role, JhiLanguageService, Group, MessageService, $timeout, $rootScope, OptionUser, Principal, Outlets) {
        var vm = this;
        vm.clear = clear;
        vm.authority;
        vm.searchAuthority = searchAuthority;
        vm.role = {code: "", name: "", kinouList: "", resultList: ""};
        vm.getFunction = getFunction;
        vm.functionCheck = functionCheck;
        vm.save = save;
        vm.loadData = loadData;
        //vm.getFunction();
        loadData();
        vm.oldCode = "";

        function loadData() {
            if (param.code != null && param.code != '') {
                Role.getRole({code: param.code}, function (result) {
                    vm.role.code = result.code;
                    vm.oldCode = result.code;
                    vm.role.name = result.name;
                    vm.role.kinouList = result.kinouList;
                    vm.role.resultList = result.kinouList;
                    //functionCheck();
                });
            }else {
                getFunction();
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function searchAuthority(){
            /*var container = $('#aDiv');
            var scrollTo = $("input[name='" + vm.authority+ "']");
            if(vm.authority == ""){
                container.animate({
                    scrollTop: 0
                });
            }else{
                container.animate({
                    scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()- 10
                });
            }*/
            vm.role.kinouList = vm.role.resultList;
            if(vm.authority != "" && vm.authority != null){
                angular.forEach(vm.role.kinouList,function(data,index,array){
                    if(!data.name.match(vm.authority)){
                        data.showFlag = false;
                    }else{
                        data.showFlag = true;
                    }
                });
            }else{
                angular.forEach(vm.role.kinouList,function(data,index,array){
                    data.showFlag = true;
                });
            }
        }

        function getFunction() {
            Role.getFunction({}, function (data){
                vm.role.kinouList = data;
                vm.role.resultList = data;
            });
        }

        function functionCheck (){
            vm.role.kinouList.sort(function (a,b){
                if(b.checked == a.checked){
                    return a.id - b.id;
                }
                return b.checked - a.checked;
            });
            var container = $('#aDiv');
            var scrollTo = $("input[name='" + vm.authority+ "']");
            container.animate({
                scrollTop: 0
            });
        }

        function save(){
            var entity = angular.copy(vm.role);
            if(vm.oldCode == ''){
                Role.createRole(entity, function(result){
                    MessageService.success("roleManagement.role.roleSaved");
                    $uibModalInstance.close(result);
                    $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
                },function(error){
                    AlertService.error(error.data.message);
                });
            }else{
                entity.originalCode = vm.oldCode;
                Role.updateRole(entity, function(result){
                    MessageService.success("roleManagement.role.roleSaved");
                    $uibModalInstance.close(result);
                    $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
                },function(error){
                    MessageService.error("global.messages.error.save");
                });
            }

        }
    }
})();
