(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Outlets', Outlets);

    Outlets.$inject = ['$resource'];

    function Outlets ($resource) {
        var service = $resource('api/outlets', {}, {
            'save': {
                method: 'POST'
            },
            'delete': {
                method: 'DELETE'
            },
            'getByUserId': {
                method: 'GET',
                url: 'api/outlets/getByUserId'
            },
            'query': {
                method: 'GET',
                url: 'api/outlets/list',
                isArray: true
            },
            'get': {
                method: 'GET'
            }
        });

        return service;
    }

})();
