(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('RobotManagementController', RobotManagementController);

    RobotManagementController.$inject = ['Pepper','$rootScope','Group','paginationConstants'];

    function RobotManagementController(Pepper, $rootScope,Group, paginationConstants) {
        var vm = this;

        vm.serviceTypeList = [];
        vm.parentGroup = [];
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.loadAllGroups = loadAllGroups;
        vm.peppers = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.searchData = {pepperName: "", selectGroup: ""};
        vm.defaultSearchData = {pepperName: "", selectGroup: ""};
        vm.loadAll();
        vm.loadParentGroup = loadParentGroup;
        vm.loadParentGroup();
        vm.search = search;
        vm.changeGroup = changeGroup;
        
        vm.clickGroupListClick = clickGroupListClick;
        vm.inputGroupNm = inputGroupNm;

        function loadAllGroups() {
            Group.parentGroup(function(result){
                vm.groups = result;
                for (var j = 0; j < vm.peppers.length; j ++) {
                    for (var i = 0; i < vm.groups.length; i ++) {
                        if (vm.groups[i].id != null && vm.groups[i].id != '' && vm.peppers[j].groupId != null && vm.peppers[j].groupId != '') {
                            if (vm.groups[i].id.toString() == vm.peppers[j].groupId.toString()) {
                                vm.peppers[j].groupName = vm.groups[i].name;
                            }
                        }
                    }
                }
            });
        }

        function loadAll () {
            //获取pepperd的值
            Pepper.query({page: vm.page - 1, size: vm.itemsPerPage, pepperName : vm.searchData.pepperName, groupId : vm.searchData.selectGroup},
                function (result, headers){
                vm.peppers = result;
                vm.totalItems = headers('X-Total-Count');
                vm.groups = result;
                vm.queryCount = vm.totalItems;
                //获取group的值
                vm.loadAllGroups();
            });
            $rootScope.$broadcast('pepperUpdate');
        }

        function search() {
            vm.page = 1;
            loadAll();
        }

        function changeGroup() {
            search();
        }
        function clear() {
            vm.pepper = {
                id: null, pepperName: null, groupId: null
            };
            vm.searchData = angular.copy(vm.defaultSearchData);
            search();
            vm.editForm.$setPristine();
            vm.editForm.$setUntouched();
        }

        function loadParentGroup() {
            Group.parentGroup({}, function(result){
                vm.parentGroup = result;
            });
        }
        
        function clickGroupListClick(gp) {
        	vm.searchData.selectGroup = gp.id;
        	vm.searchData.selectGroupNm = gp.name + gp.serviceTypeName;
        	vm.groupSelectListShowFlg = false;
        }
        
        function inputGroupNm() {
        	vm.groupSelectListShowFlg = false;
        	if (vm.searchData.selectGroupNm != undefined && vm.searchData.selectGroupNm != "") {
        		vm.searchData.selectGroup = "gid0";
        	} else {
        		vm.searchData.selectGroup = "";
        	}
        	
        	for (var i = 0; i < vm.parentGroup.length; i ++) {
        		if (vm.searchData.selectGroupNm != undefined && vm.searchData.selectGroupNm != "") {
        			if ((vm.parentGroup[i].name + vm.parentGroup[i].serviceTypeName).indexOf(vm.searchData.selectGroupNm) != -1) {
        				vm.parentGroup[i].showFlg = true;
        				vm.groupSelectListShowFlg = true;
        			} else {
        				vm.parentGroup[i].showFlg = false;
        			}
        		} else {
        			vm.parentGroup[i].showFlg = true;
        		}
        	}
        }
    }
})();
