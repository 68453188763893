(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'User', 'JhiLanguageService','Group', 'MessageService', '$timeout', '$rootScope','OptionUser','Principal', 'Outlets'];

    function UserManagementDialogController ($stateParams, $uibModalInstance, User, JhiLanguageService, Group, MessageService, $timeout, $rootScope, OptionUser, Principal, Outlets) {
        var vm = this;

        vm.authorities = ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_PARTNER'];
        vm.auths = ['admin', 'member', 'follower'];
        vm.groups = [];
        vm.clear = clear;
        vm.loadAllGroups = loadAllGroups;
        vm.languages = null;
        vm.save = save;
        vm.user = {};
        vm.password = "";
        vm.selectAuth = "";
        // 是否展示贩卖店内容信息
        vm.showShop = false;
        // 贩卖店基本信息
        vm.outlets = {"userId": "", "name": "", "tel": "", "fax": "", "branch": "", "person": ""};

        vm.groupAuth = [];
        vm.repeatFlg = false;
        // 所选节点不能在一棵树枝上
        vm.parentErrorFlg = false;

        vm.tempAuthorities = null;
        vm.changeGroup = changeGroup;
        vm.addGroup = addGroup;
        vm.delGroup = delGroup;
        vm.checkRepeat = checkRepeat;
        vm.loadHourSalary = loadHourSalary;
        vm.getGroupShow = getGroupShow;
        vm.loadAuthorities = loadAuthorities;
        // 改变用户角色时候触发
        vm.authorityChange = authorityChange;
        vm.changeAu = changeAu;
        vm.exist = false;
        vm.existButton = false;
        vm.existEmail = false;
        vm.existEmailButton = false;
        vm.loginMouseleave = loginMouseleave;
        vm.emailMouseleave = emailMouseleave;
        vm.isAdminRoleFlag = false;

        vm.loadUser = loadUser()

        function changeAu(au,checked, $event) {
            /*if (vm.user.authorities == au) {
                $event.preventDefault();
            } else {
                vm.user.authorities = au;
            }*/
            authorityChange(au,checked);
        }

        vm.loadAllGroups();

        Principal.hasAuthority('ROLE_ADMIN').then(function(result){
            if(result) {
                vm.isAdmin = true;
            } else {
                vm.isAdmin = false;
            }
        });

        function loadUser() {
            if ($stateParams.login) {
                User.get({login : $stateParams.login}, function(user) {
                    vm.user = user;
                    vm.user.authorities = [];
                    vm.user.roleList = [];
                    loadAuthorities()
                })
            } else {
                vm.user = {
                    id: null, login: null, firstName: null, lastName: null, email: null,
                    activated: true, created: false, langKey: null, createdBy: null, createdDate: null,
                    lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                    resetKey: null, authorities: null, groupAuthes: []
                }
                vm.user.authorities = [];
                vm.user.roleList = [];
                loadAuthorities()
            }
        }

        function loadAuthorities() {
            Principal.identity().then(function(account) {
                for (var i = 0; i < account.authorities.length; i++) {
                    if (account.authorities[i] == "ROLE_ADMIN") {
                        vm.isAdminRoleFlag = true;
                    }
                }
                //是admin
                if(vm.isAdminRoleFlag){
                    User.getRole(function(r){
                        vm.user.roleList = r;
                        for(var i=0; i < vm.user.roleList.length; i++){
                            if(vm.user.roleList[i].name == 'ROLE_USER'){
                                vm.user.roleList[i].disabled = true;
                                vm.user.roleList[i].checked = true;
                            }else {
                                vm.user.roleList[i].disabled = false;
                            }
                        }
                        if ($stateParams.login != undefined) {
                            User.get({login: $stateParams.login}, function (result) {
                                var hasAdmin = false;
                                vm.hasAdmin = hasAdmin;
                                vm.user = result;
                                vm.user.roleList = r;
                                for(var j=0; j < vm.user.roleList.length; j++){
                                    for (var i = 0; i < result.authorities.length; i++) {
                                        if (result.authorities[i] == vm.user.roleList[j].name) {
                                            vm.user.roleList[j].checked = true;
                                        }
                                        if (result.authorities[i] == "ROLE_ADMIN") {
                                            hasAdmin = true;
                                        }
                                        if (result.authorities[i] == "ROLE_PARTNER") {
                                            vm.showShop = true;
                                        }
                                    }
                                }
                                if (vm.user.authorities.indexOf('ROLE_PARTNER') != -1) {
                                    Outlets.getByUserId({userId: vm.user.id}, function(outletsResult){
                                        vm.outlets = outletsResult;
                                    });
                                }
                            });
                        }
                    });
                }else {
                    //新增
                    if($stateParams.login == undefined){
                        //查当前登录账号绑定的Role
                        User.getSelectRole({login: account.login}, function (role) {
                            for(var a=0; a < role.length; a++){
                                role[a].checked = true;
                                vm.user.roleList.push(role[a]);
                                if(role[a].name == 'ROLE_USER'){
                                    role[a].disabled = true;
                                }else {
                                    role[a].disabled = false;
                                }
                            }
                        });
                        //编辑
                    }else {
                        //查出所有的Role
                        User.getRole(function(r){
                            vm.roleList = r;
                            if ($stateParams.login != undefined) {
                                //查当前登录账号绑定的Role
                                User.get({login: account.login}, function (result) {
                                    var hasAdmin = false;
                                    vm.hasAdmin = hasAdmin;
                                    vm.user.roleList = [];
                                    for(var j=0; j < vm.roleList.length; j++){
                                        for (var i = 0; i < result.authorities.length; i++) {
                                            if (result.authorities[i] == vm.roleList[j].name) {
                                                if(vm.roleList[j].name == 'ROLE_USER'){
                                                    vm.roleList[j].disabled = true;
                                                }
                                                vm.user.roleList.push(vm.roleList[j]);
                                            }
                                            if (result.authorities[i] == "ROLE_ADMIN") {
                                                hasAdmin = true;
                                            }
                                            if (result.authorities[i] == "ROLE_PARTNER") {
                                                vm.showShop = true;
                                            }
                                        }
                                    }
                                    if (vm.user.authorities.indexOf('ROLE_PARTNER') != -1) {
                                        Outlets.getByUserId({userId: vm.user.id}, function(outletsResult){
                                            vm.outlets = outletsResult;
                                        });
                                    }
                                    //查询选中的账号的Role;
                                    User.getSelectRole({login: $stateParams.login}, function (selectRole) {
                                        for(var a=0; a < vm.user.roleList.length; a++){
                                            var number = 0;
                                            for (var b = 0; b < selectRole.length; b++) {
                                                if (selectRole[b].name == vm.user.roleList[a].name) {
                                                    vm.user.roleList[a].checked = true;
                                                    number = 1;
                                                }
                                            }
                                        }
                                        //判断之前选中的在现在的父的里边有没有
                                        for (var c = 0; c < selectRole.length; c++) {
                                            var number = 0;
                                            for(var d = 0; d < vm.user.roleList.length; d++){
                                                if (selectRole[c].name == vm.user.roleList[d].name) {
                                                    number = 1;
                                                }
                                            }
                                            if(number == 0){
                                                selectRole[c].checked = true;
                                                vm.user.roleList.push(selectRole[c]);
                                            }
                                        }
                                    });
                                });
                            }
                        });
                    }
                }
            });
        }

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            // 保存用户信息成功之后，获取得到用户的ID，进行贩卖店信息的处理
            saveOutlets(result.id);
            if (angular.isDefined(vm.user.option) &&  vm.user.option.hourSalary !== null) {
                OptionUser.saveHourSalary(vm.user,function () {
                    vm.isSaving = false;
                    MessageService.success("global.messages.info.save");
                    $uibModalInstance.close(result);
                    $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
                });
            } else {
                vm.isSaving = false;
                MessageService.success("global.messages.info.save");
                $uibModalInstance.close(result);
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            }
        }

        function onSaveError () {
            vm.user.authorities = angular.copy(vm.tempAuthorities);
            vm.isSaving = false;
            MessageService.error("global.messages.error.save");
        }

        function save () {
            vm.user.authorities = [];
            vm.isSaving = true;
            for (var i = vm.user.groupAuthes.length - 1; i >= 0; i --) {
                if (vm.user.groupAuthes[i].groupId == undefined || vm.user.groupAuthes[i].groupId == "") {
                    vm.user.groupAuthes.splice(i, 1);
                }
            }

            for (var i = 0; i < vm.user.roleList.length; i++) {
                if(vm.user.roleList[i].checked){
                    vm.user.authorities.push(vm.user.roleList[i].name);
                }
            }
            vm.tempAuthorities = angular.copy(vm.user.authorities);
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                vm.user.password = vm.password;
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function loadAllGroups() {
            Group.manageGroup({}, function(result){
                vm.groups = result;
                vm.loadHourSalary(vm.user.id);
            });
        }

        function loadHourSalary(userId) {
            if (angular.isDefined(userId) && userId !== null) {
                OptionUser.get({userId:userId}, function (result) {
                    vm.user.option = result;
                });
            }
        }

        function changeGroup(index) {
            vm.checkRepeat();
            if (vm.user.groupAuthes[index].groupAuth == null || vm.user.groupAuthes[index].groupAuth == "") {
                vm.user.groupAuthes[index].groupAuth = angular.copy(vm.auths[0]);
            }
        }

        function addGroup() {
            vm.user.groupAuthes.push({});
        }

        function delGroup(index) {;
            vm.user.groupAuthes.splice(index, 1);
            vm.checkRepeat();
        }

        function checkRepeat() {
            vm.repeatFlg = false;
            for (var i = 0; i < vm.user.groupAuthes.length; i ++) {
                for (var j = i + 1; j < vm.user.groupAuthes.length; j ++) {
                    if (vm.user.groupAuthes[i].groupId == vm.user.groupAuthes[j].groupId) {
                        vm.repeatFlg = true;
                    }
                }
            }
            vm.parentErrorFlg = false;
            if (!vm.repeatFlg) {
                // 判断他的父节点是否在选择的节点里
                // 找到所选节点的所有的父节点
                // 循环所选的节点

                for (var i = 0; i < vm.user.groupAuthes.length; i ++) {
                    // 找到他所有的父节点
                    var allParentIds = []
                    getAllParentGroup(allParentIds, vm.user.groupAuthes[i].groupId);
                    for (var j = 0; j < vm.user.groupAuthes.length; j ++) {
                        if (j != i && allParentIds.indexOf(vm.user.groupAuthes[j].groupId) != -1) {
                            vm.parentErrorFlg = true;
                        }
                    }
                }
            }
        }

        function getAllParentGroup(result, groupId) {
            for (var i = 0; i < vm.groups.length; i ++) {
                if (vm.groups[i].parentId != '') {
                    if (vm.groups[i].id == groupId) {
                        result.push(vm.groups[i].parentId)
                        result = getAllParentGroup(result, vm.groups[i].parentId)
                    }
                }
            }
        }

        function getGroupShow(groupId) {
            var result = false;
            if (groupId == undefined) {
                result = true;
            }
            for (var i = 0; i < vm.groups.length; i ++) {
                if (groupId == vm.groups[i].id) {
                    result = true;
                }
            }
            return result;
        }

        /**
         * 如果选中的角色是贩卖店，那么显示贩卖店的额外信息表格
         */
        function authorityChange(name,checked) {
            if (name == "ROLE_PARTNER") {
                if(checked){
                    vm.showShop = true;
                }else{
                    vm.showShop = false;
                }
            }else{
                if(vm.showShop){
                    return false;
                }
            }
        }

        /**
         * 保存贩卖店的信息
         */
        function saveOutlets(id) {
            if (vm.showShop) {
                vm.outlets.userId = id;
                Outlets.save(vm.outlets);
            } else {
                Outlets.delete({userId: id});
            }
        }

        function loginMouseleave(){
            if(vm.user.login != ''){
                User.queryLogin({login: vm.user.login}, function (result) {
                    if (result.id == undefined) {
                        vm.exist = false;
                        vm.existButton = false;
                    } else {
                        if(vm.user.id != null){
                            if(vm.user.id != result.id){
                                vm.exist = true;
                                vm.existButton = true;
                            }else{
                                vm.exist = false;
                                vm.existButton = false;
                            }
                        }else{
                            vm.exist = true;
                            vm.existButton = true;
                        }
                    }
                });
            }else {
                vm.exist = false;
                vm.existButton = false;
            }
        }

        function emailMouseleave(){
            if(vm.user.email != ''){
                User.queryEmail({email: vm.user.email}, function (result) {
                    if (result.id == undefined) {
                        vm.existEmail = false;
                        vm.existEmailButton = false;
                    } else {
                        if(vm.user.id != null){
                            if(vm.user.id != result.id){
                                vm.existEmail = true;
                                vm.existEmailButton = true;
                            }else{
                                vm.existEmail = false;
                                vm.existEmailButton = false;
                            }
                        }else{
                            vm.existEmail = true;
                            vm.existEmailButton = true;
                        }
                    }
                });
            }else {
                vm.existEmail = false;
                vm.existEmailButton = false;
            }
        }

    }
})();
