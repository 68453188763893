(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('SettingsUploadPhotoController',SettingsUploadPhotoController);

    SettingsUploadPhotoController.$inject = ['$stateParams', '$uibModalInstance', 'entity','Upload','User','MessageService'];

    function SettingsUploadPhotoController ($stateParams, $uibModalInstance, entity,Upload,User,MessageService) {
        var vm = this;

        vm.user = entity;
        vm.clear = clear;
        vm.uploadPic = uploadPic;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function uploadPic() {
            Upload.upload({
                url: 'library/api/upload/file',
                data: {fileName: vm.picFile.name, file: Upload.dataUrltoBlob(vm.croppedDataUrl, vm.picFile.name)}
            }).success(function (library) {
                MessageService.success("photo.message.upload.success");
                vm.picFile = null;
                var data = {'id':vm.user.id,'imageUrl':'/library/api/library/image/'+library.id};
                User.uploadPhoto(data,function(result){
                    MessageService.success("global.messages.info.save");
                    $uibModalInstance.close(result);
                },function(error){
                    MessageService.error("global.error.save");
                });
            }).error(function () {
                MessageService.error("photo.message.upload.error");
            });
        }
    }
})();
