(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Library', Library);

    Library.$inject = ['$resource'];

    function Library ($resource) {
        var service = $resource('library/api/library', {}, {
            "list": {
                method: 'GET',
                isArray: true,
                url: 'library/api/libraries'
            },
            "query": {
                method: 'GET',
                isArray: true,
                url: 'library/api/libraries/belong/:belong/type/:type'
            },
            "save": {
                method: 'POST'
            },
            "del": {
                method: 'POST',
                url: 'library/api/library/del'
            },
            "get": {
                method: 'GET',
                url: 'library/api/library/:id'
            },
            "queryPublic": {
                method: 'GET',
                isArray: true,
                url: 'library/api/libraries/public/belong/:belong/type/:type'
            },
            "update": {
                method: 'PUT'
            },
            "url": {
                method: 'GET'
            }
        });

        return service;
    }

})();
