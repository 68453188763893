(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('ProductType', ProductType);

    ProductType.$inject = ['$resource'];

    function ProductType ($resource) {
        var service = $resource('api/productType', {}, {
            'query': {
                method: 'GET',
                url: 'api/productType/list',
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'save': {
                method: 'POST'
            },
            'delete': {
                method: 'DELETE'
            },
            'checkRepeat': {
                method: 'GET',
                url: 'api/productType/repeat'
            },
            'list': {
                method: 'GET',
                url: 'api/productType/all',
                isArray: true
            }
        });

        return service;
    }

})();
