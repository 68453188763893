(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('DeleteTokenDialogController', DeleteTokenDialogController);

    DeleteTokenDialogController.$inject = ['$uibModalInstance', 'entity', 'TokenService', 'MessageService', '$timeout', '$rootScope'];

    function DeleteTokenDialogController ($uibModalInstance, entity, TokenService, MessageService, $timeout, $rootScope) {
        var vm = this;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            TokenService.deleteToken({token: entity}, function () {
                    MessageService.success("global.messages.info.delete");
                    $uibModalInstance.close(true);
                });
        }
    }
})();
