(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['Auth', 'Principal', '$stateParams', '$localStorage', '$sessionStorage', '$state', 'MessageService', '$scope'];

    function PasswordController (Auth, Principal, $stateParams, $localStorage, $sessionStorage, $state, MessageService, $scope) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.dologin = dologin;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;

        dologin();

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function dologin() {
        	if ($stateParams.token != undefined && $stateParams.token != '') {
                var settings = {
                    "url": "/api/account",
                    "method": "GET",
                    "headers": {
                        "Authorization": "Bearer " + $stateParams.token
                    },
                    success: function(res){
                        Auth.loginWithToken($stateParams.token);
                        vm.account = res
                        $scope.$apply()
                    },
                    error: function() {
                        $state.go('password');
                    }
                }
                $.ajax(settings);
        	}
        }

        function changePassword () {
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;
                Auth.changePassword(vm.password).then(function () {
                    vm.error = null;
                    MessageService.success("password.messages.success");
                    Auth.logout();
                    $state.go('home');
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }
    }
})();
