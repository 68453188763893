(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('license-staff', {
            parent: 'app',
            url: '/settings/license-staff',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/customer/license/staff/license-staff.html',
                    controller: 'LicenseStaffController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('license');
                    return $translate.refresh();
                }]
            }
        }).state('license-staff.edit', {
            parent: 'license-staff',
            url: '/edit/{jigyoushoId}/{staffId}',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/customer/license/staff/dialog/license-staff-edit-dialog.html',
                    controller: 'LicenseStaffEditDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        param: {userId: $stateParams.userId, groupId: $stateParams.groupId}
                    }
                }).result.then(function() {
                    $state.go('license-staff', null, { reload: true });
                }, function() {
                    $state.go('license-staff');
                });
            }]
        }).state('license-staff-manager', {
            parent: 'app',
            url: '/settings/license-staff-manager',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/customer/license/staff/license-staff-manager.html',
                    controller: 'LicenseStaffManagerController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('license');
                    return $translate.refresh();
                }]
            }
        }).state('license-staff-manager.edit', {
            parent: 'license-staff-manager',
            url: '/edit/{jigyoushoId}/{staffId}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/customer/license/staff/dialog/license-staff-edit-dialog.html',
                    controller: 'LicenseStaffEditDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        param: {userId: $stateParams.userId, groupId: $stateParams.groupId}
                    }
                }).result.then(function() {
                    $state.go('license-staff', null, { reload: true });
                }, function() {
                    $state.go('license-staff');
                });
            }]
        });
    }
})();
