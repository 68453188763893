(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('settings', {
            parent: 'account',
            url: '/account/settings',
            data: {
                pageTitle: 'global.menu.account.settings'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/settings.html',
                    controller: 'SettingsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settings');
                    return $translate.refresh();
                }]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
                var top = $uibModalStack.getTop();
                if (top) {
                    $uibModalStack.dismiss(top.key);
                }
                Principal.identity().then(function(account) {
                	if (account != null) {
                		if (account.login.indexOf(";") != -1) {
                    		$state.go('home');
                            $uibModal.open({
                                templateUrl: 'app/home/dialog/license.html',
                                controller: 'HomeLicenseController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg'
                            }).result.then(function() {
                                $state.go('home', null, { reload: true });
                            }, function() {
                                $state.go('home');
                            });
                        }
                	} else {
                		LoginService.open();
                	}
                	
                });
            }]
        }).state('settings.photo', {
            parent: 'settings',
            url: '/photo/{login}',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/account/settings/setting-photo.html',
                    controller: 'SettingsPhotoController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['User', function(User) {
                            return User.get({login : $stateParams.login});
                        }]
                    }
                }).result.then(function() {
                    $state.go('settings', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        }).state('settings.upload', {
            parent: 'settings',
            url: '/photo/upload/{login}',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/account/settings/setting-upload-photo.html',
                    controller: 'SettingsUploadPhotoController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['User', function(User) {
                            return User.get({login : $stateParams.login});
                        }]
                    }
                }).result.then(function() {
                    $state.go('settings', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        }).state('settingsToken', {
            parent: 'account',
            url: '/account/settings/{token}',
            data: {
                pageTitle: 'global.menu.account.settings'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/settings.html',
                    controller: 'SettingsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('settings');
                    return $translate.refresh();
                }]
            }
        })
        ;
    }
})();
