(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .directive('hasFunction', hasFunction);

    hasFunction.$inject = ['Principal'];

    function hasFunction(Principal) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
        	
            var func = attrs.hasFunction.replace(/\s+/g, '');
            
            var setVisible = function () {
                    element.removeClass('hidden');
                },
                setHidden = function () {
                    element.addClass('hidden');
                },
                defineVisibility = function (reset) {
                	
                    if (reset) {
                        setVisible();
                    }
                    
                    Principal.hasFunction(func)
                        .then(function (result) {
                            if (result) {
                                setVisible();
                            } else {
                                setHidden();
                            }
                        });
                };

            if (func.length > 0) {
            	
                defineVisibility(true);

                scope.$watch(function() {
                    return Principal.isAuthenticated();
                }, function() {
                    defineVisibility(true);
                });
            }
        }
    }
})();
