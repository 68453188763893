(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('UserManagementDetailController', UserManagementDetailController);

    UserManagementDetailController.$inject = ['$uibModalInstance','$stateParams', 'User', 'Outlets'];

    function UserManagementDetailController($uibModalInstance,$stateParams, User, Outlets) {
        var vm = this;

        vm.load = load;
        vm.user = {};
        vm.outlets = {};
        vm.outletsShow = false;
        vm.close = close;

        vm.loadAuthorities = loadAuthorities;
        
        vm.authorities = [];
        vm.loadAuthorities();
        
        function loadAuthorities() {
        	User.getRole(function(result){
        		vm.authorities = result;
        		vm.load($stateParams.login);
        	});
        }

        function load(login) {
            User.get({login: login}, function(result) {
                vm.user = result;
                if (vm.user.authorities.indexOf("ROLE_PARTNER") != -1) {
                    Outlets.getByUserId({userId: vm.user.id}, function (outletsResult) {
                        vm.outletsShow = true;
                        vm.outlets = outletsResult;
                    });
                }
                for (var j in vm.user.authorities) {
                	for (var k in vm.authorities) {
                    	if (vm.authorities[k].name == vm.user.authorities[j]) {
                    		vm.user.authorities[j] = vm.authorities[k];
                    		break;
                    	}
                    }
                }
            });
        }

        function close () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
