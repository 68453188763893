(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('role-management', {
                parent: 'app',
                url: '/settings/role?page&sort',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'roleManagement.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/system/role-management/role-management.html',
                        controller: 'RoleManagementController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('role-management');
                        return $translate.refresh();
                    }]

                }
            })
            .state('role-management.new', {
                parent: 'role-management',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/system/role-management/role-management-create.html',
                        controller: 'RoleManagementCreateController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            param: {code: $stateParams.code}
                        }
                    }).result.then(function() {
                        $state.go('role-management', null, { reload: true });
                    }, function() {
                        $state.go('role-management');
                    });
                }]
            })
            .state('role-management.edit', {
                parent: 'role-management',
                url: '/edit/{code}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/system/role-management/role-management-create.html',
                        controller: 'RoleManagementCreateController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            param: {code: $stateParams.code}
                        }
                    }).result.then(function() {
                        $state.go('role-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('role-management.show', {
                parent: 'role-management',
                url: '/show/{code}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/system/role-management/role-management-show.html',
                        controller: 'RoleManagementShowController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            param: {code: $stateParams.code}
                        }
                    }).result.then(function() {
                        $state.go('role-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('role-management.delete', {
                parent: 'role-management',
                url: '/delete/{code}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/system/role-management/role-management-delete.html',
                        controller: 'RoleManagementDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Role', function(Role) {
                                return Role.getRole({code : $stateParams.code});
                            }]
                        }
                    }).result.then(function() {
                        $state.go('role-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }
})();
