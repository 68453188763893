(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('category', {
            parent: 'system',
            url: '/robot/category',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'global.menu.admin.category'
            },
            views: {
                'content@': {
                    templateUrl: 'app/system/category/category.html',
                    controller: 'CategoryController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                // 文字
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('category');
                    return $translate.refresh();
                }]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
            	var top = $uibModalStack.getTop();
            	if (top) {
            	    $uibModalStack.dismiss(top.key);
            	}
                Principal.identity().then(function(account) {
                	if (account != null) {
                		var gtc_caretree2_robot_allfunction_fullaccess = false;
                    	if (account.functions != null) {
                        	for (var i in account.functions) {
                        		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                        			gtc_caretree2_robot_allfunction_fullaccess = true;
                        		}
                        	}
                        }
                    	if (gtc_caretree2_robot_allfunction_fullaccess == false) {
                    		$state.go('home');
                        	$uibModal.open({
                                templateUrl: 'app/home/dialog/license.html',
                                controller: 'HomeLicenseController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg'
                            }).result.then(function() {
                                $state.go('home', null, { reload: true });
                            }, function() {
                                $state.go('home');
                            });
                        }
                	} else {
                		LoginService.open();
                	}
                	
                });
            }]
        })
            .state('new', {
                parent: 'category',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
                	Principal.identity().then(function(account) {
                		if (account != null) {
                			var gtc_caretree2_robot_allfunction_fullaccess = false;
                        	if (account.functions != null) {
                            	for (var i in account.functions) {
                            		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                            			gtc_caretree2_robot_allfunction_fullaccess = true;
                            		}
                            	}
                            }
                        	if (gtc_caretree2_robot_allfunction_fullaccess != false) {
                            	$uibModal.open({
                                    templateUrl: 'app/system/category/category-edit-dialog.html',
                                    controller: 'CategoryEditDialogController',
                                    controllerAs: 'vm',
                                    backdrop: 'static',
                                    size: 'lg',
                                    resolve: {
                                        entity: function () {
                                            return {id: null, categoryType: null, categoryValue: null};
                                        }
                                    }
                                }).result.then(function() {
                                    $state.go('category', null, { reload: true });
                                }, function() {
                                    $state.go('category');
                                });
                            }
                		} else {
                			LoginService.open();
                		}
                    	
                    });
                }]
            })
            .state('edit', {
                parent: 'category',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
                	Principal.identity().then(function(account) {
                		if (account != null) {
                			var gtc_caretree2_robot_allfunction_fullaccess = false;
                        	if (account.functions != null) {
                            	for (var i in account.functions) {
                            		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                            			gtc_caretree2_robot_allfunction_fullaccess = true;
                            		}
                            	}
                            }
                        	if (gtc_caretree2_robot_allfunction_fullaccess != false) {
                            	$uibModal.open({
                                    templateUrl: 'app/system/category/category-edit-dialog.html',
                                    controller: 'CategoryEditDialogController',
                                    controllerAs: 'vm',
                                    backdrop: 'static',
                                    size: 'lg',
                                    resolve: {
                                        entity: ['Category', function(Category) {
                                            return Category.get({id: $stateParams.id});
                                        }]
                                    }
                                }).result.then(function() {
                                    $state.go('category', null, {reload: true});
                                }, function() {
                                    $state.go('category');
                                });
                            }
                		} else {
                			LoginService.open();
                		}
                    	
                    });
                }]
            })
            .state('delete', {
                parent: 'category',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
                	Principal.identity().then(function(account) {
                		if (account != null) {
                			var gtc_caretree2_robot_allfunction_fullaccess = false;
                        	if (account.functions != null) {
                            	for (var i in account.functions) {
                            		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                            			gtc_caretree2_robot_allfunction_fullaccess = true;
                            		}
                            	}
                            }
                        	if (gtc_caretree2_robot_allfunction_fullaccess != false) {
                            	$uibModal.open({
                                    templateUrl: 'app/system/category/category-delete-dialog.html',
                                    controller: 'CategoryDeleteController',
                                    controllerAs: 'vm',
                                    size: 'md',
                                    resolve: {
                                        entity: ['Category', function(Category) {
                                            return Category.get({id: $stateParams.id});
                                        }]
                                    }
                                }).result.then(function() {
                                    $state.go('category', null, { reload: true });
                                }, function() {
                                    $state.go('^');
                                });
                            }
                		} else {
                			LoginService.open();
                		}
                    	
                    });
                }]
            });
    }
})();
