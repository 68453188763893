(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('storage-management', {
                parent: 'app',
                url: '/settings/storage',
                data: {
                    pageTitle: 'storageManagement.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/system/storage-management/storage-management.html',
                        controller: 'StorageManagementController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('storage-management');
                        return $translate.refresh();
                    }]
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService','Role', 'Kinou',
                    function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService, Role, Kinou) {
                        Principal.identity().then(function(account) {
                            if (account != null) {
                                var caretreeFlag = false;
                                var adminFlag = false;
                                var userFlag = false;
                                var partnerFlag = false;
                                var developerFlag = false;
                                if (account.authorities.indexOf("ROLE_CARETREE") != -1) {
                                    Kinou.getAuthorityFunction({roleName:"ROLE_CARETREE", functionCd:"GTC_CareTree2_License_Storage_FullAccess"}, function(result){
                                        if(result.flag){
                                            caretreeFlag = true;
                                        }
                                        if (!caretreeFlag) {
                                            $state.go('accessdenied');
                                        }
                                    });
                                }
                                if (account.authorities.indexOf("ROLE_ADMIN") != -1) {
                                    Kinou.getAuthorityFunction({roleName:"ROLE_ADMIN", functionCd:"GTC_CareTree2_License_Storage_FullAccess"}, function(result){
                                        if(result.flag){
                                            adminFlag = true;
                                        }
                                        if (!adminFlag) {
                                            $state.go('accessdenied');
                                        }
                                    });
                                }
                                if (account.authorities.indexOf("ROLE_USER") != -1) {
                                    Kinou.getAuthorityFunction({roleName:"ROLE_USER", functionCd:"GTC_CareTree2_License_Storage_FullAccess"}, function(result){
                                        if(result.flag){
                                            userFlag = true;
                                        }
                                        if (!userFlag) {
                                            $state.go('accessdenied');
                                        }
                                    });
                                }
                                if (account.authorities.indexOf("ROLE_PARTNER") != -1) {
                                    Kinou.getAuthorityFunction({roleName:"ROLE_PARTNER", functionCd:"GTC_CareTree2_License_Storage_FullAccess"}, function(result){
                                        if(result.flag){
                                            partnerFlag = true;
                                        }
                                        if (!partnerFlag) {
                                            $state.go('accessdenied');
                                        }
                                    });
                                }
                                if (account.authorities.indexOf("ROLE_DEVELOPER") != -1) {
                                    Kinou.getAuthorityFunction({roleName:"ROLE_DEVELOPER", functionCd:"GTC_CareTree2_License_Storage_FullAccess"}, function(result){
                                        if(result.flag){
                                            developerFlag = true;
                                        }
                                        if (!developerFlag) {
                                            $state.go('accessdenied');
                                        }
                                    });
                                }
                            } else {
                                LoginService.open();
                            }
                        });
                }]
            })
            .state('storage-management.delete', {
                parent: 'storage-management',
                url: '/{id}/{houjinId}/delete',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', function($stateParams, $state, $uibModal, Principal, $uibModalStack) {
                    $uibModal.open({
                        templateUrl: 'app/system/storage-management/storage-management-delete-dialog.html',
                        controller: 'StorageManagementDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: {id: $stateParams.id, houjinId: $stateParams.houjinId}
                        }
                    }).result.then(function() {
                        $state.go('storage-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('storage-management.sendMail', {
                parent: 'storage-management',
                url: '{houjinId}/{alertMailFlg}/sendMail',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', function($stateParams, $state, $uibModal, Principal, $uibModalStack) {
                    $uibModal.open({
                        templateUrl: 'app/system/storage-management/storage-management-mail-dialog.html',
                        controller: 'StorageManagementMailController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: {houjinId: $stateParams.houjinId, alertMailFlg: $stateParams.alertMailFlg}
                        }
                    }).result.then(function() {
                        $state.go('storage-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('storage-management.rule', {
                parent: 'storage-management',
                url: '/{houjinId}/{planType}/{totalSize}/rule',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', function($stateParams, $state, $uibModal, Principal, $uibModalStack) {
                    $uibModal.open({
                        templateUrl: 'app/system/storage-management/storage-management-rule-dialog.html',
                        controller: 'StorageManagementRuleController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: {houjinId: $stateParams.houjinId, planType: $stateParams.planType, totalSize:$stateParams.totalSize}
                        }
                    }).result.then(function() {
                        $state.go('storage-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('storage-management.confirmStorage', {
                parent: 'storage-management',
                url: '/confirm',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', function($stateParams, $state, $uibModal, Principal, $uibModalStack) {
                    $uibModal.open({
                        templateUrl: 'app/system/storage-management/storage-management-confirm-dialog.html',
                        controller: 'StorageManagementConfirmController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {

                        }
                    }).result.then(function() {
                        $state.go('storage-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('storage-management.plan', {
                parent: 'storage-management',
                url: '/plan',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', function($stateParams, $state, $uibModal, Principal, $uibModalStack) {
                    $uibModal.open({
                        templateUrl: 'app/system/storage-management/storage-management-plan-dialog.html',
                        controller: 'StorageManagementPlanController',
                        controllerAs: 'vm',
                        size: 'lg'
                    }).result.then(function() {
                        $state.go('storage-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            }).state('storage-management.same', {
                parent: 'storage-management',
                url: '/same',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', function($stateParams, $state, $uibModal, Principal, $uibModalStack) {
                    $uibModal.open({
                        templateUrl: 'app/system/storage-management/storage-management-plan-same-dialog.html',
                        controller: 'StorageManagementPlanSameController',
                        controllerAs: 'vm',
                        size: 'lg'
                    }).result.then(function() {
                        $state.go('storage-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }
})();
