(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ShokushuManagementDialogController',ShokushuManagementDialogController);

    ShokushuManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'Shokushu', 'JhiLanguageService','Group', 'MessageService', '$timeout', '$rootScope'];

    function ShokushuManagementDialogController ($stateParams, $uibModalInstance, entity, Shokushu, JhiLanguageService, Group, MessageService, $timeout, $rootScope) {
        var vm = this;

        vm.groups = [];
        vm.clear = clear;
        vm.loadAllGroups = loadAllGroups;
        vm.save = save;
        vm.shokushu = entity;
        vm.enumShokushuType = Shokushu.enum({"name":"ShokushuType"},function(result){
            console.log(result);
        });

        vm.loadAllGroups();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            MessageService.success("global.messages.info.save");
            $uibModalInstance.close(result);
            $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("global.messages.error.save");
        }

        function save () {
            vm.isSaving = true;
            if (vm.shokushu.id !== null) {
                Shokushu.update(vm.shokushu, onSaveSuccess, onSaveError);
            } else {
                Shokushu.save(vm.shokushu, onSaveSuccess, onSaveError);
            }
        }

        function loadAllGroups() {
            Group.query({}, function(result){
                vm.groups = result;
            });
        }
        // function loadAllGroups() {
        //     Group.parentGroup({}, function(result){
        //         vm.groups = result;
        //     });
        // }

    }
})();
