(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('QrCodeDetailController', QrCodeDetailController);

    QrCodeDetailController.$inject = ['$stateParams', 'QrCode','paginationConstants'];

    function QrCodeDetailController ($stateParams, QrCode,paginationConstants) {

        var vm = this;
        vm.pageCount = 1;
        vm.page = 1;
        vm.links = null;
        vm.totalItems = null;
        vm.libraryList = [];
        vm.before = false;
        vm.load = load;
        vm.riyoushaList = {};
        vm.riyoushaAllList = {};
        vm.load();
        vm.printpage = printpage;
        vm.reload = reload;
        vm.showButtonFlag = false;
        vm.printAll = printAll;
        vm.beforeClick = beforeClick;

        function printAll(){
            var bdhtml=window.document.body.innerHTML;
            document.body.innerHTML=document.getElementById('allRiyousha').innerHTML;
            window.print();
            window.document.body.innerHTML=bdhtml;
        }

        function beforeClick(){
            if(vm.before){
                vm.before = false;
            }else{
                vm.before = true;
            }
            vm.reload();
        }
        function load() {
            QrCode.query({groupId: $stateParams.groupId, page: vm.page - 1, size: paginationConstants.itemsPerPage, beforeFlag: vm.before, qrCodeFlag: true, webFlg: true}, function(result){
                if (result.count != null) {
                    vm.totalItems = result.count;
                    var remainder = vm.totalItems % 20;
                    if(remainder == 0){
                        vm.pageCount = vm.totalItems/20;
                    }else{
                        vm.pageCount = Math.floor(vm.totalItems/20) + 1;
                    }
                    vm.riyoushaList = result.riyousha;
                    vm.riyoushaAllList = result.riyoushaAll;vm.showButtonFlag = true;
                } else {
                    vm.totalItems = 0;
                    vm.riyoushaList = [];
                }
            });
        }

        function printpage(myDiv){
            //var newstr = document.all.item(myDiv).innerHTML;
            var newstr = document.getElementById(myDiv).innerHTML;
            var oldstr = document.body.innerHTML;
            document.body.innerHTML = newstr;
            window.print();//好像这个方法不能执行
            document.body.innerHTML = oldstr;
            return false;
        }

        function reload() {
            load();
        }
    }
})();
