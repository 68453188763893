(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Enum', Enum);

    Enum.$inject = ['$resource'];

    function Enum ($resource) {
        var service = $resource('robot/api/enum', {}, {
            'get': {
                method: 'GET',
                isArray: true,
                url: 'robot/api/enum/name/:name'
            },
            'getGateWay': {
                method: 'GET',
                isArray: true,
                url: 'api/enum/name/:name'
            },
            'getGateWayTwo': {
                method: 'GET',
                isArray: true,
                url: 'api/enum/twoName/:name'
            },
            'getLibrary': {
                method: 'GET',
                isArray: true,
                url: 'library/api/enum/name/:name'
            },
            'getTimeCard': {
                method: 'GET',
                isArray: true,
                url: 'timecard/api/enum/:name'
            },
            'getTimeCard2': {
                method: 'GET',
                isArray: true,
                url: 'timecard/api/enum2/:name'
            }
        });

        return service;
    }

})();
