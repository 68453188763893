(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('DownloaddialogController', DownloaddialogController);
    DownloaddialogController.$inject = ['$uibModalInstance', 'Principal', 'Enum', 'Library', 'ParseLinks', 'paginationConstants', '$scope', '$sce'];
    function DownloaddialogController($uibModalInstance, Principal, Enum, Library, ParseLinks, paginationConstants, $scope, $sce) {
        var vm = this;

        $scope.sce = $sce.trustAsResourceUrl;
        vm.close = close;
        vm.imageDownload = imageDownload;
        vm.csvDownload = csvDownload;


        function close() {
            $uibModalInstance.close("");
        }
        function imageDownload(){
            if(confirm("画像をダウンロードしますか？")){
                $uibModalInstance.close("image");
            }
        }
        function csvDownload(){
            if(confirm("CSVファイルをダウンロードしますか？")){
                $uibModalInstance.close("csv");
            }
        }
    }
})();
