(function() {
    'use strict';

    var jhiItemCount = {
        template: '<div class="info">' +
            '全{{$ctrl.queryCount}}件中　'+
            '{{(($ctrl.page - 1) * $ctrl.itemsPerPage) === 0 ? 1 : (($ctrl.page - 1) * $ctrl.itemsPerPage + 1)}}件〜'+
            '{{($ctrl.page * $ctrl.itemsPerPage) < $ctrl.queryCount ? ($ctrl.page * $ctrl.itemsPerPage) : $ctrl.queryCount}}件を表示' +
                    // 'Showing {{(($ctrl.page - 1) * $ctrl.itemsPerPage) === 0 ? 1 : (($ctrl.page - 1) * $ctrl.itemsPerPage + 1)}} - ' +
                    // '{{($ctrl.page * $ctrl.itemsPerPage) < $ctrl.queryCount ? ($ctrl.page * $ctrl.itemsPerPage) : $ctrl.queryCount}} ' +
                    // 'of {{$ctrl.queryCount}} items.' +
                '</div>',
        bindings: {
            page: '<',
            queryCount: '<total',
            itemsPerPage: '<'
        }
    };

    angular
        .module('gatewayApp')
        .component('jhiItemCount', jhiItemCount);
})();
