(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ShokushuManagementDeleteController', ShokushuManagementDeleteController);

    ShokushuManagementDeleteController.$inject = ['$uibModalInstance', 'entity', 'Shokushu', 'MessageService', '$timeout', '$rootScope'];

    function ShokushuManagementDeleteController ($uibModalInstance, entity, Shokushu, MessageService, $timeout, $rootScope) {
        var vm = this;

        vm.shokushu = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            Shokushu.delete({id: vm.shokushu.id},
                function () {
                    MessageService.success("global.messages.info.delete");
                    $uibModalInstance.close(true);
                    $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
                });
        }
    }
})();
