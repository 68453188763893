(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('StorageManagementConfirmController', StorageManagementConfirmController);

    StorageManagementConfirmController.$inject = ['$uibModalInstance', 'StroageService', 'MessageService', '$timeout', '$rootScope'];

    function StorageManagementConfirmController ($uibModalInstance, StroageService, MessageService, $timeout, $rootScope) {
        var vm = this;
        vm.clear = clear;

        function clear(){
            $uibModalInstance.close('cancel');
        }
    }
})();
