(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ActivationController', ActivationController);

    ActivationController.$inject = ['$stateParams', 'Auth', 'LoginService'];

    function ActivationController ($stateParams, Auth, LoginService) {
    	
        var vm = this;
        
        vm.activateLogout = activateLogout;
        
        activateLogout();
        
        function activateLogout() {
        	vm.isNavbarCollapsed = false;
            Auth.logout();
            vm.pepperList = [];
            vm.pepper = {};
            vm.groupList = [];
            vm.group = {};
            vm.messages = [];
        }

        Auth.activateAccount({key: $stateParams.key}).then(function () {
            vm.error = null;
            vm.success = 'OK';
        }).catch(function () {
            vm.success = null;
            vm.error = 'ERROR';
        });

        vm.login = LoginService.open;
    }
})();
