(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Pepper', Pepper);

    Pepper.$inject = ['$resource'];

    function Pepper ($resource) {
        var service = $resource('robot/api/pepper', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                url: 'robot/api/peppers'
            },
            'check': {
                method: 'GET',
                isArray: true,
                url: 'robot/api/checkPeppers'
            },
            'save': {
                method: 'POST'
            },
            'update': {
                method:'PUT'
            },
            'get': {
                method: 'GET',
                url: 'robot/api/pepper/:id'
            },
            'selectService': {
                method: 'POST',
                isArray: true,
                url:'robot/api/pepper/services'
            },
            'delete': {
                method:'DELETE',
                url: 'robot/api/pepper/:id'
            }
        });

        return service;
    }

})();
