(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('VariableDeleteController', VariableDeleteController);

    VariableDeleteController.$inject = ['$uibModalInstance', 'entity', 'Variable', '$rootScope', 'Uploader'];

    function VariableDeleteController ($uibModalInstance, entity, Variable, $rootScope, Uploader) {

        var vm = this;

        vm.variable = entity;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            del();
            Variable.delete({id: id}, function () {
                $rootScope.variableType = vm.variable.itemType;
                $uibModalInstance.close(true);
            });
        }

        function del() {
            Uploader.delByName({name: vm.variable.id}, function(){
                vm.delFlg = true;
            });
        }
    }
})();
