(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('HomeLicenseController',HomeLicenseController);

    HomeLicenseController.$inject = ['$stateParams', '$state', '$uibModalInstance', 'JhiLanguageService', 'MessageService', '$timeout', '$rootScope','OptionUser','Principal', 'Outlets'];

    function HomeLicenseController ($stateParams, $state, $uibModalInstance, JhiLanguageService, MessageService, $timeout, $rootScope, OptionUser, Principal, Outlets) {
        var vm = this;
        vm.clear = clear;
        vm.goInquiry = goInquiry;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function goInquiry(){
            //window.open('/#/inquiry');
            //$state.go('inquiry');
            $uibModalInstance.dismiss('colse');
        }
    }
})();
