(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('RoleManagementShowController',RoleManagementShowController);

    RoleManagementShowController.$inject = ['$stateParams', 'param', '$uibModalInstance', 'Role', 'JhiLanguageService','Group', 'MessageService', '$timeout', '$rootScope','OptionUser','Principal', 'Outlets'];

    function RoleManagementShowController ($stateParams, param, $uibModalInstance, Role, JhiLanguageService, Group, MessageService, $timeout, $rootScope, OptionUser, Principal, Outlets) {
        var vm = this;
        vm.clear = clear;
        vm.loadData = loadData;
        vm.role = {code: "", name: "", kinouList: []};
        loadData();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function loadData() {
            if (param.code != null && param.code != '') {
                Role.getRole({code: param.code}, function (result) {
                    vm.role.code = result.code;
                    vm.role.name = result.name;
                    for(var i=0; i<result.kinouList.length;i++){
                        if(result.kinouList[i].checked){
                            vm.role.kinouList.push(result.kinouList[i]);
                        }
                    }
                });
            }
        }
    }
})();
