(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('PepperSetting', PepperSetting);

    PepperSetting.$inject = ['$resource'];

    function PepperSetting ($resource) {
        var service = $resource('robot/api/pepper-settings', {}, {
            "query": {
                method: 'GET',
                isArray: true,
                url: 'robot/api/pepper-settings/pepperId/:pepperId/type/:type'
            },
            "copy": {
                method: 'POST',
                url: 'robot/api/pepper-settings/copy/dto',
            },
            "save": {
                method: "POST",
                url: 'robot/api/pepper-settings/dto'
            }
        });

        return service;
    }

})();
