(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('SettingsPhotoController',SettingsPhotoController);

    SettingsPhotoController.$inject = ['$stateParams', '$uibModalInstance', 'entity','Uploader','User','MessageService'];

    function SettingsPhotoController ($stateParams, $uibModalInstance, entity,Uploader,User,MessageService) {
        var vm = this;

        vm.user = entity;
        vm.photo = null;
        vm.clear = clear;
        vm.save = save;


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            console.log("photo upload success");
            var data = {'id':vm.user.id,'imageUrl':'/library/api/library/image/'+result.id};
            User.uploadPhoto(data,function(result){
                MessageService.success("global.messages.info.save");
                $uibModalInstance.close(result);
            },function(error){
                MessageService.error("global.error.save");
            });
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("library.message.uploadError");
        }

        function save () {
            vm.isSaving = true;
            var data = {};
            data.file = vm.photo;
            data.fileName = vm.user.login + ".jpg";
            Uploader.upload(data,onSaveSuccess,onSaveError);
        }
    }
})();
