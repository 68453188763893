(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('KeiyakuplanManagementPlansetController',KeiyakuplanManagementPlansetController);

    KeiyakuplanManagementPlansetController.$inject = ['$stateParams', 'param', 'AlertService', '$uibModalInstance', 'Kinou', 'Enum', 'JhiLanguageService','Group', 'MessageService', '$timeout', '$rootScope','OptionUser','Principal', 'Outlets'];

    function KeiyakuplanManagementPlansetController ($stateParams, param, AlertService, $uibModalInstance, Kinou, Enum, JhiLanguageService, Group, MessageService, $timeout, $rootScope, OptionUser, Principal, Outlets) {
        var vm = this;
        vm.clear = clear;
        vm.authority;
        vm.searchAuthority = searchAuthority;
        vm.keiyakuplanList = [];
        vm.permissionList = [];
        vm.save = save;
        vm.loadData = loadData;
        loadData();

        function loadData() {
            Enum.getGateWayTwo({name: 'Permission'}, function(result) {
                vm.permissionList = result;
            });
            Kinou.getKeiyakuplan({kinouId: param.kinouId}, function (result) {
                vm.keiyakuplanList = result;
            });

        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function searchAuthority(){

        }

        function save(){
            var entity = angular.copy(vm.keiyakuplanList);
            Kinou.svaeKeiyakuplan({kinouId: param.kinouId}, entity, function(result){
                MessageService.success("keiyakuplanManagement.home.keiyakuplanSaved");
                $uibModalInstance.close(result);
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            },function(error){
                AlertService.error(error.data.message);
                MessageService.error("global.messages.error.save");
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            });
        }
    }
})();
