(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('SelectedGroup', SelectedGroup);

    SelectedGroup.$inject = ['$localStorage','Principal'];

    function SelectedGroup ($localStorage, Principal) {

        var service = {
            setCurrent: setCurrent,
            setGroupList: setGroupList,
            current: current,
            groupList: groupList

        };

        return service;

        function setCurrent(group) {
            $localStorage.group = group;
        }

        function setGroupList(list) {
            $localStorage.groupList = list;
        }

        function current() {
            if (Principal.isAuthenticated()) {
                return $localStorage.group;
            }
        }

        function groupList() {
            if (Principal.isAuthenticated()) {
                return $localStorage.groupList;
            }
            return [];
        }
    }
})();
