(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ShiftTimeDetailController', ShiftTimeDetailController);

    ShiftTimeDetailController.$inject = ['$scope', '$state', 'AlertService', '$timeout', '$stateParams', '$rootScope' , 'User', 'Group', 'ShiftTime','pagingParams','paginationConstants','ParseLinks'];

    function ShiftTimeDetailController ($scope, $state, AlertService, $timeout,$stateParams,$rootScope, User, Group, ShiftTime,pagingParams,paginationConstants,ParseLinks) {
        var vm = this;
        var regex = /^([01]\d|2[0-3]):?([0-5]\d)$/;
        var regex2 = /^([01]\d|2[0-3])([0-5]\d)$/;
        var regex3 = /^[0-9]{1,7}$/;

        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.state = 1;
        vm.houjinFlag = false;
        vm.selectGroupFlag = false;
        vm.reloadHoujinTimeZoneFlag = false;
        vm.timeZoneIsNullFlag = false;
        vm.codeSaveFlag = false;
        vm.nameSaveFlag = false;
        vm.parentFlag = false;
        vm.timeType = false;

        vm.parentGroup = [];
        vm.resultList = [];
        vm.shiftTimeList = [];
        vm.groupAllList = [];
        vm.goEdit = goEdit;
        vm.goAdd = goAdd;
        vm.goCancel = goCancel;
        vm.changeState = changeState;
        vm.codeUniqueCheck = codeUniqueCheck;
        vm.nameUniqueCheck = nameUniqueCheck;

        //页面加载时，取得所有的Group
        vm.loadParentGroup = loadParentGroup;
        //Group切换
        vm.changeGroup = changeGroup;
        vm.save = save;
        vm.loadShiftTime = loadShiftTime;
        vm.goDel = goDel;
        vm.startTimeUpdate = startTimeUpdate;
        vm.endTimeUpdate = endTimeUpdate;
        vm.restTimeUpdate = restTimeUpdate;
        vm.reloadHoujinTimeZone = reloadHoujinTimeZone;

        //页面加载后取得所有Group信息
        vm.loadParentGroup();

        function loadParentGroup() {
            Group.parentGroup({}, function(result){
                vm.parentGroup = result;
                if ($stateParams.groupId !== null) {
                    result.forEach(function(item){
                        if (item.id == $stateParams.groupId) {
                            vm.group = item;
                        }
                    });
                } else {
                    vm.group = vm.parentGroup[0];
                }
                vm.groupId = vm.group.id;
                vm.loadShiftTime();
            });
        }

        function loadShiftTime () {
            //当前group为parentId==0的group
            if(vm.group.parentId == ''){
                vm.parentFlag = true;
            }
            ShiftTime.getAll({"groupId":vm.groupId, "state":vm.state}, function(result, headers){
                vm.resultList = result;
                vm.shiftTimeList = angular.copy(result);
                vm.timeZoneIsNullFlag = false;
                //自己没有，则找父
                if(vm.resultList.length == 0){
                    Group.parentGroupList({"groupId":vm.groupId}, function(result){
                        var gid = "";
                        for(var i=0; i<result.length; i++) {
                            if(i == 0){
                                gid += "gid" + result[i].id;
                            }else {
                                gid += ",gid" + result[i].id;
                            }
                        }
                        ShiftTime.getParentAll({"groupId":gid, "state":vm.state}, function(result, headers){
                            if(result.length==0){
                                vm.timeZoneIsNullFlag = false;
                                var shift = {};
                                shift.id = null;
                                shift.code = "";
                                shift.name = "";
                                shift.startTime = "";
                                shift.endTime = "";
                                shift.state = 1;
                                shift.showFlag = false;
                                vm.resultList.push(shift);
                            }else {
                                vm.resultList = result;
                                vm.timeZoneIsNullFlag = true;
                            }
                        })
                    });
                }
            });
        }

        function changeState() {
            loadShiftTime();
        }

        function goEdit(shiftTime){
            shiftTime.showFlag = false;
        }

        function goCancel(shiftTime, index){
            vm.timeType = false;
            shiftTime.startTimeError = false;
            shiftTime.endTimeError = false;
            vm.shiftTimeList.forEach(function (shift) {
                if(shift.id == shiftTime.id){
                    shiftTime.showFlag = true;
                    shiftTime.code = shift.code;
                    shiftTime.name = shift.name;
                    shiftTime.startTime = shift.startTime;
                    shiftTime.endTime = shift.endTime;
                    shiftTime.state = shift.state;
                    shiftTime.restTime = shift.restTime;
                    codeUniqueCheck (shiftTime, index);
                    nameUniqueCheck (shiftTime, index);
                }
            });
        }

        function goAdd(index) {
            var shift = {};
            shift.id = null;
            shift.code = "";
            shift.name = "";
            shift.startTime = "";
            shift.endTime = "";
            shift.state = 1;
            shift.showFlag = false;
            shift.restTime = "";
            vm.resultList.splice(index+1,0,shift);
        }

        function goDel(index) {
            if(vm.resultList[index].id == null){
                vm.resultList.splice(index, 1);
            }else{
                ShiftTime.delete({id:vm.resultList[index].id}, onSaveSuccess, onSaveError);
            }
        }

        function changeGroup() {
            loadShiftTime();
        }

        function save(){
            if(confirm("一括保存してもよろしいでしょうか")){
                if(vm.reloadHoujinTimeZoneFlag){
                    if(confirm("シフト時間帯を再設定すると既存データを削除してもよろしいでしょうか")){
                        ShiftTime.save({groupId:vm.groupId,shiftList:vm.resultList, reloadHoujinTimeZoneFlag:vm.reloadHoujinTimeZoneFlag}, onSaveSuccess, onSaveError);
                    }
                }else {
                    ShiftTime.save({groupId:vm.groupId,shiftList:vm.resultList, reloadHoujinTimeZoneFlag:vm.reloadHoujinTimeZoneFlag}, onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveSuccess (result) {
            vm.reloadHoujinTimeZoneFlag = false;
            loadShiftTime();
        }

        function onSaveError (result) {
            vm.resultList = result.data.shiftList;
        }

        function startTimeUpdate(shiftTimeZone, index, shiftTimeZoneList){
            var startTime = $("#startTime"+index).val();
            if (shiftTimeZone.startTime == null || shiftTimeZone.startTime == "") {
                shiftTimeZone.startTimeError = false;
            } else if (regex.test(startTime)) {
                shiftTimeZone.startTimeError = false;
            } else {
                shiftTimeZone.startTimeError = true;
            }
            saveFlag(shiftTimeZoneList);
        }

        function endTimeUpdate(shiftTimeZone, index, shiftTimeZoneList) {
            var endTime = $("#endTime"+index).val();
            if (shiftTimeZone.endTime == null || shiftTimeZone.endTime == "") {
                shiftTimeZone.endTimeError = false;
            } else if (regex.test(endTime)) {
                shiftTimeZone.endTimeError = false;
            } else {
                shiftTimeZone.endTimeError = true;
            }
            saveFlag(shiftTimeZoneList);
        }
        
        function restTimeUpdate(shiftTimeZone, index, shiftTimeZoneList) {
            shiftTimeZone.restTime = $("#restTime"+index).val();
            if (shiftTimeZone.restTime == null || shiftTimeZone.restTime == "") {
                shiftTimeZone.restTimeError = false;
            } else if (regex3.test(shiftTimeZone.restTime)) {
                shiftTimeZone.restTimeError = false;
            } else {
                shiftTimeZone.restTimeError = true;
            }
            saveFlag(shiftTimeZoneList);
        }

        function saveFlag(shiftTimeZoneList) {
            //跳出循环用
            var breakFlag = true;
            angular.forEach(shiftTimeZoneList, function(data){
                if(breakFlag) {
                    vm.timeType = false;
                    if(data.endTimeError == true || data.startTimeError == true || data.restTimeError == true) {
                        vm.timeType = true;
                        breakFlag = false;
                    }
                }
            });
        }

        function reloadHoujinTimeZone () {
            vm.reloadHoujinTimeZoneFlag = true;
            /*Group.queryParentGroupId({"groupId":vm.groupId}, function (group) {
                //当前用户的父groupId
                var groupId = "gid" + group.groupId;
                ShiftTime.getAll({"groupId":groupId, "state":vm.state}, function(result, headers){
                    result.forEach(function(item) {
                        item.id = null;
                    });
                    vm.resultList = result;
                    vm.shiftTimeList = angular.copy(result);
                });
            });*/
            //找自己的父类的值
            Group.parentGroupList({"groupId":vm.groupId}, function(result){
                var gid = "";
                var group = vm.groupId.substring()
                for(var i=0; i<result.length; i++) {
                    //除过自己
                    var tmp = "gid" + result[i].id;
                    if(tmp != vm.groupId){
                        if(gid == ""){
                            gid += "gid" + result[i].id;
                        }else {
                            gid += ",gid" + result[i].id;
                        }
                    }
                }
                ShiftTime.getParentAll({"groupId":gid, "state":vm.state}, function(result, headers){
                    result.forEach(function(item) {
                        item.id = null;
                        item.showFlag = false;
                    });
                    vm.resultList = result;
                    vm.shiftTimeList = angular.copy(result);
                })
            });
        }

        function codeUniqueCheck (shiftTimeZone, index) {
            if(shiftTimeZone.code != null && shiftTimeZone.code != "") {
                shiftTimeZone.codeRequiredFlag = false;
            }
            for(var i=0; i<vm.resultList.length; i++) {
                if(vm.resultList[i].code == shiftTimeZone.code && index != i){
                    shiftTimeZone.codeSingleFlag = true;
                    vm.codeSaveFlag = true;
                    break;
                }else {
                    shiftTimeZone.codeSingleFlag = false;
                    vm.codeSaveFlag = false;
                }
            }
        }

        function nameUniqueCheck (shiftTimeZone, index) {
            if(shiftTimeZone.name != null && shiftTimeZone.name != "") {
                shiftTimeZone.nameRequiredFlag = false;
            }
            for(var i=0; i<vm.resultList.length; i++) {
                if(vm.resultList[i].name == shiftTimeZone.name && index != i){
                    shiftTimeZone.nameSingleFlag = true;
                    vm.nameSaveFlag = true;
                    break;
                }else {
                    shiftTimeZone.nameSingleFlag = false;
                    vm.nameSaveFlag = false;
                }
            }
        }
    }
})();
