(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ProductSelectDialogController', ProductSelectDialogController);

    ProductSelectDialogController.$inject = ['paginationConstants', 'Product', 'ProductType', '$uibModalInstance'];

    function ProductSelectDialogController (paginationConstants, Product, ProductType, $uibModalInstance) {

        var vm = this;

        // 翻页所需
        vm.page = 1;
        vm.links = null;
        vm.totalItems = null;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        // 商品种类
        vm.kindList= ["ケアリス", "あそぶ", "タイムカード", "アナライズ"];
        // 製品列表
        vm.productList = [];
        // 查询条件
        vm.searchData = {"id": "", "name": "", "kind": 0};
        vm.defaultSearchData = {"id": "", "name": "", "kind": 0};
        // 商品种类
        vm.productTypeList = [];

        // 初始加载数据
        vm.loadData = loadData;
        // 清空搜索条件
        vm.clear = clear;
        // 点击查询
        vm.search = search;
        // 删除指定商品
        vm.remove = remove;
        // 获取商品种类列表
        vm.loadKindList = loadKindList;
        // 选择指定
        vm.select = select;
        // 关闭dialog
        vm.close = close;

        loadData();
        loadKindList();

        function loadData() {
            var kind = 0;
            if (vm.searchData.kind != null && vm.searchData.kind != '') {
                kind = vm.searchData.kind;
            }
            Product.queryDialog({
                page: vm.page - 1,
                id: vm.searchData.id,
                name: vm.searchData.name,
                kind: kind}, function(result, headers){
                vm.totalItems = headers('X-Total-Count');
                vm.productList = result;
                vm.queryCount = vm.totalItems;
            });
        }

        function remove(id) {
            if(confirm('削除してもよろしいですか?')) {
                Product.delete({id: id}, function(){
                    search();
                    MessageService.success("product.message.deleted");
                    $rootScope.$broadcast('messageChanged');
                });
            }
        }

        function loadKindList() {
            // 获取制品种类列表
            ProductType.list(function(result){
                vm.productTypeList = result;
            });
        }

        function search() {
            vm.page = 1;
            loadData();
        }

        function clear() {
            vm.searchData = angular.copy(vm.defaultSearchData);
            vm.page = 1;
            loadData();
        }

        function select(selected) {
            $uibModalInstance.close(selected);
        }

        function close() {
            $uibModalInstance.close("");
        }
    }
})();
