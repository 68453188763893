(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('StorageManagementDeleteController', StorageManagementDeleteController);

    StorageManagementDeleteController.$inject = ['$uibModalInstance', 'entity', 'StroageService', 'MessageService', '$timeout', '$rootScope'];

    function StorageManagementDeleteController ($uibModalInstance, entity, StroageService, MessageService, $timeout, $rootScope) {
        var vm = this;

        vm.year = entity.id;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.error = false;
        vm.houjinId = entity.houjinId;
        vm.date = "";
        getLastYearYestdy(new Date());
        function getLastYearYestdy(date){
            var strYear = date.getFullYear() - vm.year;
            var strDay = date.getDate();
            var strMonth = date.getMonth()+1;
            if(strMonth<10)
            {
                strMonth="0"+strMonth;
            }
            if(strDay<10)
            {
                strDay="0"+strDay;
            }
            vm.date = strYear+"/"+strMonth+"/"+strDay;
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            StroageService.deletePicture({houjinId:vm.houjinId,duration: vm.year},
                function () {
                    MessageService.success("storageManagement.home.deletedSuccessMessage");
                    $uibModalInstance.close(true);
                    $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
                }, function () {
                    vm.error = true;
                });
        }
    }
})();
