(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('SelectedPepper', SelectedPepper);

    SelectedPepper.$inject = ['$localStorage','Principal'];

    function SelectedPepper ($localStorage, Principal) {

        var service = {
            setCurrent: setCurrent,
            setPepperList: setPepperList,
            current: current,
            pepperList: pepperList

        };

        return service;

        function setCurrent(pepper) {
            $localStorage.pepper = pepper;
        }

        function setPepperList(list) {
            $localStorage.pepperList = list;
        }

        function current() {
            if (Principal.isAuthenticated()) {
                return $localStorage.pepper;
            }
        }

        function pepperList() {
            if (Principal.isAuthenticated()) {
                return $localStorage.pepperList;
            }
            return [];
        }
    }
})();
