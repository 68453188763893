(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('RoleManagementDeleteController',RoleManagementDeleteController);

        RoleManagementDeleteController.$inject = ['$stateParams', 'entity', '$uibModalInstance', 'Role', 'JhiLanguageService','Group', 'MessageService', '$timeout', '$rootScope','OptionUser','Principal', 'Outlets'];

    function RoleManagementDeleteController ($stateParams, entity, $uibModalInstance, Role, JhiLanguageService, Group, MessageService, $timeout, $rootScope, OptionUser, Principal, Outlets) {
        var vm = this;
        vm.clear = clear;
        vm.delete = deleteRole;
        vm.role = entity;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function deleteRole (code){
            Role.deleteRole({code: code}, function(){
                MessageService.success("roleManagement.role.roleDelete");
                $uibModalInstance.close(true);
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            });
        }
    }
})();
