(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ProductTypeController', ProductTypeController);

    ProductTypeController.$inject = ['paginationConstants', 'ProductType', 'MessageService', '$rootScope', '$state'];

    function ProductTypeController (paginationConstants, ProductType, MessageService, $rootScope, $state) {

        var vm = this;

        // 翻页所需
        vm.page = 1;
        vm.links = null;
        vm.totalItems = null;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        // 查询条件
        vm.searchData = {"name": "","id": ""};
        vm.defaultSearchData = {"name": "","id": ""};

        // 商品种类列表
        vm.productTypeList = [];

        // 获取数据
        vm.loadData = loadData;
        // 点击查询
        vm.search = search;
        // 清空搜索条件
        vm.clear = clear;
        // 初始加载数据
        loadData();
        vm.goEdit = goEdit;
        vm.goDel = goDel;
        vm.goNew = goNew;

        // 删除指定商品种类
        vm.remove = remove;

        function loadData() {
            if($rootScope.page != undefined){
                vm.page = $rootScope.page;
                $rootScope.page = undefined;
            }
            if($rootScope.id != undefined){
                vm.searchData.id = $rootScope.id;
                $rootScope.id = undefined;
            }
            if($rootScope.name != undefined){
                vm.searchData.name = $rootScope.name;
                $rootScope.name = undefined;
            }

            ProductType.query({
                page: vm.page - 1,
                name: vm.searchData.name,
                id: vm.searchData.id}, function(result, headers){
                vm.totalItems = headers('X-Total-Count');
                vm.productTypeList = result;
                vm.queryCount = vm.totalItems;
                setTimeout(function() {
                    //屏幕高度
                    var height = document.documentElement.clientHeight;
                    //导航部分的高度
                    var navbarHeight = $("#navbarHeight").height();//20
                    //检索部分的高度
                    var searchHeight = $("#searchHeight").height();//15
                    //表头高度
                    var headerHeight = $("#headerHeight").height();//
                    //分页部分高度
                    var pageHeight = $("#pageHeight").height();
                    //copyRight
                    var copyRight = $("#copyRight").height();
                    var tableDivHeight = height-navbarHeight-19-searchHeight-headerHeight-20-pageHeight-10-copyRight-10-40;
                    $("#tableDiv").css("max-height",tableDivHeight);
                }, 20);
            });
        }

        function search() {
            vm.page = 1;
            loadData();
        }

        function clear() {
            vm.searchData = angular.copy(vm.defaultSearchData);
            vm.page = 1;
            loadData();
        }

        function remove(id) {
            if(confirm('削除してもよろしいですか?')) {
                ProductType.delete({id: id}, function(){
                    search();
                    MessageService.success("productType.message.deleted");
                    $rootScope.$broadcast('messageChanged');
                });
            }
        }

        function goEdit(id) {
            $rootScope.id = vm.searchData.id;
            $rootScope.name = vm.searchData.name;
            $rootScope.page = vm.page;
            $state.go('productType.edit', {id: id});
        }

        function goDel(id) {
            $rootScope.id = vm.searchData.id;
            $rootScope.name = vm.searchData.name;
            $rootScope.page = vm.page;
            $state.go('productType.delete', {id: id});
        }

        function goNew() {
            $rootScope.page = vm.page;
            $state.go('productType.edit');
        }
    }
})();
