(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('AnalyzeshowController', AnalyzeshowController);
    AnalyzeshowController.$inject = ['$stateParams', 'ParseLinks','paginationConstants','Uploader','$scope','$timeout','Library','Group','Upload','$filter','Comment','User','MessageService','$rootScope','Analyze','$uibModal'];
    function AnalyzeshowController($stateParams,ParseLinks,paginationConstants,Uploader,$scope,$timeout,Library,Group,Upload,$filter,Comment,User,MessageService,$rootScope,Analyze,$uibModal) {
        var vm = this;
        vm.riyoushaId = $stateParams.riyoushaId;
        vm.jigyoushoId = $stateParams.jigyoushoId;
        vm.jisshiDt = $stateParams.jisshiDt;
        vm.riyoushaName = "";
        vm.jigyoushoName = "";
        vm.jisshiDtBefore = "";
        var jisshiDtSixteen = null;
        var jisshiDtSeventeen = null;
        vm.formatJisshiDt = "";
        vm.groupId = "1";
        vm.page = 1;
        vm.userDto = {};
        vm.commentsShowList = [];
        vm.comment = {id:null,riyoushaId:null,jigyoushoId:null,datumDay:null,commentContent:null,userId:null,commentDate:null};
        vm.commentNew = {id:null,riyoushaId:null,jigyoushoId:null,datumDay:null,commentContent:null,userId:null,commentDate:null};
        vm.photoList = [];
        vm.fileList = [];
        vm.temperatureXList = [];
        vm.temperatureYList = [];
        vm.contentTemperature = "実施日,体温" + "\n";
        vm.pulseXList = [];
        vm.pulseYList = [];
        vm.contentPulse = "実施日,脈拍" + "\n";
        vm.activityXList = [];
        vm.activityNumberYList = [];
        vm.activityActiveYList = [];
        vm.activityExYList = [];
        vm.contentActivity = "実施日,活動量" + "\n";
        vm.bloodPressureXList = [];
        vm.bloodPressureUYList = [];
        vm.bloodPressureLYList = [];
        vm.contentBloodPressure = "実施日,血圧【上】,血圧【下】" + "\n";
        vm.getUpXList = [];
        vm.getUpYList = [];
        vm.contentGetUp = "実施日,起床時間" + "\n";
        vm.sleepXList = [];
        vm.sleepYList = [];
        vm.contentSleep = "実施日,就寝時間" + "\n";
        vm.spo2XList = [];
        vm.spo2YList = [];
        vm.spo2TemperatureList = [];
        vm.weightXList = [];
        vm.weightYList = [];
        vm.contentWeight = "実施日,体重" + "\n";
        vm.dietList = [];
        vm.dietFrontList = [];
        vm.dietAfterList = [];
        vm.contentDiet = "実施日,食事,水分,排尿,排便,体重"+ "\n";

        vm.loadAll = loadAll;
        vm.base64Img2Blob = base64Img2Blob;
        vm.downloadFile = downloadFile;
        vm.loadPhotos = loadPhotos;
        vm.loadFiles = loadFiles;
        vm.downloadByDiet = downloadByDiet;
        vm.showWords = showWords;
        vm.hidenWords = hidenWords;
        vm.showFlag = false;
        vm.show= show;
        vm.isMobile = isMobile;
        var jisshiDtBeforeWeight = null;
        vm.number = 0;
        vm.xLength = "75%";
        vm.yLength = "0";
        loadAll();
        vm.mobile_flag = isMobile();
        vm.ipadClass= "col-md-4 col-sm-4 col-xs-12 text-center";
        vm.pcClass= "col-md-3 col-sm-4 col-xs-12 text-center";
        vm.jigyoushoIpadClass= "col-md-12";
        vm.jigyoushoPcClass= "col-md-1 text-right";
        vm.riyoushaPcClass= "col-md-3";
        vm.jigyoushoPhonClass= "col-md-1";
        vm.isMobileAndIpad = false;
        vm.isPc = false;
        vm.activeStateFlag = true;
        vm.activeStateFlag2 = true;
        vm.clickTab = clickTab;
        vm.download = download;
        vm.activeStateXList = [];
        //D01起上り
        vm.okiagariYList = [];
        //D02端座位
        vm.tanzaiYList = [];
        //D03離床
        vm.rishouYList = [];
        //D04柵越え
        vm.sakugoeYList = [];
        //D05ずり落ち
        vm.ziriochiYList = [];
        //D06入室
        vm.nyuusituYList = [];
        //D07退室
        vm.taishitsuYList = [];
        //D10動き出し
        vm.tenYList = [];
        //D11入床
        vm.elevenYList = [];
        //D12体動
        vm.twelveYList = [];
        vm.contentActiveState = "実施日,提供時間,起上り,端座位,離床,柵越え,ずり落ち,入室,退室,動き出し,入床,体動" + "\n";
        vm.contentActiveState2 = "実施日,提供時間,右側端,左側端,頭側端,足側端,呼び出し,応答,心拍高,心拍低,呼吸高,呼吸低" + "\n";


        if(vm.mobile_flag){
            vm.number = 1;
            vm.xLength = "center";
            vm.yLength = "5%";
        }
        if(vm.mobile_flag == 1 || vm.mobile_flag == 0){
            vm.isMobileAndIpad = true;
        }else{
            vm.isPc = true;
        }

        angular.element(document).ready(function () {
            getData();
        });

        function loadAll() {
            vm.formatJisshiDt = new Date(vm.jisshiDt.substr(0,10)+"T"+vm.jisshiDt.substr(11,18));
            var beforeWeight = angular.copy(vm.formatJisshiDt);
            beforeWeight.setMonth(beforeWeight.getMonth()-6);
            var yWeight = beforeWeight.getFullYear();
            var mWeight = beforeWeight.getMonth()+1;
            var dWeight = beforeWeight.getDate();
            if(mWeight<10) {
                mWeight="0"+mWeight;
            }
            if(dWeight<10) {
                dWeight="0"+dWeight;
            }
            //vm.jisshiDtBefore = new Date(s.substr(0,10)+"T"+s.substr(11,18));
            vm.jisshiDtBefore = getTargetday(-31,vm.formatJisshiDt);
            //jisshiDtSixteen = new Date(sixteen.substr(0,10)+"T"+sixteen.substr(11,18));
            jisshiDtSixteen = getTargetday(-16,vm.formatJisshiDt);
            //jisshiDtSeventeen = new Date(Date.parse(y + '/' + m + '/' + seventeenDay));
            jisshiDtSeventeen = getTargetday(-15,vm.formatJisshiDt);
            jisshiDtBeforeWeight = new Date(Date.parse(yWeight + '/' + mWeight + '/' + dWeight));
            Comment.commentlistShow({riyoushaId: vm.riyoushaId,jigyoushoId: vm.jigyoushoId,jisshiDt: $filter('date')(vm.jisshiDt, "yyyy-MM-dd"),jisshiDtBefore: $filter('date')(vm.jisshiDtBefore, "yyyy-MM-dd")}, function(result){
                vm.commentsShowList = result;
                vm.commentsList = [];
                vm.commentNew.commentContent = null;
                vm.commentsList.push(vm.commentNew);
            });
            getRiyoushaNameAndJigyoushoId(vm.riyoushaId);
            activeState(vm.riyoushaId,vm.jigyoushoId,vm.jisshiDt);
            activeState2(vm.riyoushaId,vm.jigyoushoId,vm.jisshiDt);
            temperature(vm.riyoushaId,vm.jigyoushoId,vm.jisshiDt,vm.jisshiDtBefore);
            pulse(vm.riyoushaId,vm.jigyoushoId,vm.jisshiDt,vm.jisshiDtBefore);
            activity(vm.riyoushaId,vm.jigyoushoId,vm.jisshiDt,vm.jisshiDtBefore);
            bloodPressure(vm.riyoushaId,vm.jigyoushoId,vm.jisshiDt,vm.jisshiDtBefore);
            getUp(vm.riyoushaId,vm.jigyoushoId,vm.jisshiDt,vm.jisshiDtBefore);
            sleep(vm.riyoushaId,vm.jigyoushoId,vm.jisshiDt,vm.jisshiDtBefore);
            spo2(vm.riyoushaId,vm.jigyoushoId,vm.jisshiDt,vm.jisshiDtBefore);
            //diet(vm.riyoushaId,vm.jigyoushoId,vm.jisshiDt,vm.jisshiDtBefore,1);
            diet(vm.riyoushaId,vm.jigyoushoId,jisshiDtSixteen,vm.jisshiDtBefore,2);
            diet(vm.riyoushaId,vm.jigyoushoId,vm.jisshiDt,jisshiDtSeventeen,3);
            weightweight(vm.riyoushaId,vm.jigyoushoId,vm.jisshiDt,vm.jisshiDtBefore);
            loadPhotos();
            loadFiles();
        }
        function getRiyoushaNameAndJigyoushoId(riyoushaId){
            Analyze.getRiyoushaNameAndJigyoushoName({riyoushaId: riyoushaId},function(result){
                vm.riyoushaName = result["RIYOUSHANAME"];
                vm.jigyoushoName= result["JIGYOUSHONAME"];
            });
        }
        function activeState2(riyoushaId,jigyoushoId,jisshiDt){
            if(jisshiDt != 0){
                jisshiDt = $filter('date')(jisshiDt, "yyyy-MM-dd").substring(0,10);
            }
            vm.thirteenYList = [];
            vm.fourteenYList = [];
            vm.fifteenYList = [];
            vm.sixteenYList = [];
            vm.seventeenYList = [];
            vm.eighteenYList = [];
            vm.nineteenYList = [];
            vm.twentyYList = [];
            vm.twentyOneYList = [];
            vm.twentyTwoYList = [];
            vm.contentActiveState2 = "実施日,提供時間,右側端,左側端,頭側端,足側端,呼び出し,応答,心拍高,心拍低,呼吸高,呼吸低" + "\n";
            Analyze.getActiveState({riyoushaId: riyoushaId,jigyoushoId:jigyoushoId,jisshiDt: jisshiDt},
                function(result){
                    //循环24小时
                    for(var i=0;i<result.length;i++){
                        var thirteen = "";
                        var fourteen = "";
                        var fifteen = "";
                        var sixteen = "";
                        var seventeen = "";
                        var eighteen = "";
                        var nineteen = "";
                        var twenty = "";
                        var twentyOne = "";
                        var twentyTwo = "";
                        var D13 = 0;
                        var D14 = 0;
                        var D15 = 0;
                        var D16 = 0;
                        var D17 = 0;
                        var D18 = 0;
                        var D19 = 0;
                        var D20 = 0;
                        var D21 = 0;
                        var D22 = 0;
                        if(result[i].bikou == undefined){
                            vm.thirteenYList.push(0);
                            vm.fourteenYList.push(0);
                            vm.fifteenYList.push(0);
                            vm.sixteenYList.push(0);
                            vm.seventeenYList.push(0);
                            vm.eighteenYList.push(0);
                            vm.nineteenYList.push(0);
                            vm.twentyYList.push(0);
                            vm.twentyOneYList.push(0);
                            vm.twentyTwoYList.push(0);
                        }else{
                            var tmType = result[i].bikou.split(",");
                            //循环同一个小时有多条数据
                            for(var a=0;a<tmType.length;a++){
                                var type = tmType[a].split("回");
                                if(type==''){
                                    vm.thirteenYList.push(0);
                                    vm.fourteenYList.push(0);
                                    vm.fifteenYList.push(0);
                                    vm.sixteenYList.push(0);
                                    vm.seventeenYList.push(0);
                                    vm.eighteenYList.push(0);
                                    vm.nineteenYList.push(0);
                                    vm.twentyYList.push(0);
                                    vm.twentyOneYList.push(0);
                                    vm.twentyTwoYList.push(0);
                                }else{
                                    //循环备考里每一项的值
                                    for(var x=0;x<type.length;x++){
                                        //D13右側端
                                        if(type[x].indexOf("D13") >= 0){
                                            D13 = parseInt(D13) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if(type[x].indexOf("D14") >= 0){
                                            //D14左側端
                                            D14 = parseInt(D14) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));//0+3
                                        } else if(type[x].indexOf("D15") >= 0){
                                            //D15頭側端
                                            D15 = parseInt(D15) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if(type[x].indexOf("D16") >= 0){
                                            //D16足側端
                                            D16 = parseInt(D16) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if(type[x].indexOf("D17") >= 0){
                                            //D17呼び出し
                                            D17 = parseInt(D17) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if(type[x].indexOf("D18") >= 0){
                                            //D18応答
                                            D18 = parseInt(D18) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if(type[x].indexOf("D19") >= 0){
                                            //D19心拍高
                                            D19 = parseInt(D19) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if (type[x].indexOf("D20") >= 0){
                                            //D20心拍低
                                            D20 = parseInt(D20) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if (type[x].indexOf("D21") >= 0){
                                            //D21呼吸高
                                            D21 = parseInt(D21) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if (type[x].indexOf("D22") >= 0){
                                            //D22呼吸低
                                            D22 = parseInt(D22) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        }
                                    }
                                }
                            }
                            vm.thirteenYList.push(D13);
                            thirteen = D13;
                            vm.fourteenYList.push(D14);
                            fourteen = D14;
                            vm.fifteenYList.push(D15);
                            fifteen = D15;
                            vm.sixteenYList.push(D16);
                            sixteen = D16;
                            vm.seventeenYList.push(D17);
                            seventeen = D17;
                            vm.eighteenYList.push(D18);
                            eighteen = D18;
                            vm.nineteenYList.push(D19);
                            nineteen = D19;
                            vm.twentyYList.push(D20);
                            twenty = D20;
                            vm.twentyOneYList.push(D21);
                            twentyOne = D21;
                            vm.twentyTwoYList.push(D22);
                            twentyTwo = D22;
                        }
                        var tm = "";
                        var bk = "";
                        if(result[i].kansatsuTm == undefined){
                            tm = "";
                        }else {
                            tm = result[i].kansatsuTm;
                        }
                        if(result[i].bikou == undefined){
                            bk = "";
                        }else{
                            bk = result[i].bikou;
                        }
                        vm.contentActiveState2 += jisshiDt + ',' + tm + "," + thirteen + ","+ fourteen + ","+ fifteen + ","+ sixteen + ","+ seventeen + ","+ eighteen + ","+ nineteen + ","+ twenty + ","+ twentyOne + ","+ twentyTwo + ","+ "\n";
                    }
                    vm.activeStateFlag2 = true;
                },function () {
                    vm.activeStateFlag2 = true;
                });
        }
        function activeState(riyoushaId,jigyoushoId,jisshiDt){
            if(jisshiDt != 0){
                jisshiDt = $filter('date')(jisshiDt, "yyyy-MM-dd").substring(0,10);
            }
            vm.okiagariYList = [];
            vm.tanzaiYList = [];
            vm.rishouYList = [];
            vm.sakugoeYList = [];
            vm.ziriochiYList = [];
            vm.nyuusituYList = [];
            vm.taishitsuYList = [];
            vm.tenYList = [];
            vm.elevenYList = [];
            vm.twelveYList = [];
            vm.contentActiveState = "実施日,提供時間,起上り,端座位,離床,柵越え,ずり落ち,入室,退室,動き出し,入床,体動" + "\n";
            Analyze.getActiveState({riyoushaId: riyoushaId,jigyoushoId:jigyoushoId,jisshiDt: jisshiDt},
                function(result){
                    for(var i=0;i<result.length;i++){
                        var one = "";
                        var two = "";
                        var three = "";
                        var four = "";
                        var five = "";
                        var six = "";
                        var seven = "";
                        var ten = "";
                        var eleven = "";
                        var twelve = "";
                        var D01 = 0;
                        var D02 = 0;
                        var D03 = 0;
                        var D04 = 0;
                        var D05 = 0;
                        var D06 = 0;
                        var D07 = 0;
                        var D10 = 0;
                        var D11 = 0;
                        var D12 = 0;

                        if(result[i].bikou == undefined){
                            vm.okiagariYList.push(0);
                            vm.tanzaiYList.push(0);
                            vm.rishouYList.push(0);
                            vm.sakugoeYList.push(0);
                            vm.ziriochiYList.push(0);
                            vm.nyuusituYList.push(0);
                            vm.taishitsuYList.push(0);
                            vm.tenYList.push(0);
                            vm.elevenYList.push(0);
                            vm.twelveYList.push(0);
                        }else{
                            var tmType = result[i].bikou.split(",");
                            for(var a=0;a<tmType.length;a++){
                                var type = tmType[a].split("回");
                                if(type==''){
                                    vm.okiagariYList.push(0);
                                    vm.tanzaiYList.push(0);
                                    vm.rishouYList.push(0);
                                    vm.sakugoeYList.push(0);
                                    vm.ziriochiYList.push(0);
                                    vm.nyuusituYList.push(0);
                                    vm.taishitsuYList.push(0);
                                    vm.tenYList.push(0);
                                    vm.elevenYList.push(0);
                                    vm.twelveYList.push(0);
                                }else{
                                    for(var x=0;x<type.length;x++){
                                        //D01起上り
                                        if(type[x].indexOf("D01") >= 0){
                                            D01 = parseInt(D01) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if(type[x].indexOf("D02") >= 0){
                                            //D02端座位
                                            D02 = parseInt(D02) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if(type[x].indexOf("D03") >= 0){
                                            //D03離床
                                            D03 = parseInt(D03) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if(type[x].indexOf("D04") >= 0){
                                            //D04柵越え
                                            D04 = parseInt(D04) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if(type[x].indexOf("D05") >= 0){
                                            //D05ずり落ち
                                            D05 = parseInt(D05) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if(type[x].indexOf("D06") >= 0){
                                            //D06入室
                                            D06 = parseInt(D06) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if(type[x].indexOf("D07") >= 0){
                                            //D07退室
                                            D07 = parseInt(D07) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if (type[x].indexOf("D10") >= 0){
                                            //D10動き出し
                                            D10 = parseInt(D10) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if (type[x].indexOf("D11") >= 0){
                                            //D11入床
                                            D11 = parseInt(D11) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        } else if (type[x].indexOf("D12") >= 0){
                                            //D12体動
                                            D12 = parseInt(D12) + parseInt(type[x].substring(type[x].indexOf('<')+1,type[x].indexOf('>')));
                                        }
                                    }
                                }
                            }
                            vm.okiagariYList.push(D01);
                            one = D01;
                            vm.tanzaiYList.push(D02);
                            two = D02;
                            vm.rishouYList.push(D03);
                            three = D03;
                            vm.sakugoeYList.push(D04);
                            four = D04;
                            vm.ziriochiYList.push(D05);
                            five = D05;
                            vm.nyuusituYList.push(D06);
                            six = D06;
                            vm.taishitsuYList.push(D07);
                            seven = D07;
                            vm.tenYList.push(D10);
                            ten = D10;
                            vm.elevenYList.push(D11);
                            eleven = D11;
                            vm.twelveYList.push(D12);
                            twelve = D12;
                        }
                        var tm = "";
                        var bk = "";
                        if(result[i].kansatsuTm == undefined){
                            tm = "";
                        }else {
                            tm = result[i].kansatsuTm;
                        }
                        if(result[i].bikou == undefined){
                            bk = "";
                        }else{
                            bk = result[i].bikou;
                        }
                        vm.contentActiveState += jisshiDt + ',' + tm + "," + one + ","+ two + ","+ three + ","+ four + ","+ five + ","+ six + ","+ seven + ","+ ten + ","+ eleven + ","+ twelve + ","+ "\n";
                    }
                    vm.activeStateFlag = true;
                },function () {
                    vm.activeStateFlag = true;
                });
        }
        function temperature(riyoushaId,jigyoushoId,jisshiDt,jisshiDtBefore){
            if(jisshiDt != 0){
                jisshiDt = $filter('date')(jisshiDt, "yyyy-MM-dd");
            }

            Analyze.getTemperature({riyoushaId: riyoushaId,jigyoushoId:jigyoushoId,jisshiDt: jisshiDt,jisshiDtBefore: $filter('date')(jisshiDtBefore, "yyyy-MM-dd")}, function(result){
                vm.temperatureXList.length = 0;
                vm.temperatureYList.length = 0;
                vm.contentTemperature = "実施日,体温" + "\n";
                for(var r in result){
                    if(r <= 31){
                        vm.temperatureXList.push(result[r].jisshiDt);
                        vm.temperatureYList.push(result[r].taion);
                        if(result[r].taion == null){
                            result[r].taion = "";
                        }
                        vm.contentTemperature += result[r].jisshiDt + "," + result[r].taion + "\n";
                    }
                }
            });
        }
        function pulse(riyoushaId,jigyoushoId,jisshiDt,jisshiDtBefore){
            if(jisshiDt != 0){
                jisshiDt = $filter('date')(jisshiDt, "yyyy-MM-dd");
            }
            Analyze.getPulse({riyoushaId: riyoushaId,jigyoushoId:jigyoushoId,jisshiDt: jisshiDt,jisshiDtBefore: $filter('date')(jisshiDtBefore, "yyyy-MM-dd")}, function(result){
                vm.pulseXList.length = 0;
                vm.pulseYList.length = 0;
                vm.contentPulse = "実施日,脈拍" + "\n";
                for(var r in result){
                    if(r <= 31){
                        vm.pulseXList.push(result[r].jisshiDt);
                        vm.pulseYList.push(result[r].myakuhaku);
                        if(result[r].myakuhaku == null){
                            result[r].myakuhaku = "";
                        }
                        vm.contentPulse += result[r].jisshiDt + "," + result[r].myakuhaku + "\n";
                    }
                }
            });
        }
        function activity(riyoushaId,jigyoushoId,jisshiDt,jisshiDtBefore){
            if(jisshiDt != 0){
                jisshiDt = $filter('date')(jisshiDt, "yyyy-MM-dd");
            }
            Analyze.getActivity({riyoushaId: riyoushaId,jigyoushoId:jigyoushoId,jisshiDt: jisshiDt,jisshiDtBefore: $filter('date')(jisshiDtBefore, "yyyy-MM-dd")}, function(result){
                vm.activityXList.length = 0;
                vm.activityNumberYList.length = 0;
                vm.activityActiveYList.length = 0;
                vm.activityExYList.length = 0;
                vm.contentActivity = "実施日,歩数,アクティブ歩数,Ex" + "\n";
                for(var r in result){
                    if(r <= 31){
                        vm.activityXList.push(result[r].jisshiDt);
                        vm.activityNumberYList.push(result[r].activityNumber);
                        vm.activityActiveYList.push(result[r].active);
                        vm.activityExYList.push(result[r].activityEx);
                        if(result[r].activityNumber == null){
                            result[r].activityNumber = "";
                        }
                        if(result[r].active == null){
                            result[r].active = "";
                        }
                        if(result[r].activityEx == null){
                            result[r].activityEx = "";
                        }
                        vm.contentActivity += result[r].jisshiDt + "," + result[r].activityNumber + "," + result[r].active + "," + result[r].activityEx +"\n";
                    }
                }
            });
        }
        function bloodPressure(riyoushaId,jigyoushoId,jisshiDt,jisshiDtBefore){
            if(jisshiDt != 0){
                jisshiDt = $filter('date')(jisshiDt, "yyyy-MM-dd");
            }
            Analyze.getBloodPressure({riyoushaId: riyoushaId,jigyoushoId:jigyoushoId,jisshiDt: jisshiDt,jisshiDtBefore: $filter('date')(jisshiDtBefore, "yyyy-MM-dd")}, function(result){
                vm.bloodPressureXList.length = 0;
                vm.bloodPressureUYList.length = 0;
                vm.bloodPressureLYList.length = 0;
                vm.contentBloodPressure = "実施日,血圧【上】,血圧【下】" + "\n";
                for(var r in result){
                    if(r <= 31){
                        vm.bloodPressureXList.push(result[r].jisshiDt);
                        vm.bloodPressureUYList.push(result[r].ketsuatsuUe);
                        vm.bloodPressureLYList.push(result[r].ketsuatsuShita);
                        if(result[r].ketsuatsuUe == null){
                            result[r].ketsuatsuUe = "";
                        }
                        if(result[r].ketsuatsuShita == null){
                            result[r].ketsuatsuShita = "";
                        }
                        vm.contentBloodPressure += result[r].jisshiDt + "," + result[r].ketsuatsuUe + "," + result[r].ketsuatsuShita + "\n";
                    }
                }
            });
        }
        function getUp(riyoushaId,jigyoushoId,jisshiDt,jisshiDtBefore){
            if(jisshiDt != 0){
                jisshiDt = $filter('date')(jisshiDt, "yyyy-MM-dd");
            }
            Analyze.getGetUp({riyoushaId: riyoushaId,jigyoushoId:jigyoushoId,jisshiDt: jisshiDt,jisshiDtBefore: $filter('date')(jisshiDtBefore, "yyyy-MM-dd"),type:1}, function(result){
                vm.getUpXList.length = 0;
                vm.getUpYList.length = 0;
                vm.contentGetUp = "実施日,起床時間" + "\n";
                for(var r in result){
                    if(r <= 31){
                        vm.getUpXList.push(result[r].jisshiDt);
                        vm.getUpYList.push(result[r].startTime);
                        if(result[r].startTime == null){
                            result[r].startTime = "";
                        }
                        vm.contentGetUp += result[r].jisshiDt + "," + result[r].startTime + "\n";
                    }
                }
            });
        }
        function showWords() {
            vm.showFlag = true;
        }
        function hidenWords(){
            vm.showFlag = false;
        }
        function base64Img2Blob(code){
            var parts = code.split(';base64,');
            var contentType = parts[0].split(':')[1];
            var raw = window.atob(parts[1]);
            var rawLength = raw.length;
            var uInt8Array = new Uint8Array(rawLength);
            for (var i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }
            return new Blob([uInt8Array], {type: contentType});
        }
        function downloadFile(fileName, content){
            var aLink = document.createElement('a');
            var blob = base64Img2Blob(content); //new Blob([content]);
            var evt = document.createEvent("HTMLEvents");
            evt.initEvent("click", false, false);//initEvent 不加后两个参数在FF下会报错
            aLink.download = fileName;
            aLink.href = URL.createObjectURL(blob);
            aLink.dispatchEvent(evt);
            aLink.click();
        }
        function sleep(riyoushaId,jigyoushoId,jisshiDt,jisshiDtBefore){
            if(jisshiDt != 0){
                jisshiDt = $filter('date')(jisshiDt, "yyyy-MM-dd");
            }
            Analyze.getSleep({riyoushaId: riyoushaId,jigyoushoId:jigyoushoId,jisshiDt: jisshiDt,jisshiDtBefore: $filter('date')(jisshiDtBefore, "yyyy-MM-dd"),type:2}, function(result){
                vm.sleepXList.length = 0;
                vm.sleepYList.length = 0;
                vm.contentSleep = "実施日,就寝時間" + "\n";
                for(var r in result){
                    if(r <= 31){
                        vm.sleepXList.push(result[r].jisshiDt);
                        vm.sleepYList.push(result[r].startTime);
                        if(result[r].startTime == null){
                            result[r].startTime = "";
                        }
                        vm.contentSleep += result[r].jisshiDt + "," + result[r].startTime + "\n";
                    }
                }
            });
        }
        function spo2(riyoushaId,jigyoushoId,jisshiDt,jisshiDtBefore){
            if(jisshiDt != 0){
                jisshiDt = $filter('date')(jisshiDt, "yyyy-MM-dd");
            }
            Analyze.getTemperature({riyoushaId: riyoushaId,jigyoushoId:jigyoushoId,jisshiDt: jisshiDt,jisshiDtBefore: $filter('date')(jisshiDtBefore, "yyyy-MM-dd"),type:2}, function(result){
                vm.spo2XList.length = 0;
                vm.spo2YList.length = 0;
                vm.spo2TemperatureList.length = 0;
                vm.contentSpo2 = "実施日,SPO2,体温" + "\n";
                for(var r in result){
                    if(r <= 31){
                        vm.spo2XList.push(result[r].jisshiDt);
                        vm.spo2YList.push(result[r].spo);
                        vm.spo2TemperatureList.push(result[r].taion);
                        if(result[r].spo == null){
                            result[r].spo = "";
                        }
                        if(result[r].taion == null){
                            result[r].taion = "";
                        }
                        vm.contentSpo2 += result[r].jisshiDt + "," + result[r].spo + "," + result[r].taion + "\n";
                    }
                }
            });
        }
        function weightweight(riyoushaId,jigyoushoId,jisshiDt,jisshiDtBefore){
            if(jisshiDt != 0){
                jisshiDt = $filter('date')(jisshiDt, "yyyy-MM-dd");
            }
            Analyze.getWeight({riyoushaId: riyoushaId,jigyoushoId:jigyoushoId,jisshiDt: jisshiDt,jisshiDtBefore: $filter('date')(jisshiDtBeforeWeight, "yyyy-MM-dd"),type:2}, function(result){
                vm.weightXList.length = 0;
                vm.weightYList.length = 0;
                vm.contentWeight = "実施日,体重" + "\n";
                for(var r in result){
                    if(r <= 31){
                        vm.weightXList.push(result[r].jisshiDt);
                        vm.weightYList.push(result[r].taijuu);
                        if(result[r].taijuu == null){
                            result[r].taijuu = "";
                        }
                        vm.contentWeight += result[r].jisshiDt + "," + result[r].taijuu + "\n";
                    }
                }
                redashResize();
                //document.getElementById("scrollDiv").scrollLeft=document.getElementById("scrollDiv").scrollWidth;
                document.getElementById("scrollDiv1").scrollLeft=document.getElementById("scrollDiv1").scrollWidth;
                document.getElementById("scrollDiv2").scrollLeft=document.getElementById("scrollDiv2").scrollWidth;
            });
        }
        function diet(riyoushaId,jigyoushoId,jisshiDt,jisshiDtBefore,type){
            Analyze.getDiet({riyoushaId: riyoushaId,jigyoushoId:jigyoushoId,jisshiDt: $filter('date')(jisshiDt, "yyyy-MM-dd"),jisshiDtBefore: $filter('date')(jisshiDtBefore, "yyyy-MM-dd")}, function(result){
                if(type == 1){
                    vm.dietList.length = 0;
                    vm.dietList = result;
                    vm.contentDiet = "実施日,食事,水分,排尿,排便,体重"+ "\n";
                    for(var r in result){
                        if(r < 31){
                            if(result[r].shushokuSesshuryo == null){
                                result[r].shushokuSesshuryo = "";
                            }
                            if(result[r].suibunSesshuryo == null){
                                result[r].suibunSesshuryo = "";
                            }
                            if(result[r].nyouCount == null){
                                result[r].nyouCount = "";
                            }
                            if(result[r].benCount == null){
                                result[r].benCount = "";
                            }
                            if(result[r].taijuu == null){
                                result[r].taijuu = "";
                            }
                            vm.contentDiet += result[r].jisshiDt + "," + result[r].shushokuSesshuryo + "," + result[r].suibunSesshuryo + "," + result[r].nyouCount + "," + result[r].benCount + "," + result[r].taijuu + "\n";
                        }
                    }
                }else if(type == 2){
                    vm.dietFrontList.length = 0;
                    vm.dietFrontList = result;
                }else if(type == 3){
                    vm.dietAfterList.length = 0;
                    vm.dietAfterList = result;
                    /*var count = 16-result.length;
                    if(vm.dietAfterList.length < 16){
                        for(var i=0;i<count;i++){
                            var dto ={};
                            vm.dietAfterList.push(dto);
                        }
                    }*/
                }

            });
        }
        function loadFiles(){
            Comment.getFileListPublic({riyoushaId: vm.riyoushaId,startDt: $filter('date')(vm.jisshiDtBefore, "yyyy-MM-dd HH:mm:ss"),
                endDt: $filter('date')(vm.jisshiDt, "yyyy-MM-dd HH:mm:ss"),groupId: vm.groupId,
                page: vm.page - 1, size: paginationConstants.itemsPerPage}, function(result, headers){
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.fileList = result;
                for(var i = 0; i < vm.fileList.length; i++){
                    getUserNameBuFile(i);
                }
            });
            Comment.user({}, function (result) {
                vm.userDto = result;
            });
        }

        function loadPhotos() {
            Comment.getPhotoListPublic({riyoushaId: vm.riyoushaId,startDt: $filter('date')(vm.jisshiDtBefore, "yyyy-MM-dd HH:mm:ss"),
                endDt: $filter('date')(vm.jisshiDt, "yyyy-MM-dd HH:mm:ss"),groupId: vm.groupId,
                page: vm.page - 1, size: paginationConstants.itemsPerPage}, function(result, headers){
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.photoList = result;
                for(var i = 0; i < vm.photoList.length; i++){
                    getUserName(i);
                }
            });
            Comment.user({}, function (result) {
                vm.userDto = result;
            });
        }
        function getUserName(i){
            User.get({login: vm.photoList[i].lastModifiedBy},function (r) {
                vm.photoList[i].lastModifiedBy = r.lastName + " " + r.firstName;
            });
        }
        function getUserNameBuFile(i){
            User.get({login: vm.fileList[i].lastModifiedBy},function (r) {
                vm.fileList[i].lastModifiedBy = r.lastName + " " + r.firstName;
            });
        }
        function downloadByDiet(){
            if(confirm("CSVファイルをダウンロードですか？")){
                diet(vm.riyoushaId,vm.jigyoushoId,vm.jisshiDt,vm.jisshiDtBefore);
                var downloadLink = document.getElementById('downloadByDiet');
                downloadLink.download = '食事/排泄/体重.csv';
                downloadLink.href = "data:text/csv;charset=utf-8,\ufeff" + vm.contentDiet;
            }
        }
        function show(id){
            var modalInstance = $uibModal.open({
                templateUrl : 'app/analyze/photo-show-dialog.html',
                controller : 'AnalyzePhotoShowDialogController',
                controllerAs: 'vm',
                size : 'lg',
                resolve : {
                    entity: ['Uploader', function(Uploader) {
                        return Uploader.get({id : id});
                    }]
                }
            });
        }

        function isMobile() {
            var mobile_flag;
            var is_iPd = navigator.userAgent.match(/(iPad|iPod)/i) != null;
            var is_mobi = navigator.userAgent.toLowerCase().match(/(ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|win ce)/i) != null;
            if(is_iPd){
                mobile_flag = 0;
            }
            if(is_mobi){
                mobile_flag = 1;
            }
            return mobile_flag;
        }
        //重新绘制图表
        function redashResize(){
            angular.element(document).ready(function () {
                getData();
            });
        }
        //写真切换到图表
        function clickTab(){
            redashResize();
        }
        function getData(){
            <!--体温-->
            var temperatureChart = echarts.init(document.getElementById('temperature'));
            var temperatureOption = {
                title: {
                    text: '体温'
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        magicType : {
                            show: true,
                            type: ['line', 'bar'],
                            title: {line:"切替", bar:"切替"}
                        },
                        dataZoom: {
                            show: true,
                            yAxisIndex: 'none',
                            title:{zoom:"部分拡大",back:"戻す"}
                        },
                        restore : {show: true,title:"リセット"}
                    },
                    x: vm.xLength,
                    itemSize:"12",
                    y: vm.yLength
                },
                tooltip : {
                    trigger: 'axis'
                },
                legend: {
                    data:['体温（℃）']
                },
                xAxis: {
                    type : 'category',
                    boundaryGap : false,
                    axisLabel:{interval:vm.number,rotate:90},
                    data : vm.temperatureXList
                },
                yAxis: {
                    name: '℃',
                    type: 'value',
                    axisLabel:{interval:0},
                    min: '30',
                    max: '45'
                },
                visualMap: [{
                    type: 'piecewise',
                    top: 'center',
                    show: false,
                    seriesIndex: 0,
                    pieces: [
                        { max: 45, min: 0, color: 'blue'}
                    ]
                }],
                series: [{
                    name: '体温（℃）',
                    type: 'line',
                    data: vm.temperatureYList,
                    markPoint : {
                        data : [
                            {type : 'max', name: '最大値'},
                            {type : 'min', name: '最小値'}
                        ]
                    },
                    markLine : {
                        data : [
                            {type : 'average', name: '平均値'}
                        ]
                    }
                }]
            };
            temperatureChart.setOption(temperatureOption);
            <!--脈拍-->
            var pulseChart = echarts.init(document.getElementById('pulse'));
            var pulseOption = {
                title: {
                    text: '脈拍'
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        magicType : {
                            show: true,
                            type: ['line', 'bar'],
                            title: {line:"切替", bar:"切替"}
                        },
                        dataZoom: {
                            show: true,
                            yAxisIndex: 'none',
                            title:{zoom:"部分拡大",back:"戻す"}
                        },
                        restore : {show: true,title:"リセット"}
                    },
                    x: vm.xLength,
                    itemSize:"12",
                    y: vm.yLength
                },
                tooltip : {
                    trigger: 'axis'
                },
                legend: {
                    data:['脈拍（回/分）']
                },
                xAxis: {
                    type : 'category',
                    boundaryGap : false,
                    axisLabel:{interval:vm.number,rotate:90},
                    data : vm.pulseXList
                },
                yAxis : {
                    name: '回/分',
                    type: 'value',
                    axisLabel:{interval:0},
                    min: '0',
                    max: '180'
                },
                visualMap: [{
                    type: 'piecewise',
                    top: 'center',
                    show: false,
                    seriesIndex: 0,
                    pieces: [
                        { max: 200, min: 0, color: 'blue'}
                    ]
                }],
                series: [{
                    name: '脈拍（回/分）',
                    type: 'line',
                    data: vm.pulseYList,
                    markPoint : {
                        data : [
                            {type : 'max', name: '最大値'},
                            {type : 'min', name: '最小値'}
                        ]
                    },
                    markLine : {
                        data : [
                            {type : 'average', name: '平均値'}
                        ]
                    }
                }]
            };
            pulseChart.setOption(pulseOption);
            <!--活動量-->
            var activityChart = echarts.init(document.getElementById('activity'));
            var activityOption = {
                color: ['#FF69B4','#7FFF00','#63B8FF'],
                title: {
                    text: '活動量'
                },
                tooltip : {
                    trigger: 'axis'
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        magicType : {
                            show: true,
                            type: ['line', 'bar'],
                            title: {line:"切替", bar:"切替"}
                        },
                        dataZoom: {
                            show: true,
                            yAxisIndex: 'none',
                            title:{zoom:"部分拡大",back:"戻す"}
                        },
                        restore : {show: true,title:"リセット"}
                    },
                    x: vm.xLength,
                    itemSize:"12",
                    y: vm.yLength
                },
                legend: {
                    data:['歩数','アクティブ歩数','Ex']
                },
                xAxis : [
                    {
                        type : 'category',
                        boundaryGap : false,
                        axisLabel:{interval:vm.number,rotate:90},
                        data : vm.activityXList
                    }
                ],
                yAxis : [
                    {
                        name: '歩',
                        type: 'value',
                        axisLabel:{interval:0},
                        min: '0',
                        max: '20000'
                    },
                    {
                        name: 'Ex',
                        type : 'value',
                        axisLabel:{interval:0},
                        min : '0',
                        max : '10'
                    }
                ],
                series : [{
                    name:'歩数',
                    type:'line',
                    data:vm.activityNumberYList,
                    markPoint : {
                        data : [
                            {type : 'max', name: '最大値'},
                            {type : 'min', name: '最小値'}
                        ]
                    },
                    markLine : {
                        data : [
                            {type : 'average', name: '平均値'}
                        ]
                    }
                },
                    {
                        name:'アクティブ歩数',
                        type:'line',
                        data:vm.activityActiveYList,
                        markPoint : {
                            data : [
                                {type : 'max', name: '最大値'},
                                {type : 'min', name: '最小値'}
                            ]
                        },
                        markLine : {
                            data : [
                                {type : 'average', name: '平均値'}
                            ]
                        }
                    },
                    {
                        name:'Ex',
                        type:'line',
                        yAxisIndex: 1,
                        data:vm.activityExYList,
                        markPoint : {
                            data : [
                                {type : 'max', name: '最大値'},
                                {type : 'min', name: '最小値'}
                            ]
                        },
                        markLine : {
                            data : [
                                {type : 'average', name: '平均値'}
                            ]
                        }
                    }]
            };
            activityChart.setOption(activityOption);
            <!--血圧-->
            var pressureChart = echarts.init(document.getElementById('pressure'));
            var pressureOption = {
                color: ["#B22222", "#B22222"],
                title: {
                    text: '血圧'
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        magicType : {
                            show: true,
                            type: ['line', 'bar'],
                            title: {line:"切替", bar:"切替"}
                        },
                        dataZoom: {
                            show: true,
                            yAxisIndex: 'none',
                            title:{zoom:"部分拡大",back:"戻す"}
                        },
                        restore : {show: true,title:"リセット"}
                    },
                    x: vm.xLength,
                    itemSize:"12",
                    y: vm.yLength
                },
                calculable : true,
                tooltip : {
                    trigger: 'axis'
                },
                legend: {
                    data:['血圧【上】','血圧【下】']
                },
                xAxis : [
                    {
                        type : 'category',
                        boundaryGap : false,
                        axisLabel:{interval:vm.number,rotate:90},
                        data : vm.bloodPressureXList
                    }
                ],
                yAxis : [
                    {
                        name: 'mmHg',
                        type : 'value',
                        axisLabel:{interval:0},
                        min : '0',
                        max : '230'
                    },
                    {
                        name: 'mmHg',
                        type : 'value',
                        axisLabel:{interval:0},
                        min : '0',
                        max : '230'
                    }
                ],
                visualMap: [{
                    type: 'piecewise',
                    top: 'center',
                    show: false,
                    seriesIndex: 0,
                    pieces: [
                        { max: 300, min: 0, color: 'blue'}
                    ]
                },{
                    type: 'piecewise',
                    top: 'center',
                    show: false,
                    seriesIndex: 1,
                    pieces: [
                        { max: 300, min: 0, color: 'blue'}
                    ]
                }],
                series : [
                    {
                        name:'血圧【上】',
                        type:'line',
                        data:vm.bloodPressureUYList,
                        markPoint : {
                            data : [
                                {type : 'max', name: '最大値'},
                                {type : 'min', name: '最小値'}
                            ]
                        },
                        markLine : {
                            data : [
                                {type : 'average', name: '平均値'}
                            ]
                        }
                    },
                    {
                        name:'血圧【下】',
                        type:'line',
                        symbol: 'emptyTriangle',
                        yAxisIndex: 1,
                        data:vm.bloodPressureLYList,
                        markPoint : {
                            data : [
                                {type : 'max', name: '最大値'},
                                {type : 'min', name: '最小値'}
                            ]
                        },
                        markLine : {
                            data : [
                                {type : 'average', name: '平均値'}
                            ]
                        }
                    }
                ]
            };
            pressureChart.setOption(pressureOption);
            <!--起床-->
            var getUpChart = echarts.init(document.getElementById('getUp'));
            var getUpOption = {
                title: {
                    text: '起床'
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        magicType : {
                            show: true,
                            type: ['line', 'bar'],
                            title: {line:"切替", bar:"切替"}
                        },
                        dataZoom: {
                            show: true,
                            yAxisIndex: 'none',
                            title:{zoom:"部分拡大",back:"戻す"}
                        },
                        restore : {show: true,title:"リセット"}
                    },
                    x: vm.xLength,
                    itemSize:"12",
                    y: vm.yLength
                },
                tooltip : {
                    trigger: 'axis'
                },
                legend: {
                    data:['起床']
                },
                xAxis: {
                    type : 'category',
                    boundaryGap : false,
                    axisLabel:{interval:vm.number,rotate:90},
                    data : vm.getUpXList
                },
                yAxis: {
                    name: '時間',
                    type: 'value',
                    axisLabel:{interval:0},
                    min: '0',
                    max: '24'
                },
                visualMap: [{
                    type: 'piecewise',
                    top: 'center',
                    show: false,
                    seriesIndex: 0,
                    pieces: [
                        { max: 24, min: 0, color: 'blue'}
                    ]
                }],
                series : {
                    name:'起床',
                    type:'line',
                    data: vm.getUpYList,
                    markPoint : {
                        data : [
                            {type : 'max', name: '最大値'},
                            {type : 'min', name: '最小値'}
                        ]
                    },
                    markLine : {
                        data : [
                            {type : 'average', name: '平均値'}
                        ]
                    }
                }
            };
            getUpChart.setOption(getUpOption);
            <!--就寝-->
            var sleepChart = echarts.init(document.getElementById('sleep'));
            var sleepOption = {
                title: {
                    text: '就寝'
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        magicType : {
                            show: true,
                            type: ['line', 'bar'],
                            title: {line:"切替", bar:"切替"}
                        },
                        dataZoom: {
                            show: true,
                            yAxisIndex: 'none',
                            title:{zoom:"部分拡大",back:"戻す"}
                        },
                        restore : {show: true,title:"リセット"}
                    },
                    x: vm.xLength,
                    itemSize:"12",
                    y: vm.yLength
                },
                tooltip : {
                    trigger: 'axis'
                },
                legend: {
                    data:['就寝']
                },
                calculable : true,
                xAxis : [
                    {
                        type : 'category',
                        boundaryGap : false,
                        axisLabel:{interval:vm.number,rotate:90},
                        data : vm.sleepXList
                    }
                ],
                yAxis : {
                    name: '時間',
                    type: 'value',
                    axisLabel:{interval:0},
                    min: '0',
                    max: '24'
                },
                visualMap: [{
                    type: 'piecewise',
                    top: 'center',
                    show: false,
                    seriesIndex: 0,
                    pieces: [
                        { max: 24, min: 0, color: 'blue'}
                    ]
                }],
                series : {
                    name:'就寝',
                    type:'line',
                    data: vm.sleepYList,
                    markPoint : {
                        data : [
                            {type : 'max', name: '最大値'},
                            {type : 'min', name: '最小値'}
                        ]
                    },
                    markLine : {
                        data : [
                            {type : 'average', name: '平均値'}
                        ]
                    }
                }
            };
            sleepChart.setOption(sleepOption);
            <!--SPO2-->
            var spo2Chart = echarts.init(document.getElementById('spo2'));
            var spo2Option = {
                color: ["#B22222", "#B22222"],
                title: {
                    text: 'SPO2'
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        magicType : {
                            show: true,
                            type: ['line', 'bar'],
                            title: {line:"切替", bar:"切替"}
                        },
                        dataZoom: {
                            show: true,
                            yAxisIndex: 'none',
                            title:{zoom:"部分拡大",back:"戻す"}
                        },
                        restore : {show: true,title:"リセット"}
                    },
                    x: vm.xLength,
                    itemSize:"12",
                    y: vm.yLength
                },
                calculable : true,
                tooltip : {
                    trigger: 'axis'
                },
                legend: {
                    data:['SPO2','体温']
                },
                xAxis : [
                    {
                        type : 'category',
                        boundaryGap : false,
                        axisLabel:{interval:vm.number,rotate:90},
                        data : vm.spo2XList
                    }
                ],
                yAxis : [
                    {
                        name: '%',
                        type : 'value',
                        axisLabel:{interval:0},
                        min : '0',
                        max : '150'
                    },
                    {
                        name: '℃',
                        type : 'value',
                        axisLabel:{interval:0},
                        min : '30',
                        max : '45'
                    }
                ],
                visualMap: [{
                    type: 'piecewise',
                    top: 'center',
                    show: false,
                    seriesIndex: 0,
                    pieces: [
                        { max: 300, min: 0, color: 'blue'}
                    ]
                },{
                    type: 'piecewise',
                    top: 'center',
                    show: false,
                    seriesIndex: 1,
                    pieces: [
                        { max: 300, min: 0, color: 'blue'}
                    ]
                }],
                series : [
                    {
                        name:'SPO2',
                        type:'line',
                        data:vm.spo2YList,
                        markPoint : {
                            data : [
                                {type : 'max', name: '最大値'},
                                {type : 'min', name: '最小値'}
                            ]
                        },
                        markLine : {
                            data : [
                                {type : 'average', name: '平均値'}
                            ]
                        }
                    },
                    {
                        name:'体温',
                        type:'line',
                        symbol: 'emptyTriangle',
                        yAxisIndex: 1,
                        data:vm.spo2TemperatureList,
                        markPoint : {
                            data : [
                                {type : 'max', name: '最大値'},
                                {type : 'min', name: '最小値'}
                            ]
                        },
                        markLine : {
                            data : [
                                {type : 'average', name: '平均値'}
                            ]
                        }
                    }
                ]
            };
            spo2Chart.setOption(spo2Option);
            <!--体重-->
            var weightChart = echarts.init(document.getElementById('weight'));
            var weightOption = {
                title: {
                    text: '体重'
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        magicType : {
                            show: true,
                            type: ['line', 'bar'],
                            title: {line:"切替", bar:"切替"}
                        },
                        dataZoom: {
                            show: true,
                            yAxisIndex: 'none',
                            title:{zoom:"部分拡大",back:"戻す"}
                        },
                        restore : {show: true,title:"リセット"}
                    },
                    x: vm.xLength,
                    itemSize:"12",
                    y: vm.yLength
                },
                tooltip : {
                    trigger: 'axis'
                },
                legend: {
                    data:['体重']
                },
                xAxis : [
                    {
                        type : 'category',
                        boundaryGap : false,
                        axisLabel:{interval:vm.number,rotate:90},
                        data : vm.weightXList
                    }
                ],
                yAxis : {
                    name: 'kg',
                    type: 'value',
                    axisLabel:{interval:0},
                    min: '0',
                    max: '150'
                },
                visualMap: [{
                    type: 'piecewise',
                    top: 'center',
                    show: false,
                    seriesIndex: 0,
                    pieces: [
                        { max: 200, min: 0, color: 'blue'}
                    ]
                }],
                series : {
                    name:'体重',
                    type:'line',
                    data: vm.weightYList,
                    markPoint : {
                        data : [
                            {type : 'max', name: '最大値'},
                            {type : 'min', name: '最小値'}
                        ]
                    },
                    markLine : {
                        data : [
                            {type : 'average', name: '平均値'}
                        ]
                    }
                }
            };
            weightChart.setOption(weightOption);
            <!--活動状態-->
            var activeStateChart = echarts.init(document.getElementById('activeState'));
            var activeStateOption = {
                color: ['#FF0000','#0000EE','#00FF00','#4B0082','#436EEE','#FF34B3','#CD919E','#8B2500','#848484','#B8860B'],
                title: {
                    text: '活動状態1'
                },
                grid: {
                    top:'17%'
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        magicType : {
                            show: true,
                            type: ['line', 'bar'],
                            title: {line:"切替", bar:"切替"}
                        },
                        dataZoom: {
                            show: true,
                            yAxisIndex: 'none',
                            title:{zoom:"部分拡大",back:"戻す"}
                        },
                        restore : {show: true,title:"リセット"},
                        /*myTool: {
                            show: true,
                            title: "ダウンロード",
                            icon: 'path://M502.010485 765.939573c3.773953 3.719718 8.686846 5.573949 13.596669 5.573949 0.075725 0 0.151449-0.010233 0.227174-0.011256 0.329505 0.016373 0.654916 0.050142 0.988514 0.050142 0.706081 0 1.400906-0.042979 2.087545-0.116657 4.352121-0.366344 8.607028-2.190899 11.961426-5.496178l335.053985-330.166675c7.619538-7.509021 7.709589-19.773346 0.200568-27.393907s-19.774369-7.711636-27.39493-0.201591L536.193005 706.304358 536.193005 50.019207c0-10.698666-8.67252-19.371186-19.371186-19.371186s-19.371186 8.67252-19.371186 19.371186l0 657.032164-306.881342-302.44838c-7.618515-7.509021-19.883863-7.419993-27.393907 0.199545-7.509021 7.619538-7.419993 19.884886 0.199545 27.393907L502.010485 765.939573z M867.170139 711.020776c-10.698666 0-19.371186 8.67252-19.371186 19.371186l0 165.419494c0 13.054317-10.620895 23.675212-23.676236 23.675212L205.182103 919.486668c-13.054317 0-23.676236-10.620895-23.676236-23.675212L181.505867 730.391962c0-10.698666-8.67252-19.371186-19.371186-19.371186s-19.371186 8.67252-19.371186 19.371186l0 165.419494c0 34.416857 28.000728 62.416562 62.417585 62.416562l618.941638 0c34.417881 0 62.417585-27.999704 62.417585-62.416562L886.540302 730.391962C886.541325 719.693296 877.868805 711.020776 867.170139 711.020776z',
                            onclick: function(){
                                var modalInstance = $uibModal.open({
                                    templateUrl : 'app/analyze/downloaddialog.html',
                                    controller : 'DownloaddialogController',
                                    controllerAs: 'vm',
                                    size : 'md',
                                    resolve : {
                                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                            $translatePartialLoader.addPart('riyousha-analyze');
                                            return $translate.refresh();
                                        }]
                                    }
                                });
                                modalInstance.result.then(function(result) {
                                    if (result == 'csv') {
                                        var userAgent = navigator.userAgent;
                                        if (!!window.ActiveXObject || "ActiveXObject" in window || userAgent.indexOf("Edge") > -1){
                                            csvDownload('活動状態1.csv',vm.contentActiveState);
                                        }else{
                                            var downloadLink = document.getElementById('downloadByActiveState');
                                            downloadLink.download = '活動状態1.csv';
                                            var str =  encodeURIComponent(vm.contentActiveState);
                                            downloadLink.href = "data:text/csv;charset=utf-8,\ufeff"+str;
                                            downloadLink.click();
                                        }
                                    }
                                    if(result == 'image'){
                                        var canvas = document.getElementById("activeState").getElementsByTagName("canvas")[0];
                                        downloadFile('活動状態1.png', canvas.toDataURL("image/png"));
                                    }
                                },function(){
                                    MessageService.error("global.error.internalServerError");
                                })
                            }
                        }*/
                    },
                    x: vm.xLength,
                    itemSize:"12",
                    y: vm.yLength
                },
                tooltip : {
                    trigger: 'axis'
                },
                legend: [
                    {data:['起上り','端座位','離床　　','柵越え','ずり落ち']},
                    {
                        top: '20',
                        data:['入室　','退室　','動き出し','入床　','体動　　']
                    }
                ],
                xAxis: {
                    type : 'category',
                    axisLabel:{interval:vm.number,rotate:90},
                    data : ['0時','1時','2時','3時','4時','5時','6時','7時','8時','9時','10時','11時','12時','13時','14時','15時','16時','17時','18時','19時','20時','21時','22時','23時']
                },
                yAxis: {
                    name: '回',
                    type: 'value',
                    axisLabel:{interval:0},
                    min: '0',
                    max: '120'
                },
                series: [
                    {
                        name: '起上り',
                        type: 'bar',barWidth:15,
                        stack: '活動状態',
                        data: vm.okiagariYList
                    },
                    {
                        name: '端座位',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.tanzaiYList
                    },
                    {
                        name: '離床　　',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.rishouYList
                    },
                    {
                        name: '柵越え',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.sakugoeYList
                    },
                    {
                        name: 'ずり落ち',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.ziriochiYList
                    },
                    {
                        name: '入室　',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.nyuusituYList
                    },
                    {
                        name: '退室　',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.taishitsuYList
                    },
                    {
                        name: '動き出し',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.tenYList
                    },
                    {
                        name: '入床　',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.elevenYList
                    },
                    {
                        name: '体動　　',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.twelveYList
                    }
                ]
            }
            activeStateChart.setOption(activeStateOption);
            <!--活動状態2-->
            var activeStateChart2 = echarts.init(document.getElementById('activeState2'));
            var activeStateOption2 = {
                color: ['#FF0000','#0000EE','#00FF00','#4B0082','#436EEE','#FF34B3','#CD919E','#8B2500','#848484','#B8860B'],
                title: {
                    text: '活動状態2'
                },
                grid: {
                    top:'17%'
                },
                toolbox: {
                    show : true,
                    feature : {
                        mark : {show: true},
                        magicType : {
                            show: true,
                            type: ['line', 'bar'],
                            title: {line:"切替", bar:"切替"}
                        },
                        dataZoom: {
                            show: true,
                            yAxisIndex: 'none',
                            title:{zoom:"部分拡大",back:"戻す"}
                        },
                        restore : {show: true,title:"リセット"},
                        /*myTool: {
                            show: true,
                            title: "ダウンロード",
                            icon: 'path://M502.010485 765.939573c3.773953 3.719718 8.686846 5.573949 13.596669 5.573949 0.075725 0 0.151449-0.010233 0.227174-0.011256 0.329505 0.016373 0.654916 0.050142 0.988514 0.050142 0.706081 0 1.400906-0.042979 2.087545-0.116657 4.352121-0.366344 8.607028-2.190899 11.961426-5.496178l335.053985-330.166675c7.619538-7.509021 7.709589-19.773346 0.200568-27.393907s-19.774369-7.711636-27.39493-0.201591L536.193005 706.304358 536.193005 50.019207c0-10.698666-8.67252-19.371186-19.371186-19.371186s-19.371186 8.67252-19.371186 19.371186l0 657.032164-306.881342-302.44838c-7.618515-7.509021-19.883863-7.419993-27.393907 0.199545-7.509021 7.619538-7.419993 19.884886 0.199545 27.393907L502.010485 765.939573z M867.170139 711.020776c-10.698666 0-19.371186 8.67252-19.371186 19.371186l0 165.419494c0 13.054317-10.620895 23.675212-23.676236 23.675212L205.182103 919.486668c-13.054317 0-23.676236-10.620895-23.676236-23.675212L181.505867 730.391962c0-10.698666-8.67252-19.371186-19.371186-19.371186s-19.371186 8.67252-19.371186 19.371186l0 165.419494c0 34.416857 28.000728 62.416562 62.417585 62.416562l618.941638 0c34.417881 0 62.417585-27.999704 62.417585-62.416562L886.540302 730.391962C886.541325 719.693296 877.868805 711.020776 867.170139 711.020776z',
                            onclick: function(){
                                var modalInstance = $uibModal.open({
                                    templateUrl : 'app/analyze/downloaddialog.html',
                                    controller : 'DownloaddialogController',
                                    controllerAs: 'vm',
                                    size : 'md',
                                    resolve : {
                                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                            $translatePartialLoader.addPart('riyousha-analyze');
                                            return $translate.refresh();
                                        }]
                                    }
                                });
                                modalInstance.result.then(function(result) {
                                    if (result == 'csv') {
                                        var userAgent = navigator.userAgent;
                                        if (!!window.ActiveXObject || "ActiveXObject" in window || userAgent.indexOf("Edge") > -1){
                                            csvDownload('活動状態2.csv',vm.contentActiveState2);
                                        }else{
                                            var downloadLink = document.getElementById('downloadByActiveState2');
                                            downloadLink.download = '活動状態2.csv';
                                            var str =  encodeURIComponent(vm.contentActiveState2);
                                            downloadLink.href = "data:text/csv;charset=utf-8,\ufeff"+str;
                                            downloadLink.click();
                                        }
                                    }
                                    if(result == 'image'){
                                        var canvas = document.getElementById("activeState2").getElementsByTagName("canvas")[0];
                                        downloadFile('活動状態2.png', canvas.toDataURL("image/png"));
                                    }
                                },function(){
                                    MessageService.error("global.error.internalServerError");
                                })
                            }
                        }*/
                    },
                    x: vm.xLength,
                    itemSize:"12",
                    y: vm.yLength
                },
                tooltip : {
                    trigger: 'axis'
                },
                legend: [
                    {data:['右側端','左側端','頭側端','足側端','呼び出し']},
                    {
                        top: '20',
                        data:['応答　','心拍高','心拍低','呼吸高','呼吸低　']
                    }
                ],
                xAxis: {
                    type : 'category',
                    axisLabel:{interval:vm.number,rotate:90},
                    data : ['0時','1時','2時','3時','4時','5時','6時','7時','8時','9時','10時','11時','12時','13時','14時','15時','16時','17時','18時','19時','20時','21時','22時','23時']
                },
                yAxis: {
                    name: '回',
                    type: 'value',
                    axisLabel:{interval:0},
                    min: '0',
                    max: '120'
                },
                series: [
                    {
                        name: '右側端',
                        type: 'bar',barWidth:15,
                        stack: '活動状態',
                        data: vm.thirteenYList
                    },
                    {
                        name: '左側端',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.fourteenYList
                    },
                    {
                        name: '頭側端',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.fifteenYList
                    },
                    {
                        name: '足側端',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.sixteenYList
                    },
                    {
                        name: '呼び出し',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.seventeenYList
                    },
                    {
                        name: '応答　',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.eighteenYList
                    },
                    {
                        name: '心拍高',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.nineteenYList
                    },
                    {
                        name: '心拍低',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.twentyYList
                    },
                    {
                        name: '呼吸高',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.twentyOneYList
                    },
                    {
                        name: '呼吸低　',
                        type: 'bar',
                        stack: '活動状態',
                        data: vm.twentyTwoYList
                    }
                ]
            }
            activeStateChart2.setOption(activeStateOption2);

            window.onresize = function (){
                temperatureChart.resize();
                pulseChart.resize();
                activityChart.resize();
                pressureChart.resize();
                getUpChart.resize();
                sleepChart.resize();
                spo2Chart.resize();
                weightChart.resize();
                activeStateChart.resize();
                activeStateChart2.resize();
            };
        }
        //获取选择日期的前多少天
        function getTargetday(num,date){
            var number=null;
            if(num==null||num==0){
                number=0;
            }else{
                number=num;
            }
            var targetday_milliseconds= date.getTime()+1000*60*60*24*number;
            var targetday=new  Date();
            targetday.setTime(targetday_milliseconds);
            var y = targetday.getFullYear();
            var m = targetday.getMonth()+1;
            var d = targetday.getDate();
            targetday = new Date(Date.parse(y + '/' + m + '/' + d));
            return targetday;
        }

        //文件下载
        function download(id){
            var aLink = document.createElement('a');
            aLink.href = "/library/api/library/downloadfile/" + id;
            document.body.appendChild(aLink);
            aLink.click();
            document.body.removeChild(aLink);
        }
    }
})();
