(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('NoLicenseController', NoLicenseController);

    NoLicenseController.$inject = ['$translate', 'LicenseService'];

    function NoLicenseController($translate, LicenseService) {
        var vm = this;
        //$translate.instant();
        // 主页地址
        vm.caretreeLink = "";
        // 获取主页地址
        LicenseService.caretreeLink(function(result){
            vm.caretreeLink = result.homePage;
        });

    }
})();
