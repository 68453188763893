(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('GroupManagementDialogController',GroupManagementDialogController);

    GroupManagementDialogController.$inject = ['$uibModalInstance', 'entity', 'Group', 'MessageService','Jigyousho', '$timeout', '$rootScope', 'Principal'];

    function GroupManagementDialogController ($uibModalInstance, entity, Group, MessageService, Jigyousho, $timeout, $rootScope, Principal) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.loadAllServiceTypes = loadAllServiceTypes;//新規登録する時に該当スタッフが利用できるサービス一覧を取得
        vm.serviceTypeChanged = serviceTypeChanged;
        vm.loadParentGroup = loadParentGroup;
        vm.group = null;
        vm.parentGroup = [];
        vm.entity = entity;
        vm.allGroupFlags = [];
        vm.groupNameExist = true;

        vm.loadGroup = loadGroup();
        vm.groupNameCheck = groupNameCheck;
        groupNameCheck();

        Principal.hasAuthority('ROLE_ADMIN').then(function(result){
            if(result) {
                vm.isAdmin = true;
            } else {
                vm.isAdmin = false;
            }
        });

        function loadGroup() {
            Group.getAllGroupFlags(function (allGroupFlags) {
                vm.allGroupFlags = allGroupFlags;
                if (entity.id != "" && entity.id != null) {
                    Group.get({id : entity.id}, function(result){
                        vm.group = result;
                        if (vm.group.parentId != null && vm.group.parentId != "") {
                            vm.group.parentId = vm.group.parentId.toString();
                        }
                        vm.loadParentGroup();
                        if (vm.group.jigyoushoId != "" && vm.group.jigyoushoId != null) {
                            vm.loadAllServiceTypes();
                        }
                        for (var i = 0; i < vm.allGroupFlags.length; i ++) {
                            for (var j = 0; j < vm.group.flags.length; j ++) {
                                if (vm.group.flags[j].id == vm.allGroupFlags[i].id) {
                                    vm.allGroupFlags[i].checked = true;
                                }
                            }
                        }
                    });
                } else {
                    vm.group = entity;
                    vm.loadParentGroup();
                    return false;
                }
            });
        }

        function loadAllServiceTypes() {
            Group.selectService(
                {jigyoushoCd:vm.group.jigyoushoCd, caretreeId: vm.group.caretreeId, caretreePw: vm.group.caretreePw},
                function(result){
                    vm.group.serviceList = result;
                    if (vm.group.serviceType != null) {
                        vm.group.serviceType = vm.group.serviceType.toString();
                    }
                });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            MessageService.success("groupManagement.saved");
            $uibModalInstance.close(result);
            $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
        }

        function onSaveError (res) {
            if (res.data) {
                MessageService.error(res.data.message);
            } else {
                MessageService.error("groupManagement.errors.save_error");
            }
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            // 整理flags
            vm.group.flags = [];
            for (var i = 0; i < vm.allGroupFlags.length; i ++) {
                if (vm.allGroupFlags[i].checked) {
                    var flag = {};
                    flag.id = vm.allGroupFlags[i].id;
                    flag.name = vm.allGroupFlags[i].name;
                    vm.group.flags.push(flag);
                }
            }
            vm.group.timecardShiftFlag = vm.group.timecardShiftFlag == 0 ? false : true
            if (vm.group.id != null && vm.group.id != "") {
                vm.group.groupUsers = [];
                Group.update(vm.group, onSaveSuccess, onSaveError);
            } else {
                Group.save(vm.group, onSaveSuccess, onSaveError);
            }
        }

        function serviceTypeChanged() {
            if (vm.group.serviceType != "" && vm.group.serviceType != null) {
                Jigyousho.getJigyousho({jigyoushoCd:vm.group.jigyoushoCd,
                    serviceType:vm.group.serviceType,
                    caretreeId:vm.group.caretreeId,
                    caretreePw:vm.group.caretreePw},function(result){
                    if (result.jigyousho){
                        vm.group.jigyoushoId = result.jigyousho.id;
                        vm.group.caretreeStaffId = result.common.staffId;
                    }
                },function(){
                    $uibModalInstance.close();
                    MessageService.error("groupManagement.message.getNone");
                });
            } else {
                vm.group.jigyoushoId = "";
                vm.group.caretreeStaffId = "";
            }

        }

        function loadParentGroup() {
            if (entity.id === "" || entity.id == null) {
                // 新規
                // 自分管理できるグループを取得
                Group.manageGroup({}, function(result) {
                    setParentGroupList(result)
                });
            } else {
                // 編集
                // 自分管理できるグループを取得、編集するグループをこのグループの子を排除
                Group.parentGroupExceptMyself({groupId: entity.id}, function(result) {
                    setParentGroupList(result)
                })
            }

        }

        function setParentGroupList(result) {
            vm.parentGroup = result;
            if (!vm.isAdmin) {
                if (entity.id === "" || entity.id == null) {
                    if (vm.parentGroup.length > 0) {
                        // デフォルトは第一位のごループ
                        vm.group.parentId = vm.parentGroup[0].id;
                    }
                } else {
                    // 自存の親グループを存在判断、存在する場合、そのままリストを表示、存在しない場合、自存親グループを追加
                    if (vm.group.parentId !== null && vm.group.parentId !== '') {
                        var exist = false
                        vm.parentGroup.forEach(function(item) {
                            if (item.id === vm.group.parentId) {
                                exist = true
                            }
                        })
                        if (!exist) {
                            Group.get({id : vm.group.parentId}, function(parentRes){
                                // 親子関係表示
                                vm.parentGroup.forEach(function(item) {
                                    if (item.name.startsWith("|")) {
                                        item.name = "　　" + item.name
                                    } else {
                                        item.name = "|----" + item.name
                                    }
                                })
                                // 第一位で、自存親グループを追加
                                vm.parentGroup.unshift(parentRes)
                            })
                        }
                    }
                }
            }
        }

        // 查询group名是否存在
        function groupNameCheck() {
            vm.groupNameExist = false
            if (vm.group != null && vm.group.name != undefined && vm.group.name != '') {
                Group.queryGroupName({id: vm.group.id, name: vm.group.name}, function (result) {
                    if (result.length == 0) {
                    	vm.groupNameExist = false;
                    } else {
                        for (var i = 0; i < result.length; i ++) {
                            if ("gid" + result[i].parentId == (vm.group.parentId ? vm.group.parentId : 'gid0')) {
                                vm.groupNameExist = true;
                            }
                        }
                    }
                });
            } else {
            	vm.groupNameExist = false;
            }
        }
    }
})();
