(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ProductEditDialogController', ProductEditDialogController);

    ProductEditDialogController.$inject = ['$uibModalInstance', 'ProductType', 'param', 'Product', '$timeout', 'MessageService', '$rootScope'];

    function ProductEditDialogController ($uibModalInstance, ProductType, param, Product, $timeout, MessageService, $rootScope) {

        var vm = this;

        // 製品
        vm.product = {id: '', productName: '', productKana: '', minCount: 1, productType: ''};
        // ライセンスタイプ
        vm.licenseType = "";
        // ライセンスタイプ
        vm.licenseTypes = [
            {id: 1, name: "アカウントライセンス"},
            {id: 2, name: "プロダクトライセンス"}
        ];
        // 用于保存修改的licenseType
        vm.productTypeObject = {};
        // 最小贩卖数是否可以编辑
        vm.minCountEditDisabled = false;

        // 制品种类列表
        vm.productTypeList = [];

        // 初始加载数据
        vm.loadData = loadData;
        // 根据选择的制品种类，确定license类型，设置最小贩卖数
        vm.showLicenseType = showLicenseType;
        // 修改制品type对应的license type，联动修改
        vm.changeLicenseType = changeLicenseType;

        // 关闭dialog
        vm.close = close;

        // 保存数据
        vm.save = save;

        vm.editFlag = false;

        loadData();

        function loadData() {
            // 获取制品信息
            if (param.id != null && param.id != '') {
                vm.editFlag = true;
                Product.get({id: param.id}, function (result) {
                    vm.product = result;
                    showLicenseType();
                });
            }
            // 获取制品种类列表
            ProductType.list(function(result){
                vm.productTypeList = result;
                if (vm.productTypeList.length > 0) {
                    var product = {id:"",name:""};
                    vm.productTypeList.unshift(product);
                }
                showLicenseType();
            });
        }

        function close () {
            $uibModalInstance.dismiss('cancel');
        }

        function showLicenseType() {
            // 循环制品种类
            for (var i = 0; i < vm.productTypeList.length; i ++) {
                // 判断所选种类和列表中的是否一致
                if (angular.equals(vm.product.productType, vm.productTypeList[i].id)) {
                    for (var j = 0; j < vm.licenseTypes.length; j ++) {
                        if (angular.equals(vm.productTypeList[i].licenseType, vm.licenseTypes[j].id)) {
                            vm.licenseType = vm.licenseTypes[j].id;
                            // 如果是1，那么最小贩卖数可以输入，如果是2，那么最小贩卖数默认为1，并且不可以输入
                            vm.product.minCount = 1;
                            if (angular.equals(vm.licenseTypes[j].id, 1)) {
                                vm.product.minCount = 1;
                                vm.minCountEditDisabled = false;
                            } else {
                                vm.product.minCount = 1;
                                vm.minCountEditDisabled = true;
                            }
                        }
                    }
                }
            }
            vm.productTypeObject = {};
        }

        function changeLicenseType() {
            for (var i = 0; i < vm.productTypeList.length; i ++) {
                if (angular.equals(vm.product.productType, vm.productTypeList[i].id)) {
                    vm.productTypeObject = angular.copy(vm.productTypeList[i]);
                }
            }
            vm.productTypeObject.licenseType = vm.licenseType;
            if (angular.equals(vm.licenseType, 1)) {
                vm.product.minCount = 1;
                vm.minCountEditDisabled = false;
            } else {
                vm.product.minCount = 1;
                vm.minCountEditDisabled = true;
            }
        }

        function save() {
            if (vm.productTypeObject.id != null) {
                ProductType.save(vm.productTypeObject);
            }

            Product.save(vm.product, function(result){
                MessageService.success("product.message.saved");
                $uibModalInstance.close(result);
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            });
        }

    }
})();
