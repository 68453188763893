(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Jigyousho', Jigyousho);

    Jigyousho.$inject = ['$resource'];

    function Jigyousho ($resource) {
        var service = $resource('api/groups/jigyousho', {}, {
            'getJigyousho':{
                method: 'POST'
            },
            'idList': {
                method: 'GET',
                isArray: true
            }
        });

        return service;
    }

})();
