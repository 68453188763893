(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('LicenseUserController', LicenseUserController);

    LicenseUserController.$inject = ['paginationConstants', 'LicenseService', 'MessageService', '$uibModal'];

    function LicenseUserController (paginationConstants, LicenseService, MessageService, $uibModal) {

        var vm = this;

        // 翻页所需
        vm.page = 1;
        vm.links = null;
        vm.totalItems = null;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        // 用户列表
        vm.userList = [];
        // license使用数
        vm.licenseUsedList = [];
        // 查询条件
        vm.searchData = {groupId: "", groupNm: "", loginId: "", name: ""};
        vm.defaultSearchData = {groupId: "", groupNm: "", loginId: "", name: ""};

        vm.noGroup = true;

        // 查询条件选择group
        vm.selectGroup = selectGroup;
        // 初始加载数据
        vm.loadData = loadData;
        // 点击查询
        vm.search = search;
        // 清空搜索条件
        vm.clear = clear;
        // 编辑用户service
        vm.edit = edit;

        // 加载完成之后自动执行
        loadData();

        function selectGroup() {
            var modalInstance = $uibModal.open({
                templateUrl : 'app/master/group/dialog/group-select-dialog.html',
                controller : 'GroupSelectDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size : 'lg',
                resolve : {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('group');
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(function(result) {
                if (result != '') {
                    vm.searchData.groupId = result.id;
                    vm.searchData.groupNm = result.name;
                    loadData();
                }
            },function(){
                //MessageService.error("global.error.internalServerError");
            })
        }

        function loadData() {
            if (vm.searchData.groupId != '') {
                vm.noGroup = false;
                LicenseService.queryUser({
                    page: vm.page - 1,
                    groupId: vm.searchData.groupId,
                    loginId: vm.searchData.loginId,
                    name: vm.searchData.name}, function(result, headers) {
                    vm.totalItems = headers('X-Total-Count');
                    vm.userList = result;
                    vm.queryCount = vm.totalItems;
                });
                LicenseService.userLicenseSum({groupId: vm.searchData.groupId}, function(result){
                    vm.licenseUsedList = result;
                });
            } else {
                vm.noGroup = true;
            }
        }

        function search() {
            vm.page = 1;
            loadData();
        }

        function clear() {
            vm.defaultSearchData.groupId = vm.searchData.groupId;
            vm.defaultSearchData.groupNm = vm.searchData.groupNm;
            vm.searchData = angular.copy(vm.defaultSearchData);
            vm.page = 1;
            loadData();
        }

        function edit(model) {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/customer/license/user/dialog/license-user-edit-dialog.html',
                controller: 'LicenseUserEditDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    param: function(){
                        return model;
                    }
                }
            });
            modalInstance.result.then(function() {
                loadData();
            },function(){
                loadData();
            })
        }

    }
})();
