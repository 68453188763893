(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Script', Script);

    Script.$inject = ['$resource'];

    function Script ($resource) {
        var service = $resource('robot/api/script', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                url: 'robot/api/scripts/type/:type/category/:category'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                },
                url: 'robot/api/script/:id'
            },
            'copy': {
                method: 'POST',
                url: 'robot/api/script/copy'
            },
            'del': {
                method: 'POST',
                url: 'robot/api/script/del'
            },
            "save": {
                method: "POST"
            },
            "make": {
                method: 'POST',
                isArray: false,
                url: 'robot/api/script/make'
            },
            "sort": {
                method: 'PUT',
                url: 'robot/api/updateSort'
            }
        });

        return service;
    }

})();
