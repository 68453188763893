(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ShiftMonthDetailController', ShiftMonthDetailController);

    ShiftMonthDetailController.$inject = ['$scope', '$state', 'AlertService', '$timeout', '$stateParams', '$rootScope' , 'Timecard', 'Shokushu', 'User', 'Group', 'ShiftMonth', 'ShiftTime','pagingParams','paginationConstants','ParseLinks', 'Principal', 'ShiftOccupation'];

    function ShiftMonthDetailController ($scope, $state, AlertService, $timeout,$stateParams,$rootScope, Timecard, Shokushu, User, Group, ShiftMonth, ShiftTime,pagingParams,paginationConstants,ParseLinks,Principal, ShiftOccupation) {
        var vm = this;
        vm.page = 1;
        vm.totalItems = null;
        vm.currentYear = $stateParams.currentYear;
        vm.currentMonth = $stateParams.currentMonth;
        vm.timeZoneList = [];
        vm.weekdays = [{"week":'第１週'},{"week":'第２週'},{"week":'第３週'},{"week":'第４週'},{"week":'第５週'}];
        vm.weekCount = 5;
        vm.surplusDays = 0;
        vm.userStaffOccupation = [];
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.timeZone = {};
        vm.type = 0;
        //vm.monthWidth = 1000;
        vm.day29ShowFlag = false;
        vm.day30ShowFlag = false;
        vm.day31ShowFlag = false;
        vm.calendar = null;
        vm.row = 0;
        vm.column = 0;
        vm.yoteiTimeZoneCountList = [];
        vm.jissekiTimeZoneCountList = [];
        vm.yoteiWeekCountList = [];
        vm.jissekiWeekCountList = [];
        vm.days = 0;
        vm.confirmShowFlag = 0;
        vm.changeFlag = false;
        vm.tableHeight = 0;
        vm.messageWidth = 0;
        vm.tableWidth = 2285;
        vm.divWidth = 3082;
        vm.commentHeight = 0;
        vm.comment = "";
        vm.houjinFlag = false;
        vm.groupAllList = [];
        vm.selectGroupFlag = false;
        vm.exportHoujinFlag = false;
        vm.childrenGroupList = "";
        vm.adminFlg = false;
        vm.occupationList = [];
        vm.occupationId = '0';

        vm.changeGroup = changeGroup;
        vm.changeOccupation = changeOccupation;
        vm.rest = rest;
        vm.beforeMonth = beforeMonth;
        vm.nextMonth = nextMonth;
        vm.colorChange = colorChange;
        vm.columnClick = columnClick;
        vm.yoteiRowClick = yoteiRowClick;
        vm.jissekiRowClick = jissekiRowClick;
        vm.yoteiDayClick = yoteiDayClick;
        vm.jissekiDayClick = jissekiDayClick;
        vm.weekClick = weekClick;
        vm.yoteiMousemove = yoteiMousemove;
        vm.jissekiMousemove = jissekiMousemove;
        vm.confirmClick = confirmClick;
        vm.cancelClick = cancelClick;
        vm.save = save;
        vm.transition = transition;
        vm.loadOccupation = loadOccupation;

        if (!angular.isDefined(vm.currentYear) || vm.currentYear == "") {
            vm.currentYear = new Date().getFullYear();
        }
        if (!angular.isDefined(vm.currentMonth) || vm.currentMonth == "") {
            vm.currentMonth = new Date().getMonth()+1;
        }

        vm.loadParentGroup = loadParentGroup;
        vm.loadCalendar = loadCalendar;
        vm.loadShiftMonth = loadShiftMonth;
        vm.yoteiMousedown = yoteiMousedown;
        vm.jissekiMousedown = jissekiMousedown;
        vm.yoteiDisabledFlag = false;
        vm.jissekiDisabledFlag = true;
        vm.updateDate = updateDate;

        //页面加载后取得所有Group信息
        vm.loadParentGroup();
        vm.loadCalendar();

        function loadOccupation() {
            Group.queryParentGroupId({"groupId":vm.groupId}, function (group) {
                //当前用户的父groupId
                var groupId = "gid" + group.groupId;
                ShiftOccupation.getAllByGroupId({"groupId":groupId}, function(result){
                    vm.occupationList = result;
                });
            });
        }
        function loadParentGroup() {
            Group.parentGroup({}, function(result){
                vm.parentGroup = result;
                if ($stateParams.groupId !== null) {
                    result.forEach(function(item){
                        if (item.id == $stateParams.groupId) {
                            vm.group = item;
                        }
                    });
                } else {
                    vm.group = vm.parentGroup[0];
                }
                vm.groupId = vm.group.id;
                vm.groupName = vm.group.name;
                vm.loadOccupation();
                vm.loadShiftMonth();
            });
            //vm.monthWidth = $("#div").width() - 350;
        }

        function rest(){
            vm.comment = "";
            vm.confirmShowFlag = 0;
            vm.currentYear = new Date().getFullYear();
            vm.currentMonth = new Date().getMonth()+1;
            vm.loadCalendar();
            vm.loadShiftMonth();
        }

        function beforeMonth(){
            vm.comment = "";
            vm.confirmShowFlag = 0;
            if (Number(vm.currentMonth) === 1) {
                vm.currentMonth = 12;
                vm.currentYear = Number(vm.currentYear) - 1;
            } else {
                vm.currentMonth = Number(vm.currentMonth) - 1;
            }
            vm.loadCalendar();
            vm.loadShiftMonth();
        }

        function nextMonth(){
            vm.comment = "";
            vm.confirmShowFlag = 0;
            if (Number(vm.currentMonth) === 12) {
                vm.currentMonth = 1;
                vm.currentYear = Number(vm.currentYear) + 1;
            } else {
                vm.currentMonth = Number(vm.currentMonth) + 1;
            }
            vm.loadCalendar();
            vm.loadShiftMonth();
        }

        function loadTimeZone(){
            ShiftTime.getMontTimeZoneAll({"groupId":vm.groupId, "year":vm.currentYear,"month":vm.currentMonth}, function(result){
                vm.timeZoneList = result;
                vm.timeZone = result[0];
                count();
                weekCount();
                changeJissekiColor();
                resetColor();
                if(vm.selectGroupFlag){
                    resetHoujinColor();
                }
                angular.forEach(vm.userStaffOccupation, function(entity,r,s){
                    var rowHright = $("#yoteiRowHeight"+r).height() + $("#jissekiRowHeight"+r).height();
                    entity.rowHeight = rowHright/2;
                    //计算每个子group的时间带
                    //预定
                    var yoteiSingleTimeZoneList = [];
                    vm.timeZoneList.forEach(function (timezome){
                        var yoteiSingleTime = {};
                        yoteiSingleTime.name = timezome.name;
                        entity.monthShiftYotei.timeZoneCountDTO.forEach(function (yoteiTime){
                            if(timezome.name == yoteiTime.name){
                                yoteiSingleTime.id = yoteiTime.id
                                yoteiSingleTime.timeZoneCount = yoteiTime.timeZoneCount;
                            }
                        });
                        if (isNaN(yoteiSingleTime.id)){
                            yoteiSingleTime.id = timezome.id
                            yoteiSingleTime.timeZoneCount = 0;
                        }
                        yoteiSingleTimeZoneList.push(yoteiSingleTime);
                    });
                    entity.monthShiftYotei.timeZoneCountDTO = yoteiSingleTimeZoneList;
                    //实际
                    var jissekiSingleTimeZoneList = [];
                    vm.timeZoneList.forEach(function (timezome){
                        var jissekiSingleTime = {};
                        jissekiSingleTime.name = timezome.name;
                        entity.monthShiftJisseki.timeZoneCountDTO.forEach(function (jissekiTime){
                            if(timezome.name == jissekiTime.name){
                                jissekiSingleTime.id = jissekiTime.id
                                jissekiSingleTime.timeZoneCount = jissekiTime.timeZoneCount;
                            }
                        });
                        if (isNaN(jissekiSingleTime.id)){
                            jissekiSingleTime.id = timezome.id
                            jissekiSingleTime.timeZoneCount = 0;
                        }
                        jissekiSingleTimeZoneList.push(jissekiSingleTime);
                    });
                    entity.monthShiftJisseki.timeZoneCountDTO = jissekiSingleTimeZoneList;
                });
                //获取table真实宽度
                vm.tableWidth = 360+vm.days*45+125+vm.timeZoneList.length * 45;
                vm.divWidth = vm.tableWidth+ 20;
                setTimeout(function() {
                    changeHeight();
                },100);
            });
        }

        function getStateAlreadySave(timeZoneTotal){
            ShiftTime.getMonthStateAlreadySave({"timeZoneTotal":timeZoneTotal,"groupId": vm.groupId,
                    "year":vm.currentYear,"month":vm.currentMonth}, function(result){
                vm.timeZoneList = result;
                vm.timeZone = result[0];
                count();
                weekCount();
                changeJissekiColor();
                resetColor();
                if(vm.selectGroupFlag){
                    resetHoujinColor();
                }
                angular.forEach(vm.userStaffOccupation, function(entity,r,s){
                    var rowHright = $("#yoteiRowHeight"+r).height() + $("#jissekiRowHeight"+r).height();
                    entity.rowHeight = rowHright/2;
                });
                //获取table真实宽度
                vm.tableWidth = 360+vm.days*45+125+vm.timeZoneList.length * 45;
                vm.divWidth = vm.tableWidth+ 20;
                setTimeout(function() {
                    changeHeight();
                },100);
            });
        }

        function loadCalendar() {
            vm.calendar = Timecard.calendar({'year':vm.currentYear,'month':vm.currentMonth},function () {
                vm.days = vm.calendar.days.length;
                vm.surplusDays = vm.calendar.days.length%7;
                if(vm.weekdays.length == 5){
                    if(vm.calendar.days.length<29){
                        vm.weekdays.splice(4,1);
                        vm.weekCount = 4;
                    }
                }
                if(vm.weekdays.length == 4){
                    if(vm.calendar.days.length>29){
                        vm.weekdays.splice(5,0,{"week":'第５週'});
                        vm.weekCount = 5;
                    }
                }
                if(vm.surplusDays == 0) {
                    vm.day29ShowFlag = false;
                    vm.day30ShowFlag = false;
                    vm.day31ShowFlag = false;
                }else if(vm.surplusDays == 1) {
                    vm.day29ShowFlag = true;
                    vm.day30ShowFlag = false;
                    vm.day31ShowFlag = false;
                }else if(vm.surplusDays == 2) {
                    vm.day29ShowFlag = true;
                    vm.day30ShowFlag = true;
                    vm.day31ShowFlag = false;
                }else if(vm.surplusDays == 3) {
                    vm.day29ShowFlag = true;
                    vm.day30ShowFlag = true;
                    vm.day31ShowFlag = true;
                }
            });
        }

        function loadShiftMonth() {
            Principal.hasGroupAuth(["admin"])
                .then(function (result) {
                    if (result) {
                        vm.adminFlg = true;
                    }
                });
            vm.childrenGroupList = "test";
            vm.exportHoujinFlag = false;
            getShiftMonth(vm.groupId, vm.occupationId, false);

            vm.changeFlag = false;
        }

        function getShiftMonth(groupId, occupationId, houjinFlag) {
            ShiftMonth.queryList({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                groupId: groupId,
                occupationId: occupationId,
                year: vm.currentYear,
                month: vm.currentMonth,
                houjinFlag: houjinFlag
            },function (result,headers) {
                vm.userStaffOccupation = result;
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                angular.forEach(vm.userStaffOccupation, function(data,index,array){
                    /*User.queryUserOne({id: data.userId}, function(result){
                        data.userName = result.lastName + " " + result.firstName;
                    });*/
                    if(data.monthShiftJisseki.comment != "" && data.monthShiftJisseki.comment != null){
                        vm.comment = data.monthShiftJisseki.comment;
                    }
                });
                loadTimeZone();
            });
        }
        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function colorChange () {
            if(vm.confirmShowFlag != 1) {
                if(vm.changeFlag){
                    alert("記録に変更があります。先に保存してください。");
                    if(vm.type == 0){
                        vm.type = 1
                    }else if(vm.type == 1) {
                        vm.type = 0
                    }
                }else{
                    angular.forEach(vm.userStaffOccupation, function(entity,r,s){
                        if(vm.type == 0){
                            for(var i=1; i<=31; i++){
                                entity.monthShiftYotei['color'+i] = "white";
                                entity.monthShiftJisseki['color' + i] = "#EEEEEE";
                            }
                            vm.yoteiDisabledFlag = false;
                            vm.jissekiDisabledFlag = true;
                        }else if(vm.type == 1) {
                            for(var i=1; i<=31; i++){
                                entity.monthShiftYotei['color'+i] = "#EEEEEE";
                                entity.monthShiftJisseki['color' + i] = "white";
                            }
                            vm.yoteiDisabledFlag = true;
                            vm.jissekiDisabledFlag = false;
                        }
                    });
                }
            }
        }

        //双击天单元格触发整列选中
        function columnClick(value){
            if(vm.type == 0){
                if(confirm("該当日の予定設定をクリアしても大丈夫でしょうか")){
                    yoteiColumnClick(value);
                }
            } else if(vm.type == 1){
                if(confirm("該当日の実績設定をクリアしても大丈夫でしょうか")){
                    jissekiColumnClick(value);
                }
            }
        }
        function yoteiColumnClick(value){
            if(!vm.yoteiDisabledFlag){
                vm.changeFlag = true;
                angular.forEach(vm.userStaffOccupation, function(entity,r,s){
                    entity.monthShiftYotei.yoteiChangeFlag = true;
                    entity.monthShiftYotei['day'+value] = "";
                    entity.monthShiftYotei['timeZoneName' + value] = "";
                });
                count();
                weekCount();
                changeJissekiColor();
            }
        }
        function jissekiColumnClick(value){
            if(!vm.jissekiDisabledFlag){
                vm.changeFlag = true;
                angular.forEach(vm.userStaffOccupation, function(entity,r,s){
                    entity.monthShiftJisseki.jissekiChangeFlag = true;
                    entity.monthShiftJisseki['day'+value] = "";
                    entity.monthShiftJisseki['timeZoneName' + value] = "";

                });
                count();
                weekCount();
                changeJissekiColor();
            }
        }

        //双击预定单元格整行取消
        function yoteiRowClick(monthShift){
            if(!vm.yoteiDisabledFlag){
                if(confirm("該当スタッフの予定設定をクリアしても大丈夫でしょうか")){
                    vm.changeFlag = true;
                    monthShift.monthShiftYotei.yoteiChangeFlag = true;
                    for (var i = 1; i<=vm.days; i++) {
                        monthShift.monthShiftYotei['day'+i] = "";
                        monthShift.monthShiftYotei['timeZoneName'+i] = "";
                    }
                    count();
                    weekCount();
                    changeJissekiColor();
                }
            }
        }
        function jissekiRowClick(monthShift){
            if(!vm.jissekiDisabledFlag){
                if(confirm("該当スタッフの実績設定をクリアしても大丈夫でしょうか")){
                    if(vm.type == 1){
                        vm.changeFlag = true;
                        monthShift.monthShiftJisseki.jissekiChangeFlag = true;
                        for (var i = 1; i<=vm.days; i++) {
                            monthShift.monthShiftJisseki['day'+i] = "";
                            monthShift.monthShiftJisseki['timeZoneName'+i] = "";
                        }
                        count();
                        weekCount();
                        changeJissekiColor();
                    }
                }
            }
        }

        //单击单个单元格
        function yoteiDayClick (monthShift, value) {
            if(!vm.yoteiDisabledFlag){
                vm.changeFlag = true;
                monthShift.monthShiftYotei.yoteiChangeFlag = true;
                if(monthShift.monthShiftYotei['day'+value] == vm.timeZone.id){
                    monthShift.monthShiftYotei['day'+value] = "";
                    monthShift.monthShiftYotei['timeZoneName'+value] = "";
                }else {
                    var result = false;
                    angular.forEach(vm.userStaffOccupation, function(entity,r,s){
                        //同一个staff 且不是自己
                        if(entity.userId == monthShift.userId && entity.monthShiftYotei.id != monthShift.monthShiftYotei.id){
                            //同一个预定值 且不是休
                            if(vm.timeZone.id == entity.monthShiftYotei['day'+value] && vm.timeZone.name != "休"){
                                result = true;
                            }
                        }
                    });
                    if(result) {
                        if(confirm("該当スタッフの勤務時間帯が重複しています、確認してください。")) {
                            monthShift.monthShiftYotei['day'+value] = vm.timeZone.id;
                            monthShift.monthShiftYotei['timeZoneName'+value] = vm.timeZone.name;
                        }
                    }else {
                        monthShift.monthShiftYotei['day'+value] = vm.timeZone.id;
                        monthShift.monthShiftYotei['timeZoneName'+value] = vm.timeZone.name;
                    }
                }
                count();
                weekCount();
                changeJissekiColor();
            }
        }
        function jissekiDayClick (monthShift, value) {
            if(!vm.jissekiDisabledFlag){
                vm.changeFlag = true;
                monthShift.monthShiftJisseki.jissekiChangeFlag = true;
                if(monthShift.monthShiftJisseki['day'+value] == vm.timeZone.id){
                    monthShift.monthShiftJisseki['day'+value] = "";
                    monthShift.monthShiftJisseki['timeZoneName'+value] = "";
                }else {
                    var result = false;
                    angular.forEach(vm.userStaffOccupation, function(entity,r,s){
                        //同一个staff 且不是自己
                        if(entity.userId == monthShift.userId && entity.monthShiftJisseki.id != monthShift.monthShiftJisseki.id){
                            //同一个预定值 且不是休
                            if(vm.timeZone.id == entity.monthShiftJisseki['day'+value] && vm.timeZone.name != "休"){
                                result = true;
                            }
                        }
                    });
                    if(result) {
                        if(confirm("該当スタッフの勤務時間帯が重複しています、確認してください。")) {
                            monthShift.monthShiftJisseki['day'+value] = vm.timeZone.id;
                            monthShift.monthShiftJisseki['timeZoneName'+value] = vm.timeZone.name;
                        }
                    }else {
                        monthShift.monthShiftJisseki['day'+value] = vm.timeZone.id;
                        monthShift.monthShiftJisseki['timeZoneName'+value] = vm.timeZone.name;
                    }
                }
                count();
                weekCount();
                changeJissekiColor();
            }
        }

        //双击周单元格触发
        function weekClick (week) {
            if(vm.type == 0){
                if(confirm(" 該当週の予定設定をクリアしても大丈夫でしょうか")){
                    yoteiWeekClick(week);
                }
            } else if(vm.type == 1 ){
                if(confirm(" 該当週の実績設定をクリアしても大丈夫でしょうか")){
                    jissekiWeekClick(week);
                }
            }
        }
        function yoteiWeekClick (week) {
            if(!vm.yoteiDisabledFlag){
                vm.changeFlag = true;
                var firstDay = 0;
                var days = 0;
                if(week == 0){
                    firstDay=1;
                    days = 7;
                } else if(week == 1){
                    firstDay=8;
                    days = 14;
                } if(week == 2){
                    firstDay=15;
                    days = 21;
                } if(week == 3){
                    firstDay=22;
                    days = 28;
                } if(week == 4){
                    firstDay=29;
                    if(vm.surplusDays == 1) {
                        days = 29
                    } else if(vm.surplusDays == 2) {
                        days = 30
                    } else if(vm.surplusDays == 3) {
                        days = 31
                    }
                }

                angular.forEach(vm.userStaffOccupation, function(entity,r,s){
                    for (var i = firstDay; i<=days; i++) {
                        entity.monthShiftYotei.yoteiChangeFlag = true;
                        entity.monthShiftYotei['day'+i] = "";
                        entity.monthShiftYotei['timeZoneName' + i] = "";
                    }
                });
                count();
                weekCount();
                changeJissekiColor();
            }
        }
        function jissekiWeekClick (week) {
            if(!vm.jissekiDisabledFlag){
                vm.changeFlag = true;
                var firstDay = 0;
                var days = 0;
                if(week == 0){
                    firstDay=1;
                    days = 7;
                } else if(week == 1){
                    firstDay=8;
                    days = 14;
                } if(week == 2){
                    firstDay=15;
                    days = 21;
                } if(week == 3){
                    firstDay=22;
                    days = 28;
                } if(week == 4){
                    firstDay=29;
                    if(vm.surplusDays == 1) {
                        days = 29
                    } else if(vm.surplusDays == 2) {
                        days = 30
                    } else if(vm.surplusDays == 3) {
                        days = 31
                    }
                }
                angular.forEach(vm.userStaffOccupation, function(entity,r,s){
                    for (var i = firstDay; i<=days; i++) {
                        entity.monthShiftJisseki.jissekiChangeFlag = true;
                        entity.monthShiftJisseki['day'+i] = "";
                        entity.monthShiftJisseki['timeZoneName' + i] = "";
                    }
                });
                count();
                weekCount();
                changeJissekiColor();
            }
        }

        //鼠标移动到单元格触发
        function yoteiMousemove (monthShift, yy, xx) {
            if(vm.type == 0){
                document.onkeydown = function(e){
                    if(e.keyCode == 17 && vm.row != 0 && vm.column != 0){
                        //横向选中的
                        var r = Math.abs(vm.column-xx);
                        //纵向选中的
                        var y = Math.abs(vm.row - yy);
                        for (var i=0; i<=y; i++){
                            var index = vm.row+i-1;
                            for (var a=0; a<=r; a++) {
                                var dayNumber = vm.column + a;
                                vm.userStaffOccupation[index].monthShiftYotei['color' + dayNumber] = "yellow";
                            }
                        }
                    }
                }
            }
        }
        function jissekiMousemove (monthShift, yy, xx) {
            if(vm.type == 1) {
                document.onkeydown = function(e){
                    if(e.keyCode == 17 && vm.row != 0 && vm.column != 0){
                        //横向选中的
                        var r = Math.abs(vm.column-xx);
                        //纵向选中的
                        var y = Math.abs(vm.row - yy);
                        for (var i=0; i<=y; i++){
                            var index = vm.row+i-1;
                            for (var a=0; a<=r; a++) {
                                var dayNumber = vm.column + a;
                                vm.userStaffOccupation[index].monthShiftJisseki['color' + dayNumber] = "yellow";
                            }
                        }
                    }
                }
            }
        }

        //按下Ctrl键，鼠标在单元格按下之后出发
        function yoteiMousedown (y, x, e ) {
            stopDefault(e);
            if(vm.type == 0){
                document.onkeydown = function(e){
                    if(e.keyCode == 17){
                        vm.row = y;
                        vm.column = x;
                    }
                }
            }
        }
        function jissekiMousedown (y, x, e ) {
            stopDefault(e);
            if(vm.type == 1){
                document.onkeydown = function(e){
                    if(e.keyCode == 17){
                        vm.row = y;
                        vm.column = x;
                    }
                }
            }
        }

        //ctrl键弹起之后清空选中的单元格
        document.onkeyup = function (e){
            if(!vm.yoteiDisabledFlag){
                if(e.keyCode == 17){
                    vm.row = 0;
                    vm.column = 0;
                    angular.forEach(vm.userStaffOccupation, function(entity,r,s){
                        for(var i = 1;i<=31;i++){
                            if(entity.monthShiftYotei['color'+i] == "yellow"){
                                entity.monthShiftYotei['color'+i] = "white";
                                entity.monthShiftYotei['day'+i] = vm.timeZone.id;
                                entity.monthShiftYotei['timeZoneName' + i] = vm.timeZone.name;
                            }
                        }
                    });
                    count();
                    weekCount();
                    changeJissekiColor();
                }
            }
            if(!vm.jissekiDisabledFlag){
                if(e.keyCode == 17){
                    vm.row = 0;
                    vm.column = 0;
                    angular.forEach(vm.userStaffOccupation, function(entity,r,s){
                        for(var i = 1;i<=31;i++){
                            if(entity.monthShiftJisseki['color'+i] == "yellow"){
                                entity.monthShiftJisseki['color'+i] = "white";
                                entity.monthShiftJisseki['day'+i] = vm.timeZone.id;
                                entity.monthShiftJisseki['timeZoneName' + i] = vm.timeZone.name;
                            }
                        }
                    });
                    count();
                    weekCount();
                    changeJissekiColor();
                }
            }
        }

        //阻止浏览器自己选中
        function stopDefault(e){
            if(e && e.preventDefault){
                e.preventDefault();
            }
            else{
                window.event.returnValue=false;
                return false;
            }
        }

        //计算每个时间带的合计
        function count(){
            angular.forEach(vm.userStaffOccupation, function(entity,r,s){
                angular.forEach(vm.timeZoneList, function(timeZone,t){
                    var yoteiC = 0;
                    var jissekiC = 0;
                    for(var i = 1; i<=vm.days;i++){
                        if(timeZone.id == entity.monthShiftYotei['day'+i]){
                            yoteiC+=1;
                        }
                        if(timeZone.id == entity.monthShiftJisseki['day'+i]){
                            jissekiC+=1;
                        }
                    }
                    angular.forEach(entity.monthShiftYotei.timeZoneCountDTO, function(dtoYotei){
                        if(dtoYotei.id == timeZone.id){
                            dtoYotei.timeZoneCount = yoteiC;
                        }
                    });
                    angular.forEach(entity.monthShiftJisseki.timeZoneCountDTO, function(dtoJisseki){
                        if(dtoJisseki.id == timeZone.id){
                            dtoJisseki.timeZoneCount = jissekiC;
                            if(yoteiC != jissekiC){
                                dtoJisseki.fontColor = "red";
                            }else {
                                dtoJisseki.fontColor = "";
                            }
                        }
                    });
                });
            });
        }

        //5週の合計 と　週平均の勤務時間
        function weekCount() {
            angular.forEach(vm.userStaffOccupation, function(entity,r,s){
                var yoteiW = 0;
                var jissekiW = 0;
                var yoteiTotalTime = 0;
                var jissekiTotalTime = 0;
                //循环单个人这个月选中的时间带
                angular.forEach(entity.monthShiftYotei.timeZoneCountDTO, function(timeZone,t){
                    //间隔的时间（分钟）
                    var betweenTime = 0;
                    if(timeZone.name != "休" && timeZone.timeZoneCount > 0){
                        angular.forEach(vm.timeZoneList, function(time){
                            if(timeZone.id == time.id) {
                                //开始时间总共的分钟数
                                var startTime = 0;
                                if(time.startTime != '') {
                                    startTime = Number(time.startTime.split(":")[0]) * 60 + Number(time.startTime.split(":")[1]);
                                }
                                //结束时间总共的分钟数
                                var endTime = 0;
                                if(time.endTime != '') {
                                    endTime = Number(time.endTime.split(":")[0]) * 60 + Number(time.endTime.split(":")[1]);
                                }
                                //休息时间
                                var restTime = 0;
                                if(time.restTime != null){
                                    restTime = time.restTime;
                                }
                                //正常班
                                if(startTime <= endTime){
                                    betweenTime = Number(endTime) - Number(startTime) - Number(restTime);
                                //夜间
                                }else {
                                    betweenTime = 1440 + Number(endTime) - Number(startTime) - Number(restTime);
                                }
                            }
                        });
                        yoteiTotalTime += Number(betweenTime * timeZone.timeZoneCount);
                        //yoteiW += timeZone.timeZoneCount;
                    }
                });
                angular.forEach(entity.monthShiftJisseki.timeZoneCountDTO, function(timeZone,t){
                    //间隔的时间（分钟）
                    var betweenTime = 0;
                    if(timeZone.name != "休"  && timeZone.timeZoneCount > 0){
                        angular.forEach(vm.timeZoneList, function(time){
                            if(timeZone.id == time.id) {
                                //开始时间总共的分钟数
                                var startTime = 0;
                                if(time.startTime != '') {
                                    startTime = Number(time.startTime.split(":")[0]) * 60 + Number(time.startTime.split(":")[1]);
                                }
                                //结束时间总共的分钟数
                                var endTime = 0;
                                if(time.endTime != '') {
                                    endTime = Number(time.endTime.split(":")[0]) * 60 + Number(time.endTime.split(":")[1]);
                                }
                                //休息时间
                                var restTime = 0;
                                if(time.restTime != null){
                                    restTime = time.restTime;
                                }
                                //正常班
                                if(startTime <= endTime){
                                    betweenTime = Number(endTime) - Number(startTime) - Number(restTime);
                                //夜间
                                }else {
                                    betweenTime = 1440 + Number(endTime) - Number(startTime) - Number(restTime);
                                }
                            }
                        });
                        jissekiTotalTime += Number(betweenTime * timeZone.timeZoneCount);
                        //jissekiW += timeZone.timeZoneCount;
                    }
                });

                //entity.monthShiftYotei.total = yoteiW * 8;
                //5週の合計
                entity.monthShiftYotei.total = (yoteiTotalTime/60).toFixed(0);
                //entity.monthShiftYotei.avgTime = yoteiW * 8/160;
                //5週平均の勤務時間
                if(entity.monthShiftYotei.total != ''){
                    entity.monthShiftYotei.avgTime = (entity.monthShiftYotei.total/160).toFixed(2);
                }
                //entity.monthShiftJisseki.total = jissekiW * 8;
                entity.monthShiftJisseki.total = (jissekiTotalTime/60).toFixed(0);
                //entity.monthShiftJisseki.avgTime = jissekiW * 8/160;
                if(entity.monthShiftJisseki.total != ''){
                    entity.monthShiftJisseki.avgTime = (entity.monthShiftJisseki.total/160).toFixed(2);
                }

                if(yoteiW * 8 != jissekiW * 8){
                    entity.monthShiftJisseki.countColor = "red";
                }else {
                    entity.monthShiftJisseki.countColor = "";
                }
                if(yoteiW * 8/160 != jissekiW * 8/160){
                    entity.monthShiftJisseki.averageColor = "red";
                }else {
                    entity.monthShiftJisseki.averageColor = "";
                }
            });
        }

        //实际和预定不相同时，实际变红色
        function changeJissekiColor() {
            angular.forEach(vm.userStaffOccupation, function (entity,r) {
                for(var i = 0; i<= vm.days; i++){
                    if(entity.monthShiftYotei['day'+i] != entity.monthShiftJisseki['day'+i]){
                        entity.monthShiftJisseki['fontColor'+i] = "red";
                    }else {
                        entity.monthShiftJisseki['fontColor'+i] = "";
                    }
                }
            });
        }

        //点击確定処理按钮
        function confirmClick () {
            vm.confirmShowFlag = 1;
            ShiftMonth.saveShiftMonth({groupId:vm.groupId, year:vm.currentYear, month:vm.currentMonth, type:vm.confirmShowFlag,
                                       comment:vm.comment, monthStaffDTOList: vm.userStaffOccupation}, confirmSuccess);
        }

        //点击確定解除
        function cancelClick () {
            vm.confirmShowFlag = 2;
            ShiftMonth.saveShiftMonth({groupId:vm.groupId, year:vm.currentYear, month:vm.currentMonth, type:vm.confirmShowFlag, monthStaffDTOList: vm.userStaffOccupation}, cancelSuccess);
        }

        function save () {
            vm.confirmShowFlag = 0;
            ShiftMonth.saveShiftMonth({groupId:vm.groupId, year:vm.currentYear, month:vm.currentMonth, type:vm.confirmShowFlag,
                                       comment:vm.comment, monthStaffDTOList: vm.userStaffOccupation}, onSaveSuccess);
        }

        function updateDate () {
            ShiftMonth.updateShiftMonthDate({groupId:vm.groupId, year:vm.currentYear, month:vm.currentMonth},
                function() {
                    alert("数据更新成功！")
                });
        }

        function onSaveSuccess (result) {
            if(result.userName != ""){
                alert("【"+ result.userName + "】対象データは他のユーザーに先に更新されたか、または他のデータと重複するため更新することができませんでした。");
                loadShiftMonth();
            }else {
                loadShiftMonth();
            }
        }

        function confirmSuccess () {
            loadShiftMonth();
        }

        function cancelSuccess () {
            loadShiftMonth();
        }

        function resetColor () {
            angular.forEach(vm.userStaffOccupation, function(entity,r,s){
                if(entity.monthShiftJisseki.type == 1){
                    vm.confirmShowFlag = 1;
                    for(var i=1; i<=31; i++){
                        entity.monthShiftYotei['color'+i] = "#EEEEEE";
                        entity.monthShiftJisseki['color' + i] = "#EEEEEE";
                    }
                    vm.yoteiDisabledFlag = true;
                    vm.jissekiDisabledFlag = true;
                }else if(entity.monthShiftJisseki.type == 0) {
                    if(vm.type == 0){
                        for(var i=1; i<=31; i++){
                            entity.monthShiftYotei['color'+i] = "white";
                            entity.monthShiftJisseki['color' + i] = "#EEEEEE";
                        }
                        vm.yoteiDisabledFlag = false;
                        vm.jissekiDisabledFlag = true;
                    }else if (vm.type == 1) {
                        for(var i=1; i<=31; i++){
                            entity.monthShiftYotei['color'+i] = "#EEEEEE";
                            entity.monthShiftJisseki['color' + i] = "white";
                        }
                        vm.yoteiDisabledFlag = true;
                        vm.jissekiDisabledFlag = false;
                    }
                }
                if(entity.monthShiftJisseki.id == null){
                    for(var i=1; i<=31; i++){
                        entity.monthShiftYotei['color'+i] = "white";
                        entity.monthShiftJisseki['color' + i] = "#EEEEEE";
                    }
                    vm.yoteiDisabledFlag = false;
                    vm.jissekiDisabledFlag = true;
                }
            });
        }

        function resetHoujinColor () {
            angular.forEach(vm.userStaffOccupation, function(entity,r,s){
                for(var i=1; i<=31; i++){
                    entity.monthShiftYotei['color'+i] = "#EEEEEE";
                    entity.monthShiftJisseki['color' + i] = "#EEEEEE";
                }
                vm.yoteiDisabledFlag = true;
                vm.jissekiDisabledFlag = true;
            });
        }

        function changeGroup () {
            vm.occupationId = '0';
            vm.parentGroup.forEach(function(e){
                if (e.id == vm.groupId) {
                    vm.group = e;
                    vm.groupName = vm.group.name;
                    vm.groupId = vm.group.id;
                    vm.comment = "";
                    loadOccupation();
                    loadShiftMonth();
                }
            });
        }

        function changeOccupation() {
            vm.parentGroup.forEach(function(e){
                if (e.id == vm.groupId) {
                    vm.group = e;
                    vm.groupName = vm.group.name;
                    vm.groupId = vm.group.id;
                    vm.comment = "";
                    loadOccupation();
                    loadShiftMonth();
                }
            });
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                groupId: vm.groupId == null ? "" : vm.groupId,
                currentYear:vm.currentYear,
                currentMonth:vm.currentMonth
            });
        }

        function changeHeight() {
            //最上边导航部分的高度
            var navbarHeight = $(".navbar").height();
            vm.messageWidth = ($(".navbar").width() - 400)/2;
            //上边表头固定部分的高度
            var titleHeight = $("#titleDiv").height();
            //下边固定部分的高度
            var bottomHeight = $("#bottomDiv").height();
            //copyRight部分的高度
            var copyRightHright = $("#copyRight").height();
            //浏览器可见区域的高度
            var height = document.documentElement.clientHeight;
            if(bottomHeight == 0){
                vm.tableHeight = height - navbarHeight - titleHeight - bottomHeight - copyRightHright -250;
            }else {
                vm.tableHeight = height - navbarHeight - titleHeight - bottomHeight - copyRightHright -205;
            }
            if(vm.tableHeight < 280){
                vm.tableHeight = 280;
            }
            //备注栏的高度
            vm.commentHeight = $("#timeZoneHeight").height();
        }
    }
})();
