(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ScheduleController', ScheduleController);

    ScheduleController.$inject = ['Schedule', '$scope', 'Collection', 'Enum', 'SelectedPepper', 'MessageService', '$state'];

    function ScheduleController (Schedule, $scope, Collection, Enum, SelectedPepper, MessageService, $state) {
        var vm = this;

        vm.datas = {pepperId: null, scheduleList: []};
        vm.repeats = [{code: 1, value: ""}, {code: 2, value: "毎月"}, {code: 3, value: "毎週"}];
        vm.itemTypes = [];
        vm.collections = null;
        vm.pepper = SelectedPepper.current();
        vm.index = null;
        vm.defaultScriptId = null;
        vm.dayTitle = null;

        vm.loadAll = loadAll;
        vm.loadItemTypes = loadItemTypes;
        vm.loadContents = loadContents;
        vm.loadSchedule = loadSchedule;
        vm.add = add;
        vm.del = del;
        vm.save = save;
        vm.selectSchedule = selectSchedule;

        vm.loadAll();
        vm.loadSchedule();

        $scope.$on('pepperChanged', function() {
            vm.pepper = SelectedPepper.current();
            loadSchedule();
            loadContents();
        });

        function loadAll() {
            vm.loadItemTypes();
            vm.loadContents();
            vm.loadSchedule();
        }

        function loadItemTypes() {
            Enum.get({name: "ScheduleItemType"}, function(result) {
                vm.itemTypes = result;
            });
        }

        function loadContents() {
            Collection.query({pepperId: vm.pepper.id}, function(result) {
                vm.collections = result;
                if (vm.collections.length > 0) {
                    vm.defaultScriptId = vm.collections[0].scriptId;
                }
            });
        }

        function loadSchedule() {
            Schedule.query({pepperId: vm.pepper.id}, function(result) {
                for (var i = 0; i < result.length; i ++) {
                    result[i].exeTime = result[i].exeTime.split(":")[0] + ":" + result[i].exeTime.split(":")[1];
                }
                vm.datas.scheduleList = result;
            });
        }

        function add() {
            var schedule = {id: null, pepperId: vm.pepperId, exeTime: null, itemType: 1, scriptId: vm.defaultScriptId, sort: null, repeatType: 1, comment: null,
                pepperScheduleMonthly: {id: null, day1: null, day2: null, day3: null, day4: null, day5: null, day6: null, day7: null,
                    day8: null, day9: null, day10: null, day11: null, day12: null, day13: null, day14: null,
                    day15: null, day16: null, day17: null, day18: null, day19: null, day20: null, day21: null,
                    day22: null, day23: null, day24: null, day25: null, day26: null, day27: null, day28: null,
                    day29: null, day30: null, day31: null},
                pepperScheduleWeekly: {id: null, day1: null, day2: null, day3: null, day4: null, day5: null, day6: null, day7: null}
            }
            vm.datas.scheduleList.push(schedule);
        }

        function del(index) {
            vm.datas.scheduleList.splice(index, 1);
            vm.index = null;
        }

        function save() {
            vm.isSaving = true;
            vm.datas.pepperId = vm.pepper.id;
            Schedule.save(vm.datas, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            vm.isSaving = false;
            MessageService.success("schedule.home.success");
            $state.go('script');
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("schedule.home.error");
        }

        function selectSchedule(index) {
            vm.index = index;
            for (var i = 0; i < vm.itemTypes.length; i ++) {
                if (vm.datas.scheduleList[index].itemType == vm.itemTypes[i].code) {
                    vm.dayTitle = vm.itemTypes[i].name;
                }
            }
        }
    }
})();
