(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .directive('hasEditAuth', hasEditAuth);

    hasEditAuth.$inject = ['Principal'];

    function hasEditAuth(Principal) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {

            var setVisible = function () {
                    element.removeClass('hidden');
                },
                setHidden = function () {
                    element.addClass('hidden');
                },
                defineVisibility = function (reset) {

                    if (reset) {
                        setVisible();
                    }
                    
                    Principal.hasEditAuth()
                        .then(function (result) {
                            if (result) {
                                setVisible();
                            } else {
                                setHidden();
                            }
                        });
                };

            defineVisibility(true);

            scope.$watch(function() {
                return Principal.isAuthenticated();
            }, function() {
                defineVisibility(true);
            });
        }
    }
})();
