(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TimecardManagementController', TimecardManagementController);

    TimecardManagementController.$inject = ['$scope', '$state', 'AlertService', '$stateParams', 'User', 'Group', 'Timecard','TimezoneService', 'ParseLinks', 'pagingParams', 'paginationConstants'];

    function TimecardManagementController ($scope, $state, AlertService, $stateParams, User, Group, Timecard,TimezoneService,ParseLinks, pagingParams, paginationConstants) {
        var vm = this;

        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;

        vm.userTimecardList = [];
        vm.parentGroup = [];
        vm.groupId = $stateParams.groupId;
        vm.currentYear = $stateParams.currentYear;
        vm.currentMonth = $stateParams.currentMonth;
        vm.group = null;
        vm.timezone = null;
        vm.calendar = null;
        vm.reportFlag = false;

        if (!angular.isDefined(vm.currentYear) || vm.currentYear == "") {
            vm.currentYear = new Date().getFullYear();
        }
        if (!angular.isDefined(vm.currentMonth) || vm.currentMonth == "") {
            vm.currentMonth = new Date().getMonth()+1;
        }

        //页面加载时，取得所有的Group
        vm.loadParentGroup = loadParentGroup;
        //Group切换时，自动取得Staff
        vm.changeGroup = changeGroup;
        //取得该Group的所有Staff信息
        vm.loadTimecards = loadTimecards;
        vm.loadCalendar = loadCalendar;
        vm.rest = rest;
        vm.nextMonth = nextMonth;
        vm.beforeMonth = beforeMonth;
        vm.popover = popover;

        //页面加载后取得所有Group信息
        vm.loadParentGroup();
        vm.loadCalendar();

        function rest() {
            vm.currentYear = new Date().getFullYear();
            vm.currentMonth = new Date().getMonth()+1;
            vm.loadCalendar();
            vm.loadTimecards(vm.group);
        }

        function nextMonth() {
            if (Number(vm.currentMonth) === 12) {
                vm.currentMonth = 1;
                vm.currentYear = Number(vm.currentYear) + 1;
            } else {
                vm.currentMonth = Number(vm.currentMonth) + 1;
            }
            vm.loadCalendar();
            vm.loadTimecards(vm.group);
        }

        function beforeMonth() {
            if (Number(vm.currentMonth) === 1) {
                vm.currentMonth = 12;
                vm.currentYear = Number(vm.currentYear) - 1;
            } else {
                vm.currentMonth = Number(vm.currentMonth) - 1;
            }
            vm.loadCalendar();
            vm.loadTimecards(vm.group);
        }

        function loadParentGroup() {
            Group.parentGroup({}, function(result){
                vm.parentGroup = result;
                result.forEach(function(item) {
                   if (vm.groupId != null && vm.groupId == item.id) {
                       vm.group = item;
                       vm.timezone = TimezoneService.getOffset(vm.group.timezone);
                   }
                });
                if (vm.group == null) {
                    vm.group = vm.parentGroup[0];
                    vm.groupId = vm.parentGroup[0]['id'];
                    vm.timezone = TimezoneService.getOffset(vm.group.timezone);
                }
                vm.loadTimecards();
            });
        }

        function loadCalendar() {
            vm.calendar = Timecard.calendar({'year':vm.currentYear,'month':vm.currentMonth});
        }

        function changeGroup() {
        	pagingParams.page = 1;
            angular.forEach(vm.parentGroup,function(data,index,array){
                if (data.id === vm.groupId) {
                    vm.group = data;
                    vm.timezone = TimezoneService.getOffset(vm.group.timezone);
                }
            });
            vm.loadTimecards();
        }

        function loadTimecards() {
            User.query({
                pageNumber: vm.page - 1,
                pageSize: vm.itemsPerPage,
                sort: sort(),
                groupId:vm.groupId
            },function (result,headers) {
                //vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                //vm.page = pagingParams.page;
                var data = {'users':result,'year':vm.currentYear,'month':vm.currentMonth,'groupId':vm.groupId};
                Timecard.queryMonth(data,function(result){
                    vm.userTimecardList = result.users;
                    vm.reportFlag = result.reportFlag;
                },function(error){
                    AlertService.error(error.data.message);
                });
            });
        }

        function popover(timecard) {
            var rawData = timecard.rawData;
            if (rawData.length === 0) return '<span>データがありません。</span>';
            var message = "";
            for (var i = 0; i < rawData.length; i++) {
                message+='<span>' + rawData[i]["inTime"] + '〜' + rawData[i]["outTime"] + '</span><br/>';
            }
            return message;
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            vm.loadTimecards()
        }

        function sort () {
            // var result = [vm.predicate + ' ' + (vm.reverse ? 'asc' : 'desc')];
            // if (vm.predicate !== 'id') {
            //     result.push('id asc');
            // }
            return ['id asc'];
        }
    }
})();
