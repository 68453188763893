(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('FunctionManagementController', FunctionManagementController);

    FunctionManagementController.$inject = ['Principal', 'Enum', 'Kinou', 'ParseLinks', 'MessageService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService', 'Group', '$filter', 'SelectedGroup', '$scope', '$rootScope'];

    function FunctionManagementController(Principal, Enum, Kinou, ParseLinks, MessageService, $state, pagingParams, paginationConstants, JhiLanguageService, Group, $filter, SelectedGroup, $scope, $rootScope) {
        var vm = this;
        vm.searchData = {kinouName: "", kinouCode: ""};
        vm.defaultSearchData = {kinouName: "", kinouCode: ""};
        vm.kinouList = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.clear = clear;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.loadAll();
        vm.goEdit = goEdit;
        vm.goDel = goDel;
        vm.goNew = goNew;

        function loadAll () {
            if($rootScope.page != undefined){
                vm.page = $rootScope.page;
                $rootScope.page = undefined;
            }
            if($rootScope.kinouName != undefined){
                vm.searchData.kinouName = $rootScope.kinouName;
                $rootScope.kinouName = undefined;
            }
            if($rootScope.kinouCode != undefined){
                vm.searchData.kinouCode = $rootScope.kinouCode;
                $rootScope.kinouCode = undefined;
            }
            Kinou.query({
                page: vm.page - 1,
                size: vm.itemsPerPage,
                name: vm.searchData.kinouName,
                code: vm.searchData.kinouCode,
                sort: sort()
            }, onSuccess, onError);
        }

        function search(){
            vm.page = 1;
            loadAll();
        }

        function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.kinouList = data;
            setTimeout(function() {
                //屏幕高度
                var height = document.documentElement.clientHeight;
                //导航部分的高度
                var navbarHeight = $("#navbarHeight").height();//20
                //检索部分的高度
                var searchHeight = $("#searchHeight").height();//15
                //表头高度
                var headerHeight = $("#headerHeight").height();//
                //分页部分高度
                var pageHeight = $("#pageHeight").height();
                //copyRight
                var copyRight = $("#copyRight").height();
                var tableDivHeight = height-navbarHeight-19-searchHeight-headerHeight-20-pageHeight-10-copyRight-10-40;
                $("#tableDiv").css("max-height",tableDivHeight);
            }, 20);
        }

        function onError(){
        }

        function clear () {
            vm.searchData = angular.copy(vm.defaultSearchData);
            search();
        }

        function loadPage (page) {
            vm.page = page;
            //vm.transition();
        }

        function transition () {

        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }

        function goEdit(kinouId) {
            $rootScope.kinouName = vm.searchData.kinouName;
            $rootScope.kinouCode = vm.searchData.kinouCode;
            $rootScope.page = vm.page;
            $state.go('function-management.edit', {kinouId: kinouId});
        }

        function goDel(kinouId) {
            $rootScope.kinouName = vm.searchData.kinouName;
            $rootScope.kinouCode = vm.searchData.kinouCode;
            $rootScope.page = vm.page;
            $state.go('function-management.delete', {kinouId: kinouId});
        }

        function goNew() {
            $rootScope.kinouName = vm.searchData.kinouName;
            $rootScope.kinouCode = vm.searchData.kinouCode;
            $rootScope.page = vm.page;
            $state.go('function-management.new');
        }
    }
})();
