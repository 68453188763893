(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('ScriptCategory', ScriptCategory);

    ScriptCategory.$inject = ['$resource'];

    function ScriptCategory ($resource) {
        var service = $resource('robot/api/variable/:scriptId', {}, {
            "query": {
                method: 'GET',
                isArray: true,
                url:'robot/api/variableList/:itemType'
            },
            "save": {
                method: "POST"
            },
            "delete": {
                method: "DELETE"
            },
            "get": {
                method: "GET"
            }
        });

        return service;
    }

})();
