(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('StorageManagementMailController', StorageManagementMailController);

    StorageManagementMailController.$inject = ['$uibModalInstance', 'entity', 'StroageService', 'MessageService', '$timeout', '$rootScope'];

    function StorageManagementMailController ($uibModalInstance, entity, StroageService, MessageService, $timeout, $rootScope) {
        var vm = this;
        vm.clear = clear;
        vm.confirmExecute = confirmExecute;
        vm.error = false;
        vm.houjinId = entity.houjinId;
        vm.alertMailFlg = entity.alertMailFlg;
        function clear () {
            $uibModalInstance.close(true);
        }

        function confirmExecute() {
            StroageService.mailStop({
                    houjinId:vm.houjinId,
                    alertMailFlg: vm.alertMailFlg
                },
                function(){
                    if(vm.alertMailFlg){
                        MessageService.success("storageManagement.home.mailStop");
                    }else{
                        MessageService.success("storageManagement.home.mail");
                    }

                    $uibModalInstance.close(true);
                    $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
                }, function () {
                    vm.error = true;
                });
        }
    }
})();
