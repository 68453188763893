(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Principal', Principal);

    Principal.$inject = ['$q', 'Account'];

    function Principal ($q, Account) {
        var _identity,
            _staffIdentity,
            _authenticated = false;

        var service = {
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            hasFunction: hasFunction,
            hasGroupAuth: hasGroupAuth,
            hasEditAuth: hasEditAuth,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved,
            isStaffManager: isStaffManager,
            staffIdentity: staffIdentity
        };

        return service;

        function authenticate (identity, staffIdentity) {
            _identity = identity;
            _staffIdentity = staffIdentity;
            _authenticated = identity !== null;
        }

        function hasAnyAuthority (authorities) {
            if (!_authenticated || !_identity || !_identity.authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function hasAuthority (authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function(_id) {
                return _id.authorities && _id.authorities.indexOf(authority) !== -1;
            }, function(){
                return false;
            });
        }

        // 用户是否包含指定机能权限
        function hasFunction(func) {

        	if (!_authenticated) {
                return $q.when(false);
            }
            return this.identity().then(function(_id) {
                return _id.functions && _id.functions.indexOf(func) !== -1;
            }, function(){
                return false;
            });
        }

        //用户所属组权限判定
        function hasGroupAuth (auth) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function(_id) {
                if (!_id.groupAuthes) return false;

                if (_id.authorities
                    && (_id.authorities.indexOf("ROLE_ADMIN") !== -1
                    || _id.authorities.indexOf("ROLE_DEVELOPER") !== -1)) {
                    return true;
                }
                var result = false;
                _id.groupAuthes.forEach(function (element) {
                    auth.forEach(function (au) {
                        if (element.groupAuth === au) {
                            result = true;
                            return result;
                        }
                    });
                });
                return result;
            }, function(){
                return false;
            });
        }
        
        // 用户是否有查看设定管理的权限判定
        function hasEditAuth () {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function(_id) {
                

                if (_id.authorities
                    && (_id.authorities.indexOf("ROLE_ADMIN") !== -1
                    || _id.authorities.indexOf("ROLE_DEVELOPER") !== -1)) {
                    return true;
                }
                if (!_id.groupAuthes) return false;
                var result = false;
                _id.groupAuthes.forEach(function (element) {
                    if (element.groupAuth === 'admin') {
                        result = true;
                        return result;
                    }
                });
                return result;
            }, function(){
                return false;
            });
        }

        function identity (force) {

            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.get().$promise
                .then(getAccountThen)
                .catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen (account) {
                _identity = account.data;
                _authenticated = true;
                deferred.resolve(_identity);
            }

            function getAccountCatch () {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated () {
            return _authenticated;
        }

        function isIdentityResolved () {
            return angular.isDefined(_identity);
        }

        // 用户是否有staff管理权限
        function isStaffManager() {
            if (!_authenticated) {
                return $q.when(false);
            }
            return this.staffIdentity().then(function(_id){
                return _id.staffManager;
            }, function(){
                return false;
            });
        }

        function staffIdentity(force) {

            var deferred = $q.defer();

            if (force === true) {
                _staffIdentity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_staffIdentity)) {
                deferred.resolve(_staffIdentity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.staffManager().$promise
                .then(getStaffThen)
                .catch(getStaffCatch);

            return deferred.promise;

            function getStaffThen (staff) {
                _staffIdentity = staff;
                _authenticated = true;
                deferred.resolve(_staffIdentity);
            }

            function getStaffCatch () {
                _staffIdentity = null;
                _authenticated = false;
                deferred.resolve(_staffIdentity);
            }
        }
    }
})();
