(function(){
    'use strict';

    angular
        .module('gatewayApp')
        .controller('StaffSingleAddController',StaffSingleAddController);
    StaffSingleAddController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService','Group', 'MessageService', '$rootScope'];
    function StaffSingleAddController ($stateParams, $uibModalInstance, entity, User, JhiLanguageService, Group, MessageService, $rootScope) {
        var vm = this;
        vm.userlist = [];

        vm.clear = clear;
        vm.loadUser = loadUser;
        vm.addUser = addUser;
        vm.staffOccupationList = $rootScope.userList;
        loadUser();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function loadUser() {
            User.queryAllByGroupId({groupId:$rootScope.groupId},function(result){
                vm.userlist = result;
            });
        }

        function addUser(user) {
            $rootScope.addFlag = false;
            for(var i=0; i<vm.staffOccupationList.length; i++){
                if(user.id == vm.staffOccupationList[i].userId){
                    $rootScope.addFlag = true;
                    alert("スタッフはすでにシフト職員管理リストに存在します");
                    break;
                }
            }
            if(!$rootScope.addFlag){
                $rootScope.user = user;
                $uibModalInstance.dismiss('cancel');
            }
        }
    }
})();
