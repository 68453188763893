(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('function-management', {
                parent: 'app',
                url: '/settings/function?page&sort',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'functionManagement.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/system/function-management/function-management.html',
                        controller: 'FunctionManagementController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('function-management');
                        return $translate.refresh();
                    }]

                }
            })
            .state('function-management.new', {
                parent: 'function-management',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/system/function-management/function-management-create.html',
                        controller: 'FunctionManagementCreateController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            param: {code: $stateParams.code}
                        }
                    }).result.then(function() {
                        $state.go('function-management', null, { reload: true });
                    }, function() {
                        $state.go('function-management');
                    });
                }]
            })
            .state('function-management.edit', {
                parent: 'function-management',
                url: '/edit/{kinouId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/system/function-management/function-management-create.html',
                        controller: 'FunctionManagementCreateController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            param: {kinouId: $stateParams.kinouId}
                        }
                    }).result.then(function() {
                        $state.go('function-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('function-management.delete', {
                parent: 'function-management',
                url: '/delete/{kinouId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/system/function-management/function-management-delete.html',
                        controller: 'FunctionManagementDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Kinou', function(Kinou) {
                                return Kinou.getDelete({kinouId : $stateParams.kinouId});
                            }]
                        }
                    }).result.then(function() {
                        $state.go('function-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }
})();
