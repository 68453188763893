(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['Principal', 'Auth', 'JhiLanguageService', 'User', '$translate', '$stateParams', '$localStorage', '$sessionStorage', '$state', '$scope'];

    function SettingsController (Principal, Auth, JhiLanguageService, User, $translate, $stateParams, $localStorage, $sessionStorage, $state, $scope) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.loadPhoto = loadPhoto;
        vm.settingsAccount = null;
        vm.success = null;
        // 是否可以使用タイムカード
        vm.gtc_caretree2_timecard_allfunction_fullaccess = false;

        dologin();

        function dologin() {
        	if ($stateParams.token != undefined && $stateParams.token != '') {
                var settings = {
                    "url": "/api/account",
                    "method": "GET",
                    "headers": {
                        "Authorization": "Bearer " + $stateParams.token
                    },
                    success: function(res){
                        Auth.loginWithToken($stateParams.token);
                        vm.settingsAccount = copyAccount(res);
                        $scope.$apply()
                    },
                    error: function() {
                        $state.go('settings');
                    }
                }
                $.ajax(settings);
        	}
        }

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                seiKana: account.seiKana,
                langKey: account.langKey,
                lastName: account.lastName,
                meiKana: account.meiKana,
                login: account.login
            };
        };

        Principal.identity().then(function(account) {
            vm.settingsAccount = copyAccount(account);
            if (account != null) {
                if (account.functions != null) {
                    for (var i in account.functions) {
                        if (account.functions[i] == 'GTC_CareTree2_TimeCard_AllFunction_FullAccess') {
                            vm.gtc_caretree2_timecard_allfunction_fullaccess = true;
                        }
                    }
                }
            }
            if (vm.gtc_caretree2_timecard_allfunction_fullaccess) {
            	vm.loadPhoto();
            }
        });

        function save () {
            Auth.updateAccount(vm.settingsAccount).then(function() {
                vm.error = null;
                vm.success = 'OK';
                Principal.identity(true).then(function(account) {
                    vm.settingsAccount = copyAccount(account);
                });
                JhiLanguageService.getCurrent().then(function(current) {
                    if (vm.settingsAccount.langKey !== current) {
                        $translate.use(vm.settingsAccount.langKey);
                    }
                });
            }).catch(function() {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }

        function loadPhoto(){
            var data = {'login':vm.settingsAccount.login};
            User.getPhoto(data,function(result){
                vm.settingsAccount.imageUrl = result[0];
            },function(error){

            });
        }
    }
})();
