(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('LibraryUrlController', LibraryUrlController);

    LibraryUrlController.$inject = ['entity', '$uibModalInstance', 'Library', 'MessageService', 'Principal', 'Group', '$timeout', '$rootScope'];

    function LibraryUrlController(entity, $uibModalInstance, Library, MessageService, Principal, Group, $timeout, $rootScope) {
        var vm = this;

        vm.library = entity;
        vm.groupId = null;

        vm.close = close;
        vm.save = save;
        vm.loadAllGroups = loadAllGroups;

        vm.loadAllGroups();

        Principal.hasAuthority('ROLE_ADMIN').then(function(result){
            if(result) {
                vm.isAdmin = true;
            } else {
                vm.isAdmin = false;
            }
        });

        function close() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.library.uploadFlg = 0;
            vm.library.systemFlg = vm.isAdmin;
            Library.save(vm.library, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            MessageService.success("library.message.saveSuccess");
            $uibModalInstance.close(result);
            $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
        }

        function onSaveError() {
            MessageService.error("library.message.saveError");
        }

        function loadAllGroups() {
            Group.queryUserGroups(function(result){
                vm.groups = result;
                if (result.length > 0) {
                    vm.groupId = result[0].id;
                }
            });

        }
    }
})();
