(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('StaffEditController', StaffEditController);

    StaffEditController.$inject = [];

    function StaffEditController () {

        var vm = this;

        // スタッフ
        vm.staff = {};

        // 进入页面，加载初始数据
        vm.loadData = loadData();

        vm.back = back;

        function loadData() {
            vm.staff = {
                jigyoushoCd: "4580012",
                jigyoushoNm: "グッドツリー介護事業所",
                loginId: "",
                password: "",
                sei: "",
                mei: "",
                seiKana: "",
                meiKana: "",
                productId: "",
                productNm: "",
                beginDate: "",
                useStop: true,
                shopId: "",
                shopNm: ""
            }
        }

        function back() {
            history.back();
        }
    }
})();
