(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('script', {
            parent: 'app',
            url: '/robot/contents',
            data: {
                pageTitle: 'global.menu.system.script'
            },
            views: {
                'content@': {
                    templateUrl: 'app/system/script/script.html',
                    controller: 'ScriptController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'lastModifiedDate,desc',
                    squash: true
                },
                scriptSelectType: {
                    value: 2,
                    squash: true
                },
                scriptSelectCategory: {
                    value: 1,
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        scriptSelectType: $stateParams.selectedType,
                        scriptSelectCategory: $stateParams.selectedCategory
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('script');
                    return $translate.refresh();
                }]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', 'Kinou',
                function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService, Kinou) {
            	var top = $uibModalStack.getTop();
            	if (top) {
            	    $uibModalStack.dismiss(top.key);
            	}
                Principal.identity().then(function(account) {
                	if (account != null) {
                        var gtc_caretree2_robot_allfunction_fullaccess = false;
                        var isAdmin = false;
                        var isDeveloper = false;
                        Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_Robot_AllFunction_FullAccess"}, function(result){
                        	if (!result.flag) {
                        		$state.go('accessdenied');
                        	} else {
                        		plan($state, $uibModal, account, isAdmin, isDeveloper, gtc_caretree2_robot_allfunction_fullaccess);
                        	}
                        });
                	} else {
                		LoginService.open();
                	}
                });
            }]
        }).state('conversation', {
            parent: 'script',
            url: '/{id}/{type}/conversation',
            data: {
                pageTitle: 'global.menu.system.conversation'
            },
            views: {
                'content@': {
                    templateUrl: 'app/system/script/conversation/conversation.html',
                    controller: 'ConversationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                // 文字
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('script');
                    return $translate.refresh();
                }]
            }
        }).state('showConversation', {
            parent: 'script',
            url: '/{id}/{type}/showConversation',
            data: {
                pageTitle: 'global.menu.system.conversation'
            },
            views: {
                'content@': {
                        templateUrl: 'app/system/script/conversation/showConversation.html',
                    controller: 'ShowConversationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                // 文字
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('script');
                    return $translate.refresh();
                }]
            }
        }).state('media', {
            parent: 'script',
            url: '/{id}/{type}/media',
            data: {
                pageTitle: 'global.menu.system.music'
            },
            views: {
                'content@': {
                    templateUrl: 'app/system/script/media/media.html',
                    controller: 'MediaController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                // 文字
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('script');
                    return $translate.refresh();
                }]
            }
        }).state('showMedia', {
            parent: 'script',
            url: '/{id}/{type}/showMedia',
            data: {
                pageTitle: 'global.menu.system.music'
            },
            views: {
                'content@': {
                    templateUrl: 'app/system/script/media/showMedia.html',
                    controller: 'ShowMediaController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                // 文字
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('script');
                    return $translate.refresh();
                }]
            }
        });
    }
    function plan($state, $uibModal, account, isAdmin, isDeveloper, gtc_caretree2_robot_allfunction_fullaccess){
        if (account.authorities.indexOf("ROLE_ADMIN") != -1) {
            isAdmin = true;
        }
        if (account.authorities.indexOf("ROLE_DEVELOPER") != -1) {
            isDeveloper = true;
        }
        if (account.functions != null) {
            for (var i in account.functions) {
                if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                    gtc_caretree2_robot_allfunction_fullaccess = true;
                }
            }
        }
        if (!gtc_caretree2_robot_allfunction_fullaccess && !isAdmin && !isDeveloper) {
            $state.go('home');
            $uibModal.open({
                templateUrl: 'app/home/dialog/license.html',
                controller: 'HomeLicenseController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg'
            }).result.then(function() {
                $state.go('home', null, { reload: true });
            }, function() {
                $state.go('home');
            });
        }
    }
})();
