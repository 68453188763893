(function () {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Kinou', Kinou);

    Kinou.$inject = ['$resource'];

    function Kinou($resource) {
        var service = $resource('api/kinou', {}, {
            'query': {method: 'GET', url: 'api/kinou', isArray: true},
            'queryPlan': {method: 'GET', url: 'api/kinouPlan', isArray: true},
            'createKinou': {method: 'POST',url: 'api/kinou'},
            'getKinou': {method: 'GET', url: 'api/kinou/:kinouId'},
            'getDelete': {method: 'GET', url: 'api/kinou/:kinouId',
                    transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }},
            'updateKinou': {method: 'PUT',url: 'api/kinou'},
            'deleteKinou': {method: 'DELETE', url: 'api/kinou/:kinouId'},
            'getKeiyakuplan': {method: 'GET', url: 'api/kinou/keiyakuplan/:kinouId', isArray: true},
            'getKeiyakuplanByLicense': {method: 'GET', url: 'api/kinou/keiyakuplanlicense', isArray: true},
            'svaeKeiyakuplan': {method: 'POST',url: 'api/kinou/keiyakuplan/:kinouId', isArray: true},
            'svaeKeiyakuplanList': {method: 'POST',url: 'api/kinou/keiyakuplanList/:planId', isArray: true},
            'svaeKeiyakuplanSingle': {method: 'POST',url: 'api/kinou/keiyakuplanSingle/:planId', isArray: true},
            'getAuthorityFunction': {method: 'GET',url: 'api/kinou/:roleName/:functionCd/getAuthorityFunction'},
            'isLoginRoleHasFunctions': {
            	method: 'GET',
            	url: 'api/kinou/isLoginRoleHasFunctions'
            },
            'isLoginRole': {
                method: 'GET',
                url: 'api/kinou/isLoginRole'
            }
        });

        return service;
    }
})();
