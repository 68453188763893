(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('ShiftTime', ShiftTime);

    ShiftTime.$inject = ['$resource'];

    function ShiftTime ($resource) {
        var service = $resource('timecard/api/shift', {}, {
            'getAll': {
                method: 'GET',
                url:'timecard/api/shiftTime/all',
                isArray: true
            },
            'getParentAll': {
                method: 'GET',
                url:'timecard/api/shiftTimeParent/all',
                isArray: true
            },
            'getOneById': {
                method: 'GET',
                url:'timecard/api/shiftTime/getOneById'
            },
            'getStateAll': {
                method: 'GET',
                url:'timecard/api/shiftTime/allByState',
                isArray: true
            },
            'getMonthStateAll': {
                method: 'GET',
                url:'timecard/api/shiftTime/monthAllByState',
                isArray: true
            },
            'getMontTimeZoneAll': {
                method: 'GET',
                url:'timecard/api/shiftTime/getMontTimeZoneAll',
                isArray: true
            },
            'getStateAlreadySave': {
                method: 'GET',
                url:'timecard/api/shiftTime/getStateAlreadySave',
                isArray: true
            },
            'getMonthStateAlreadySave': {
                method: 'GET',
                url:'timecard/api/shiftTime/getMonthStateAlreadySave',
                isArray: true
            },
            'save': {
                method: 'POST',
                url:'timecard/api/shiftTime/save'
            }
            ,
            'delete': {
                method: 'DELETE',
                url:'timecard/api/shiftTime/delete/:id'
            }
        });

        return service;
    }

})();
