(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ShopController', ShopController);

    ShopController.$inject = [];

    function ShopController () {

        var vm = this;

        // 贩卖店列表
        vm.shopList = [];

        // 初始加载数据
        vm.loadData = loadData();

        // 删除指定贩卖店
        vm.remove = remove;

        function loadData() {
            var shop1 = {shopId: '1', shopNm: '販売店１', tel: '99-9999-9999', fax: '99-9999-9999', managerDuty: '', managerNm: ''};
            var shop2 = {shopId: '2', shopNm: '販売店２', tel: '99-9999-9999', fax: '99-9999-9999', managerDuty: '', managerNm: ''};
            vm.shopList.push(shop1);
            vm.shopList.push(shop2);
        }

        function remove(index) {
            vm.shopList.splice(index, 1);
        }
    }
})();
