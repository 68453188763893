(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('UserManagementUpdateAllController',UserManagementUpdateAllController);

    UserManagementUpdateAllController.$inject = ['User', '$stateParams', 'AlertService', '$http'];

    function UserManagementUpdateAllController (User, $stateParams, AlertService,$http) {
        var vm = this;
        vm.selected = $stateParams.selected;

        vm.loadData = loadData;
        vm.save = save;
        vm.saved = false;
        vm.print = print;
        vm.changePw = changePw;

        vm.loadData();
        vm.saveFlag = true;
        function changePw(user){
            if(user.password.length<6 && user.password.length>0){
                user.errorFlag = true;
                user.minFlag = true;
                user.maxFlag = false;
                user.requiredFlag = false;
            }else if(user.password.length<=50 && user.password.length>=6){
                user.errorFlag = false;
                user.minFlag = false;
                user.maxFlag = false;
                user.requiredFlag = false;
            }else if(user.password.length > 50){
                user.errorFlag = true;
                user.maxFlag = true;
                user.minFlag = false;
                user.requiredFlag = false;
            }
            if(user.password.length == 0){
                user.errorFlag = true;
                user.requiredFlag = true;
                user.minFlag = false;
                user.maxFlag = false;
            }else if(user.password.length > 0){
                user.requiredFlag = false;
            }
            vm.resultFlag = false;
            angular.forEach(vm.users,function(data,index,array){
                if(data.requiredFlag == true || data.errorFlag == true || data.maxFlag == true || data.minFlag == true){
                    vm.saveFlag = true;
                    vm.resultFlag = true;
                }
                vm.saveFlag = vm.resultFlag;

            });
        }

        function loadData() {
            User.queryAll({selected:vm.selected},function(result){
                vm.users = result;
                angular.forEach(vm.users,function(data,index,array){
                    data.requiredFlag = true;
                    data.errorFlag = true;
                    data.maxFlag = false;
                    data.minFlag = false;
                });
            });
        }

        function save() {
            var result = [];
            angular.forEach(vm.users,function(data,index,array){
                result.push({id:data.id,name:data.lastName + " " + data.firstName,password:data.password});
            });
            User.updateAll({users:result},function(data){
                vm.saved = true;
                vm.printData = result;
            });
        }

        function print() {
            $http({
                url: "/export/password",
                method: 'post',
                data: {users:vm.printData},
                responseType: 'arraybuffer'
            }).success(function (data, status, headers) {
                var type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                if (!type) throw '无效类型';
                saveAs(new Blob([data], { type: type }), decodeURI(headers()["content-disposition"]).replace("attachment;filename=",""));  // 中文乱码
            }).error(function (data, status) {
                AlertService.error("エクスポート失敗しました。");
            });

        }
    }
})();
