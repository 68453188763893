(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CategoryController', CategoryController);

    CategoryController.$inject = ['ParseLinks', 'paginationConstants', 'Category', 'Enum'];

    function CategoryController (ParseLinks, paginationConstants, Category, Enum) {
        var vm = this;

        vm.loadAllCategory = loadAllCategory;

        vm.categories = [];
        vm.categoryTypes = [];

        vm.loadAllCategory();

        function loadAllCategory() {
            Category.query({page: vm.page - 1, size: paginationConstants.itemsPerPage}, function(result, headers){
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.categories = result;
            });
        }
    }
})();
