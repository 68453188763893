(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('SelectDutyOccupationController',SelectDutyOccupationController);

    SelectDutyOccupationController.$inject = ['$stateParams', 'param', 'AlertService', '$uibModalInstance', 'JhiLanguageService','MessageService', '$timeout', '$rootScope','Principal', 'Outlets'];

    function SelectDutyOccupationController ($stateParams, param, AlertService, $uibModalInstance, JhiLanguageService, MessageService, $timeout, $rootScope, Principal, Outlets) {
        var vm = this;
        vm.dutyOccupationList;
        vm.occupation;
        vm.loadData = loadData;
        vm.clear = clear;
        vm.save = save;
        vm.yoteiList;
        vm.yotei;

        loadData();
        function loadData() {
            vm.dutyOccupationList = $rootScope.dutyOccupationList;
            vm.yoteiList = $rootScope.yoteiList;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function save () {
            $rootScope.dutyId = vm.yotei.dutyId;
            $rootScope.occupationId = vm.yotei.occupationId;
            $rootScope.staffOccupationId = vm.yotei.staffOccupationId;
            $rootScope.$broadcast('duty');
            $uibModalInstance.close();
        }
    }
})();
