(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('OptionUser', OptionUser);

    OptionUser.$inject = ['$resource'];

    function OptionUser ($resource) {
        var service = $resource('timecard/api/options/user', {}, {
            'get': {
                method: 'GET',
                url: 'timecard/api/options/user',
                isArray: false
            },
            'query': {
                method: 'POST',
                url:'timecard/api/options/user/query',
                isArray: true
            },
            'save': {
                method: 'POST',
                url:'timecard/api/options/user'
            },
            'saveHourSalary': {
                method: 'POST',
                url:'timecard/api/options/user/hoursalary'
            }
        });

        return service;
    }

})();
