(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('user-management', {
            parent: 'app',
            url: '/settings/account?page&sort',
            data: {
                pageTitle: 'userManagement.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/system/user-management/user-management.html',
                    controller: 'UserManagementController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('user-management');
                    return $translate.refresh();
                }]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', 'Kinou',function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService, Kinou) {
                Principal.identity().then(function(account) {
                	if (account != null) {
                		var isGroupAdmin = false;

                        if (account.authorities
                            && (account.authorities.indexOf("ROLE_ADMIN") !== -1
                                || account.authorities.indexOf("ROLE_DEVELOPER") !== -1)) {
                            isGroupAdmin = true;
                        } else {
                            account.groupAuthes.forEach(function (element) {
                                if (element.groupAuth === 'admin') {
                                    isGroupAdmin = true;
                                }
                            });
                        }
                        if (!isGroupAdmin) {
                            $state.go('accessdenied');
                        }
                        Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_Authentication_access_FullAccess"}, function(result){
                        	if (!result.flag) {
                        		$state.go('accessdenied');
                        	}
                        });
                	} else {
                		LoginService.open();
                	}

                });
            }]
        })
        .state('user-management-detail', {
            parent: 'user-management',
            url: '/user/:login',
            data: {
                pageTitle: 'user-management.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/system/user-management/user-management-detail.html',
                    controller: 'UserManagementDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('user-management');
                    return $translate.refresh();
                }]
            }
        })
        .state('user-management.new', {
            parent: 'user-management',
            url: '/new',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
                Principal.identity().then(function(account) {
                	if (account != null) {
                		var isGroupAdmin = false;
                        if (account.authorities
                            && (account.authorities.indexOf("ROLE_ADMIN") !== -1
                                || account.authorities.indexOf("ROLE_DEVELOPER") !== -1)) {
                            isGroupAdmin = true;
                        } else {
                            account.groupAuthes.forEach(function (element) {
                                if (element.groupAuth === 'admin') {
                                    isGroupAdmin = true;
                                }
                            });
                        }
                        if (isGroupAdmin) {
                            $uibModal.open({
                                templateUrl: 'app/system/user-management/user-management-dialog.html',
                                controller: 'UserManagementDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    entity: function () {
                                        return {
                                            id: null, login: null, firstName: null, lastName: null, email: null,
                                            activated: true, created: false, langKey: null, createdBy: null, createdDate: null,
                                            lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                                            resetKey: null, authorities: null, groupAuthes: []
                                        };
                                    }
                                }
                            }).result.then(function() {
                                $state.go('user-management', null, { reload: true });
                            }, function() {
                                $state.go('user-management');
                            });
                        }
                	} else {
                		LoginService.open();
                	}

                });
            }]
        })
        .state('user-management.edit', {
            parent: 'user-management',
            url: '/edit/{login}',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
                Principal.identity().then(function(account) {
                	if (account != null) {
                		var isGroupAdmin = false;
                        if (account.authorities
                            && (account.authorities.indexOf("ROLE_ADMIN") !== -1
                                || account.authorities.indexOf("ROLE_DEVELOPER") !== -1)) {
                            isGroupAdmin = true;
                        } else {
                            account.groupAuthes.forEach(function (element) {
                                if (element.groupAuth === 'admin') {
                                    isGroupAdmin = true;
                                }
                            });
                        }
                        if (isGroupAdmin) {
                            $uibModal.open({
                                templateUrl: 'app/system/user-management/user-management-dialog.html',
                                controller: 'UserManagementDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg'
                            }).result.then(function() {
                                $state.go('user-management', null, { reload: true });
                            }, function() {
                                $state.go('^');
                            });
                        }
                	} else {
                		LoginService.open();
                	}

                });
            }]
        })
        .state('user-management.show', {
                parent: 'user-management',
                url: '/show/{login}',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
                    Principal.identity().then(function(account) {
                    	if (account != null) {
                    		var isGroupAdmin = false;
                            if (account.authorities
                                && (account.authorities.indexOf("ROLE_ADMIN") !== -1
                                    || account.authorities.indexOf("ROLE_DEVELOPER") !== -1)) {
                                isGroupAdmin = true;
                            } else {
                                account.groupAuthes.forEach(function (element) {
                                    if (element.groupAuth === 'admin') {
                                        isGroupAdmin = true;
                                    }
                                });
                            }
                            if (isGroupAdmin) {
                                $uibModal.open({
                                    templateUrl: 'app/system/user-management/user-management-detail.html',
                                    controller: 'UserManagementDetailController',
                                    controllerAs: 'vm',
                                    backdrop: 'static',
                                    size: 'md',
                                    resolve: {
                                        entity: ['User', function(User) {
                                            return User.get({login : $stateParams.login});
                                        }]
                                    }
                                }).result.then(function() {
                                    $state.go('user-management', null, { reload: true });
                                }, function() {
                                    $state.go('^');
                                });
                            }
                    	} else {
                    		LoginService.open();
                    	}

                    });
                }]
            })
        .state('user-management.createToken', {
                parent: 'user-management',
                url: '/usersToken/{jigyoushoCd}/{login}',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
                    Principal.identity().then(function(account) {
                    	if (account != null) {
                    		var isGroupAdmin = false;
                            if (account.authorities
                                && (account.authorities.indexOf("ROLE_ADMIN") !== -1
                                    || account.authorities.indexOf("ROLE_DEVELOPER") !== -1)) {
                                isGroupAdmin = true;
                            } else {
                                account.groupAuthes.forEach(function (element) {
                                    if (element.groupAuth === 'admin') {
                                        isGroupAdmin = true;
                                    }
                                });
                            }
                            if (isGroupAdmin) {
                                $uibModal.open({
                                    templateUrl: 'app/system/user-management/user-management-createtoken.html',
                                    controller: 'UserMmanagementCreatetokenController',
                                    controllerAs: 'vm',
                                    backdrop: 'static',
                                    size: 'lg',
                                    resolve: {
                                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                            $translatePartialLoader.addPart('user-management');
                                            return $translate.refresh();
                                        }]
                                    }
                                }).result.then(function() {
                                    /*$state.go('user-management', null, { reload: true });*/
                                }, function() {
                                    $state.go('^');
                                });
                            }
                    	} else {
                    		LoginService.open();
                    	}

                    });
                }]
            })
        .state('user-management.delete', {
            parent: 'user-management',
            url: '/{login}/delete',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
                Principal.identity().then(function(account) {
                	if (account != null) {
                		var isGroupAdmin = false;
                        if (account.authorities
                            && (account.authorities.indexOf("ROLE_ADMIN") !== -1
                                || account.authorities.indexOf("ROLE_DEVELOPER") !== -1)) {
                            isGroupAdmin = true;
                        } else {
                            account.groupAuthes.forEach(function (element) {
                                if (element.groupAuth === 'admin') {
                                    isGroupAdmin = true;
                                }
                            });
                        }
                        if (isGroupAdmin) {
                            $uibModal.open({
                                templateUrl: 'app/system/user-management/user-management-delete-dialog.html',
                                controller: 'UserManagementDeleteController',
                                controllerAs: 'vm',
                                size: 'md',
                                resolve: {
                                    entity: ['User', function(User) {
                                        return User.get({login : $stateParams.login});
                                    }]
                                }
                            }).result.then(function() {
                                $state.go('user-management', null, { reload: true });
                            }, function() {
                                $state.go('^');
                            });
                        }
                	} else {
                		LoginService.open();
                	}

                });
            }]
        })
        .state('user-management.addAll', {
            parent: 'user-management',
            url: '/addAll/{groupId}',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
                Principal.identity().then(function(account) {
                	if (account != null) {
                		var isGroupAdmin = false;
                        if (account.authorities
                            && (account.authorities.indexOf("ROLE_ADMIN") !== -1
                                || account.authorities.indexOf("ROLE_DEVELOPER") !== -1)) {
                            isGroupAdmin = true;
                        } else {
                            account.groupAuthes.forEach(function (element) {
                                if (element.groupAuth === 'admin') {
                                    isGroupAdmin = true;
                                }
                            });
                        }
                        if (isGroupAdmin) {
                            $uibModal.open({
                                templateUrl: 'app/system/user-management/user-management-add-all-dialog.html',
                                controller: 'UserManagementAddAllDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    staffList: ['User', function(User) {
                                        return User.getStaffs({groupId : $stateParams.groupId}, function(result) {
                                            for (var i = 0; i < result.length; i ++) {
                                                result[i].checked = true;
                                            }

                                        });
                                    }]
                                }
                            }).result.then(function() {
                                $state.go('user-management', null, { reload: true });
                            }, function() {
                                $state.go('user-management');
                            });
                        }
                	} else {
                		LoginService.open();
                	}

                });
            }]
        })
        .state('user-management.updateAll', {
            parent: 'user-management',
            url: '/updateAll/{:selected}',
            data: {
                pageTitle: 'user-management.updateAll.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/system/user-management/user-management-update-all.html',
                    controller: 'UserManagementUpdateAllController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('user-management');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
