(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('ShiftMonth', ShiftMonth);

    ShiftMonth.$inject = ['$resource'];

    function ShiftMonth ($resource) {
        var service = $resource('timecard/api/shiftmonth', {}, {
            'queryList': {
                method: 'GET',
                url: 'timecard/api/shiftmonth/getShiftMonthList',
                isArray: true
            },
            'saveShiftMonth': {
                method: 'POST',
                url: 'timecard/api/shiftmonth/saveShiftMonthList',
                isArray: false
            },
            'saveShiftMonthByTimeCard': {
                method: 'POST',
                url: 'timecard/api/shiftmonth/saveShiftMonthByTimeCard',
                isArray: true
            },
            'queryJisseki': {
                method: 'GET',
                url: 'timecard/api/shiftmonth/getJisseki',
                isArray: true
            },
            'queryYotei': {
                method: 'GET',
                url: 'timecard/api/shiftmonth/getYotei'
            },
            'updateShiftMonthDate': {
                method: 'POST',
                url: 'timecard/api/shiftmonth/updateShiftMonthDate',
                isArray: true
            }
        });

        return service;
    }

})();
