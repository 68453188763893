(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('StorageManagementPlanSameController', StorageManagementPlanSameController);

    StorageManagementPlanSameController.$inject = ['$uibModalInstance', 'StroageService', 'MessageService', '$timeout', '$rootScope'];

    function StorageManagementPlanSameController ($uibModalInstance, StroageService, MessageService, $timeout, $rootScope) {
        var vm = this;
        vm.clear = clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
