(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('StaffOccupationDetailController', StaffOccupationDetailController);

    StaffOccupationDetailController.$inject = ['$scope', '$uibModal', '$state', 'AlertService', '$timeout', '$stateParams', '$rootScope' , 'User', 'Timecard', 'StaffOccupation', 'ShiftDuty', 'ShiftOccupation', 'ShiftMonth', 'Group', 'pagingParams','paginationConstants','ParseLinks'];

    function StaffOccupationDetailController ($scope, $uibModal, $state, AlertService, $timeout,$stateParams,$rootScope, User, Timecard, StaffOccupation, ShiftDuty, ShiftOccupation, ShiftMonth, Group, pagingParams,paginationConstants,ParseLinks) {
        var vm = this;
        vm.selectFlag = false;
        vm.page = $stateParams.page;
        vm.page1 = $stateParams.page;
        vm.totalItems = null;
        vm.parentGroup = [];
        vm.dutyList = [];
        vm.occupationList = [];
        vm.resultList = [];
        vm.userList = [];
        vm.currentYear = $stateParams.currentYear;
        vm.currentMonth = $stateParams.currentMonth;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.userType = true;
        vm.pageNumber = 20;
        vm.startNumber = 0;
        vm.selectGroupFlag = false;
        vm.groupAllList = [];
        //页面加载时，取得所有的Group
        vm.loadParentGroup = loadParentGroup;
        //Group切换
        vm.changeGroup = changeGroup;
        vm.loadStaffOccupation = loadStaffOccupation;
        vm.transition = transition;
        vm.loadPage = loadPage;
        vm.loadDuty = loadDuty;
        vm.loadOccupation = loadOccupation;
        vm.goEdit = goEdit;
        vm.goCancel = goCancel;
        vm.goAdd = goAdd;
        vm.goDel = goDel;
        vm.save = save;
        vm.changeSelect = changeSelect;
        vm.rest = rest;
        vm.beforeMonth = beforeMonth;
        vm.nextMonth = nextMonth;
        vm.loadCalendar = loadCalendar;
        vm.reloadStaff = reloadStaff;
        vm.staffSingeAdd = staffSingeAdd;
        vm.nextMonthFlag = false;
        vm.staffIsNullFlag = false;

        if (!angular.isDefined(vm.currentYear) || vm.currentYear == "") {
            vm.currentYear = new Date().getFullYear();
        }
        if (!angular.isDefined(vm.currentMonth) || vm.currentMonth == "") {
            vm.currentMonth = new Date().getMonth()+1;
        }

        //页面加载后取得所有Group信息
        vm.loadParentGroup();
        vm.loadCalendar();

        function loadParentGroup() {
            Group.parentGroup({}, function(result){
                vm.parentGroup = result;
                if ($stateParams.groupId !== null) {
                    result.forEach(function(item){
                        if (item.id == $stateParams.groupId) {
                            vm.group = item;
                        }
                    });
                } else {
                    vm.group = vm.parentGroup[0];
                }
                vm.groupId = vm.group.id;
                vm.loadStaffOccupation();
            });
        }

        function changeGroup() {
            //vm.selectFlag = false;
            pagingParams.page = 1;
            /*if(vm.houjinFlag){
                vm.groupAllList.forEach(function (item) {
                    if(vm.groupId != item.id){
                        vm.selectGroupFlag = true;
                    }else {
                        vm.selectGroupFlag = false;
                    }
                });
            }*/
            vm.parentGroup.forEach(function(e){
                if (e.id == vm.groupId) {
                    vm.group = e;
                    vm.groupId = vm.group.id;
                    loadStaffOccupation();
                }
            });
        }

        function loadDuty() {
            Group.queryParentGroupId({"groupId":vm.group.id}, function (group) {
                //当前用户的父groupId
                var groupId = "gid" + group.groupId;
                ShiftDuty.getAllByGroupId({"groupId":groupId}, function(result){
                    vm.dutyList = result;
                });
            });
            /*//判断登录账号是不是法人
            Group.groupList({"depth": 0}, function(groupList){
                vm.groupAllList = groupList;
                for(var i=0; i<groupList.length; i++){
                    if(!vm.houjinFlag){
                        for(var x=0; x<groupList[i].flags.length; x++){
                            if(groupList[i].flags[x].id == 2){
                                vm.houjinFlag = true;
                                break;
                            }
                        }
                    }
                }
                //如果是法人，直接根据groupId查询
                if(vm.houjinFlag && !vm.selectGroupFlag){
                    ShiftDuty.getAllByGroupId({"groupId":vm.group.id}, function(result){
                        vm.dutyList = result;
                    });
                    //普通用户则查找他父节点的所有的数据
                }else {
                    Group.queryParentGroupId({"groupId":vm.group.id}, function (group) {
                        //当前用户的父groupId
                        var groupId = "gid" + group.groupId;
                        ShiftDuty.getAllByGroupId({"groupId":groupId}, function(result){
                            vm.dutyList = result;
                        });
                    });
                }
            });*/
        }

        function loadOccupation() {
            Group.queryParentGroupId({"groupId":vm.group.id}, function (group) {
                //当前用户的父groupId
                var groupId = "gid" + group.groupId;
                ShiftOccupation.getAllByGroupId({"groupId":groupId}, function(result){
                    vm.occupationList = result;
                });
            });
            /*//判断登录账号是不是法人
            Group.groupList({"depth": 0}, function(groupList){
                for(var i=0; i<groupList.length; i++){
                    if(!vm.houjinFlag){
                        for(var x=0; x<groupList[i].flags.length; x++){
                            if(groupList[i].flags[x].id == 2){
                                vm.houjinFlag = true;
                                break;
                            }
                        }
                    }
                }
                //如果是法人，直接根据groupId查询
                if(vm.houjinFlag && !vm.selectGroupFlag){
                    ShiftOccupation.getAllByGroupId({"groupId":vm.group.id}, function(result){
                        vm.occupationList = result;
                    });
                    //普通用户则查找他父节点的所有的数据
                }else {
                    Group.queryParentGroupId({"groupId":vm.group.id}, function (group) {
                        //当前用户的父groupId
                        var groupId = "gid" + group.groupId;
                        ShiftOccupation.getAllByGroupId({"groupId":groupId}, function(result){
                            vm.occupationList = result;
                        });
                    });
                }
            });*/
        }

        function loadStaffOccupation() {
            //查询シフト職員管理表中是否保存了数据
            vm.nextMonthFlag = false;
            vm.userType = false;
            StaffOccupation.queryUserByGroupId({
                groupId:vm.groupId,
                year: vm.currentYear,
                month: vm.currentMonth,
                nextMonthFlag: vm.nextMonthFlag,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage
            },function (result,headers) {
                if(result.length > 0){
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.page = pagingParams.page;
                    vm.page1 = pagingParams.page;
                    var data = {'userList':result, 'groupId':vm.groupId, 'year': vm.currentYear, 'month': vm.currentMonth, 'nextMonthFlag': vm.nextMonthFlag};
                    StaffOccupation.queryDutyOccupation(data,function(result){
                        vm.userList = result;
                        vm.resultList = angular.copy(result);
                        vm.loadDuty();
                        vm.loadOccupation();
                        if(vm.nextMonthFlag){
                            angular.forEach(vm.userList, function(data){
                                data.showFlag = true;
                                data.id = null;
                            });
                        }
                    },function(error){
                        AlertService.error(error.data.message);
                    });
                }else {
                    //如果当前月没数据,则取该group下的所有user
                    vm.userType = true;
                    User.queryAllByGroupId({groupId:vm.groupId},function(result){
                        vm.totalItems = result.length;
                        vm.queryCount = vm.totalItems;
                        vm.page = pagingParams.page;
                        vm.page1 = pagingParams.page;
                        vm.startNumber = ((vm.page - 1) * vm.itemsPerPage) === 0 ? 0 : ((vm.page - 1) * vm.itemsPerPage);
                        var data = {'users':result, 'groupId':vm.groupId, 'year': vm.currentYear, 'month': vm.currentMonth};
                        StaffOccupation.queryDuty(data,function(result){
                            vm.userList = result;
                            vm.resultList = angular.copy(result);
                            vm.loadDuty();
                            vm.loadOccupation();
                        },function(error){
                            AlertService.error(error.data.message);
                        });
                    });
                }
            });
        }

        function reloadStaff () {
            //删除之前登录的数据
            if(confirm("シフト職員管理と月次シフト表の設定をクリアしますが、再読み込みしてもよろしいでしょうか")){
                var data = {'groupId':vm.groupId, 'year': vm.currentYear, 'month': vm.currentMonth};
                StaffOccupation.deletedStaffOccupation(data,function(result){
                    if(result){
                        vm.userType = true;
                        User.queryAllByGroupId({groupId:vm.groupId},function(result){
                            vm.totalItems = result.length;
                            vm.queryCount = vm.totalItems;
                            vm.page = pagingParams.page;
                            vm.page1 = pagingParams.page;
                            vm.startNumber = ((vm.page - 1) * vm.itemsPerPage) === 0 ? 0 : ((vm.page - 1) * vm.itemsPerPage);
                            var data = {'users':result, 'groupId':vm.groupId, 'year': vm.currentYear, 'month': vm.currentMonth};
                            StaffOccupation.queryDuty(data,function(result){
                                vm.userList = result;
                                vm.resultList = angular.copy(result);
                                vm.loadDuty();
                                vm.loadOccupation();
                            },function(error){
                                AlertService.error(error.data.message);
                            });
                        });
                    }
                },function(error){
                    AlertService.error(error.data.message);
                });
            }
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            if(vm.selectFlag == true){
                alert("保存してないデータがありまして、先に保存してください。")
                vm.page = vm.page1;
                return false;
            }
            /*if(vm.houjinFlag){
                vm.groupAllList.forEach(function (item) {
                    if(vm.groupId != item.id){
                        vm.selectGroupFlag = true;
                    }else {
                        vm.selectGroupFlag = false;
                    }
                });
            }*/
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                groupId: vm.groupId == null ? "" : vm.groupId,
                currentYear:vm.currentYear,
                currentMonth:vm.currentMonth
            });
        }

        function goEdit(user){
            user.showFlag = true;
            vm.selectFlag = true;
        }

        function goCancel(user){
            user.showFlag = false;
            vm.resultList.forEach(function (result){
                if(result.id === user.id){
                    user.occupationId = result.occupationId;
                    user.dutyId = result.dutyId;
                }
            });
        }

        function goAdd(index, user){
            vm.pageNumber += 1;
            vm.selectFlag = true;
            var u = {};
            u.id = null;
            u.userId = user.userId;
            u.userName = user.userName;
            u.occupationId = '';
            u.dutyId = '';
            u.showFlag = true;
            u.firstFlag = false;
            u.deleteFlag = false;
            u.rowspan = user.rowspan;
            vm.userList.splice(((vm.page-1)*20)+index+1,0,u);
            vm.userList.forEach(function(result){
                if(user.userId == result.userId){
                    result.rowspan += 1;
                }
            });
        }

        function goDel(index, user){
            if(confirm('該当スタッフをシフト管理から外してもよろしいでしょうか？')){
                if(!user.showFlag){
                    ShiftMonth.queryJisseki({staffOccupationId:user.staffOccupationId}, function(result){
                        if(result.length > 0) {
                            if(confirm('シフト職員データを削除するとともに関連シフトデータも削除してよろしいでしょうか？')){
                                doDeleted(user);
                            }
                        }else {
                            doDeleted(user);
                        }
                    });
                }else {
                    doDeleted(user);
                }
            }
        }

        function doDeleted(user) {
            vm.userList.forEach(function(result, i){
                if(user.userId == result.userId){
                    user.deleteFlag = true;
                    //if(result.rowspan > 1){
                    result.rowspan -= 1;
                    //}
                    if(result.firstFlag && result.deleteFlag){
                        if(vm.userList.length>1){
                            vm.userList[i+1].firstFlag = true;
                        }
                    }
                }
            });
        }

        function save(){
            if(confirm("一括保存してよろしいでしょうか")){
                //当前用户的父groupId
                Group.queryParentGroupId({"groupId":vm.group.id}, function (group) {
                    var pGroupId = "gid" + group.groupId;
                    StaffOccupation.save({groupId:vm.group.id,userList:vm.userList, year: vm.currentYear, month: vm.currentMonth, parentGroupId: pGroupId}, onSaveSuccess, onSaveError);
                });
            }
        }

        function onSaveSuccess (result) {
            vm.selectFlag = false;
            vm.staffIsNullFlag = false;
            loadStaffOccupation();
        }

        function onSaveError (result) {
            vm.userList = result.data.userList;
        }

        function changeSelect(){
            vm.selectFlag = true;
        }

        function rest(){
            vm.selectFlag = false;
            pagingParams.page = 1;
            vm.currentYear = new Date().getFullYear();
            vm.currentMonth = new Date().getMonth()+1;
            vm.loadStaffOccupation();
            loadCalendar();
        }

        function beforeMonth(){
            vm.selectFlag = false;
            pagingParams.page = 1;
            if (Number(vm.currentMonth) === 1) {
                vm.currentMonth = 12;
                vm.currentYear = Number(vm.currentYear) - 1;
            } else {
                vm.currentMonth = Number(vm.currentMonth) - 1;
            }
            vm.loadStaffOccupation();
            loadCalendar();
        }

        function nextMonth(){
            vm.selectFlag = false;
            pagingParams.page = 1;
            if (Number(vm.currentMonth) === 12) {
                vm.currentMonth = 1;
                vm.currentYear = Number(vm.currentYear) + 1;
            } else {
                vm.currentMonth = Number(vm.currentMonth) + 1;
            }
            vm.loadStaffOccupation();
            loadCalendar();
        }

        function loadCalendar() {
            vm.calendar = Timecard.calendar({'year':vm.currentYear,'month':vm.currentMonth});
        }

        function staffSingeAdd() {
            $rootScope.userList = vm.userList;
            $rootScope.groupId = vm.groupId;
            var modalInstance = $uibModal.open({
                templateUrl: 'app/timecard/staff-occupation/staff-single-add.html',
                controller: 'StaffSingleAddController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: {}
                }
            });

            modalInstance.result.then(function(result) {
                $state.go('staff-occupation', null, { reload: true });
            },function(){
                if(!$rootScope.addFlag){
                    $state.go('staff-occupation');
                    var user = $rootScope.user;
                    if(user.lastName == null) {
                        user.lastName = '';
                    }
                    if(user.firstName == null) {
                        user.firstName = '';
                    }
                    vm.selectFlag = true;
                    var u = {};
                    u.id = null;
                    u.userId = user.id;
                    u.groupId = vm.groupId;
                    u.userName = user.lastName + ' ' + user.firstName;
                    u.occupationId = '';
                    u.dutyId = '';
                    u.showFlag = true;
                    u.firstFlag = true;
                    u.deleteFlag = false;
                    u.rowspan = 1;
                    vm.userList.splice(0,0,u);
                }

            })
            vm.staffIsNullFlag = true;
            //$state.go('staff-occupation.selectuser');
        }
    }
})();
