(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Collection', Collection);

    Collection.$inject = ['$resource'];

    function Collection ($resource) {
        var service = $resource('robot/api/collection', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                url: 'robot/api/collections/pepperId/:pepperId'
            },
            'save': {
                method:'POST'
            },
            'delete':{
                method:'DELETE',
                url:'robot/api/collection/pepperId/:pepperId/scriptId/:scriptId'
            }
        });

        return service;
    }

})();
