(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('KeiyakuplanManagementController', KeiyakuplanManagementController);

    KeiyakuplanManagementController.$inject = ['Principal', 'AlertService', '$timeout', 'Enum', 'Kinou', 'ParseLinks', 'MessageService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService', 'Group', '$filter', 'SelectedGroup', '$scope', '$rootScope'];

    function KeiyakuplanManagementController(Principal, AlertService, $timeout, Enum, Kinou, ParseLinks, MessageService, $state, pagingParams, paginationConstants, JhiLanguageService, Group, $filter, SelectedGroup, $scope, $rootScope) {
        var vm = this;
        vm.searchData = {keiyakuplanName: "", keiyakuplanCode: ""};
        vm.defaultSearchData = {keiyakuplanName: "", keiyakuplanCode: ""};
        vm.kinouList = [];
        vm.permissionList = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.clear = clear;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        //vm.transition = transition;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.loadAll();
        vm.goKeiyakuplan = goKeiyakuplan;

        vm.permission1;
        vm.permission2;
        vm.permission3;
        vm.permission4;
        vm.permission5;
        vm.permission6;
        vm.permission7;
        vm.permission8;
        vm.permission9;

        vm.showFlag1 = false;
        vm.showFlag2 = false;
        vm.showFlag3 = false;
        vm.showFlag4 = false;
        vm.showFlag5 = false;
        vm.showFlag6 = false;
        vm.showFlag7 = false;
        vm.showFlag8 = false;
        vm.showFlag9 = false;

        vm.yikuoShow11 = false;
        vm.yikuoShow12 = false;
        vm.yikuoShow13 = false;
        vm.yikuoShow14 = false;
        vm.yikuoShow21 = false;
        vm.yikuoShow22 = false;
        vm.yikuoShow23 = false;
        vm.yikuoShow24 = false;
        vm.yikuoShow31 = false;
        vm.yikuoShow32 = false;
        vm.yikuoShow33 = false;
        vm.yikuoShow34 = false;
        vm.yikuoShow41 = false;
        vm.yikuoShow42 = false;
        vm.yikuoShow43 = false;
        vm.yikuoShow44 = false;
        vm.yikuoShow51 = false;
        vm.yikuoShow52 = false;
        vm.yikuoShow53 = false;
        vm.yikuoShow54 = false;
        vm.yikuoShow61 = false;
        vm.yikuoShow62 = false;
        vm.yikuoShow63 = false;
        vm.yikuoShow64 = false;
        vm.yikuoShow71 = false;
        vm.yikuoShow72 = false;
        vm.yikuoShow73 = false;
        vm.yikuoShow74 = false;
        vm.yikuoShow81 = false;
        vm.yikuoShow82 = false;
        vm.yikuoShow83 = false;
        vm.yikuoShow84 = false;
        vm.yikuoShow91 = false;
        vm.yikuoShow92 = false;
        vm.yikuoShow93 = false;
        vm.yikuoShow94 = false;
        vm.aClick1 = aClick1;
        vm.aClick2 = aClick2;
        vm.aClick3 = aClick3;
        vm.aClick4 = aClick4;
        vm.aClick5 = aClick5;
        vm.aClick6 = aClick6;
        vm.aClick7 = aClick7;
        vm.aClick8 = aClick8;
        vm.aClick9 = aClick9;

        vm.yikuoaClick1 = yikuoaClick1;
        vm.yikuoaClick2 = yikuoaClick2;
        vm.yikuoaClick3 = yikuoaClick3;
        vm.yikuoaClick4 = yikuoaClick4;
        vm.yikuoaClick5 = yikuoaClick5;
        vm.yikuoaClick6 = yikuoaClick6;
        vm.yikuoaClick7 = yikuoaClick7;
        vm.yikuoaClick8 = yikuoaClick8;
        vm.yikuoaClick9 = yikuoaClick9;

        function aClick1(kinou, value){
            if(value == 1){
                kinou.show11 = true;
                kinou.show12 = false;
                kinou.show13 = false;
                kinou.show14 = false;
            }
            if(value == 2){
                kinou.show11 = false;
                kinou.show12 = true;
                kinou.show13 = false;
                kinou.show14 = false;
            }
            if(value == 3){
                kinou.show11 = false;
                kinou.show12 = false;
                kinou.show13 = true;
                kinou.show14 = false;
            }
            if(value == 4){
                kinou.show11 = false;
                kinou.show12 = false;
                kinou.show13 = false;
                kinou.show14 = true;
            }
            saveSingle(1,kinou);
        }
        function aClick2(kinou, value){
            if(value == 1){
                kinou.show21 = true;
                kinou.show22 = false;
                kinou.show23 = false;
                kinou.show24 = false;
            }
            if(value == 2){
                kinou.show21 = false;
                kinou.show22 = true;
                kinou.show23 = false;
                kinou.show24 = false;
            }
            if(value == 3){
                kinou.show21 = false;
                kinou.show22 = false;
                kinou.show23 = true;
                kinou.show24 = false;
            }
            if(value == 4){
                kinou.show21 = false;
                kinou.show22 = false;
                kinou.show23 = false;
                kinou.show24 = true;
            }
            saveSingle(2,kinou);
        }
        function aClick3(kinou, value){
            if(value == 1){
                kinou.show31 = true;
                kinou.show32 = false;
                kinou.show33 = false;
                kinou.show34 = false;
            }
            if(value == 2){
                kinou.show31 = false;
                kinou.show32 = true;
                kinou.show33 = false;
                kinou.show34 = false;
            }
            if(value == 3){
                kinou.show31 = false;
                kinou.show32 = false;
                kinou.show33 = true;
                kinou.show34 = false;
            }
            if(value == 4){
                kinou.show31 = false;
                kinou.show32 = false;
                kinou.show33 = false;
                kinou.show34 = true;
            }
            saveSingle(3,kinou);
        }
        function aClick4(kinou, value){
            if(value == 1){
                kinou.show41 = true;
                kinou.show42 = false;
                kinou.show43 = false;
                kinou.show44 = false;
            }
            if(value == 2){
                kinou.show41 = false;
                kinou.show42 = true;
                kinou.show43 = false;
                kinou.show44 = false;
            }
            if(value == 3){
                kinou.show41 = false;
                kinou.show42 = false;
                kinou.show43 = true;
                kinou.show44 = false;
            }
            if(value == 4){
                kinou.show41 = false;
                kinou.show42 = false;
                kinou.show43 = false;
                kinou.show44 = true;
            }
            saveSingle(4,kinou);
        }
        function aClick5(kinou, value){
            if(value == 1){
                kinou.show51 = true;
                kinou.show52 = false;
                kinou.show53 = false;
                kinou.show54 = false;
            }
            if(value == 2){
                kinou.show51 = false;
                kinou.show52 = true;
                kinou.show53 = false;
                kinou.show54 = false;
            }
            if(value == 3){
                kinou.show51 = false;
                kinou.show52 = false;
                kinou.show53 = true;
                kinou.show54 = false;
            }
            if(value == 4){
                kinou.show51 = false;
                kinou.show52 = false;
                kinou.show53 = false;
                kinou.show54 = true;
            }
            saveSingle(5,kinou);
        }
        function aClick6(kinou, value){
            if(value == 1){
                kinou.show61 = true;
                kinou.show62 = false;
                kinou.show63 = false;
                kinou.show64 = false;
            }
            if(value == 2){
                kinou.show61 = false;
                kinou.show62 = true;
                kinou.show63 = false;
                kinou.show64 = false;
            }
            if(value == 3){
                kinou.show61 = false;
                kinou.show62 = false;
                kinou.show63 = true;
                kinou.show64 = false;
            }
            if(value == 4){
                kinou.show61 = false;
                kinou.show62 = false;
                kinou.show63 = false;
                kinou.show64 = true;
            }
            saveSingle(6,kinou);
        }
        function aClick7(kinou, value){
            if(value == 1){
                kinou.show71 = true;
                kinou.show72 = false;
                kinou.show73 = false;
                kinou.show74 = false;
            }
            if(value == 2){
                kinou.show71 = false;
                kinou.show72 = true;
                kinou.show73 = false;
                kinou.show74 = false;
            }
            if(value == 3){
                kinou.show71 = false;
                kinou.show72 = false;
                kinou.show73 = true;
                kinou.show74 = false;
            }
            if(value == 4){
                kinou.show71 = false;
                kinou.show72 = false;
                kinou.show73 = false;
                kinou.show74 = true;
            }
            saveSingle(7,kinou);
        }
        function aClick8(kinou, value){
            if(value == 1){
                kinou.show81 = true;
                kinou.show82 = false;
                kinou.show83 = false;
                kinou.show84 = false;
            }
            if(value == 2){
                kinou.show81 = false;
                kinou.show82 = true;
                kinou.show83 = false;
                kinou.show84 = false;
            }
            if(value == 3){
                kinou.show81 = false;
                kinou.show82 = false;
                kinou.show83 = true;
                kinou.show84 = false;
            }
            if(value == 4){
                kinou.show81 = false;
                kinou.show82 = false;
                kinou.show83 = false;
                kinou.show84 = true;
            }
            saveSingle(8,kinou);
        }
        function aClick9(kinou, value){
            if(value == 1){
                kinou.show91 = true;
                kinou.show92 = false;
                kinou.show93 = false;
                kinou.show94 = false;
            }
            if(value == 2){
                kinou.show91 = false;
                kinou.show92 = true;
                kinou.show93 = false;
                kinou.show94 = false;
            }
            if(value == 3){
                kinou.show91 = false;
                kinou.show92 = false;
                kinou.show93 = true;
                kinou.show94 = false;
            }
            if(value == 4){
                kinou.show91 = false;
                kinou.show92 = false;
                kinou.show93 = false;
                kinou.show94 = true;
            }
            saveSingle(9,kinou);
        }

        function yikuoaClick1(value){
            if(value == 1){
                vm.yikuoShow11 = true;
                vm.yikuoShow12 = false;
                vm.yikuoShow13 = false;
                vm.yikuoShow14 = false;
            }
            if(value == 2){
                vm.yikuoShow11 = false;
                vm.yikuoShow12 = true;
                vm.yikuoShow13 = false;
                vm.yikuoShow14 = false;
            }
            if(value == 3){
                vm.yikuoShow11 = false;
                vm.yikuoShow12 = false;
                vm.yikuoShow13 = true;
                vm.yikuoShow14 = false;
            }
            if(value == 4){
                vm.yikuoShow11 = false;
                vm.yikuoShow12 = false;
                vm.yikuoShow13 = false;
                vm.yikuoShow14 = true;
            }

            $.each(vm.kinouList,function(n,kinou) {
                if(value == 1){
                    kinou.show11 = true;
                    kinou.show12 = false;
                    kinou.show13 = false;
                    kinou.show14 = false;
                }
                if(value == 2){
                    kinou.show11 = false;
                    kinou.show12 = true;
                    kinou.show13 = false;
                    kinou.show14 = false;
                }
                if(value == 3){
                    kinou.show11 = false;
                    kinou.show12 = false;
                    kinou.show13 = true;
                    kinou.show14 = false;
                }
                if(value == 4){
                    kinou.show11 = false;
                    kinou.show12 = false;
                    kinou.show13 = false;
                    kinou.show14 = true;
                }
            });
            save(1);
        }
        function yikuoaClick2(value){
            if(value == 1){
                vm.yikuoShow21 = true;
                vm.yikuoShow22 = false;
                vm.yikuoShow23 = false;
                vm.yikuoShow24 = false;
            }
            if(value == 2){
                vm.yikuoShow21 = false;
                vm.yikuoShow22 = true;
                vm.yikuoShow23 = false;
                vm.yikuoShow24 = false;
            }
            if(value == 3){
                vm.yikuoShow21 = false;
                vm.yikuoShow22 = false;
                vm.yikuoShow23 = true;
                vm.yikuoShow24 = false;
            }
            if(value == 4){
                vm.yikuoShow21 = false;
                vm.yikuoShow22 = false;
                vm.yikuoShow23 = false;
                vm.yikuoShow24 = true;
            }

            $.each(vm.kinouList,function(n,kinou) {
                if(value == 1){
                    kinou.show21 = true;
                    kinou.show22 = false;
                    kinou.show23 = false;
                    kinou.show24 = false;
                }
                if(value == 2){
                    kinou.show21 = false;
                    kinou.show22 = true;
                    kinou.show23 = false;
                    kinou.show24 = false;
                }
                if(value == 3){
                    kinou.show21 = false;
                    kinou.show22 = false;
                    kinou.show23 = true;
                    kinou.show24 = false;
                }
                if(value == 4){
                    kinou.show21 = false;
                    kinou.show22 = false;
                    kinou.show23 = false;
                    kinou.show24 = true;
                }
            });
            save(2);
        }
        function yikuoaClick3(value){
            if(value == 1){
                vm.yikuoShow31 = true;
                vm.yikuoShow32 = false;
                vm.yikuoShow33 = false;
                vm.yikuoShow34 = false;
            }
            if(value == 2){
                vm.yikuoShow31 = false;
                vm.yikuoShow32 = true;
                vm.yikuoShow33 = false;
                vm.yikuoShow34 = false;
            }
            if(value == 3){
                vm.yikuoShow31 = false;
                vm.yikuoShow32 = false;
                vm.yikuoShow33 = true;
                vm.yikuoShow34 = false;
            }
            if(value == 4){
                vm.yikuoShow31 = false;
                vm.yikuoShow32 = false;
                vm.yikuoShow33 = false;
                vm.yikuoShow34 = true;
            }

            $.each(vm.kinouList,function(n,kinou) {
                if(value == 1){
                    kinou.show31 = true;
                    kinou.show32 = false;
                    kinou.show33 = false;
                    kinou.show34 = false;
                }
                if(value == 2){
                    kinou.show31 = false;
                    kinou.show32 = true;
                    kinou.show33 = false;
                    kinou.show34 = false;
                }
                if(value == 3){
                    kinou.show31 = false;
                    kinou.show32 = false;
                    kinou.show33 = true;
                    kinou.show34 = false;
                }
                if(value == 4){
                    kinou.show31 = false;
                    kinou.show32 = false;
                    kinou.show33 = false;
                    kinou.show34 = true;
                }
            });
            save(3);
        }
        function yikuoaClick4(value){
            if(value == 1){
                vm.yikuoShow41 = true;
                vm.yikuoShow42 = false;
                vm.yikuoShow43 = false;
                vm.yikuoShow44 = false;
            }
            if(value == 2){
                vm.yikuoShow41 = false;
                vm.yikuoShow42 = true;
                vm.yikuoShow43 = false;
                vm.yikuoShow44 = false;
            }
            if(value == 3){
                vm.yikuoShow41 = false;
                vm.yikuoShow42 = false;
                vm.yikuoShow43 = true;
                vm.yikuoShow44 = false;
            }
            if(value == 4){
                vm.yikuoShow41 = false;
                vm.yikuoShow42 = false;
                vm.yikuoShow43 = false;
                vm.yikuoShow44 = true;
            }

            $.each(vm.kinouList,function(n,kinou) {
                if(value == 1){
                    kinou.show41 = true;
                    kinou.show42 = false;
                    kinou.show43 = false;
                    kinou.show44 = false;
                }
                if(value == 2){
                    kinou.show41 = false;
                    kinou.show42 = true;
                    kinou.show43 = false;
                    kinou.show44 = false;
                }
                if(value == 3){
                    kinou.show41 = false;
                    kinou.show42 = false;
                    kinou.show43 = true;
                    kinou.show44 = false;
                }
                if(value == 4){
                    kinou.show41 = false;
                    kinou.show42 = false;
                    kinou.show43 = false;
                    kinou.show44 = true;
                }
            });
            save(4);
        }
        function yikuoaClick5(value){
            if(value == 1){
                vm.yikuoShow51 = true;
                vm.yikuoShow52 = false;
                vm.yikuoShow53 = false;
                vm.yikuoShow54 = false;
            }
            if(value == 2){
                vm.yikuoShow51 = false;
                vm.yikuoShow52 = true;
                vm.yikuoShow53 = false;
                vm.yikuoShow54 = false;
            }
            if(value == 3){
                vm.yikuoShow51 = false;
                vm.yikuoShow52 = false;
                vm.yikuoShow53 = true;
                vm.yikuoShow54 = false;
            }
            if(value == 4){
                vm.yikuoShow51 = false;
                vm.yikuoShow52 = false;
                vm.yikuoShow53 = false;
                vm.yikuoShow54 = true;
            }

            $.each(vm.kinouList,function(n,kinou) {
                if(value == 1){
                    kinou.show51 = true;
                    kinou.show52 = false;
                    kinou.show53 = false;
                    kinou.show54 = false;
                }
                if(value == 2){
                    kinou.show51 = false;
                    kinou.show52 = true;
                    kinou.show53 = false;
                    kinou.show54 = false;
                }
                if(value == 3){
                    kinou.show51 = false;
                    kinou.show52 = false;
                    kinou.show53 = true;
                    kinou.show54 = false;
                }
                if(value == 4){
                    kinou.show51 = false;
                    kinou.show52 = false;
                    kinou.show53 = false;
                    kinou.show54 = true;
                }
            });
            save(5);
        }
        function yikuoaClick6(value){
            if(value == 1){
                vm.yikuoShow61 = true;
                vm.yikuoShow62 = false;
                vm.yikuoShow63 = false;
                vm.yikuoShow64 = false;
            }
            if(value == 2){
                vm.yikuoShow61 = false;
                vm.yikuoShow62 = true;
                vm.yikuoShow63 = false;
                vm.yikuoShow64 = false;
            }
            if(value == 3){
                vm.yikuoShow61 = false;
                vm.yikuoShow62 = false;
                vm.yikuoShow63 = true;
                vm.yikuoShow64 = false;
            }
            if(value == 4){
                vm.yikuoShow61 = false;
                vm.yikuoShow62 = false;
                vm.yikuoShow63 = false;
                vm.yikuoShow64 = true;
            }

            $.each(vm.kinouList,function(n,kinou) {
                if(value == 1){
                    kinou.show61 = true;
                    kinou.show62 = false;
                    kinou.show63 = false;
                    kinou.show64 = false;
                }
                if(value == 2){
                    kinou.show61 = false;
                    kinou.show62 = true;
                    kinou.show63 = false;
                    kinou.show64 = false;
                }
                if(value == 3){
                    kinou.show61 = false;
                    kinou.show62 = false;
                    kinou.show63 = true;
                    kinou.show64 = false;
                }
                if(value == 4){
                    kinou.show61 = false;
                    kinou.show62 = false;
                    kinou.show63 = false;
                    kinou.show64 = true;
                }
            });
            save(6);
        }
        function yikuoaClick7(value){
            if(value == 1){
                vm.yikuoShow71 = true;
                vm.yikuoShow72 = false;
                vm.yikuoShow73 = false;
                vm.yikuoShow74 = false;
            }
            if(value == 2){
                vm.yikuoShow71 = false;
                vm.yikuoShow72 = true;
                vm.yikuoShow73 = false;
                vm.yikuoShow74 = false;
            }
            if(value == 3){
                vm.yikuoShow71 = false;
                vm.yikuoShow72 = false;
                vm.yikuoShow73 = true;
                vm.yikuoShow74 = false;
            }
            if(value == 4){
                vm.yikuoShow71 = false;
                vm.yikuoShow72 = false;
                vm.yikuoShow73 = false;
                vm.yikuoShow74 = true;
            }

            $.each(vm.kinouList,function(n,kinou) {
                if(value == 1){
                    kinou.show71 = true;
                    kinou.show72 = false;
                    kinou.show73 = false;
                    kinou.show74 = false;
                }
                if(value == 2){
                    kinou.show71 = false;
                    kinou.show72 = true;
                    kinou.show73 = false;
                    kinou.show74 = false;
                }
                if(value == 3){
                    kinou.show71 = false;
                    kinou.show72 = false;
                    kinou.show73 = true;
                    kinou.show74 = false;
                }
                if(value == 4){
                    kinou.show71 = false;
                    kinou.show72 = false;
                    kinou.show73 = false;
                    kinou.show74 = true;
                }
            });
            save(7);
        }
        function yikuoaClick8(value){
            if(value == 1){
                vm.yikuoShow81 = true;
                vm.yikuoShow82 = false;
                vm.yikuoShow83 = false;
                vm.yikuoShow84 = false;
            }
            if(value == 2){
                vm.yikuoShow81 = false;
                vm.yikuoShow82 = true;
                vm.yikuoShow83 = false;
                vm.yikuoShow84 = false;
            }
            if(value == 3){
                vm.yikuoShow81 = false;
                vm.yikuoShow82 = false;
                vm.yikuoShow83 = true;
                vm.yikuoShow84 = false;
            }
            if(value == 4){
                vm.yikuoShow81 = false;
                vm.yikuoShow82 = false;
                vm.yikuoShow83 = false;
                vm.yikuoShow84 = true;
            }

            $.each(vm.kinouList,function(n,kinou) {
                if(value == 1){
                    kinou.show81 = true;
                    kinou.show82 = false;
                    kinou.show83 = false;
                    kinou.show84 = false;
                }
                if(value == 2){
                    kinou.show81 = false;
                    kinou.show82 = true;
                    kinou.show83 = false;
                    kinou.show84 = false;
                }
                if(value == 3){
                    kinou.show81 = false;
                    kinou.show82 = false;
                    kinou.show83 = true;
                    kinou.show84 = false;
                }
                if(value == 4){
                    kinou.show81 = false;
                    kinou.show82 = false;
                    kinou.show83 = false;
                    kinou.show84 = true;
                }
            });
            save(8);
        }
        function yikuoaClick9(value){
            if(value == 1){
                vm.yikuoShow91 = true;
                vm.yikuoShow92 = false;
                vm.yikuoShow93 = false;
                vm.yikuoShow94 = false;
            }
            if(value == 2){
                vm.yikuoShow91 = false;
                vm.yikuoShow92 = true;
                vm.yikuoShow93 = false;
                vm.yikuoShow94 = false;
            }
            if(value == 3){
                vm.yikuoShow91 = false;
                vm.yikuoShow92 = false;
                vm.yikuoShow93 = true;
                vm.yikuoShow94 = false;
            }
            if(value == 4){
                vm.yikuoShow91 = false;
                vm.yikuoShow92 = false;
                vm.yikuoShow93 = false;
                vm.yikuoShow94 = true;
            }

            $.each(vm.kinouList,function(n,kinou) {
                if(value == 1){
                    kinou.show91 = true;
                    kinou.show92 = false;
                    kinou.show93 = false;
                    kinou.show94 = false;
                }
                if(value == 2){
                    kinou.show91 = false;
                    kinou.show92 = true;
                    kinou.show93 = false;
                    kinou.show94 = false;
                }
                if(value == 3){
                    kinou.show91 = false;
                    kinou.show92 = false;
                    kinou.show93 = true;
                    kinou.show94 = false;
                }
                if(value == 4){
                    kinou.show91 = false;
                    kinou.show92 = false;
                    kinou.show93 = false;
                    kinou.show94 = true;
                }
            });
            save(9);
        }
        function loadAll () {
            if($rootScope.page != undefined){
                vm.page = $rootScope.page;
                $rootScope.page = undefined;
            }
            if($rootScope.keiyakuplanName != undefined){
                vm.searchData.keiyakuplanName = $rootScope.keiyakuplanName;
                $rootScope.keiyakuplanName = undefined;
            }
            if($rootScope.keiyakuplanCode != undefined){
                vm.searchData.keiyakuplanCode = $rootScope.keiyakuplanCode;
                $rootScope.keiyakuplanCode = undefined;
            }
            Enum.getGateWayTwo({name: 'Permission'}, function(result) {
                vm.permissionList = result;
            });
            Kinou.queryPlan({
                page: vm.page - 1,
                size: vm.itemsPerPage,
                name: vm.searchData.keiyakuplanName,
                code: vm.searchData.keiyakuplanCode,
                sort: sort()
            }, onSuccess, onError);
        }

        function search(){
            vm.page = 1;
            loadAll();
        }

        function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.kinouList = data;
            $.each(vm.kinouList,function(n,value) {
                if(value.permission1 == 1){
                    value.show11 = true;
                } else if(value.permission1 == 2){
                    value.show12 = true;
                } else if(value.permission1 == 3){
                    value.show13 = true;
                } else if(value.permission1 == 4){
                    value.show14 = true;
                }

                if(value.permission2 == 1){
                    value.show21 = true;
                } else if(value.permission2 == 2){
                    value.show22 = true;
                } else if(value.permission2 == 3){
                    value.show23 = true;
                } else if(value.permission2 == 4){
                    value.show24 = true;
                }

                if(value.permission3 == 1){
                    value.show31 = true;
                } else if(value.permission3 == 2){
                    value.show32 = true;
                } else if(value.permission3 == 3){
                    value.show33 = true;
                } else if(value.permission3 == 4){
                    value.show34 = true;
                }

                if(value.permission4 == 1){
                    value.show41 = true;
                } else if(value.permission4 == 2){
                    value.show42 = true;
                } else if(value.permission4 == 3){
                    value.show43 = true;
                } else if(value.permission4 == 4){
                    value.show44 = true;
                }

                if(value.permission5 == 1){
                    value.show51 = true;
                } else if(value.permission5 == 2){
                    value.show52 = true;
                } else if(value.permission5 == 3){
                    value.show53 = true;
                } else if(value.permission5 == 4){
                    value.show54 = true;
                }

                if(value.permission6 == 1){
                    value.show61 = true;
                } else if(value.permission6 == 2){
                    value.show62 = true;
                } else if(value.permission6 == 3){
                    value.show63 = true;
                } else if(value.permission6 == 4){
                    value.show64 = true;
                }

                if(value.permission7 == 1){
                    value.show71 = true;
                } else if(value.permission7 == 2){
                    value.show72 = true;
                } else if(value.permission7 == 3){
                    value.show73 = true;
                } else if(value.permission7 == 4){
                    value.show74 = true;
                }

                if(value.permission8 == 1){
                    value.show81 = true;
                } else if(value.permission8 == 2){
                    value.show82 = true;
                } else if(value.permission8 == 3){
                    value.show83 = true;;
                } else if(value.permission8 == 4){
                    value.show84 = true;
                }

                if(value.permission9 == 1){
                    value.show91 = true;
                } else if(value.permission9 == 2){
                    value.show92 = true;
                } else if(value.permission9 == 3){
                    value.show93 = true;
                } else if(value.permission9 == 4){
                    value.show94 = true;
                }
            });
            setTimeout(function() {
                //屏幕高度
                var height = document.documentElement.clientHeight;
                //导航部分的高度
                var navbarHeight = $("#navbarHeight").height();//20
                //检索部分的高度
                var searchHeight = $("#searchHeight").height();//15
                //表头高度
                var headerHeight = $("#headerHeight").height();//
                //分页部分高度
                var pageHeight = $("#pageHeight").height();
                //copyRight
                var copyRight = $("#copyRight").height();
                var tableDivHeight = height-navbarHeight-20-searchHeight-headerHeight-20-pageHeight-10-copyRight-10-40;
                $("#tableDiv").css("max-height",tableDivHeight);
            }, 20);
        }

        function onError(){
        }

        function clear () {
            vm.searchData = angular.copy(vm.defaultSearchData);
            search();
        }

        function loadPage (page) {
            vm.page = page;
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }

        function goKeiyakuplan(kinouId){
            $rootScope.keiyakuplanName = vm.searchData.keiyakuplanName;
            $rootScope.keiyakuplanCode = vm.searchData.keiyakuplanCode;
            $rootScope.page = vm.page;
            $state.go('keiyakuplan-management.keiyakuplan', {kinouId: kinouId});
        }

        function save(planId){
            var entity = angular.copy(vm.kinouList);
            Kinou.svaeKeiyakuplanList({planId: planId}, entity, function(result){
                MessageService.success("keiyakuplanManagement.home.keiyakuplanSaved");
            },function(error){
                AlertService.error(error.data.message);
            });
        }

        function saveSingle(planId, kinou){
            var entity = angular.copy(kinou);
            Kinou.svaeKeiyakuplanSingle({planId: planId}, entity, function(result){
                MessageService.success("keiyakuplanManagement.home.keiyakuplanSaved");
            },function(error){
                AlertService.error(error.data.message);
            });
        }
    }
})();
