(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('VitalKiroku', VitalKiroku);

    VitalKiroku.$inject = ['$resource'];

    function VitalKiroku ($resource) {
        var service = $resource('robot/api/kiroku/vital/:id', {}, {
            "query": {
                method: 'GET',
                isArray: true
            },
            "save": {
                method: "POST"
            },
            "delete": {
                method: "DELETE"
            },
            "get": {
                method: "GET"
            }
        });

        return service;
    }

})();
