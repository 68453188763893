(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .directive('kataKana', kataKana);

    function kataKana () {
        var directive = {
            restrict: 'A',
            require: '?ngModel',
            link: linkFunc
        };

        var hanAlphabetList = new Array("a", "b", "c", "d", "e", "f", "g", "h", "i",
            "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w",
            "x", "y", "z", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K",
            "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y",
            "Z", "!", "\"", "#", "$", "%", "&", "'", "(", ")", "*", "+", ",", "-",
            ".", "/", ":", ";", "<", "=", ">", "?", "@", "[", "\\", "]", "^", "_",
            "`", "{", "|", "}", "~");

         var zenAlphabetList = new Array("ａ", "ｂ", "ｃ", "ｄ", "ｅ", "ｆ", "ｇ", "ｈ", "ｉ",
            "ｊ", "ｋ", "ｌ", "ｍ", "ｎ", "ｏ", "ｐ", "ｑ", "ｒ", "ｓ", "ｔ", "ｕ", "ｖ", "ｗ",
            "ｘ", "ｙ", "ｚ", "Ａ", "Ｂ", "Ｃ", "Ｄ", "Ｅ", "Ｆ", "Ｇ", "Ｈ", "Ｉ", "Ｊ", "Ｋ",
            "Ｌ", "Ｍ", "Ｎ", "Ｏ", "Ｐ", "Ｑ", "Ｒ", "Ｓ", "Ｔ", "Ｕ", "Ｖ", "Ｗ", "Ｘ", "Ｙ",
            "Ｚ", "！", "”", "＃", "＄", "％", "＆", "’", "（", "）", "＊", "＋", "，", "－",
            "．", "／", "：", "；", "＜", "＝", "＞", "？", "＠", "［", "￥", "］", "＾", "＿",
            "｀", "｛", "｜", "｝", "￣");

        var hanKatakanaList = new Array("ｱ", "ｲ", "ｳ", "ｴ", "ｵ", "ｶ", "ｷ", "ｸ", "ｹ",
            "ｺ", "ｻ", "ｼ", "ｽ", "ｾ", "ｿ", "ﾀ", "ﾁ", "ﾂ", "ﾃ", "ﾄ", "ﾅ", "ﾆ", "ﾇ",
            "ﾈ", "ﾉ",

            "ﾊ", "ﾋ", "ﾌ", "ﾍ", "ﾎ", "ﾏ", "ﾐ", "ﾑ", "ﾒ", "ﾓ", "ﾔ", "ﾕ", "ﾖ", "ﾗ",
            "ﾘ", "ﾙ", "ﾚ", "ﾛ", "ﾜ", "ｦ", "ﾝ", "ｶﾞ", "ｷﾞ", "ｸﾞ", "ｹﾞ", "ｺﾞ",

            "ｻﾞ", "ｼﾞ", "ｽﾞ", "ｾﾞ", "ｿﾞ", "ﾀﾞ", "ﾁﾞ", "ﾂﾞ", "ﾃﾞ", "ﾄﾞ", "ﾊﾞ", "ﾋﾞ",
            "ﾌﾞ", "ﾍﾞ", "ﾎﾞ", "ﾊﾟ", "ﾋﾟ", "ﾌﾟ", "ﾍﾟ", "ﾎﾟ",

            "ｧ", "ｨ", "ｩ", "ｪ", "ｫ", "ｬ", "ｭ", "ｮ", "ｯ", "ｰ", "｢", "｣", "0", "1",
            "2", "3", "4", "5", "6", "7", "8", "9", " ");

         var zenKatakanaList = new Array("ア", "イ", "ウ", "エ", "オ", "カ", "キ", "ク", "ケ",
            "コ", "サ", "シ", "ス", "セ", "ソ", "タ", "チ", "ツ", "テ", "ト", "ナ", "ニ", "ヌ",
            "ネ", "ノ",

            "ハ", "ヒ", "フ", "ヘ", "ホ", "マ", "ミ", "ム", "メ", "モ", "ヤ", "ユ", "ヨ", "ラ",
            "リ", "ル", "レ", "ロ", "ワ", "ヲ", "ン", "ガ", "ギ", "グ", "ゲ", "ゴ",

            "ザ", "ジ", "ズ", "ゼ", "ゾ", "ダ", "ヂ", "ヅ", "デ", "ド", "バ", "ビ", "ブ", "ベ",
            "ボ", "パ", "ピ", "プ", "ペ", "ポ",

            "ァ", "ィ", "ゥ", "ェ", "ォ", "ャ", "ュ", "ョ", "ッ", "ー", "「", "」", "０", "１",
            "２", "３", "４", "５", "６", "７", "８", "９", "　");

          var zenHiraganaList = new Array("あ", "い", "う", "え", "お", "か", "き", "く", "け",
            "こ", "さ", "し", "す", "せ", "そ", "た", "ち", "つ", "て", "と", "な", "に", "ぬ",
            "ね", "の",

            "は", "ひ", "ふ", "へ", "ほ", "ま", "み", "む", "め", "も", "や", "ゆ", "よ", "ら",
            "り", "る", "れ", "ろ", "わ", "を", "ん", "が", "ぎ", "ぐ", "げ", "ご",

            "ざ", "じ", "ず", "ぜ", "ぞ", "だ", "ぢ", "づ", "で", "ど", "ば", "び", "ぶ", "べ",
            "ぼ", "ぱ", "ぴ", "ぷ", "ぺ", "ぽ",

            "ぁ", "ぃ", "ぅ", "ぇ", "ぉ", "ゃ", "ゅ", "ょ", "っ", "ー", "「", "」", "０", "１",
            "２", "３", "４", "５", "６", "７", "８", "９", "　");

        return directive;

        function linkFunc (scope, element, attrs, ngModel) {
            if (!ngModel) {
                return;
            }
            element.on('blur',function(){
                ngModel.$setViewValue(convert2Hankaku(ngModel.$modelValue));
                ngModel.$render();
            });
        }

        function convert2Hankaku(src) {
            // ターゲットテキストを1文字ずつ置換
            for (var i = 0; i < src.length; i++) {
                for (var j = 0; j < hanKatakanaList.length; j++) {
                    src = src.replace(zenKatakanaList[j], hanKatakanaList[j]);
                    src = src.replace(zenHiraganaList[j], hanKatakanaList[j]);
                }
                for (var j = 0; j < hanAlphabetList.length; j++) {
                    src = src.replace(zenAlphabetList[j], hanAlphabetList[j]);
                }
            }
            return src;
        }
    }

})();
