(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('OptionUserDetailController', OptionUserDetailController);

    OptionUserDetailController.$inject = ['$scope', '$state', 'AlertService', '$timeout', '$stateParams', '$rootScope' , 'Shokushu', 'User', 'Group', 'OptionUser','pagingParams','paginationConstants','ParseLinks'];

    function OptionUserDetailController ($scope, $state, AlertService, $timeout,$stateParams,$rootScope, Shokushu, User, Group, OptionUser,pagingParams,paginationConstants,ParseLinks) {
        var vm = this;

        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;

        vm.parentGroup = [];
        vm.resultList = [];
        vm.group = null;
        vm.groupId = null;
        vm.shokushuList = [];
        vm.salaryTypeList = [{id:1,name:"時給"},{id:2,name:"日給月給"}];

        //页面加载时，取得所有的Group
        vm.loadParentGroup = loadParentGroup;
        //Group切换时，自动取得Staff
        vm.changeGroup = changeGroup;
        //取得该Group的所有Staff信息
        vm.loadUserOptions = loadUserOptions;
        vm.loadShokushu = loadShokushu;
        vm.save = save;

        //页面加载后取得所有Group信息
        vm.loadParentGroup();

        function loadParentGroup() {
            Group.parentGroup({}, function(result){
                vm.parentGroup = result;
                if ($stateParams.groupId !== null) {
                    result.forEach(function(item){
                        if (item.id == $stateParams.groupId) {
                            vm.group = item;
                        }
                    });
                } else {
                    vm.group = vm.parentGroup[0];
                }
                vm.groupId = vm.group.id;
                vm.loadUserOptions();
                vm.loadShokushu();
            });
        }

        function loadShokushu() {
            Shokushu.query({"groupId":vm.group.id},function(result){
                vm.shokushuList = result;
                if (vm.shokushuList.length == 0 && vm.group.parentId > 0) {
                    Shokushu.query({"groupId":vm.group.parentId},function(result){
                        vm.shokushuList = result;
                    });
                }
            });
        }

        function changeGroup() {
        	pagingParams.page = 1;
            vm.parentGroup.forEach(function(e){
                if (e.id == vm.groupId) {
                    vm.group = e;
                    vm.loadUserOptions();
                    vm.loadShokushu();
                }
            });

        }

        function loadUserOptions() {
            User.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    groupId:vm.groupId
                }, function (data, headers) {
                    //vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.page = pagingParams.page;
                    OptionUser.query({groupId:vm.group.id,userList:data},function (result2) {
                        vm.resultList = result2;
                    });
            });
        }


        function onSaveSuccess (result) {
            vm.isSaving = false;
            //AlertService.success("timecardOption.saved");
        }

        function onSaveError () {
            AlertService.error("timecardOption.errors.save_error");
            vm.isSaving = false;
        }

        function save () {
            OptionUser.save({groupId:vm.group.id,userList:vm.resultList}, onSaveSuccess, onSaveError);
        }



        function sort () {
            var result = [vm.predicate + ' ' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id asc');
            }
            return result;
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ' ' + (vm.reverse ? 'asc' : 'desc'),
                groupId: vm.group.id == null ? "" : vm.group.id,
            });
        }
    }
})();
