(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TimecardController', TimecardController);

    TimecardController.$inject = ['$scope', '$rootScope', '$state','filterFilter', '$uibModalInstance', 'AlertService',
        '$stateParams', 'User', 'SelectedGroup', 'Timecard','TimezoneService', 'ShiftTime', 'Auth', 'StaffOccupation', 'ShiftMonth',
        'ParseLinks', 'pagingParams', 'paginationConstants', '$interval'];

    function TimecardController ($scope, $rootScope, $state, filterFilter, $uibModalInstance,  AlertService, $stateParams,
                                 User, SelectedGroup, Timecard,TimezoneService,ShiftTime, Auth, StaffOccupation, ShiftMonth,
                                 ParseLinks, pagingParams, paginationConstants, $interval) {
        var vm = this;
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.timeZoneList = [];
        vm.showTimeZoneFlag = false;

        //複数ユーザーのタイムカードデータを維持する
        vm.gyou50On = false;
        vm.userList = [];
        vm.groups = [];
        vm.user = null;
        vm.password = null;
        //特定ユーザのタイムカードデータを維持する
        vm.timecardData = null;
        vm.listUpData = [];
        vm.group = null;
        vm.currentTime = new Date();
        vm.groupTimezone = "+0900";
        vm.timeZone = { id: null };
        vm.message = "";
        vm.isSaving = false;

        vm.clear = clear;
        //页面加载时，取得所有的Group
        vm.loadAllGroups = loadAllGroups;
        //Group切换时，自动取得Staff
        vm.changeGroup = changeGroup;
        vm.groupTitle = groupTitle;
        //Staff切换时，自动更新Staff页面信息
        vm.changeUser = changeUser;
        //取得该Group的所有Staff信息
        vm.loadUsers = loadUsers;
        vm.checkIn = checkIn;
        vm.doCheckIn = doCheckIn;
        vm.checkOut = checkOut;
        vm.doCheckOut = doCheckOut;
        vm.goOut = goOut;
        vm.doGoOut = doGoOut;
        vm.reEntry = reEntry;
        vm.doReEntry = doReEntry;
        vm.refresh = refresh;
        vm.isUserSelected = isUserSelected;
        vm.haveUserPhoto = haveUserPhoto;
        //チェックインボタン有効かどうかを判別する
        vm.isCheckInEnabled = isCheckInEnabled;
        //チェックアウトボタン有効かどうかを判別する
        vm.isCheckOutEnabled = isCheckOutEnabled;
        //外出ボタン有効かどうかを判別する
        vm.isBeOutEnabled = isBeOutEnabled;
        //再入ボタン有効かどうかを判別する
        vm.isReEntryEnabled = isReEntryEnabled;
        //五十音表示するため
        vm.startsWith = starsWith;
        //打刻履歴を表示する
        vm.listup = listup;
        vm.currentDay = currentDay;
        vm.timeZoneClick = timeZoneClick;

        vm.currentClock = "";
        vm.currentCalendar = "";
        vm.callAtTimeout = callAtTimeout;
        vm.stopInterval = stopInterval;

        vm.afterCheck = afterCheck

        callAtTimeout();
        vm.promise = $interval(function() {
            vm.callAtTimeout()
        }, 1000);

        $scope.$on('$destroy', function() {
            vm.stopInterval();
        });

        function stopInterval() {
            $interval.cancel(vm.promise);
        }

        function callAtTimeout() {
            var now = new Date()
            var h = now.getHours();
            if (h < 10) {
                h = "0" + h;
            }
            var m = now.getMinutes();
            if (m < 10) {
                m = "0" + m;
            }
            var s = now.getSeconds();
            if (s < 10) {
                s = "0" + s;
            }
            vm.currentCalendar = now;
            vm.currentClock = h + ":" + m + ":" + s;

            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            var day = now.getDate()

            var dayOfWeek = now.getDay() ; // 曜日(数値)
            var dayOfWeekStr = [ "日", "月", "火", "水", "木", "金", "土" ][dayOfWeek] ; // 曜日(日本語表記)

            vm.currentCalendar = month+"月"+day+"日("+dayOfWeekStr+")";
        }

        function currentDay() {
            var now = new Date();
            var year = now.getFullYear();
            var month = now.getMonth()+1;
            if (month < 10) {
                month = "0"+month;
            }
            var day = now.getDate();
            if (day < 10) {
                day = "0"+day;
            }
            return year+"-"+month+"-"+day;

        }

        function timeZoneClick () {
            vm.showTimeZoneFlag = false;
        }
        setInterval(function () {
            var now = new Date();
            if (vm.currentTime.getDate() !== now.getDate()) {
                console.log("日付が変わりました、画面リフレッシュします。");
                vm.currentTime = new Date();
                $state.reload();
            }
        }, 1000);

        //页面加载后取得所有Group信息
        vm.loadAllGroups();

        //Dialogを閉じます
        function clear () {
            $uibModalInstance.dismiss('cancel');
            Auth.logout();
            $state.go('home');
        }
        //グループ一覧を取得
        function loadAllGroups() {
            vm.groups = SelectedGroup.groupList();
            if (vm.group == null) {
                vm.group = vm.groups[0];
            }
            vm.loadUsers(vm.group);
        }
        //グループ切り替え
        function changeGroup(group) {
            vm.group = group;
            vm.loadUsers(vm.group);
            vm.user = null;
            vm.password = null;
            vm.timecardData = [];
            vm.listUpData = [];
        }
        function groupTitle() {
            var title = "";
            if (angular.isDefined(vm.group)) {
                if(vm.group.parentId == "0") {
                    title = vm.group.name;
                } else {
                    vm.groups.forEach(function(item){
                        if (item.id == vm.group.parentId) {
                            title = item.name.replace(/-/g, '').replace(/|/g,'') + title;
                        }
                    });
                    title += vm.group.name.replace(/-/g,'').replace(/|/g,'');
                }
            }
            return title;

        }
        //ユーザ切り替え
        function changeUser(user) {
            vm.user = user;
            vm.password = null;
            //1日なので、timecardsの配列には１レコードしかない、
            //vm.timecardDataはTimecardDTO　と同じ構造です
            vm.timecardData = vm.user.timecards[0];
            vm.listUpData = vm.listup();
        }
        //該当グループのユーザー一覧を取得
        function loadUsers(group) {
            vm.showTimeZoneFlag = false;
            vm.groupTimezone = TimezoneService.getOffset(group.timezone);
            User.query({
                groupId:group.id,
                page: vm.page - 1,
                size: vm.itemsPerPage
            },function(result,headers){
                //vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;

                var userList = result;
                var currentYear = vm.currentTime.getFullYear();
                var currentMonth = vm.currentTime.getMonth()+1;
                var currentDay = vm.currentTime.getDate();
                var data = {'groupId':group.id,'users':userList,'year':currentYear,'month':currentMonth,'day':currentDay};
                Timecard.queryDay(data,function(result){
                    vm.userList = result.users;
                },function(error){
                    AlertService.error(error.data.message);
                });
                ShiftTime.getMonthStateAll({"groupId":vm.group.id, 'year':currentYear,'month':currentMonth}, function(result){
                    vm.timeZoneList = result;
                });

            },function(error){
                AlertService.error(error.data.message);
            });
        }

        function save () {
            if(vm.timeZoneList.length > 0){
                if(vm.timeZone.id != null){
                    var data = {'groupId':vm.group.id, 'userId':vm.user.id, 'year': vm.currentTime.getFullYear(), 'month': vm.currentTime.getMonth()+1};
                    StaffOccupation.queryStaffOccupation(data,function(result){
                        if(result.length == 0){
                            alert("職種や勤務形態が設定されていませんので、先に設定してください。");
                        }else if(result.length == 1){
                            $rootScope.staffOccupationId = result[0].id;
                            $rootScope.occupationId = result[0].occupationId;
                            $rootScope.dutyId = result[0].dutyId;
                            saveShiftMonth();
                        }else if(result.length > 1){
                            $rootScope.dutyOccupationList = result;
                            var nullList = [];
                            var notNullList = [];
                            var index = 0;
                            result.forEach(function(staff){
                                ShiftMonth.queryYotei({staffOccupationId:staff.id}, function(entity){
                                    var yotei = {};
                                    var day = new Date().getDate();
                                    yotei.staffOccupationId = entity.staffOccupationId;
                                    yotei.occupationId = entity.occupationId;
                                    yotei.dutyId = entity.dutyId;
                                    yotei.dayNumber = entity['day'+day];
                                    //如果打卡的当天有预定的值
                                    if(entity['day'+day] != null){
                                        ShiftTime.getOneById({id:entity['day'+day]}, function(timeZone){
                                            yotei.nameAndTime = timeZone.nameAndTime;
                                            notNullList.push(yotei);
                                            index += 1;
                                            if(index >= result.length){
                                                //同职种同勤务形态且预定有值的超过一条以上，则弹出选择对话框
                                                if(notNullList.length>1){
                                                    $rootScope.yoteiList = notNullList;
                                                    $state.go('timecard.dutyselect');
                                                    //同职种同勤务形态且预定有值只有一条，则直接保存
                                                } else if(notNullList.length==1){
                                                    $rootScope.staffOccupationId = notNullList[0].staffOccupationId;
                                                    $rootScope.occupationId = notNullList[0].occupationId;
                                                    $rootScope.dutyId = notNullList[0].dutyId;
                                                    saveShiftMonth();
                                                    //同职种同勤务形态且 预定没有值时，给第一个打卡
                                                } else {
                                                    $rootScope.staffOccupationId = $rootScope.dutyOccupationList[0].id;
                                                    $rootScope.occupationId = $rootScope.dutyOccupationList[0].occupationId;
                                                    $rootScope.dutyId = $rootScope.dutyOccupationList[0].dutyId;
                                                    saveShiftMonth();
                                                }
                                            }
                                        });
                                    }else {
                                        nullList.push(yotei);
                                        index += 1;
                                        if(index >= result.length){
                                            //同职种同勤务形态且预定有值的超过一条以上，则弹出选择对话框
                                            if(notNullList.length>1){
                                                $rootScope.yoteiList = notNullList;
                                                $state.go('timecard.dutyselect');
                                                //同职种同勤务形态且预定有值只有一条，则直接保存
                                            } else if(notNullList.length==1){
                                                $rootScope.staffOccupationId = notNullList[0].staffOccupationId;
                                                $rootScope.occupationId = notNullList[0].occupationId;
                                                $rootScope.dutyId = notNullList[0].dutyId;
                                                saveShiftMonth();
                                                //同职种同勤务形态且 预定没有值时，给第一个打卡
                                            } else {
                                                $rootScope.staffOccupationId = $rootScope.dutyOccupationList[0].id;
                                                $rootScope.occupationId = $rootScope.dutyOccupationList[0].occupationId;
                                                $rootScope.dutyId = $rootScope.dutyOccupationList[0].dutyId;
                                                saveShiftMonth();
                                            }
                                        }
                                    }
                                });
                            });
                        }
                    });
                    vm.showTimeZoneFlag = false;
                }else {
                    vm.showTimeZoneFlag = true;
                }
            }else {
                //1:出勤,2:退勤,3:外出,4:再入
                if($scope.type == 1){
                    onSaveCheckInSuccess();
                } else if($scope.type == 2) {
                    onSaveCheckOutSuccess();
                } else if($scope.type == 3) {
                    onSavegoOutSuccess();
                } else if($scope.type == 4) {
                    onSaveReEntrySuccess();
                }
            }
        }

        $scope.$on('duty', function() {
            saveShiftMonth();
         });

        function saveShiftMonth() {
            var monthStaff = {};
            var monthShiftJisseki = {};
            monthStaff.userId = vm.user.id;
            monthStaff.occupationId = $rootScope.occupationId;
            monthStaff.dutyId = $rootScope.dutyId;
            monthStaff.staffOccupationId = $rootScope.staffOccupationId;
            monthShiftJisseki.id = null;
            monthShiftJisseki.userId = vm.user.id;
            monthShiftJisseki.staffOccupationId = $rootScope.staffOccupationId;
            monthShiftJisseki.occupationId = $rootScope.occupationId;
            monthShiftJisseki.dutyId = $rootScope.dutyId;
            monthShiftJisseki.total = 8;
            monthShiftJisseki.avgTime = 0.05;
            monthShiftJisseki.timeZoneCountDTO = [];
            for(var a = 0; a < vm.timeZoneList.length; a++ ) {
                var dto = {};
                dto.id = vm.timeZoneList[a].id;
                dto.name = vm.timeZoneList[a].name;
                if(vm.timeZoneList[a].id == vm.timeZone.id){
                    dto.timeZoneCount = 1;
                }else {
                    dto.timeZoneCount = 0;
                }
                monthShiftJisseki.timeZoneCountDTO.push(dto);
            }

            for(var i = 1; i< 31;i++){
                if(i==vm.currentTime.getDate()) {
                    monthShiftJisseki['day'+i] = vm.timeZone.id;
                }else {
                    monthShiftJisseki['day'+i] = null;
                }
            }
            monthStaff.monthShiftJisseki = monthShiftJisseki;
            //1:出勤,2:退勤,3:外出,4:再入
            ShiftMonth.saveShiftMonthByTimeCard({groupId:vm.group.id, year:vm.currentTime.getFullYear(), month:vm.currentTime.getMonth()+1, type:3, monthStaff: monthStaff});
        }

        function onSaveCheckInSuccess () {
            if (vm.user.passwordRequired) {
                if (vm.user.passwordRequired && vm.password === null) {
                    AlertService.error("パスワードを入力してください。");
                    return;
                }
                User.check({'userName':vm.user.login,'password':vm.password},function(result){
                    vm.doCheckIn();
                });
            } else {
                vm.doCheckIn();
            }
        }

        function onSaveCheckOutSuccess () {
            if (vm.user.passwordRequired) {
                if (vm.password == null) {
                    AlertService.error("パスワードを入力してください。");
                    return;
                }
                User.check({'userName':vm.user.login,'password':vm.password},function(result){
                    vm.doCheckOut();
                });
            } else {
                vm.doCheckOut();
            }
        }

        function onSavegoOutSuccess () {
            if (vm.user.passwordRequired) {
                if (vm.password == null) {
                    AlertService.error("パスワードを入力してください。");
                    return;
                }
                User.check({'userName':vm.user.login,'password':vm.password},function(result){
                    vm.doGoOut();
                });
            } else {
                vm.doGoOut();
            }
        }

        function onSaveReEntrySuccess () {
            if (vm.user.passwordRequired) {
                if (vm.password == null) {
                    AlertService.error("パスワードを入力してください。");
                    return;
                }
                User.check({'userName':vm.user.login,'password':vm.password},function(result){
                    vm.doReEntry();
                });
            } else {
                vm.doReEntry();
            }
        }

        //出勤処理
        function checkIn() {
            if (vm.user.passwordRequired) {
                if (vm.user.passwordRequired && vm.password === null) {
                    AlertService.error("パスワードを入力してください。");
                    return;
                }
                User.check({'userName':vm.user.login,'password':vm.password},function(result){
                    vm.doCheckIn();
                });
            } else {
                vm.doCheckIn();
            }
        }
        //実出勤登録処理
        function doCheckIn(){
            vm.isSaving = true;
            var timecard = {"id":null,"groupId":vm.group.id, "day": vm.currentDay(),
                "userId":vm.user.id,"password":vm.password,"origin":vm.user.password,
                "userName":vm.user.lastName+" "+ vm.user.firstName, "type":1, "inTime":new Date(),
                "year": vm.currentTime.getFullYear(), "month": vm.currentTime.getMonth() + 1,
                "timeZoneId": vm.timeZone.id, "timecardShiftFlag": vm.group.timecardShiftFlag };

            Timecard.save(timecard, function (result) {
                vm.isSaving = false;
                vm.refresh(result);
                vm.afterCheck(result);
                AlertService.success("timecard.management.checkin",{"param":vm.user.lastName + " " + vm.user.firstName});
            }, function (error) {
                vm.isSaving = false;
                AlertService.error(error.data.message);
            });
        }
        //退勤処理
        function checkOut() {
            if (vm.user.passwordRequired) {
                if (vm.password == null) {
                    AlertService.error("パスワードを入力してください。");
                    return;
                }
                User.check({'userName':vm.user.login,'password':vm.password},function(result){
                    vm.doCheckOut();
                });
            } else {
                vm.doCheckOut();
            }
        }
        //実退勤登録処理
        function doCheckOut(){
            vm.isSaving = true;
            if (vm.timecardData.haveUnFinishedTimecard === true) {
                console.log("昨日の出勤ですが、今日退勤します。");
                var timecard = {};
                timecard["groupId"] = vm.group.id;
                timecard["userId"] = vm.user.id;
                timecard["day"] = vm.currentDay();
                timecard["outTime"] = new Date();
                timecard["password"] = vm.password;
                timecard["origin"] = vm.user.password;
                timecard["userName"] = vm.user.lastName+" "+ vm.user.firstName;
                timecard["year"] = vm.currentTime.getFullYear();
                timecard["month"] = vm.currentTime.getMonth() + 1;
                timecard["timeZoneId"] = vm.timeZone.id;
                timecard["timecardShiftFlag"] = vm.group.timecardShiftFlag
                Timecard.yesterday(timecard, function (result) {
                    vm.isSaving = false;
                    vm.refresh(result);
                    vm.afterCheck(result);
                    AlertService.success("timecard.management.checkout",{"param":vm.user.lastName + " " + vm.user.firstName});
                }, function (error) {
                    vm.isSaving = false;
                    AlertService.error(error.data.message);
                });

            } else {
                var checkInData = filterFilter(vm.timecardData.rawData,{type: 1});
                var timecard = checkInData[checkInData.length -1];
                timecard["groupId"] = vm.group.id;
                timecard["outTime"] = new Date();
                timecard["password"] = vm.password;
                timecard["origin"] = vm.user.password;
                timecard["userName"] = vm.user.lastName+" "+ vm.user.firstName;
                timecard["year"] = vm.currentTime.getFullYear();
                timecard["month"] = vm.currentTime.getMonth() + 1;
                timecard["timeZoneId"] = vm.timeZone.id;
                timecard["timecardShiftFlag"] = vm.group.timecardShiftFlag
                Timecard.update(timecard, function (result) {
                    vm.isSaving = false;
                    vm.refresh(result);
                    vm.afterCheck(result);
                    AlertService.success("timecard.management.checkout",{"param":vm.user.lastName + " " + vm.user.firstName});
                }, function (error) {
                    vm.isSaving = false;
                    AlertService.error(error.data.message);
                });
            }
        }
        //外出処理
        function goOut() {
            if (vm.user.passwordRequired) {
                if (vm.password == null) {
                    AlertService.error("パスワードを入力してください。");
                    return;
                }
                User.check({'userName':vm.user.login,'password':vm.password},function(result){
                    vm.doGoOut();
                });
            } else {
                vm.doGoOut();
            }
        }
        //実外出登録処理
        function doGoOut(){
            vm.isSaving = true;
            var timecard = {"id":null,"groupId":vm.group.id, "day": vm.currentDay(),
                "userId":vm.user.id,"password":vm.password, "origin":vm.user.password,
                "userName":vm.user.lastName+" "+ vm.user.firstName, "type":2, "inTime":new Date(),
                "year": vm.currentTime.getFullYear(), "month": vm.currentTime.getMonth() + 1,
                "timeZoneId": vm.timeZone.id, "timecardShiftFlag": vm.group.timecardShiftFlag};
            Timecard.beOut(timecard, function (result) {
                vm.isSaving = false;
                vm.refresh(result);
                vm.afterCheck(result);
                AlertService.success("timecard.management.beout",{"param":vm.user.lastName + " " + vm.user.firstName});
            }, function (error) {
                vm.isSaving = false;
                AlertService.error(error.data.message);
            });
        }
        //再入処理
        function reEntry() {
            if (vm.user.passwordRequired) {
                if (vm.password == null) {
                    AlertService.error("パスワードを入力してください。");
                    return;
                }
                User.check({'userName':vm.user.login,'password':vm.password},function(result){
                    vm.doReEntry();
                });
            } else {
                vm.doReEntry();
            }
        }
        //実再入登録処理
        function doReEntry() {
            vm.isSaving = true;

            var beOutData = filterFilter(vm.timecardData.rawData,{type: 2});
            var timecard = beOutData[beOutData.length -1];
            timecard["groupId"] = vm.group.id;
            timecard["outTime"] = new Date();
            timecard["password"] = vm.password;
            timecard["origin"] = vm.user.password;
            timecard["userName"] = vm.user.lastName+" "+ vm.user.firstName;
            timecard["year"] = vm.currentTime.getFullYear();
            timecard["month"] = vm.currentTime.getMonth() + 1;
            timecard["timeZoneId"] = vm.timeZone.id;
            timecard["timecardShiftFlag"] = vm.group.timecardShiftFlag

            Timecard.reEntry(timecard, function (result) {
                vm.isSaving = false;
                vm.refresh(result);
                vm.afterCheck(result);
                AlertService.success("timecard.management.reentry",{"param":vm.user.lastName + " " + vm.user.firstName});
            }, function (error) {
                vm.isSaving = false;
                AlertService.error(error.data.message);
            });
        }

        /**
         * 打刻データを保存したら、ロカルデータを再更新します
         * @param result
         */
        function refresh(result) {
            vm.password = null;
            vm.user.timecards[0].workTimeDetail.startTime = result.workTimeDetail.startTime;
            vm.user.timecards[0].workTimeDetail.endTime = result.workTimeDetail.endTime;
            vm.user.timecards[0].workTimeDetail.status = result.workTimeDetail.status;
            vm.user.timecards[0].workTimeDetail.nextDay = result.workTimeDetail.nextDay;
            vm.user.timecards[0].workTimeDetail.checkinEnabled = result.workTimeDetail.checkinEnabled;
            vm.user.timecards[0].workTimeDetail.checkoutEnabled = result.workTimeDetail.checkoutEnabled;
            vm.user.timecards[0].workTimeDetail.beoutEnabled = result.workTimeDetail.beoutEnabled;
            vm.user.timecards[0].workTimeDetail.reentryEnabled = result.workTimeDetail.reentryEnabled;
            vm.user.timecards[0].haveUnFinishedTimecard = result.haveUnFinishedTimecard;
            vm.user.timecards[0].rawData = result.rawData;

            vm.timecardData = vm.user.timecards[0];
            vm.listUpData = vm.listup();
        }

        /**
         * 打刻画面にユーザを選らんだかどうかを判断する
         * @returns {boolean}
         */
        function isUserSelected() {
            if (vm.user === undefined || vm.user === null) {
                return false;
            }
            if (vm.user.id === undefined) {
                return false;
            }
            return true;
        }

        /**
         * 打刻画面で利用者は写真を表示するかどうかを判断する
         * @returns {boolean}
         */
        function haveUserPhoto() {
            if (vm.user === null) {
                return false;
            }
            if (vm.user.imageUrl === null) {
                return false;
            }
            if (vm.user.imageUrl.length > 0) {
                return true;
            }
            return false;
        }
        //チェックインボタンは有効かどうかを判別する
        function isCheckInEnabled() {
            if (vm.timecardData === null){
                return false;
            } else if (!angular.isDefined(vm.timecardData.workTimeDetail)){
                return false;
            } else {
                return vm.timecardData.workTimeDetail.checkinEnabled;
            }
        }
        //チェックアウトは有効かどうかを判別する
        function isCheckOutEnabled() {
            if (vm.timecardData === null) {
                return false;
            } else if(!angular.isDefined(vm.timecardData.workTimeDetail)){
                return false;
            } else {
                return vm.timecardData.workTimeDetail.checkoutEnabled;
            }
        }
        //外出ボタンは有効かどうかを判別する
        function isBeOutEnabled() {
            if (vm.timecardData === null) {
                return false;
            } else if(!angular.isDefined(vm.timecardData.workTimeDetail)){
                return false;
            } else {
                return vm.timecardData.workTimeDetail.beoutEnabled;
            }
        }
        //再入ボタンは有効かどうかを判別する
        function isReEntryEnabled () {
            if (vm.timecardData === null) {
                return false;
            } else if (!angular.isDefined(vm.timecardData.workTimeDetail)){
                return false;
            } else {
                return vm.timecardData.workTimeDetail.reentryEnabled;
            }
        }

        function starsWith(actual, expected) {
            var lowerStr = (actual + "").toLowerCase();
            return lowerStr.indexOf(expected.toLowerCase()) === 0;
        }

        function listup() {
            var result = [];
            if (vm.timecardData === null) {
                return result;
            } else if (angular.isUndefined(vm.timecardData) || vm.timecardData.length === 0) {
                return result;
            }
            var data = vm.timecardData.rawData;
            if (angular.isDefined(data) && data.length > 0) {
                var index = 0;
                angular.forEach(data, function(value, key){
                    if (value !== null && angular.isDefined(value)) {
                        if(value.type === 1) {
                            if (angular.isDefined(value.inTime) && value.inTime !== null) {
                                if(vm.timecardData.workTimeDetail.nextDay === true) {
                                    result.push({"name":"出勤(昨日)","time":value.inTime});
                                } else {
                                    result.push({"name":"出勤","time":value.inTime});
                                }

                                index += 1;
                            }
                            if (angular.isDefined(value.outTime) && value.outTime !== null) {
                                result.push({"name":"退勤","time":value.outTime});
                            }
                        }
                        if(value.type === 2) {
                            if (angular.isDefined(value.inTime) && value.inTime !== null) {
                                result.splice(index, 0, {"name":"外出","time":value.inTime});
                                index += 1;
                            }
                            if (angular.isDefined(value.outTime) && value.outTime !== null) {
                                result.splice(index, 0,  {"name":"再入","time":value.outTime});
                                index += 1;
                            }
                        }
                    }
                });
            }
            return result;
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            vm.loadUsers(vm.group);
        }

        function afterCheck(result) {
            vm.showTimeZoneFlag = false;
            if (result.type == 3) {
                vm.showTimeZoneFlag = true;
            } else if (result.type == 4) {
                alert("職種や勤務形態が設定されていませんので、先に設定してください。");
            } else if (result.type == 5) {
                $rootScope.yoteiList = result.yoteiList;
                $state.go('timecard.dutyselect');
            }
        }
    }
})();
