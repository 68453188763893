(function() {
    'use strict';
//TimecardOption
    angular
        .module('gatewayApp')
        .controller('GroupOptionController', GroupOptionController);

    GroupOptionController.$inject = ['MessageService', '$state', 'Group', 'GroupOption'];

    function GroupOptionController(MessageService, $state, Group, GroupOption) {
        var vm = this;

        vm.group = [];
        vm.loadGroupOption = loadGroupOption;
        vm.groupOptions = [];
        //页面加载时，取得所有的Group
        vm.loadParentGroup = loadParentGroup;

        vm.save = save;

        vm.loadParentGroup();

        function loadGroupOption () {
            GroupOption.getAll({"groups":vm.group}, function(result, headers){
                vm.groupOptions = result.groups;
            });
        }

        function loadParentGroup() {
            var ids = new Array();
            Group.parentGroup({}, function(result){
                result.forEach(function(value,index){
                    ids.push({groupId:value.id,groupName:value.name});
                });
                vm.group = ids;
                vm.loadGroupOption();
            });
        }

        function save(){
            GroupOption.save({"groups":vm.groupOptions},function(result){

            },function(error){

            });
        }

    }
})();
