(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ShiftDutyDetailController', ShiftDutyDetailController);

    ShiftDutyDetailController.$inject = ['$scope', '$state', 'AlertService', '$timeout', '$stateParams', '$rootScope' , 'Shokushu', 'User', 'Group', 'ShiftDuty','pagingParams','paginationConstants','ParseLinks'];

    function ShiftDutyDetailController ($scope, $state, AlertService, $timeout,$stateParams,$rootScope, Shokushu, User, Group, ShiftDuty,pagingParams,paginationConstants,ParseLinks) {
        var vm = this;

        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.houjinFlag = false;
        vm.selectGroupFlag = false;
        vm.groupAllList = [];
        vm.parentGroup = [];
        vm.resultList = [];
        vm.shiftDutyList= [];
        vm.state = 1;
        vm.codeSaveFlag = false;
        vm.nameSaveFlag = false;
        vm.parentFlag = false;

        vm.goEdit = goEdit;
        vm.goAdd = goAdd;
        vm.goCancel = goCancel;
        vm.codeUniqueCheck = codeUniqueCheck;
        vm.nameUniqueCheck = nameUniqueCheck;

        //页面加载时，取得所有的Group
        vm.loadParentGroup = loadParentGroup;
        //Group切换
        vm.changeGroup = changeGroup;
        vm.save = save;
        vm.loadShiftDuty = loadShiftDuty;
        vm.goDel = goDel;
        vm.changeState = changeState;

        //页面加载后取得所有Group信息
        vm.loadParentGroup();

        function loadParentGroup() {
            Group.parentGroup({}, function(result){
                vm.parentGroup = result;
                if ($stateParams.groupId !== null) {
                    result.forEach(function(item){
                        if (item.id == $stateParams.groupId) {
                            vm.group = item;
                        }
                    });
                } else {
                    vm.group = vm.parentGroup[0];
                }
                vm.groupId = vm.group.id;
                vm.loadShiftDuty();
            });
        }

        function loadShiftDuty () {
            //如果父groupId = 0,则可以编辑，否则不可以编辑
            vm.parentGroup.forEach(function (item) {
                if(vm.groupId == item.id){
                    if(item.parentId == ''){
                        vm.parentFlag = true;
                    }else {
                        vm.parentFlag = false;
                    }
                }
            });
            //如果group为parentId==0的group则直接查询
            if(vm.parentFlag) {
                ShiftDuty.getAll({"groupId":vm.groupId, "state":vm.state}, function(result, headers){
                    vm.resultList = result;
                    vm.shiftDutyList = angular.copy(result);
                    if(vm.resultList.length==0){
                        var shift = {};
                        shift.id = null;
                        shift.code = "";
                        shift.name = "";
                        shift.state = 1;
                        shift.showFlag = false;
                        vm.resultList.push(shift);
                    }
                });
            //否则查父group的
            }else {
                Group.queryParentGroupId({"groupId":vm.groupId}, function (group) {
                    //当前用户的父groupId
                    var groupId = "gid" + group.groupId;
                    ShiftDuty.getAll({"groupId":groupId, "state":vm.state}, function(result, headers){
                        vm.resultList = result;
                    });
                });
            }

            //判断登录账号是不是法人
            /*Group.groupList({"depth": 0}, function(groupList){
                vm.groupAllList = groupList;
                for(var i=0; i<groupList.length; i++){
                    if(!vm.houjinFlag){
                        for(var x=0; x<groupList[i].flags.length; x++){
                            if(groupList[i].flags[x].id == 2){
                                vm.houjinFlag = true;
                                break;
                            }
                        }
                    }
                }
                //如果是法人，直接根据groupId查询
                if(vm.houjinFlag){
                    ShiftDuty.getAll({"groupId":vm.group.id, "state":vm.state}, function(result, headers){
                        vm.resultList = result;
                        vm.shiftDutyList = angular.copy(result);
                        if(vm.resultList.length==0){
                            var shift = {};
                            shift.id = null;
                            shift.code = "";
                            shift.name = "";
                            shift.state = 1;
                            shift.showFlag = false;
                            vm.resultList.push(shift);
                        }
                    });
                //普通用户则查找他父节点的所有的数据
                }else {
                    Group.queryParentGroupId({"groupId":vm.group.id}, function (group) {
                        //当前用户的父groupId
                        var groupId = "gid" + group.groupId;
                        ShiftDuty.getAll({"groupId":groupId, "state":vm.state}, function(result, headers){
                            vm.resultList = result;
                            vm.shiftDutyList = angular.copy(result);
                            if(vm.resultList.length==0){
                                var shift = {};
                                shift.id = null;
                                shift.code = "";
                                shift.name = "";
                                shift.state = 1;
                                shift.showFlag = false;
                                vm.resultList.push(shift);
                            }
                        });
                    });
                }
            });*/
        }

        function changeState() {
            loadShiftDuty();
        }

        function goEdit(shiftDuty){
            shiftDuty.showFlag = false;
        }

        function goCancel(shiftDuty, index){
            vm.shiftDutyList.forEach(function (shift){
                if(shift.id == shiftDuty.id){
                    shiftDuty.showFlag = true;
                    shiftDuty.code = shift.code;
                    shiftDuty.name = shift.name;
                    shiftDuty.state = shift.state;
                    codeUniqueCheck (shiftDuty, index);
                    nameUniqueCheck (shiftDuty, index);
                }
            });
        }

        function goAdd(index) {
            var shift = {};
            shift.id = null;
            shift.code = "";
            shift.name = "";
            shift.state = 1;
            shift.showFlag = false;
            vm.resultList.splice(index+1,0,shift);
        }

        function goDel(index) {
            if(vm.resultList[index].id == null){
                vm.resultList.splice(index, 1);
            }else{
                ShiftDuty.delete({id:vm.resultList[index].id}, onSaveSuccess, onSaveError);
            }
        }

        function changeGroup(group) {
            vm.loadShiftDuty();
        }

        function save(){
            if(confirm("一括保存してもよろしいでしょうか")){
                ShiftDuty.save({groupId:vm.group.id,shiftList:vm.resultList}, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            loadShiftDuty();
        }

        function onSaveError (result) {
            vm.resultList = result.data.shiftList;
        }

        function codeUniqueCheck (shiftDuty, index) {
            for(var i=0; i<vm.resultList.length; i++) {
                if(vm.resultList[i].code == shiftDuty.code && index != i){
                    shiftDuty.codeSingleFlag = true;
                    vm.codeSaveFlag = true;
                    break;
                }else {
                    shiftDuty.codeSingleFlag = false;
                    vm.codeSaveFlag = false;
                }
            }
        }

        function nameUniqueCheck (shiftDuty, index) {
            for(var i=0; i<vm.resultList.length; i++) {
                if(vm.resultList[i].name == shiftDuty.name && index != i){
                    shiftDuty.nameSingleFlag = true;
                    vm.nameSaveFlag = true;
                    break;
                }else {
                    shiftDuty.nameSingleFlag = false;
                    vm.nameSaveFlag = false;
                }
            }
        }
    }
})();
