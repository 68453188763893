(function () {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Role', Role);

    Role.$inject = ['$resource'];

    function Role ($resource) {
        var service = $resource('api/role', {}, {
            'query': {method: 'GET', url: 'api/role', isArray: true},
            'getFunction': {method: 'GET', url: 'api/role/kinou', isArray: true},
            'createRole': {method: 'POST',url: 'api/role'},
            'updateRole': {method: 'PUT',url: 'api/role'},
            'getRole': {method: 'GET', url: 'api/:code/role'},
            'deleteRole': {method: 'DELETE', url: 'api/:code/role'},
            'getFunctionByRole': {method: 'GET', url: 'api/:functionCode/getFunctionByRole'}
        });

        return service;
    }
})();
