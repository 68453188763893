(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('AdminDailyController', AdminDailyController);

    AdminDailyController.$inject = ['Setting', '$scope', 'SelectedPepper', 'MessageService'];

    function AdminDailyController (Setting, $scope, SelectedPepper, MessageService) {
        var vm = this;

        vm.datas = {pepperId: null, pepperSettingList: []};
        vm.repeats = [{code: 1, value: "毎年"}, {code: 2, value: "毎月"}, {code: 3, value: "毎週"}];
        vm.index = null;
        vm.pepper = SelectedPepper.current();

        vm.loadPepperSettings = loadPepperSettings;
        vm.add = add;
        vm.del = del;
        vm.save = save;
        vm.cancel = cancel;
        vm.changeDays = changeDays;

        vm.loadPepperSettings();

        $scope.$on('pepperChanged', function() {
            vm.pepper = SelectedPepper.current();
            loadPepperSettings();
        });

        function loadPepperSettings() {
            Setting.query({pepperId: vm.pepper.id, type: 4}, function(result) {
                vm.datas.pepperSettingList = result;
            });
        }

        function add() {
            var pepperSetting = {id: null, pepperId: null, variableType: 6, itemName: null, variableName: null, variableValue: null, repeatType: 1, sampleText: null,
                pepperSettingMonthly: {id: null, day1: null, day2: null, day3: null, day4: null, day5: null, day6: null, day7: null,
                    day8: null, day9: null, day10: null, day11: null, day12: null, day13: null, day14: null,
                    day15: null, day16: null, day17: null, day18: null, day19: null, day20: null, day21: null,
                    day22: null, day23: null, day24: null, day25: null, day26: null, day27: null, day28: null,
                    day29: null, day30: null, day31: null},
                pepperSettingWeekly: {id: null, day1: null, day2: null, day3: null, day4: null, day5: null, day6: null, day7: null},
                selected: false};
            vm.datas.pepperSettingList.push(pepperSetting);
            vm.changeDays(vm.datas.pepperSettingList.length - 1);
        }

        function del(index) {
            vm.datas.pepperSettingList.splice(index, 1);
        }

        function save() {
            vm.isSaving = true;
            vm.datas.pepperId = vm.pepper.id;
            vm.datas.type = 4;
            Setting.save(vm.datas, onSaveSuccess, onSaveError);
        }

        function cancel() {
            vm.loadPepperSettings();
        }

        function onSaveSuccess() {
            vm.isSaving = false;
            vm.loadPepperSettings();
            MessageService.success("daily.home.success");
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("daily.home.error");
        }

        function changeDays(index) {
            vm.index = index;
        }
    }
})();
