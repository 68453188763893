(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('VariableEditDialogController', VariableEditDialogController);

    VariableEditDialogController.$inject = ['$uibModalInstance', 'entity', 'Variable', '$rootScope', 'Upload', 'MessageService', 'Library', 'Uploader', '$timeout'];

    function VariableEditDialogController ($uibModalInstance, entity, Variable, $rootScope, Upload, MessageService, Library, Uploader, $timeout) {

        var vm = this;

        vm.picFile = null;
        vm.url = null;
        vm.delFlg = false;

        vm.clear = clear;
        vm.save = save;
        vm.del = del;

        vm.variable = entity;

        loadUrl();

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $rootScope.variableType = vm.variable.itemType;
            // upload
            if (vm.picFile != null) {
                uploadPic(result);
            } else {
                saveSuccess();
            }
        }

        function onSaveError() {
            MessageService.error("variable.edit.saveError")
            vm.isSaving = false;
        }

        function save() {
            vm.isSaving = true;
            Variable.save(vm.variable, onSaveSuccess, onSaveError);
        }

        function uploadPic(result) {
            Upload.upload({
                url: 'library/api/upload/movement',
                data: {fileName: result.id, file: vm.picFile}
            }).success(function () {
                saveSuccess();
            }).error(function () {
                MessageService.error("variable.edit.photo.saveError");
            });
        }

        function saveSuccess() {
            MessageService.success("variable.edit.saveSuccess");
            $uibModalInstance.close();
            $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
        }

        function loadUrl() {
            Library.url(function(result){
                vm.url = result.url;
            })
        }

        function del() {
            Uploader.delByName({name: vm.variable.id}, function(){
                vm.delFlg = true;
            });
        }
    }
})();
