(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('LoginRedashController', LoginRedashController);

    LoginRedashController.$inject = ['$stateParams','$scope','Library','Group','Upload','$filter','Comment','MessageService','$rootScope'];

    function LoginRedashController($stateParams,$scope,Library,Group,Upload,$filter,Comment,MessageService,$rootScope) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.reurl = "";

        loadAll();

        function loadAll () {
            vm.reurl = "http://analyze-test.caretree.jp/dashboard/testdashboards?p_jigyoushoId="
                + $stateParams.jigyoushoId + "&p_riyoushaId=" + $stateParams.riyoushaId
                + "&p_jisshiDt=" + $stateParams.jisshiDt + "&p_jisshiDtBefore=" + $stateParams.jisshiDtBefore + "&fullscreen";
        }

    }
})();
