(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('LibraryController', LibraryController);

    LibraryController.$inject = ['paginationConstants', 'Enum', 'Principal', 'Library', 'ParseLinks', '$state', 'MessageService'];

    function LibraryController(paginationConstants, Enum, Principal, Library, ParseLinks, $state, MessageService) {

        var vm = this;

        vm.belong = {};
        vm.types = {};
        vm.publishTypes = {};
        vm.selectedBelong = 2;
        vm.selectedType = 1;
        vm.page = 1;
        vm.links = null;
        vm.totalItems = null;
        vm.libraryList = [];
        vm.selected = {ids: []};

        vm.loadAll = loadAll;
        vm.loadBelong = loadBelong;
        vm.loadTypes = loadTypes;
        vm.loadLibraries = loadLibraries;
        vm.loadPublishTypes = loadPublishTypes;

        vm.changeBelong = changeBelong;
        vm.changeType = changeType;
        vm.copyToClipboard = copyToClipboard;
        vm.selectLibrary = selectLibrary;
        vm.changePublishType = changePublishType;
        vm.delLibraries = delLibraries;

        vm.jump = jump;
        vm.edit = edit;

        vm.goEdit = goEdit;

        loadAll();

        Principal.hasAuthority('ROLE_ADMIN').then(function(result){
            if(result) {
                vm.isAdmin = true;
            } else {
                vm.isAdmin = false;
            }
        });

        function loadAll() {
            loadBelong();
            loadTypes();
            loadLibraries();
            loadPublishTypes();
        }

        function loadBelong() {
            Enum.getLibrary({name: "LibraryBelongType"}, function(result) {
                vm.belong = result;
            });
        }

        function loadTypes() {
            Enum.getLibrary({name: "FileType"}, function(result) {
                vm.types = result;
            });
        }

        function loadPublishTypes() {
            Enum.getLibrary({name: "PublishType"}, function(result) {
                vm.publishTypes = result;
            });
        }

        function loadLibraries() {
            vm.selected.ids = [];
            Library.query({page: vm.page - 1, size: paginationConstants.itemsPerPage, belong: vm.selectedBelong, type: vm.selectedType}, function(result, headers){
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.libraryList = result;
            });
        }

        function changeBelong(selectedBelong) {
            vm.selectedBelong = selectedBelong;
            vm.page = 1;
            loadLibraries();
        }

        function changeType(selectedType) {
            vm.selectedType = selectedType;
            vm.page = 1;
            loadLibraries();
        }

        function copyToClipboard(url) {

            document.getElementById('copyUrl').value = url;
            document.getElementById('copyUrl').focus();
            document.getElementById('copyUrl').style.display = '';
            document.getElementById('copyUrl').select();
            setTimeout(function() {
                document.execCommand('copy');
                document.getElementById('copyUrl').style.display = 'none';
            }, 20);
        }

        function selectLibrary(id) {
            var checkbox = document.getElementById('checkbox' + id);
            checkbox.checked = !checkbox.checked;
            if (checkbox.checked) {
                vm.selected.ids.push(id);
            } else {
                for (var i = vm.selected.ids.length - 1; i >= 0; i --) {
                    if (vm.selected.ids[i] == id) {
                        vm.selected.ids.splice(i, 1);
                    }
                }
            }
        }

        function changePublishType(index) {
            Library.update(vm.libraryList[index], onChangeSuccess, onChangeError);
        }

        function onChangeSuccess() {
            MessageService.success("library.message.changeSuccess");
        }

        function onChangeError() {
            MessageService.error("library.message.changeError");
        }

        function delLibraries() {
            Library.del(vm.selected, onDelSuccess, onDelError);
        }

        function onDelSuccess() {
            MessageService.success("library.message.delSuccess");
            loadLibraries();
        }

        function onDelError() {
            MessageService.error("library.message.delError");
            loadLibraries();
        }

        function jump(type, id) {
            if (type == 1) {
                $state.go('library-upload');
            } else if (type == 2) {
                $state.go('library-url', {id: id});
            }
        }

        function edit() {
            if (vm.selected.ids.length != 1) {
                MessageService.error("library.message.selected");
                return false;
            } else {
                var id = vm.selected.ids[0];
                goEdit(id);
            }
        }

        function goEdit(id) {
            $state.go('library-edit', {id: id});
        }
    }
})();
