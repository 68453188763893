(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('price', {
            parent: 'app',
            url: '/settings/price',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/master/price/price.html',
                    controller: 'PriceController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('price');
                    return $translate.refresh();
                }]
            }
        }).state('price.edit', {
            parent: 'price',
            url: '/edit/{id}',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/master/price/dialog/price-edit-dialog.html',
                    controller: 'PriceEditDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        param: {id: $stateParams.id}
                    }
                }).result.then(function() {
                    $state.go('price', null, { reload: true });
                }, function() {
                    $state.go('price');
                });
            }]
        }).state('price.delete', {
            parent: 'price',
            url: '/delete/{id}/{productId}',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/master/price/dialog/price-delete-dialog.html',
                    controller: 'PriceDeleteDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Price', function(Price) {
                            return Price.get({id : $stateParams.id});
                        }],
                        productEntity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.productId});
                        }]
                    }
                }).result.then(function() {
                    $state.go('price', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
