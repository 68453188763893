(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('StaffController', StaffController);

    StaffController.$inject = [];

    function StaffController () {

        var vm = this;

        // スタッフ列表
        vm.staffList = [];

        // 进入页面，加载初始数据
        vm.loadData = loadData();

        // 删除指定staff
        vm.remove = remove;

        function loadData() {
            var staff1 = {loginId: "test1", sei: "泉", mei: "一郎", productNm: "ケアリス", shopNm: "ケア樹", beginDate: "2018/05/16", useStop: true};
            var staff2 = {loginId: "test2", sei: "泉", mei: "二郎", productNm: "ケアリス", shopNm: "ケア樹", beginDate: "2018/05/16", useStop: false};
            vm.staffList.push(staff1);
            vm.staffList.push(staff2);
        }

        function remove(index) {
            vm.staffList.splice(index, 1);
        }

    }
})();
