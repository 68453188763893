(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Schedule', Schedule);

    Schedule.$inject = ['$resource'];

    function Schedule ($resource) {
        var service = $resource('robot/api/schedule', {}, {
            "query": {
                method: 'GET',
                isArray: true,
                url: 'robot/api/schedules/pepperId/:pepperId'
            },
            "save": {
                method: "POST"
            }
        });

        return service;
    }

})();
