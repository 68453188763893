(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Price', Price);

    Price.$inject = ['$resource'];

    function Price ($resource) {
        var service = $resource('api/price', {}, {
            'query': {
                method: 'GET',
                url: 'api/price/list',
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'save': {
                method: 'POST'
            },
            'delete': {
                method: 'DELETE'
            },
            'getByProductId': {
                method: 'GET',
                url: 'api/price/productId/:productId'
            }
        });

        return service;
    }

})();
