(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('VitalKirokuController', VitalKirokuController);

    VitalKirokuController.$inject = ['entity', 'VitalKiroku', '$rootScope','SelectedPepper','MessageService'];

    function VitalKirokuController (entity, VitalKiroku, $rootScope,SelectedPepper,MessageService) {
        var vm = this;

        vm.save = save;
        vm.pepper = SelectedPepper.current();

        vm.vital = entity;

        function onSaveSuccess(result) {
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function save() {
            if (vm.pepper == null) {
                MessageService.error("ロボットを設定してない,ロボットを選んしてください。");
                return;
            }
            vm.isSaving = true;
            vm.vital.pepperId = vm.pepper.id;
            VitalKiroku.save(vm.vital, onSaveSuccess, onSaveError);
        }
    }
})();
