(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('GroupManagementDeleteController', GroupManagementDeleteController);

    GroupManagementDeleteController.$inject = ['$uibModalInstance', 'entity', 'Group', 'MessageService', '$timeout', '$rootScope'];

    function GroupManagementDeleteController ($uibModalInstance, entity, Group, MessageService, $timeout, $rootScope) {
        var vm = this;

        vm.group = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.error = false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (groupId) {
            Group.delete({id: groupId},
                function () {
                    MessageService.success("groupManagement.deleted");
                    $uibModalInstance.close(true);
                    $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
                }, function () {
                    vm.error = true;
                });
        }
    }
})();
