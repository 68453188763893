(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('PriceController', PriceController);

    PriceController.$inject = ['Price', 'paginationConstants', 'MessageService', '$rootScope'];

    function PriceController (Price, paginationConstants, MessageService, $rootScope) {

        var vm = this;

        // 翻页所需
        vm.page = 1;
        vm.links = null;
        vm.totalItems = null;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.isOpenDateFrom = false;
        vm.isOpenDateTo = false;
        vm.openCalendar = openCalendar;

        // 查询条件
        vm.searchData = {productId: "", productNm: "", stopFlag: "", dateFrom: "", dateTo: ""};
        vm.defaultSearchData = {productId: "", productNm: "", stopFlag: "", dateFrom: "", dateTo: ""};

        // 是否列表
        vm.whetherList = [{id: 0, value: "販売中"}, {id: 1, value: "販売終了"}];

        // 单价列表
        vm.priceList = [];

        // 初始加载数据
        vm.loadData = loadData;

        // 删除指定单价
        vm.remove = remove;
        // 点击查询
        vm.search = search;
        // 清空搜索条件
        vm.clear = clear;

        // 初始自动执行
        loadData();

        function loadData() {
            Price.query({
                page: vm.page - 1,
                productId: vm.searchData.productId,
                productNm: vm.searchData.productNm,
                stopFlag: vm.searchData.stopFlag,
                dateFrom: $('#dateFrom').val(),
                dateTo: $('#dateTo').val()}, function(result, headers){
                vm.totalItems = headers('X-Total-Count');
                vm.priceList = result;
                vm.queryCount = vm.totalItems;
            });
        }

        function remove(id) {
            if(confirm('削除してもよろしいですか?')) {
                Price.delete({id: id}, function(){
                    search();
                    MessageService.success("price.message.deleted");
                    $rootScope.$broadcast('messageChanged');
                });
            }
        }

        function search() {
            vm.page = 1;
            loadData();
        }

        function clear() {
            $('#dateFrom').val("");
            $('#dateTo').val("");
            vm.searchData = angular.copy(vm.defaultSearchData);
            vm.page = 1;
            loadData();
        }

        function openCalendar(i) {
            if (i == 1) {
                vm.isOpenDateFrom = true;
            } else if (i == 2) {
                vm.isOpenDateTo = true;
            }
        }
    }
})();
