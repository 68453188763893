(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('GroupManagementController', GroupManagementController);

    GroupManagementController.$inject = ['ParseLinks', 'paginationConstants','Group','$rootScope', '$state'];

    function GroupManagementController(ParseLinks, paginationConstants,Group, $rootScope, $state) {
        var vm = this;

        vm.serviceTypeList = [];
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.transition = transition;

        vm.groups = [];

        vm.page = 1;
        vm.links = null;
        vm.totalItems = null;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.goEdit = goEdit;
        vm.goDel = goDel;
        vm.goNew = goNew;
        
        vm.searchData = {name: "", jigyoushoCd: "", serviceType: ""};
        vm.defaultSearchData = {name: "", jigyoushoCd: "", serviceType: ""};
        vm.search = search	;
        
        vm.loadAll();
        
        function search(){
            vm.page = 1;
            loadAll();
        }

        function loadAll () {
            if($rootScope.page != undefined){
                vm.page = $rootScope.page;
                $rootScope.page = undefined;
            }
            if ($rootScope.searchData != undefined) {
            	vm.searchData = $rootScope.searchData;
            	$rootScope.searchData = undefined;
            }
            Group.query({page: vm.page - 1, size: vm.itemsPerPage, all: false, name: vm.searchData.name, jigyoushoCd: vm.searchData.jigyoushoCd, serviceType: vm.searchData.serviceType},
                onSuccess, onError);
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.groups = data;
            vm.queryCount = vm.totalItems;
            setTimeout(function() {
                //屏幕高度
                var height = document.documentElement.clientHeight;
                //导航部分的高度
                var navbarHeight = $("#navbarHeight").height();//20
                //检索部分的高度
                var searchHeight = $("#searchHeight").height();//15
                //表头高度
                var headerHeight = $("#headerHeight").height();//
                //分页部分高度
                var pageHeight = $("#pageHeight").height();
                //copyRight
                var copyRight = $("#copyRight").height();
                var tableDivHeight = height-navbarHeight-19-searchHeight-headerHeight-20-pageHeight-10-copyRight-10-40;
                $("#tableDiv").css("max-height",tableDivHeight);
            }, 20);
        }

        function onError(){
        }

        function clear () {
        	vm.searchData = angular.copy(vm.defaultSearchData);
            search();
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function goEdit(id) {
            $rootScope.page = vm.page;
            $rootScope.searchData = vm.searchData;
            $state.go('group-management.edit', {id: id});
        }

        function goDel(id) {
            $rootScope.page = vm.page;
            $rootScope.searchData = vm.searchData;
            $state.go('group-management.delete', {id: id});
        }

        function goNew() {
            $rootScope.page = vm.page;
            $rootScope.searchData = vm.searchData;
            $state.go('group-management.new');
        }
    }
})();
