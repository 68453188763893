(function (){
    'use strict';
    angular.module('gatewayApp')
        .directive('formatPrice', formatPrice);
    formatPrice.$inject = ['$parse'];

    function formatPrice($parse){
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return linkFunc;

        function linkFunc(scope, element, attrs){
            function limit(){
                var limitV=element[0].value;
                limitV=limitV.replace(/[^0-9.]/g,"");
                element[0].value=limitV;
                $parse(attrs['ngModel']).assign(scope, limitV);
                format();
            }

            //对输入数字的整数部分插入千位分隔符
            function format(){
                var formatV=element[0].value;
                var array=new Array();
                array=formatV.split(".");
                var re=/(-?\d+)(\d{3})/;
                while(re.test(array[0])){
                    array[0]=array[0].replace(re,"$1,$2")
                }
                var returnV=array[0];
                for(var i=1;i<array.length;i++){
                    returnV+="."+array[i];
                }
                element[0].value=returnV;
                $parse(attrs['ngModel']).assign(scope, formatV);
            }

            scope.$watch(attrs.ngModel,function(){
                limit();
            })

        }
    }
})();
