(function () {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('UserToken', UserToken);

    UserToken.$inject = ['$resource'];

    function UserToken ($resource) {
        var service = $resource('api/userToken/:login', {}, {
            'query': {
                method: 'GET',
                url: 'api/usersToken'
            }
        });

        return service;
    }
})();
