(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('RoleManagementController', RoleManagementController);

    RoleManagementController.$inject = ['Principal', 'Enum', 'Role', 'ParseLinks', 'MessageService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService', 'Group', '$filter', 'SelectedGroup', '$scope', '$rootScope'];

    function RoleManagementController(Principal, Enum, Role, ParseLinks, MessageService, $state, pagingParams, paginationConstants, JhiLanguageService, Group, $filter, SelectedGroup, $scope, $rootScope) {
        var vm = this;
        vm.searchData = {roleName: "", roleCode: ""};
        vm.defaultSearchData = {roleName: "", roleCode: ""};
        vm.role = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.clear = clear;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.loadAll();
        vm.goEdit = goEdit;
        vm.goDel = goDel;
        vm.goNew = goNew;

        function loadAll () {

            if($rootScope.page != undefined){
                vm.page = $rootScope.page;
                $rootScope.page = undefined;
            }
            if($rootScope.roleCode != undefined){
                vm.searchData.roleCode = $rootScope.roleCode;
                $rootScope.roleCode = undefined;
            }
            if($rootScope.roleName != undefined){
                vm.searchData.roleName = $rootScope.roleName;
                $rootScope.roleName = undefined;
            }
            Role.query({
                page: vm.page - 1,
                size: vm.itemsPerPage,
                name: vm.searchData.roleName,
                code: vm.searchData.roleCode,
                sort: sort()
            }, onSuccess, onError);
        }

        function search(){
            vm.page = 1;
            loadAll();
        }

        function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.role = data;
            setTimeout(function() {
                //屏幕高度
                var height = document.documentElement.clientHeight;
                //导航部分的高度
                var navbarHeight = $("#navbarHeight").height();//20
                //检索部分的高度
                var searchHeight = $("#searchHeight").height();//15
                //表头高度
                var headerHeight = $("#headerHeight").height();//
                //分页部分高度
                var pageHeight = $("#pageHeight").height();
                //copyRight
                var copyRight = $("#copyRight").height();
                var tableDivHeight = height-navbarHeight-19-searchHeight-headerHeight-20-pageHeight-10-copyRight-10-40;
                $("#tableDiv").css("max-height",tableDivHeight);
            }, 20);
        }

        function onError(){
        }

        function clear () {
            vm.searchData = angular.copy(vm.defaultSearchData);
            search();
        }

        function loadPage (page) {
            vm.page = page;
            //vm.transition();
        }

        function transition () {

        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }

        function goEdit(code) {
            $rootScope.roleCode = vm.searchData.roleCode;
            $rootScope.roleName = vm.searchData.roleName;
            $rootScope.page = vm.page;
            $state.go('role-management.edit', {code: code});
        }

        function goDel(code, name) {
            $rootScope.roleCode = vm.searchData.roleCode;
            $rootScope.roleName = vm.searchData.roleName;
            $rootScope.page = vm.page;
            $state.go('role-management.delete', {code: code, name: name});
        }

        function goNew() {
            $rootScope.roleCode = vm.searchData.roleCode;
            $rootScope.roleName = vm.searchData.roleName;
            $rootScope.page = vm.page;
            $state.go('role-management.new');
        }
    }
})();
