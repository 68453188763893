(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('schedule', {
            parent: 'script',
            url: '/schedule',
            data: {
                pageTitle: 'global.menu.system.schedule'
            },
            views: {
                'content@': {
                    templateUrl: 'app/system/script/schedule/schedule.html',
                    controller: 'ScheduleController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                // 文字
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('schedule');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
