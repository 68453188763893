(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CategoryEditDialogController', CategoryEditDialogController);

    CategoryEditDialogController.$inject = ['$uibModalInstance', 'entity', 'Category', 'JhiLanguageService', 'Enum', 'MessageService', '$timeout', '$rootScope'];

    function CategoryEditDialogController ($uibModalInstance, entity, Category, JhiLanguageService, Enum, MessageService, $timeout, $rootScope) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;

        vm.languages = null;
        vm.category = entity;

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            MessageService.success("global.messages.info.save");
            $uibModalInstance.close(result);
            $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
        }

        function onSaveError() {
            MessageService.error("global.messages.error.save");
            vm.isSaving = false;
        }

        function save() {
            vm.isSaving = true;
            if (vm.category.id !== null) {
                Category.update(vm.category, onSaveSuccess, onSaveError);
            } else {
                Category.save(vm.category, onSaveSuccess, onSaveError);
            }
        }
    }
})();
