(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('JigyoushoResourceService', JigyoushoResourceService);

    JigyoushoResourceService.$inject = ['$resource'];

    function JigyoushoResourceService ($resource) {
        var service = $resource('api/jigyousho', {}, {
            'jigyoushoList': {
                method: 'GET',
                url: 'api/jigyousho/jigyoushoList',
                isArray: true
            },
            'serviceTypeList': {
                method: 'GET',
                url: 'api/jigyousho/serviceTypeList',
                isArray: true
            }
        });

        return service;
    }

})();
