(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('FunctionManagementDeleteController',FunctionManagementDeleteController);

    FunctionManagementDeleteController.$inject = ['$stateParams', 'entity', '$uibModalInstance', 'Kinou', 'JhiLanguageService','Group', 'MessageService', '$timeout', '$rootScope','OptionUser','Principal', 'Outlets'];

    function FunctionManagementDeleteController ($stateParams, entity, $uibModalInstance, Kinou, JhiLanguageService, Group, MessageService, $timeout, $rootScope, OptionUser, Principal, Outlets) {
        var vm = this;
        vm.clear = clear;
        vm.delete = deleteKinou;
        vm.kinou = entity;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function deleteKinou (id){
            Kinou.deleteKinou({kinouId: id}, function(){
                MessageService.success("functionManagement.kinou.kinouDelete");
                $uibModalInstance.close(true);
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            });
        }
    }
})();
