(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CaretreeApplicationController', CaretreeApplicationController);

    CaretreeApplicationController.$inject = ['paginationConstants', 'MessageService', '$rootScope', '$state'];

    function CaretreeApplicationController (paginationConstants, MessageService, $rootScope, $state) {

        var vm = this;
        vm.inquiry = {name:'', houjinName:'', jigyoushoName:'', telephoneNumber:''}
        vm.phoneRequiredFlag = false;
        vm.nameRequiredFlag = false;
        vm.save = save;
        vm.loadData = loadData;

        loadData();

        function loadData() {

        }
        function save(){
            if(vm.inquiry.name == ""){
                vm.nameRequiredFlag = true;
            } else {
                vm.nameRequiredFlag = false;
            }
            if(vm.inquiry.telephoneNumber == ""){
                vm.phoneRequiredFlag = true;
            } else {
                vm.phoneRequiredFlag = false;
            }
            if(!vm.nameRequiredFlag && !vm.phoneRequiredFlag){
                $rootScope.inquiry = vm.inquiry;
                $state.go('inquiry.validation');
            }
        }
    }
})();
