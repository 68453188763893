(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('StroageService', StorageService);

    StorageService.$inject = ['$resource'];

    function StorageService ($resource) {
        var service = $resource('caretree/api/storages', {}, {
            'query': {
                method: 'GET',
                url: 'caretree/api/storage/list',
                isArray: true
            },
            'houjinList': {
                method: 'GET',
                url: 'caretree/api/storages/houjin/:id'
            },
            'deletePicture': {
                method: 'POST',
                url: 'caretree/api/storages/houjin'
            },
            'mailStop': {
                method: 'PUT',
                url: 'caretree/api/storages/houjin'
            },
            'sendMail': {
                method: 'PUT',
                url: 'caretree/api/storages/sendMail'
            },
            'storageByHoujinId':{
                method: 'GET',
                url: 'caretree/api/storages/storage/:id'
            },
            'storageUpdateTime':{
                method: 'GET',
                url: 'caretree/api/storages/storageUpdateTime/:id'
            },
            'insertStorage': {
                method: 'PUT',
                url: 'caretree/api/storages/insertStorage'
            },
            'updateStorageTotalSize': {
                method: 'PUT',
                url: 'caretree/api/storages/updateStorageTotalSize'
            }
        });

        return service;
    }

})();
