(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Category', Category);

    Category.$inject = ['$resource'];

    function Category ($resource) {
        var service = $resource('robot/api/category', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                url: 'robot/api/categories/page'
            },
            'save': {
                method: 'POST'
            },
            'get': {
                method: 'GET',
                url: 'robot/api/category/:id'
            },
            'update': {
                method: 'PUT'
            },
            'delete': {
                method: 'DELETE',
                url: 'robot/api/category/:id'
            },
            'list': {
                method: 'GET',
                isArray: true,
                url: 'robot/api/categories'
            },
            'allList': {
                method: 'GET',
                isArray: true,
                url: 'robot/api/categories/all'
            }
        });

        return service;
    }

})();
