(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Uploader', Uploader);

    Uploader.$inject = ['$resource'];

    function Uploader ($resource) {
        var service = $resource('library/api/upload', {}, {
            "query": {
                method: 'GET',
                isArray: true
            },
            "upload":{
                method: 'POST',
                url: "library/api/upload/photo"
            },
            "changePublishType": {
                method: "PUT"
            },
            "del": {
                method: "DELETE",
                url: "library/api/upload/id/:id"
            },
            "get": {
                method: "GET",
                url: "library/api/upload/id/:id"
            },
            "delByName": {
                method: "DELETE",
                url: "library/api/upload/movementDel/:name"
            }
        });

        return service;
    }

})();
