(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('UserMmanagementCreatetokenController', UserMmanagementCreatetokenController);

    UserMmanagementCreatetokenController.$inject = ['$uibModalInstance','$stateParams', 'UserToken', 'Outlets', '$uibModal', 'TokenService', 'MessageService'];

    function UserMmanagementCreatetokenController($uibModalInstance,$stateParams, UserToken, Outlets, $uibModal, TokenService, MessageService) {
        var vm = this;

        vm.load = load;
        vm.user = {};
        vm.outlets = {};
        vm.outletsShow = false;
        vm.close = close;
        vm.save = save;
        vm.update = update;
        vm.copyToken = copyToken;
        vm.deleteToken = deleteToken;
        vm.token = "";

        vm.load($stateParams.jigyoushoCd, $stateParams.login);

        function load(jigyoushoCd, login) {
            UserToken.query({jigyoushoCd: jigyoushoCd, login: login}, function(result) {
                vm.token = result.token;
            });
        }

        function close () {
            $uibModalInstance.dismiss('cancel');
        }

        function save (){
            var entity = {jigyoushoCd: $stateParams.jigyoushoCd, loginId: $stateParams.login};
            TokenService.createToken(entity, function(result){
                MessageService.success("userManagement.token.tokenSaved");
                vm.token = result.token;
            });
        }

        function update(){
            var entity = {jigyoushoCd: $stateParams.jigyoushoCd, loginId: $stateParams.login};
            TokenService.updateToken(entity, function(result){
                MessageService.success("userManagement.token.tokenUpdated");
                vm.token = result.token;
            });
        }

        function copyToken(token) {
            $('#copyText').val(token).show().focus().select();
            document.execCommand("copy");
            $('#copyText').hide();
        }

        function deleteToken(token){
            var modalInstance = $uibModal.open({
                templateUrl : 'app/system/user-management/dialog/delete-token-dialog.html',
                controller : 'DeleteTokenDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size : 'md',
                resolve: {
                    entity: ['TokenService', function(TokenService) {
                        return token ;
                    }]
                }
            });
            modalInstance.result.then(function(result) {
                vm.token = null;
            },function(){
                //MessageService.error("global.error.internalServerError");
            });
        }
    }
})();
