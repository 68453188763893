(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Group', Group);

    Group.$inject = ['$resource'];

    function Group ($resource) {
        var service = $resource('api/groups', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                url: 'api/groups/private'
            },
            'queryUserGroups': {
                method: 'GET',
                isArray: true,
                url: 'api/groups/user/private',
                params: {"api" : "false"}
            },
            'save': {
                method: 'POST'
            },
            'update': {
                method:'PUT'
            },
            'get': {
                method: 'GET',
                url: 'api/groups/edit/:id'
            },
            'caretree': {
                method:'GET',
                url: 'api/groups/caretreeInfo/:groupId'
            },
            'selectService': {
                method: 'POST',
                isArray: true,
                url:'api/groups/services'
            },
            'delete': {
                method:'DELETE',
                url: 'api/groups/:id'
            },
            'parentGroup': {
                method: 'GET',
                isArray: true,
                url: 'api/groups/parentGroup'
            },
            'manageGroup': {
                method: 'GET',
                isArray: true,
                url: 'api/groups/manageGroup'
            },
            'parentGroupExceptMyself': {
                method: 'GET',
                isArray: true,
                url: 'api/groups/parentGroupExceptMyself'
            },
            'parentGroupByGroupNm': {
                method: 'GET',
                isArray: true,
                url: 'api/groups/parentGroupByGroupNm'
            },
            'groupList': {
                method: 'GET',
                isArray: true,
                url: 'api/groups/user'
            },
            'riyoushaList': {
                method: 'GET',
                isArray: true,
                url:'caretree/api/groups/:groupId/customers'
            },
            'subGroupIdList': {
                method: 'GET',
                isArray: true,
                url: 'api/groups/subgroup'
            },
            'queryDialog': {
                method: 'GET',
                url: 'api/groups/selectList',
                isArray: true
            },
            'getAllGroupFlags': {
                method: 'GET',
                url: 'api/groups/allGroupFlags',
                isArray: true
            },
            'queryGroupName': {
            	method: 'GET',
            	url: 'api/groups/queryGroupName',
            	isArray: true
            },
            'queryParentGroupId': {
                method: 'GET',
                url: 'api/groups/queryParentGroupId'
            },
            'queryChildrenList': {
                method: 'GET',
                url: 'api/groups/queryChildrenList'
            },
            'parentGroupList': {
                method: 'GET',
                isArray: true,
                url: 'api/groups/parentGroupList'
            }
        });

        return service;
    }

})();
