(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('ShiftDuty', ShiftDuty);

    ShiftDuty.$inject = ['$resource'];

    function ShiftDuty ($resource) {
        var service = $resource('timecard/api/shift', {}, {
            'getAll': {
                method: 'GET',
                url:'timecard/api/shift/all',
                isArray: true
            },
            'getAllByGroupId': {
                method: 'GET',
                url:'timecard/api/shift/getAllByGroupId',
                isArray: true
            },
            'save': {
                method: 'POST',
                url:'timecard/api/shift/save'
            },
            'delete': {
                method: 'DELETE',
                url:'timecard/api/shift/delete/:id'
            }
        });

        return service;
    }

})();
