(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('PhotoShowDialogController', PhotoShowDialogController);

    PhotoShowDialogController.$inject = ['entity', '$uibModalInstance'];

    function PhotoShowDialogController(entity, $uibModalInstance) {

        var vm = this;

        vm.library = entity;

        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
