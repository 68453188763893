(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('PriceDeleteDialogController', PriceDeleteDialogController);

    PriceDeleteDialogController.$inject = ['$uibModalInstance', 'entity', 'Price', 'MessageService', '$timeout', '$rootScope', 'productEntity'];

    function PriceDeleteDialogController ($uibModalInstance, entity, Price, MessageService, $timeout, $rootScope, productEntity) {
        var vm = this;

        vm.price = entity;
        vm.product = productEntity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.error = false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Price.delete({id: id}, function() {
                MessageService.success("price.message.deleted");
                $uibModalInstance.close(true);
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            }, function () {
                vm.error = true;
            });
        }
    }
})();
