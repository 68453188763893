(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .provider('MessageService', MessageService);

    function MessageService() {
        this.toast = false;
        /*jshint validthis: true */
        this.$get = getService;

        this.showAsToast = function(isToast) {
            this.toast = isToast;
        };

        getService.$inject = ['$rootScope', '$translate'];

        function getService ($rootScope, $translate) {

            return {
                success: success,
                error: error,
                errorWithSecond: errorWithSecond,
                warning: warning
            };

            function success(message) {
                var str = [];
                if ($rootScope.msgs) {
                    str = JSON.parse($rootScope.msgs);
                }
                var myDate = new Date();
                str.push({"text": $translate.instant(message), "time": myDate.getHours() + ":" + (myDate.getMinutes() > 9 ? myDate.getMinutes() : "0" + myDate.getMinutes()), "type": "message_success"});
                $rootScope.msgs = JSON.stringify(str);
                $rootScope.$broadcast('messageChanged');
            }

            function error(message) {
                var str = [];
                if ($rootScope.msgs) {
                    str = JSON.parse($rootScope.msgs);
                }
                var myDate = new Date();
                str.push({"text": $translate.instant(message), "time": myDate.getHours() + ":" + (myDate.getMinutes() > 9 ? myDate.getMinutes() : "0" + myDate.getMinutes()), "type": "message_error"});
                $rootScope.msgs = JSON.stringify(str);
                $rootScope.$broadcast('messageChanged');
            }

            function errorWithSecond(message, second) {
                var str = [];
                if ($rootScope.msgs) {
                    str = JSON.parse($rootScope.msgs);
                }
                var myDate = new Date();
                str.push({"text": $translate.instant(message), "time": myDate.getHours() + ":" + (myDate.getMinutes() > 9 ? myDate.getMinutes() : "0" + myDate.getMinutes()), "type": "message_error"});
                $rootScope.msgs = JSON.stringify(str);
                $rootScope.$broadcast('messageShowWithTime', second);
            }

            function warning(message) {
                var str = [];
                if ($rootScope.msgs) {
                    str = JSON.parse($rootScope.msgs);
                }
                var myDate = new Date();
                str.push({"text": $translate.instant(message), "time": myDate.getHours() + ":" + (myDate.getMinutes() > 9 ? myDate.getMinutes() : "0" + myDate.getMinutes()), "type": "message_warning"});
                $rootScope.msgs = JSON.stringify(str);
                $rootScope.$broadcast('messageChanged');
            }
        }
    }
})();
