(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('RobotManagementDialogController',RobotManagementDialogController);

    RobotManagementDialogController.$inject = ['$uibModalInstance', 'Pepper', 'MessageService', 'Group', '$timeout', '$rootScope', '$stateParams'];

    function RobotManagementDialogController ($uibModalInstance, Pepper, MessageService, Group, $timeout, $rootScope, $stateParams) {
        var vm = this;

        vm.pepper = {id: null, userId:0, caretreeId: null, caretreePw: null, jigyoushoCd: null, serviceType: null, pepperName: null};
        vm.goups = [];

        vm.clear = clear;
        vm.save = save;
        vm.loadAllGroupsByGroupNm = loadAllGroupsByGroupNm;

        vm.loadAllGroups = loadAllGroups();
        vm.clearGroups = clearGroups;

        vm.groupNm = "";
        
        vm.clickGroupListClick = clickGroupListClick;
        vm.inputGroupNm = inputGroupNm;
        
        vm.loadPepperInfo = loadPepperInfo;

        function loadAllGroups() {
            Group.parentGroup(function(result){
                vm.groups = result;
                if ($stateParams.id != null && $stateParams.id != '') {
                	loadPepperInfo();
                }
            });
        }
        
        function loadPepperInfo() {
        	Pepper.get({id : $stateParams.id}, function(result) {
                if (result != null) {
                    vm.pepper = result;
                    if (vm.pepper.groupId != null && vm.pepper.groupId != '') {
                        vm.pepper.groupId = vm.pepper.groupId.toString();
                    }
                    for (var i = 0; i < vm.groups.length; i++) {
                    	if (vm.pepper.groupId == vm.groups[i].id) {
                    		vm.groupNm = vm.groups[i].name + vm.groups[i].serviceTypeName;
                    	}
                    }
                }
            });
        }

        function loadAllGroupsByGroupNm() {
            Group.parentGroupByGroupNm({groupNm: vm.groupName},function(result){
                vm.groups = result;
                vm.pepper.groupId = result[0].id;
            });
        }

        function clearGroups(){
            vm.groupName = "";
            loadAllGroupsByGroupNm();
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            MessageService.success("robotManagement.message.saveSuccess");
            $uibModalInstance.close(result);
            $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
        }

        function onSaveError () {
            MessageService.error("robotManagement.message.saveError");
            vm.isSaving = false;
        }

        function save () {
            Pepper.check({pepperName : vm.pepper.pepperName, groupId: vm.pepper.groupId},
            function(result){
                if(result.length > 0){
                    if(vm.pepper.id == null){
                        alert("違うなロボート名を登録するください！");
                        return false;
                    } else {
                        if(vm.pepper.id == result[0].id){
                            vm.isSaving = true;
                            if (vm.pepper.id !== null) {
                                Pepper.update(vm.pepper, onSaveSuccess, onSaveError);
                            } else {
                                Pepper.save(vm.pepper, onSaveSuccess, onSaveError);
                            }
                        }else {
                            alert("違うなロボート名を登録するください！");
                            return false;
                        }
                    }

                }else{
                    vm.isSaving = true;
                    if (vm.pepper.id !== null) {
                        Pepper.update(vm.pepper, onSaveSuccess, onSaveError);
                    } else {
                        Pepper.save(vm.pepper, onSaveSuccess, onSaveError);
                    }
                }
            });
        }
        
        function clickGroupListClick(gp) {
        	vm.pepper.groupId = gp.id;
        	vm.groupNm = gp.name + gp.serviceTypeName;
        	vm.groupSelectListShowFlg = false;
        }
        
        function inputGroupNm() {
        	vm.groupSelectListShowFlg = false;
    		vm.pepper.groupId = "";
        	
        	for (var i = 0; i < vm.groups.length; i ++) {
        		if (vm.groupNm != undefined && vm.groupNm != "") {
        			if ((vm.groups[i].name + vm.groups[i].serviceTypeName).indexOf(vm.groupNm) != -1) {
        				vm.groups[i].showFlg = true;
        				vm.groupSelectListShowFlg = true;
        			} else {
        				vm.groups[i].showFlg = false;
        			}
        		} else {
        			vm.parentGroup[i].showFlg = true;
        		}
        	}
        }
    }
})();
