(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('library', {
            parent: 'script',
            url: '/library',
            data: {
                pageTitle: 'global.menu.system.library'
            },
            views: {
                'content@': {
                    templateUrl: 'app/system/script/library/library.html',
                    controller: 'LibraryController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                // 文字
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('library');
                    return $translate.refresh();
                }]
            }
        }).state('library-url', {
            parent: 'library',
            url: '/{id}/library-url',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
            	Principal.identity().then(function(account) {
            		if (account != null) {
            			var gtc_caretree2_robot_allfunction_fullaccess = false;
                    	if (account.functions != null) {
                        	for (var i in account.functions) {
                        		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                        			gtc_caretree2_robot_allfunction_fullaccess = true;
                        		}
                        	}
                        }
                    	if (gtc_caretree2_robot_allfunction_fullaccess != false) {
                        	$uibModal.open({
                                templateUrl: 'app/system/script/library/url/library-url.html',
                                controller: 'LibraryUrlController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'md',
                                resolve: {
                                    entity: ['Library', function (Library) {
                                        if ($stateParams.id != 0) {
                                            return Library.get({id: $stateParams.id});
                                        } else {
                                            return {id: null, fileName: '', url: '', comment: '', publishType: 3};
                                        }

                                    }]
                                }
                            }).result.then(function() {
                                $state.go('library', null, { reload: true });
                            }, function() {
                                $state.go('library');
                            });
                        }
            		} else {
            			LoginService.open();
            		}
                	
                });
            }]
        }).state('library-upload', {
            parent: 'library',
            url: '/library-upload',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal','LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
            	Principal.identity().then(function(account) {
            		if (account != null) {
            			var gtc_caretree2_robot_allfunction_fullaccess = false;
                    	if (account.functions != null) {
                        	for (var i in account.functions) {
                        		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                        			gtc_caretree2_robot_allfunction_fullaccess = true;
                        		}
                        	}
                        }
                    	if (gtc_caretree2_robot_allfunction_fullaccess != false) {
                        	$uibModal.open({
                                templateUrl: 'app/system/script/library/upload/library-upload.html',
                                controller: 'LibraryUploadController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'md'
                            }).result.then(function() {
                                $state.go('library', null, { reload: true });
                            }, function() {
                                $state.go('library');
                            });
                        }
            		} else {
            			LoginService.open();
            		}
                	
                });
            }]
        }).state('library-edit', {
            parent: 'library',
            url: '/{id}/library-edit',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
            	Principal.identity().then(function(account) {
            		if (account != null) {
            			var gtc_caretree2_robot_allfunction_fullaccess = false;
                    	if (account.functions != null) {
                        	for (var i in account.functions) {
                        		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                        			gtc_caretree2_robot_allfunction_fullaccess = true;
                        		}
                        	}
                        }
                    	if (gtc_caretree2_robot_allfunction_fullaccess != false) {
                        	$uibModal.open({
                                templateUrl: 'app/system/script/library/edit/library-edit.html',
                                controller: 'LibraryEditController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'md',
                                resolve: {
                                    entity: ['Library', function (Library) {
                                        return Library.get({id: $stateParams.id});
                                    }]
                                }
                            }).result.then(function() {
                                $state.go('library', null, { reload: true });
                            }, function() {
                                $state.go('library');
                            });
                        }
            		} else {
            			LoginService.open();
            		}
                	
                });
            }]
        });
    }
})();
