(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('timecard', {
            parent: 'app',
            url: '/timecard/timecard',
            data: {
                pageTitle: 'global.menu.timecard.main'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', 'Kinou',
                function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService, Kinou) {
            	var top = $uibModalStack.getTop();
            	if (top) {
            	    $uibModalStack.dismiss(top.key);
            	}
            	Principal.identity().then(function(account) {
            		if (account != null) {
                        var gtc_caretree2_timecard_allfunction_fullaccess = false;
                        var isAdmin = false;
                        var isDeveloper = false;
                        Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_TimeCard_AllFunction_FullAccess"}, function(result){
                        	if (!result.flag) {
                        		$state.go('accessdenied');
                        	} else {
                        		plan($state, $uibModal, account, isAdmin, isDeveloper, gtc_caretree2_timecard_allfunction_fullaccess);
                        	}
                        });
            		} else {
            			LoginService.open();
            		}
                });
            }]
        })
        .state('timecard.dutyselect', {
            parent: 'timecard',
            url: '/dutyselect',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', '$uibModalStack', '$rootScope', function($stateParams, $state, $uibModal, $uibModalStack, $rootScope) {
                $uibModal.open({
                    templateUrl: 'app/timecard/select-duty-occupation.html',
                    controller: 'SelectDutyOccupationController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        param: {code: $stateParams.code}
                    }
                }).result.then(function() {
                    $state.go('timecard', null, { reload: false });
                }, function() {
                    $state.go('timecard');
                });
            }]
        });
    }
    function plan($state, $uibModal, account, isAdmin, isDeveloper, gtc_caretree2_timecard_allfunction_fullaccess){
        if (account.authorities.indexOf("ROLE_ADMIN") != -1) {
            isAdmin = true;
        }
        if (account.authorities.indexOf("ROLE_DEVELOPER") != -1) {
            isDeveloper = true;
        }
        if (account.functions != null) {
            for (var i in account.functions) {
                if (account.functions[i] == 'GTC_CareTree2_TimeCard_AllFunction_FullAccess') {
                    gtc_caretree2_timecard_allfunction_fullaccess = true;
                }
            }
        }
        if (!gtc_caretree2_timecard_allfunction_fullaccess && !isAdmin && !isDeveloper) {
            $state.go('home');
            $uibModal.open({
                templateUrl: 'app/home/dialog/license.html',
                controller: 'HomeLicenseController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg'
            }).result.then(function() {
                $state.go('home', null, { reload: true });
            }, function() {
                $state.go('home');
            });
        }else{
            $uibModal.open({
                templateUrl: 'app/timecard/timecard.html',
                controller: 'TimecardController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'full-screen',
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('timecard');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
                $state.go('home');
            }, function() {
                $state.go('home');
            });
        }
    }
})();
