(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'Enum', 'User', 'ParseLinks', 'MessageService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService', 'Group', '$filter', 'SelectedGroup', '$scope', '$rootScope', '$stateParams', 'StaffService'];

    function UserManagementController(Principal, Enum, User, ParseLinks, MessageService, $state, pagingParams, paginationConstants, JhiLanguageService, Group, $filter, SelectedGroup, $scope, $rootScope, $stateParams, StaffService) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.searchData = {loginId: "", name: "", mailAddress: "", selectGroup: "", activat: "", authorities: 0};
        vm.defaultSearchData = {loginId: "", name: "", mailAddress: "", selectGroup: "", activat: "", authorities: 0};
        vm.currentAccount = null;
        vm.languages = null;
        vm.loadAll = loadAll;
        vm.setActive = setActive;
        vm.users = [];
        vm.page = $stateParams.page;
        vm.totalItems = null;
        vm.clear = clear;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;

        //vm.searchData.selectGroup = SelectedGroup.current() != null ? SelectedGroup.current().id : null;
        vm.parentGroup = [];
        vm.selectedUser = [];
        vm.selectedUserId = selectedUserId;
        vm.select = select;
        vm.isChecked = isChecked;
        vm.loadParentGroup = loadParentGroup;
        vm.changeGroup = changeGroup;
        vm.research = research;

        vm.gotoUpdateAll = gotoUpdateAll;
        vm.changeactivat = changeactivat;
        vm.changeactivatText = changeactivatText;
        vm.changeauthorities = changeauthorities;
        vm.changeauthoritiesText = changeauthoritiesText;

        vm.loadParentGroup();
        vm.search = search;

        vm.goEdit = goEdit;
        vm.goDel = goDel;
        vm.goNew = goNew;

        vm.clickGroupListClick = clickGroupListClick;
        vm.inputGroupNm = inputGroupNm;

        vm.version = 2;
        vm.changeVersion = changeVersion;
        vm.searchData.staff = {name: "", id: "", login: "", houjinNm: "", jigyoushoNm: ""};
        vm.clearStaff = clearStaff;
        vm.changePage = changePage;

        vm.clickGroupListClick = clickGroupListClick;
        vm.inputGroupNm = inputGroupNm;

        vm.systemAuthorities = {};

        vm.authorities = [];
        vm.loadAuthorities = loadAuthorities;
        vm.loadUsers = loadUsers;

        vm.loadAll();

        function loadAll () {
            if(vm.searchData.authorities == 0){
                vm.searchData.authorities = "";
            }
            if($rootScope.page != undefined){
                vm.page = $rootScope.page;
                $rootScope.page = undefined;
            }
            if ($rootScope.searchData != undefined) {
            	vm.searchData = $rootScope.searchData;
            	$rootScope.searchData = undefined;
            }
            loadTypes();
            loadAuthorities();
        }

        function loadAuthorities() {
        	User.getRole(function(result){
        		vm.authorities = result;
        		loadUsers();
        	});
        }

        function loadUsers() {
        	User.query({
                pageNumber: vm.page - 1,
                pageSize: vm.itemsPerPage,
                sort: sort(),
                groupId: vm.searchData.selectGroup == null ? "" : vm.searchData.selectGroup,
                login: vm.searchData.loginId,
                userName: vm.searchData.name,
                mailAddress: vm.searchData.mailAddress,
                activat: vm.searchData.activat == null ? "" : vm.searchData.activat,
                authorities: vm.searchData.authorities
            }, onSuccess, onError);
        }

        Principal.hasAuthority('ROLE_ADMIN').then(function(result){
            if(result) {
                vm.isAdmin = true;
            } else {
                vm.isAdmin = false;
            }
        });

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });
        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });

        function setActive (user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        function search(){
            vm.page = 1;
            loadAll();
        }

        function changeactivat(){
            search();
        }

        function changeauthorities(){
            search();
        }

        function changeauthoritiesText(vlaue){
            vm.searchData.authorities = vlaue;
            search();
        }

        function changeactivatText(value){
            vm.searchData.activat = value;
            search();
        }

        function onSuccess(data, headers) {
            for (var i = 0; i < data.length; i ++) {
                var adminFlg = false;
                for (var j in data[i].authorities) {
                    if (data[i].authorities[j] == 'ROLE_ADMIN') {
                        adminFlg = true;
                    }
                    for (var k in vm.authorities) {
                    	if (vm.authorities[k].name == data[i].authorities[j]) {
                    		data[i].authorities[j] = vm.authorities[k];
                    		break;
                    	}
                    }
                }
                data[i].hasAdmin = adminFlg;
            }
            //hide anonymous user from user management: it's a required user for Spring Security
            for (var i in data) {
                if (data[i]['login'] === 'anonymoususer') {
                    data.splice(i, 1);
                }
            }
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.users = data;
            setTimeout(function() {
                //屏幕高度
                var height = document.documentElement.clientHeight;
                //导航部分的高度
                var navbarHeight = $("#navbarHeight").height();//20
                //检索部分的高度
                var searchHeight = $("#searchHeight").height();//15
                //表头高度
                var headerHeight1 = $("#headerHeight1").height();
                var headerHeight2 = $("#headerHeight2").height();
                //分页部分高度
                var pageHeight = $("#pageHeight").height();
                //copyRight
                var copyRight = $("#copyRight").height();

                if(vm.version == 2){
                    var tableDivHeight2 = height-navbarHeight-20-searchHeight-headerHeight2-20-pageHeight-10-copyRight-10-40;
                    $("#tableDiv2").css("max-height",tableDivHeight2);
                }
                if(vm.version == 1){
                    var tableDivHeight1 = height-navbarHeight-20-searchHeight-headerHeight1-20-pageHeight-10-copyRight-10-40;
                    $("#tableDiv1").css("max-height",tableDivHeight1);
                }
            }, 20);
        }

        function onError(error) {
            MessageService.error(error.data.message);
        }

        function clear () {
            vm.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null, groupAuthes: null
            };
            vm.searchData = angular.copy(vm.defaultSearchData);
            search();
        }

        function sort () {
            var result = [vm.predicate + ' ' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ' ' + (vm.reverse ? 'asc' : 'desc'),
                groupId: vm.selectGroup == null ? "" : vm.selectGroup,
                search: vm.currentSearch

            });
        }

        function loadParentGroup() {
            Group.parentGroup({}, function(result){
                vm.parentGroup = result;
            });
        }

        function changeGroup() {

            var selected = null;
            vm.selectedUser = [];
            for (var i = 0; i < vm.parentGroup.length; i ++) {
                if (vm.parentGroup[i].id == vm.searchData.selectGroup) {
                    selected = vm.parentGroup[i];
                }
            }
            SelectedGroup.setCurrent(selected);
            $rootScope.$broadcast('groupChanged');
            search();
            //research();
        }

        function research() {
            User.query({
                page: 0,
                size: vm.itemsPerPage,
                sort: sort(),
                groupId: vm.selectGroup == null ? "" : vm.selectGroup
            }, onSuccess, onError);
        }

        function select(user){
           if (vm.isChecked(user)) {
               angular.forEach(vm.selectedUser, function (u){
                   if(user.id == u.userId){
                       var index = vm.selectedUser.indexOf(u);
                       vm.selectedUser.splice(index,1);
                   }
               });
           } else {
               var userName = "";
               if (user.lastName != null && user.lastName != "") {
                   userName += user.lastName;
               }
               if (user.firstName != null && user.firstName != "" && user.lastName != null && user.lastName != "") {
                   userName += " ";
               }
               if (user.firstName != null && user.firstName != "") {
                   userName += user.firstName;
               }
               vm.selectedUser.push({userId:user.id,userName:userName});
           }
        }

        function isChecked(user) {
            //var selected = $filter('filter')(vm.selectedUser, {userId: user.id})[0];
            var result = false;
            angular.forEach(vm.selectedUser, function (u){
                if(user.id == u.userId){
                    result = true;
                }
            });
            if (result){
                return true;
            }

            angular.forEach(this.groups, function(group) {
                if (group !== openGroup) {
                    group.isOpen = false;
                }
            });
            return false;
        }

        function selectedUserId(){
            var ids = [];
            angular.forEach(vm.selectedUser,function(data,index,array){
                ids.push(data.userId);
            });
            return ids;
        }

        $scope.$on('groupChanged', function() {
            vm.searchData.selectGroup = SelectedGroup.current() != null ? SelectedGroup.current().id : null;
            search();
        })

        function gotoUpdateAll() {
            if (vm.selectedUserId().length > 0) {
                $state.go("user-management.updateAll", {selected:vm.selectedUserId()});
            } else {
                MessageService.error("userManagement.error.noSelected");
                return false;
            }

        }

        function loadTypes() {
            /*Enum.getGateWay({name: "SystemAuthorities"}, function(result) {
                vm.systemAuthorities = result;
            });*/
            User.getRole(function(r) {
                var role = {name: "", nameDetail: "すべて", checked : true};
                r.unshift(role);
                vm.systemAuthorities = r;
            });
        }

        function goEdit(login) {
            $rootScope.page = vm.page;
            $rootScope.searchData = vm.searchData;
            $state.go('user-management.edit', {login: login});
        }

        function goDel(login) {
            $rootScope.page = vm.page;
            $rootScope.searchData = vm.searchData;
            $state.go('user-management.delete', {login: login});
        }

        function goNew() {
            $rootScope.page = vm.page;
            $rootScope.searchData = vm.searchData;
            $state.go('user-management.new');
        }

        function changeVersion(version) {
            vm.page = 1;
            if (version == 1) {
            	loadOneData();
            } else {
            	search();
            }

        }

        function loadOneData() {
            StaffService.query({page: vm.page - 1, size: vm.itemsPerPage,
            	name: vm.searchData.staff.name,
            	id: vm.searchData.staff.id,
            	login: vm.searchData.staff.login,
            	houjinNm: vm.searchData.staff.houjinNm,
            	jigyoushoNm: vm.searchData.staff.jigyoushoNm}, function(result, headers){
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.staffList = result;
                setTimeout(function() {
                    //屏幕高度
                    var height = document.documentElement.clientHeight;
                    //导航部分的高度
                    var navbarHeight = $("#navbarHeight").height();//20
                    //检索部分的高度
                    var searchHeight = $("#searchHeight").height();//15
                    //表头高度
                    var headerHeight1 = $("#headerHeight1").height();
                    var headerHeight2 = $("#headerHeight2").height();
                    //分页部分高度
                    var pageHeight = $("#pageHeight").height();
                    //copyRight
                    var copyRight = $("#copyRight").height();

                    if(vm.version == 2){
                        var tableDivHeight2 = height-navbarHeight-20-searchHeight-headerHeight2-20-pageHeight-10-copyRight-10-40;
                        $("#tableDiv2").css("max-height",tableDivHeight2);
                    }
                    if(vm.version == 1){
                        var tableDivHeight1 = height-navbarHeight-20-searchHeight-headerHeight1-20-pageHeight-10-copyRight-10-40;
                        $("#tableDiv1").css("max-height",tableDivHeight1);
                    }
                }, 20);
            });
        }

        function clearStaff() {
        	vm.searchData.staff = {name: "", id: "", login: "", houjinNm: "", jigyoushoNm: ""};
        	changeVersion(1);
        }

        function changePage() {
        	if (vm.version == 1) {
        		loadOneData();
        	} else {
        		loadAll();
        	}
        }

        function clickGroupListClick(gp) {
        	vm.searchData.selectGroup = gp.id;
        	vm.searchData.selectGroupNm = gp.name + gp.serviceTypeName;
        	vm.groupSelectListShowFlg = false;
        }

        function inputGroupNm() {
        	vm.groupSelectListShowFlg = false;
        	if (vm.searchData.selectGroupNm != undefined && vm.searchData.selectGroupNm != "") {
        		vm.searchData.selectGroup = "gid0";
        	} else {
        		vm.searchData.selectGroup = "";
        	}

        	for (var i = 0; i < vm.parentGroup.length; i ++) {
        		if (vm.searchData.selectGroupNm != undefined && vm.searchData.selectGroupNm != "") {
        			if ((vm.parentGroup[i].name + vm.parentGroup[i].serviceTypeName).indexOf(vm.searchData.selectGroupNm) != -1) {
        				vm.parentGroup[i].showFlg = true;
        				vm.groupSelectListShowFlg = true;
        			} else {
        				vm.parentGroup[i].showFlg = false;
        			}
        		} else {
        			vm.parentGroup[i].showFlg = true;
        		}
        	}
        }
    }
})();
