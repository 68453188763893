(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('QrCode', QrCode);

    QrCode.$inject = ['$resource'];

    function QrCode ($resource) {
        var service = $resource('api/groups', {}, {
            "query": {
                url: 'api/groups/:groupId/riyousha',
                method: 'GET'
            }
        });

        return service;
    }

})();
