(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('StaffService', StaffService);

    StaffService.$inject = ['$resource'];

    function StaffService ($resource) {
        var service = $resource('api/staff', {}, {
            'query': {
                method: 'GET',
                isArray: true,
                url: 'api/staff'
            }
        });

        return service;
    }

})();
