(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Variable', Variable);

    Variable.$inject = ['$resource'];

    function Variable ($resource) {
        var service = $resource('robot/api/variable', {}, {
            "query": {
                method: 'GET',
                isArray: true,
                url:'robot/api/variables/type/:type'
            },
            "save": {
                method: "POST"
            },
            "delete": {
                method: "DELETE",
                url: 'robot/api/variable/:id'
            },
            "get": {
                method: "GET",
                url: 'robot/api/variable/:id'
            },
            "list": {
                method: "GET",
                isArray: true,
                url:'robot/api/variables'
            }
        });

        return service;
    }

})();
