(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('LicenseStaffController', LicenseStaffController);

    LicenseStaffController.$inject = ['paginationConstants', 'LicenseService', 'MessageService', 'JigyoushoResourceService', '$uibModal'];

    function LicenseStaffController (paginationConstants, LicenseService, MessageService, JigyoushoResourceService, $uibModal) {

        var vm = this;

        // 翻页所需
        vm.page = 1;
        vm.links = null;
        vm.totalItems = null;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        // 用户列表
        vm.staffList = [];
        // 事业所list
        vm.jigyoushoList = [];
        // license使用数
        vm.licenseUsedList = [];
        // 查询条件
        vm.searchData = {houjinId: "", houjinNm: "", jigyoushoId: "", jigyoushoCd: "", loginId: "", name: ""};
        vm.defaultSearchData = {houjinId: "", houjinNm: "", jigyoushoId: "", jigyoushoCd: "", loginId: "", name: ""};

        vm.noGroup = true;

        // 查询条件选择法人
        vm.selectHoujin = selectHoujin;
        // 查询事业所list
        vm.loadJigyoushoList = loadJigyoushoList;
        // 初始加载数据
        vm.loadData = loadData;
        // 点击查询
        vm.search = search;
        // 清空搜索条件
        vm.clear = clear;
        // 编辑用户service
        vm.edit = edit;

        // 加载完成之后自动执行
        loadData();

        function selectHoujin() {
            var modalInstance = $uibModal.open({
                templateUrl : 'app/master/houjin/dialog/houjin-select-dialog.html',
                controller : 'HoujinSelectDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size : 'lg',
                resolve : {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('houjin');
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(function(result) {
                if (result != '') {
                    vm.searchData.houjinId = result.id;
                    vm.searchData.houjinNm = result.name;

                    vm.searchData.jigyoushoId = "";
                    vm.searchData.jigyoushoCd = "";
                    // 根据法人查询出来对应的事业所列表
                    loadJigyoushoList(vm.searchData.houjinId);
                }
            },function(){
                //MessageService.error("global.error.internalServerError");
            })
        }

        function loadJigyoushoList() {
            JigyoushoResourceService.jigyoushoList({houjinId: vm.searchData.houjinId}, function(result){
                vm.jigyoushoList = result;
                if (vm.jigyoushoList.length > 0) {
                    vm.searchData.jigyoushoId = vm.jigyoushoList[0].jigyoushoId;
                }
                loadData();
            });
        }

        function loadData() {
            if (vm.searchData.jigyoushoId != '') {
                vm.noJigyousho = false;
                LicenseService.queryStaff({
                    page: vm.page - 1,
                    jigyoushoId: vm.searchData.jigyoushoId,
                    loginId: vm.searchData.loginId,
                    name: vm.searchData.name}, function(result, headers) {
                    vm.totalItems = headers('X-Total-Count');
                    vm.staffList = result;
                    vm.queryCount = vm.totalItems;
                });
            } else {
                vm.noJigyousho = true;
            }
            if (vm.searchData.houjinId != '') {
                LicenseService.houjinLicenseSum({houjinId: vm.searchData.houjinId}, function(result){
                    vm.licenseUsedList = result;
                });
            }
        }

        function search() {
            vm.page = 1;
            loadData();
        }

        function clear() {
            vm.defaultSearchData.houjinId = vm.searchData.houjinId;
            vm.defaultSearchData.houjinNm = vm.searchData.houjinNm;
            vm.defaultSearchData.jigyoushoId = vm.searchData.jigyoushoId;
            vm.defaultSearchData.jigyoushoCd = vm.searchData.jigyoushoCd;
            vm.searchData = angular.copy(vm.defaultSearchData);
            vm.page = 1;
            loadData();
        }

        function edit(model) {
            var modalInstance = $uibModal.open({
                templateUrl: 'app/customer/license/staff/dialog/license-staff-edit-dialog.html',
                controller: 'LicenseStaffEditDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    param: function(){
                        return model;
                    }
                }
            });
            modalInstance.result.then(function() {
                loadData();
            },function(){
                loadData();
            })
        }

    }
})();
