(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TimecardManagementDetailController', TimecardManagementDetailController);

    TimecardManagementDetailController.$inject = ['$scope','$stateParams', 'Timecard', 'User' , 'Group' , 'moment','AlertService', '$filter','TimezoneService','GroupOption'];

    function TimecardManagementDetailController($scope,$stateParams, Timecard, User, Group, moment,AlertService, $filter,TimezoneService,GroupOption) {
        var vm = this;
        var regex = /^([01]\d|2[0-3]):?([0-5]\d)$/;
        var regex2 = /^([01]\d|2[0-3])([0-5]\d)$/;
        //add round function to momentjs
        moment.fn.round = function(precision, key, direction) {
            if(typeof direction === 'undefined') {
                direction = 'round';
            }

            var keys = ['Hours', 'Minutes', 'Seconds', 'Milliseconds'];
            var maxValues = [24, 60, 60, 1000];

            // Capitalize first letter
            key = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();

            // make sure key is plural
            if (key.indexOf('s', key.length - 1) === -1) {
                key += 's';
            }
            var value = 0;
            var rounded = false;
            var subRatio = 1;
            var maxValue ;
            for (var i in keys) {
                var k = keys[i];
                if (k === key) {
                    value = this._d['get' + key]();
                    maxValue = maxValues[i];
                    rounded = true;
                } else if(rounded) {
                    subRatio *= maxValues[i];
                    value += this._d['get' + k]() / subRatio;
                    this._d['set' + k](0);
                }
            };

            value = Math[direction](value / precision) * precision;
            value = Math.min(value, maxValue);
            this._d['set' + key](value);

            return this;
        }

        moment.fn.ceil = function(precision, key) {
            return this.round(precision, key, 'ceil');
        }

        moment.fn.floor = function(precision, key) {
            return this.round(precision, key, 'floor');
        }
        //グループID
        vm.groupId = $stateParams.groupId;
        //ユーザーID
        vm.userId = $stateParams.userId;
        //ユーザー名
        vm.userName = $stateParams.userName;
        //年
        vm.currentYear = Number($stateParams.currentYear);
        //月
        vm.currentMonth = Number($stateParams.currentMonth);
        //月間勤務データ
        vm.data = [];
        //給与計算データを維持する
        vm.salary = {};
        vm.chartData = [];
        vm.chartLabel = [];
        vm.userTimecardList = [];
        vm.parentGroup = [];
        vm.groupOption = {};
        vm.loadParentGroup = loadParentGroup;
        //Group切换时，自动取得Staff
        vm.changeGroup = changeGroup;
        //页面加载后取得所有Group信息
        vm.loadParentGroup();
        vm.changeUser = changeUser;
        //月間勤務データを取得する
        vm.loadData = loadData;
        vm.loadUserTimecardData = loadUserTimecardData;
        //年月をレセット
        vm.rest = rest;
        //次月のデータを取得
        vm.nextMonth = nextMonth;
        //前月のデータを取得
        vm.beforeMonth = beforeMonth;
        //日間勤務時間を計算
        vm.workTimeCal = workTimeCal;
        //勤務実績を承認して、履歴として残す
        vm.saveHistory = saveHistory;
        //moment.durationをフォマットする
        vm.format = format;
        vm.durationHours = durationHours;
        //初期データのフォマット
        vm.initStartTime = initStartTime;
        //初期データのフォマット
        vm.initEndTime = initEndTime;
        //初期データのフォマット
        vm.initRestTime = initRestTime;
        //入力された開始時間をmommentに変換
        vm.StartTimeUpdate = StartTimeUpdate;
        //入力された終了時間をmommentに変換
        vm.EndTimeUpdate = EndTimeUpdate;
        vm.RestimeUpdate = RestimeUpdate;
        //vm.StartTimeCheck = StartTimeCheck;
        //vm.EndTimeCheck = EndTimeCheck;
        vm.reCalculate = reCalculate;
        vm.timezone = "+0900";

        //打刻データを最初表示する時点で丸め処理を行います
        function initStartTime(detail){
            var data =  moment(detail.startTime);
            if (data.isValid()){
                if (vm.groupOption.timeRound > 0) {
                    detail.startTime = data.ceil(vm.groupOption.timeRound,'minutes');
                } else {
                    detail.startTime = data;
                }
                detail.startTimeStr = $filter('date')(detail.startTime.toDate(),'HH:mm',vm.timezone);
                detail.startTimeError = false;
                detail.isDirty = false;
            }
            vm.reCalculate();
        }
        //打刻データを最初表示する時点で丸め処理を行います
        function initEndTime(detail){
            var data =  moment(detail.endTime);
            if (data.isValid()){
                if (vm.groupOption.timeRound > 0) {
                    detail.endTime = data.floor(vm.groupOption.timeRound,'minutes');
                } else {
                    detail.endTime = data;
                }
                //detail.endTime = data.floor(30,'minutes');
                detail.endTimeStr = $filter('date')(detail.endTime.toDate(),'HH:mm',vm.timezone);
                detail.endTimeError = false;
                detail.isDirty = false;
            }
            vm.reCalculate();
        }
        function initRestTime(detail) {
            var data = moment.duration(detail.restTime);
            if (data.isValid()) {
                detail.restTime = vm.format(data);
                detail.restTimeError = false;
            } else {
                detail.restTime = "";
                detail.restTimeError = false;
            }
            detail.isDirty = false;
        }
        //入力された打刻時刻をMomentに変換する
        function StartTimeUpdate(day,detail, index){
            detail.startTimeStr = $("#startTime"+index).val();
            if (detail.startTimeStr == null || detail.startTimeStr == "") {
                detail.startTimeError = false;
                detail.startTime = null;
            } else if (regex.test(detail.startTimeStr)) {
                if (regex2.test(detail.startTimeStr)) {
                    detail.startTimeStr = detail.startTimeStr.substr(0,2)+":"+detail.startTimeStr.substr(2,2);
                }
                var start = moment(day + " " + detail.startTimeStr);
                if (start.isValid()) {
                    if (vm.groupOption.timeRound > 0) {
                        detail.startTime = start.ceil(vm.groupOption.timeRound,'minutes');
                    } else {
                        detail.startTime = start;
                    }
                    //detail.startTime = start.ceil(30,'minutes');
                    detail.startTimeStr = detail.startTime.format("HH:mm");
                    detail.startTimeError = false;
                    //開始時間を変更しました
                    detail.isDirty = true;
                }  else {
                    detail.startTime = null;
                    detail.startTimeError = true;
                }
            } else {
                detail.startTimeStr = "";
                detail.startTime = null;
            }
            vm.reCalculate();
        }
        //入力された打刻時刻をMomentに変換する
        function EndTimeUpdate(day,detail, index) {
            detail.endTimeStr = $("#endTime"+index).val();
            if (detail.endTimeStr == null || detail.endTimeStr == "") {
                detail.endTime = null;
                detail.endTimeError = false;
            } else if (regex.test(detail.endTimeStr)) {
                if (regex2.test(detail.endTimeStr)) {
                    detail.endTimeStr = detail.endTimeStr.substr(0,2)+":"+detail.endTimeStr.substr(2,2);
                }
                var end = moment(day + " "+ detail.endTimeStr);
                var start = moment(detail.startTime);
                if (end.isBefore(start)) {
                    if (confirm("退勤時間は出勤時間より前に設定しましたが、日付をまたいで打刻しますか？")) {
                        detail.nextDay = true;
                        end = end.add(1,'days');
                    } else {
                        detail.endTimeStr = "";
                        detail.endTime = null;
                        detail.endTimeError = false;
                        return;
                    }
                }
                if (end.isValid()) {
                    if (vm.groupOption.timeRound > 0) {
                        detail.endTime = end.floor(vm.groupOption.timeRound,'minutes');
                    } else {
                        detail.endTime = end;
                    }
                    //detail.endTime = end.floor(30,'minutes');
                    detail.endTimeStr = detail.endTime.format("HH:mm");
                    detail.endTimeError = false;
                    //終了時間を変更しました
                    detail.isDirty = true;
                } else {
                    detail.endTime = null;
                    detail.endTimeError = true;
                }
            } else {
                detail.endTimeStr = "";
                detail.endTime = null;
            }
            vm.reCalculate();
        }

        function RestimeUpdate(day,detail, index){
            detail.restTime = $("#restTime"+index).val();
            if (detail.restTime == null || detail.restTime == "") {
                detail.restTimeError = false;
            } else if (regex.test(detail.restTime)) {
                if (regex2.test(detail.restTime)) {
                    detail.restTime = detail.restTime.substr(0,2)+":"+detail.restTime.substr(2,2);
                }
                var rest = moment.duration(detail.restTime);
                if (rest.isValid()) {
                    detail.restTimeError = false;
                    detail.isDirty = true;
                } else {
                    detail.restTimeError = true;
                }
            } else {
                detail.restTime = "";
            }
            vm.reCalculate();
        }

        /**
         * 期間を”HH:ss”ように変更する
         * @param duration
         * @returns {string}
         */
        function format(duration){
            if (angular.isDefined(duration) && duration.isValid() && duration.as('milliseconds') > 0) {
                var day = duration.days();
                var hours = duration.hours();
                var minutes = duration.minutes();
                return ((day * 24+hours) > 9 ?(day * 24+hours): "0" + (day * 24+hours) )+":"+(minutes > 9 ? minutes : "0"+minutes);
            }
            return "";
        }

        /**
         * 期間を34.5時間のように変更する
         * @param duration
         * @returns {*}
         */
        function durationHours(duration) {
            if (angular.isDefined(duration) && duration.isValid() && duration.as('milliseconds') > 0) {
                var day = duration.days();
                var hours = duration.hours();
                var minutes = duration.minutes();
                return day * 24 + hours + minutes / 60;
            }
            return 0;
        }

        /**
         * グループ情報をロードします
         */
        function loadParentGroup() {
            Group.parentGroup({}, function(result){
                vm.parentGroup = result;
                vm.loadData();
            });
        }

        /**
         * グループ情報を切り替えるとデータを再ロードします
         */
        function changeGroup() {
            vm.userId = null;
            vm.loadData();
        }

        /**
         * ユーザを切り替えると該当ユーザのタイムカードデータを再取得
         * @param userId
         * @param userName
         */
        function changeUser(userId,userName){
            //ユーザーID
            vm.userId = userId;
            //ユーザー名
            vm.userName = userName;

            vm.loadUserTimecardData();
        }

        /**
         * 月間勤務データを取得する
         */
        function loadData() {
            angular.forEach(vm.parentGroup,function(data,index,array){
                if (data.id === vm.groupId) {
                    vm.group = data;
                    vm.timezone = TimezoneService.getOffset(vm.group.timezone);
                    GroupOption.get({groupId:vm.groupId}, function(result){
                         vm.groupOption = result;
                         //console.log(vm.groupOption);
                    });
                }
            });
            User.query({groupId:vm.groupId},function (result) {
                var data = {'users':result,'year':vm.currentYear,'month':vm.currentMonth,'groupId':vm.groupId};
                Timecard.queryMonth(data,function(result){
                    vm.userTimecardList = result.users;
                    if (vm.userId === null) {
                        vm.userId = result[0]['id'];
                        vm.userName = result[0]['lastName'] + " " + result[0]['firstName'];
                    }
                    vm.loadUserTimecardData();
                },function(error){
                    AlertService.error(error.data.message);
                });

            });
        }

        /**
         * 指定されたユーザーの月間勤務データを取得する
         */
        function loadUserTimecardData() {
            var data = {'groupId':vm.groupId, 'userId':vm.userId, 'year':vm.currentYear, 'month':vm.currentMonth};
            Timecard.query(data, function(result) {
                vm.data = result;
                vm.salary = result.salaryDTO;
                vm.salary.workHourStr = moment.utc(moment.duration(vm.salary.workTime).as('milliseconds')).format("HH:mm");
            },function(error){
                AlertService.error(error.data.message);
            });
        }

        /**
         * 年月をレセット
         */
        function rest() {
            vm.currentYear = new Date().getFullYear();
            vm.currentMonth = new Date().getMonth()+1;
            vm.loadUserTimecardData();
        }

        /**
         * 次月のデータを取得
         */
        function nextMonth() {
            if (vm.currentMonth === 12) {
                vm.currentMonth = 1;
                vm.currentYear = vm.currentYear + 1;
            } else {
                vm.currentMonth += 1;
            }
            vm.loadUserTimecardData();
        }

        /**
         * 前月のデータを取得
         */
        function beforeMonth() {
            if (vm.currentMonth === 1) {
                vm.currentMonth = 12;
                vm.currentYear -= 1;
            } else {
                vm.currentMonth -= 1;
            }
            vm.loadUserTimecardData();
        }

        /**
         * 日間勤務期間を計算
         * 1.出勤時間、退勤時間と休憩時間を取得
         * 2.夜勤かどうかを判別する、夜勤の場合は、退勤時間は１日Plus
         * 3.夜勤の場合は深夜作業時間を計算
         * 4.勤務時間を計算
         * ★お客さん要望
         * 法定通り（６時間以下休憩不要、６時間越え８時間以下、45分以上、８時間越え６０分以上）ですが、
         * 本人が６時間でも１時間の休憩をとることがあるため、申告制でお願いしたいです。
         * 深夜勤務時間は、22時から翌5時までです。
         * detail.startTime 出勤時間
         * detail.endTime 　退勤時間
         * detail.restTime  休憩時間
         * detail.nextDay  日付をまたいで打刻
         * detail.midNight 深夜作業あるフラグ
         * detail.midNightTime 22:00から05:00までの作業時間
         * detail.overtime 　普通残業時間
         * detail.overtimeNight 深夜残業時間
         * @param detail 勤務データ
         * @returns {*}
         */
        function workTimeCal(day,detail) {
            if (detail.startTime === null || detail.endTime === null) {
                detail.nextDay = false;
                detail.midNight = false;
                detail.midNightTime = moment.duration(0);
                detail.workTime = moment.duration(0);
                detail.overtime = Number(0);
                detail.overtimeNight = Number(0);
                return;
            }
            var start = moment(detail.startTime);
            var end = moment(detail.endTime);
            var rest = moment.duration(detail.restTime);
            if (!start.isValid() || !end.isValid()) {
                detail.nextDay = false;
                detail.midNight = false;
                detail.midNightTime = moment.duration(0);
                detail.workTime = moment.duration(0);
                detail.overtime = Number(0);
                detail.overtimeNight = Number(0);
                return;
            }
            //本日の22:00から次の日の05:00まで深夜時間帯です
            var nightStart = moment(day + " 22:00");
            var nightEnd = moment(day + " 05:00").add(1,'days');
            if (end.isAfter(nightStart)) {
                if (start.isAfter(nightStart)) {
                    nightStart = start;
                }
                if (end.isAfter(nightStart) && end.isBefore(nightEnd)){
                    nightEnd = end;
                }
                //22:00〜5:00　作業時間を計算
                var diff =  moment.duration(nightEnd.diff(nightStart));
                if (diff.asMilliseconds() > 0) {
                    detail.midNight = true;
                    detail.midNightTime = diff;
                    if (detail.restTimeCalculate === 1) {
                        //六時間超えと一時間休憩になります
                        var hours = detail.midNightTime.asHours();
                        if (hours >= 6) {
                            detail.midNightTime = detail.midNightTime.add(-1,"hours");
                        }
                    } else if (detail.restTimeCalculate === 2) {
                        //var restData = detail.
                        if (angular.isDefined(detail.restTimeInMiddleNight) && detail.restTimeInMiddleNight !== null && detail.isDirty === false) {
                            console.log("外出・再入時間から休憩時間へ反映する");
                            detail.midNightTime = moment.duration(detail.restTimeInMiddleNight);
                        } else {
                            console.log("["+ day + "] "+detail.startTimeStr + "-" + detail.endTimeStr + ":入力した時間から休憩時間を算出する、６時間以上であれば１時間休憩になります");
                            //六時間超えと一時間休憩になります
                            var hours = detail.midNightTime.asHours();
                            if (hours >= 6) {
                                detail.midNightTime = detail.midNightTime.add(-1,"hours");
                            }
                        }
                    }
                } else {
                    detail.midNight = false;
                    detail.midNightTime = moment.duration(0);
                }
            }
            //勤務時間を計算
            var span = moment.duration(end.diff(start)).subtract(rest);
            if (span.isValid()) {
                detail.workTime = span;
                //console.log("勤務時間計算終了："+detail.workTime);
                //普通残業と深夜残業を計算
                var overtime = span.asMilliseconds() - 8 * 3600000;
                if (overtime > 0) {//８時間以上超えたら
                    var start2 = start.add(8,'hours');
                    var nightStart2 = moment(day + " 22:00");
                    var nightEnd2 = moment(day + " 05:00").add(1,'days');
                    if (start2.isBefore(nightStart2) && end.isBefore(nightStart2)) {
                        detail.overtime = vm.durationHours(moment.duration(overtime));
                        detail.overtimeNight = Number(0);
                    } else if (start2.isBefore(nightStart2) && end.isAfter(nightStart2) && end.isBefore(nightEnd2)) {
                        var diff2 = moment.duration(nightStart2.diff(start2));
                        detail.overtime = vm.durationHours(diff2);
                        var diff3 = moment.duration(end.diff(nightStart2));
                        detail.overtimeNight = vm.durationHours(diff3);
                    } else if (start2.isBefore(nightStart2) && end.isAfter(nightEnd2)) {
                        var diff4 = moment.duration(nightEnd2.diff(nightStart2));
                        detail.overtimeNight = vm.durationHours(diff4);
                        var diff7 = moment.duration(overtime - diff4.asMilliseconds());
                        detail.overtime = vm.durationHours(diff7);
                    } else if ((start2.isAfter(nightStart2) || start2.isSame(nightStart2)) && start2.isBefore(nightEnd2) && end.isBefore(nightEnd2)) {
                        var diff5 = moment.duration(end.diff(start2));
                        detail.overtimeNight = vm.durationHours(diff5);
                        detail.overtime = Number(0);
                    } else if ((start2.isAfter(nightStart2) || start2.isSame(nightStart2)) && start2.isBefore(nightEnd2) && end.isAfter(nightEnd2)) {
                        var diff5 = moment.duration(nightEnd2.diff(start2));
                        detail.overtimeNight = vm.durationHours(diff5);
                        var diff6 = moment.duration(end.diff(nightEnd2));
                        detail.overtime = vm.durationHours(diff6);
                    } else if (start2.isAfter(nightEnd2)) {
                        detail.overtime = vm.durationHours(moment.duration(overtime));
                        detail.overtimeNight = Number(0);
                    }
                } else {
                    detail.overtime = Number(0);
                    detail.overtimeNight = Number(0);
                }
                return;
            } else {
                detail.workTime = moment.duration(0);
                detail.overtime = Number(0);
                detail.overtimeNight = Number(0);
                return;
            }
        }

        /**
         * 給与関連計算を再実行する
         */
        function reCalculate() {
            vm.salary.days = Number(0);
            vm.salary.workTime = moment.duration(0);
            vm.salary.nightTime = moment.duration(0);
            vm.salary.midNightTimeHours = Number(0);
            vm.salary.busFee = Number(0);
            vm.salary.overtime = Number(0);
            vm.salary.overtimeNight = Number(0);
            if (angular.isDefined(vm.data.detail)) {
                vm.data.detail.forEach(function(item){
                    vm.workTimeCal(item.day,item.workTimeDetail);
                    if (angular.isDefined(item.workTimeDetail.workTime) && item.workTimeDetail.workTime.isValid() && item.workTimeDetail.workTime.as('milliseconds') > 0) {
                        vm.salary.days += 1;
                        vm.salary.workTime = moment.duration(vm.salary.workTime.as('milliseconds') + item.workTimeDetail.workTime.as('milliseconds'),'milliseconds');
                        if (angular.isDefined(item.workTimeDetail.busFee)) {
                            vm.salary.busFee += Number(item.workTimeDetail.busFee);
                        }
                        if (angular.isDefined(item.workTimeDetail.midNightTime)) {
                            vm.salary.nightTime =  moment.duration(vm.salary.nightTime.as('milliseconds') + item.workTimeDetail.midNightTime.as('milliseconds'),'milliseconds');
                        }
                        if (angular.isDefined(item.workTimeDetail.overtime)) {
                            vm.salary.overtime += Number(item.workTimeDetail.overtime);
                        }
                        if (angular.isDefined(item.workTimeDetail.overtimeNight)) {
                            vm.salary.overtimeNight += Number(item.workTimeDetail.overtimeNight);
                        }
                    }
                });
            }
            vm.salary.workHours = vm.durationHours(vm.salary.workTime);
            vm.salary.workHourStr = vm.format(vm.salary.workTime);
            vm.salary.midNightTimeHours = vm.durationHours(vm.salary.nightTime);
            vm.salary.midNightTimeHourStr = vm.format(vm.salary.nightTime);
            vm.salary.overtime = Math.round(vm.salary.overtime * 100) /100;
            vm.salary.overtimeNight = Math.round(vm.salary.overtimeNight * 100)/100;
            /**
             * 基本給を計算
             * 基本給＝時給額＊勤務時間
             */
            vm.salary.hourSalary = Math.round(vm.data.salary * vm.salary.workHours);
            /**
             * 深夜勤務手当を計算
             * 深夜勤務時間＊時給金額＊0.25
             * 0.25は基本的に固定です。
             */
            vm.salary.nightSalary = Math.round(vm.salary.midNightTimeHours  * vm.data.salary * 0.25);
            /**
             * 勤務手当を計算
             * 深夜勤務時間＊勤務手当＊0.25
             */
            vm.salary.extraSalary = Math.round(vm.salary.workHours * vm.data.extraPay + vm.salary.midNightTimeHours * vm.data.extraPay * 0.25);
            /**
             * 支払い合計を計算
             * 給与額＝基本給＋時間外金額合計＋交通費
             */
            vm.salary.totalSalary = vm.salary.hourSalary + vm.salary.busFee + vm.salary.extraSalary + vm.salary.nightSalary;
        }

        /**
         * 勤務実績を承認して、履歴として残す
         */
        function saveHistory() {
            var saveFlag = true;
            vm.data.detail.forEach(function(detail){
                //同一条数据，出勤時間或者退社時間有一个为空的时候提示
                if((detail.workTimeDetail.startTime == null && detail.workTimeDetail.endTime != null) ||
                    (detail.workTimeDetail.startTime != null && detail.workTimeDetail.endTime == null)){
                    detail.workTimeDetail.startTime == null ? detail.workTimeDetail.startTimeEmptyError = true : detail.workTimeDetail.startTimeEmptyError = false;
                    detail.workTimeDetail.endTime == null ? detail.workTimeDetail.endTimeEmptyError = true : detail.workTimeDetail.endTimeEmptyError = false;
                    saveFlag = false;
                }
            });
            if(saveFlag){
                save();
            }else {
                if (confirm("出勤時間や退勤時間を未入力のまま、保存しても大丈夫でしょうか？")) {
                    save();
                }else {
                    return false;
                }
            }
        }

        function save() {
            var params = {
                "groupId":vm.groupId,
                "userId":vm.userId,
                "userName":vm.userName,
                "year":vm.currentYear,
                "month":vm.currentMonth,
                "hourSalary":vm.data.salary,
                "extraPay":vm.data.extraPay,
                "history":[],
                "salary":{}
            };
            vm.data.detail.forEach(function(detail){
                var start = moment(detail.workTimeDetail.startTime);
                var end = moment(detail.workTimeDetail.endTime);
                var rest = moment.duration(detail.workTimeDetail.restTime);
                if (start.isAfter(end)) {
                    end = end.add(1,'days');
                }
                var sub = {
                    "id":detail.workTimeDetail.id,
                    "day":detail.day,
                    "startTime":start,
                    "endTime":end,
                    "restTime":rest,
                    "workTime":detail.workTimeDetail.workTime,
                    "nightTime":detail.workTimeDetail.midNightTime,
                    "busFee":detail.workTimeDetail.busFee
                };
                params.history.push(sub);
                params.salary.workTime = vm.salary.workTime;
                params.salary.nightTime = vm.salary.nightTime;
                params.salary.overtime = vm.salary.overtime;
                params.salary.overtimeNight = vm.salary.overtimeNight;
                params.salary.busFee = vm.salary.busFee;
                params.salary.hourSalary = vm.salary.hourSalary;
                params.salary.nightSalary = vm.salary.nightSalary;
                params.salary.extraSalary = vm.salary.extraSalary;
                params.salary.totalSalary = vm.salary.totalSalary;
            });
            //console.log(params);
            Timecard.saveHistory(params,function(result){
                vm.data = result;
                vm.salary = result.salaryDTO;
                vm.reCalculate();
            },function (error) {
                AlertService.error(error.data.message);
            });
        }
    }
})();
