(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ShokushuManagementImportController',ShokushuManagementImportController);

    ShokushuManagementImportController.$inject = ['$state','$stateParams', '$uibModalInstance','Shokushu','Group'];

    function ShokushuManagementImportController ($state, $stateParams, $uibModalInstance, Shokushu, Group) {
        var vm = this;

        vm.groupId = $stateParams.id;
        vm.shokushuList = [];
        vm.clear = clear;
        vm.save = save;

        Group.caretree({groupId:vm.groupId},function(result){
            Shokushu.load(result,function(data){
                vm.shokushuList = data;
            },function(error){

            });
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            var data = {'groupId':vm.groupId,'shokushuList':vm.shokushuList};
            Shokushu.import(data,function (result) {
                vm.clear();
            });
        }

    }
})();
