(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .filter('startsWith50On', startsWith50On);

    function startsWith50On() {
        return startsWith50On;

        function startsWith50On(items, key) {
            var filtered = [];
            var letterMatch = new RegExp('', 'i');
            if (key === 'ｱ') {
                letterMatch = new RegExp('[ｱｲｳｴｵ]', 'i');
            } else if (key === 'ｶ') {
                letterMatch = new RegExp('[ｶｷｸｹｺ]', 'i');
            } else if (key === 'ｻ') {
                letterMatch = new RegExp('[ｻｼｽｾｿ]', 'i');
            } else if (key === 'ﾀ') {
                letterMatch = new RegExp('[ﾀﾁﾂﾃﾄ]', 'i');
            } else if (key === 'ﾅ') {
                letterMatch = new RegExp('[ﾅﾆﾇﾈﾉ]', 'i');
            } else if (key === 'ﾊ') {
                letterMatch = new RegExp('[ﾊﾋﾌﾍﾎ]', 'i');
            } else if (key === 'ﾏ') {
                letterMatch = new RegExp('[ﾏﾐﾑﾒﾓ]', 'i');
            } else if (key === 'ﾔ') {
                letterMatch = new RegExp('[ﾔﾕﾖ]', 'i');
            } else if (key === 'ﾗ') {
                letterMatch = new RegExp('[ﾗﾘﾙﾚﾛ]', 'i');
            } else if (key === 'ﾜ') {
                letterMatch = new RegExp('[ﾜｦﾝ]', 'i');
            }

            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                if (item.seiKana !== null && letterMatch.test(item.seiKana.substring(0, 1))) {
                    filtered.push(item);
                }
            }
            return filtered;
        }
    }
})();
