(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('LicenseStaffEditDialogController', LicenseStaffEditDialogController);

    LicenseStaffEditDialogController.$inject = ['$uibModalInstance', 'MessageService', 'LicenseService', 'param', '$timeout', '$rootScope'];

    function LicenseStaffEditDialogController ($uibModalInstance, MessageService, LicenseService, param, $timeout, $rootScope) {

        var vm = this;

        // 基本信息
        vm.info = {groupNm: "", login: "", username: ""};
        // ライセンス
        vm.licenses = [];
        //vm.license = {licenseId: null, productTypeId: null, productTypeNm: "", shopId: null, shopNm: "", productId: null, productNm: "", usedFlg: false};

        vm.endDateCheckShow = false;

        // 初始加载数据
        vm.loadData = loadData;

        // 关闭dialog
        vm.clear = clear;

        // 保存数据
        vm.save = save;

        // 关闭dialog
        vm.close = close;

        // 设置用户的license
        vm.setLicense = setLicense;

        vm.useFlag = false;

        loadData();

        function loadData() {
            if (param.jigyoushoId != null && param.jigyoushoId != '' && param.staffId != null && param.staffId != '') {
                // 基本信息的取得
                LicenseService.getStaffInfo({jigyoushoId: param.jigyoushoId, staffId: param.staffId}, function(result){
                    vm.info = result;
                });
                // 取得购买的license的种别
                LicenseService.getStaffLicense({jigyoushoId: param.jigyoushoId, staffId: param.staffId}, function(result){
                    vm.licenses = result;
                    vm.useFlag = false;
                });
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {

            vm.endDateCheckShow = false;

            if ($('#startDateCheck').is(':hidden') && $('#endDateCheck').is(':hidden')) {
                if ((new Date($('#startDate').val())).getTime() > (new Date($('#endDate').val())).getTime()) {
                    vm.endDateCheckShow = true;
                    return false;
                } else {
                    vm.endDateCheckShow = false;
                }
            }

            var entity = angular.copy(vm.license);
            entity.startDate = $('#startDate').val() == '' ? null : $('#startDate').val();
            entity.endDate = $('#endDate').val() == '' ? null : $('#endDate').val();
            LicenseService.save(entity, function(result){
                MessageService.success("license.message.saved");
                $uibModalInstance.close(result);
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            });
        }

        function close () {
            $uibModalInstance.dismiss('cancel');
        }

        function setLicense(license) {
            vm.useFlag = true;
            license.jigyoushoId = param.jigyoushoId;
            license.staffId = param.staffId;
            LicenseService.setUserLicense(license,
                function(){
                    MessageService.success("global.messages.info.update");
                    loadData();
                },
                function(result){
                    license.usedFlg = false;
                    vm.useFlag = false;
                    if (result.status == "406") {
                        alert("ライセンス使用数が最大ので、設定できません。");
                    } else {
                        MessageService.error("global.messages.error.save");
                    }
                }
            );
        }

    }
})();
