(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CommentDialogController',CommentDialogController);

    CommentDialogController.$inject = ['$filter', '$uibModalInstance', 'entity', 'Comment', 'MessageService','Jigyousho', '$timeout', '$rootScope', 'Principal'];
    function CommentDialogController ($filter, $uibModalInstance, entity, Comment, MessageService, Jigyousho, $timeout, $rootScope, Principal) {
        var vm = this;
        vm.isOpen = false;
        vm.clear = clear;
        vm.loadComment = loadComment();
        vm.comment = null;
        vm.save = save;
        vm.openCalendar = openCalendar;
        vm.a = null;
        function openCalendar(){
            vm.isOpen = true;
        }
        function loadComment() {
            if(entity.id != '' || entity.id != null){
                Comment.get({id: entity.id}, function (result) {
                    vm.comment = result;
                    vm.comment.datumDay = new Date($filter('date')(vm.comment.datumDay, "yyyy/MM/dd"));
                });
            }else{
                vm.comment = entity;
                return false;
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            MessageService.success("riyoushaAnalyze.home.updated");
            $uibModalInstance.close(result);
            $timeout(function(){$rootScope.$broadcast('commentUpdate');}, 500);
        }

        function onSaveError () {
            MessageService.error("save_error.home.save_error");
            vm.isSaving = false;
        }

        function save(){
            vm.isSaving = true;
            vm.comment.commentDate = new Date();
            var entity = angular.copy(vm.comment);
            entity.datumDay = $('#datumDay').val() == '' ? null : $('#datumDay').val();

            Comment.update(entity, onSaveSuccess, onSaveError);
        }
    }
})();

