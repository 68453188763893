(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ShokushuManagementController', ShokushuManagementController);

    ShokushuManagementController.$inject = ['Shokushu', 'Group','ParseLinks', 'MessageService', '$state', 'pagingParams', 'paginationConstants', '$stateParams'];

    function ShokushuManagementController(Shokushu, Group, ParseLinks, MessageService, $state, pagingParams, paginationConstants, $stateParams) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.shokushus = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.clear = clear;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;

        vm.selectGroup = $stateParams.groupId;
        vm.parentGroup = [];
        vm.loadParentGroup = loadParentGroup;
        vm.changeGroup = changeGroup;
        vm.loadParentGroup();

        function loadAll () {
            Shokushu.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                groupId: vm.selectGroup == null ? "" : vm.selectGroup
            }, onSuccess, onError);
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.shokushus = data;
        }

        function onError(error) {
            MessageService.error(error.data.message);
        }

        function clear () {
            vm.shokushu = {
                id: null, shokushuCd: null, shokushuName: null, salary: null,
                createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null
            };
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                groupId: vm.selectGroup == null ? "" : vm.selectGroup
            });
        }

        function loadParentGroup() {
            Group.parentGroup({}, function(result){
                vm.parentGroup = result;
                if (vm.selectGroup == null || vm.selectGroup == "") {
                	vm.selectGroup = vm.parentGroup[0]["id"];
                }
                loadAll ();
            });
        }

        function changeGroup() {
            Shokushu.query({
                page: 0,
                size: vm.itemsPerPage,
                sort: sort(),
                groupId: vm.selectGroup == null ? "" : vm.selectGroup
            }, onSuccess, onError);
        }
    }
})();
