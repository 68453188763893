(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('AnalyzePhotoShowDialogController', AnalyzePhotoShowDialogController);

    AnalyzePhotoShowDialogController.$inject = ['entity', '$uibModalInstance'];

    function AnalyzePhotoShowDialogController(entity, $uibModalInstance) {
        var vm = this;
        vm.library = entity;

        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
