(function(){
    'use strict';
    angular
        .module('gatewayApp')
        .factory('Analyze', Analyze);
    Analyze.$inject = ['$resource'];
    function Analyze($resource) {
        var service = $resource('api/analyze', {}, {
                'getTemperature':{
                    method: 'GET',
                    isArray: true,
                    url: 'caretree/api/temperatureList/:riyoushaId/:jigyoushoId/:jisshiDt/:jisshiDtBefore'
                },
                'getActiveState':{
                    method: 'GET',
                    isArray: true,
                    url: 'caretree/api/activeStateList/:riyoushaId/:jigyoushoId/:jisshiDt'
                },
                'getPulse':{
                    method: 'GET',
                    isArray: true,
                    url: 'caretree/api/pulseList/:riyoushaId/:jigyoushoId/:jisshiDt/:jisshiDtBefore'
                },
                'getActivity':{
                    method: 'GET',
                    isArray: true,
                    url: 'caretree/api/activityList/:riyoushaId/:jigyoushoId/:jisshiDt/:jisshiDtBefore'
                },
                'getBloodPressure':{
                    method: 'GET',
                    isArray: true,
                    url: 'caretree/api/bloodPressureList/:riyoushaId/:jigyoushoId/:jisshiDt/:jisshiDtBefore'
                },
                'getGetUp':{
                    method: 'GET',
                    isArray: true,
                    url: 'caretree/api/getUpList/:riyoushaId/:jigyoushoId/:jisshiDt/:jisshiDtBefore/:type'
                },
                'getSleep':{
                    method: 'GET',
                    isArray: true,
                    url: 'caretree/api/sleepList/:riyoushaId/:jigyoushoId/:jisshiDt/:jisshiDtBefore/:type'
                },
                'getSpo2':{
                    method: 'GET',
                    isArray: true,
                    url: 'caretree/api/spo2List/:riyoushaId/:jigyoushoId/:jisshiDt/:jisshiDtBefore/:type'
                },
                'getDiet':{
                    method: 'GET',
                    isArray: true,
                    url: 'caretree/api/dietList/:riyoushaId/:jigyoushoId/:jisshiDt/:jisshiDtBefore'
                },
                'getWeight':{
                    method: 'GET',
                    isArray: true,
                    url: 'caretree/api/weightList/:riyoushaId/:jigyoushoId/:jisshiDt/:jisshiDtBefore'
                },
                'getRiyoushaNameAndJigyoushoName':{
                    method: 'GET',
                    url: 'caretree/api/getRiyoushaNameAndJigyoushoName/:riyoushaId'
                },
                'getGroups': {
                    method: 'GET',
                    isArray: true,
                    url: 'api//analyze/groups'
                }
        });
        return service;
    }
})();
