(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('LicenseService', LicenseService);

    LicenseService.$inject = ['$resource'];

    function LicenseService ($resource) {
        var service = $resource('api/license', {}, {
            'query': {
                method: 'GET',
                url: 'api/license/list',
                isArray: true
            },
            'save': {
                method: 'POST'
            },
            'get': {
                method: 'GET'
            },
            'delete': {
                method: 'DELETE'
            },
            'queryUser': {
                method: 'GET',
                url: 'api/license/userList',
                isArray: true
            },
            'getUserLicense': {
                method: 'GET',
                url: 'api/license/userLicense',
                isArray: true
            },
            'getUserInfo': {
                method: 'GET',
                url: 'api/license/userInfo'
            },
            'setUserLicense': {
                method: 'POST',
                url: 'api/license/userLicense'
            },
            'userLicenseSum': {
                method: 'GET',
                url: 'api/license/userLicenseSum',
                isArray: true
            },
            "queryStaff": {
                method: 'GET',
                url: 'api/license/staffList',
                isArray: true
            },
            'queryStaffManager': {
                method: 'GET',
                url: 'api/license/staffManagerList',
                isArray: true
            },
            'houjinLicenseSum': {
                method: 'GET',
                url: 'api/license/houjinLicenseSum',
                isArray: true
            },
            'houjinLicenseSumByJigyousho': {
                method: 'GET',
                url: 'api/license/houjinLicenseSumByJigyousho',
                isArray: true
            },
            'getStaffInfo': {
                method: 'GET',
                url: 'api/license/staffInfo'
            },
            'getStaffLicense': {
                method: 'GET',
                url: 'api/license/staffLicense',
                isArray: true
            },
            'queryShopLicense': {
                method: 'GET',
                url: 'api/license/shopLicense',
                isArray: true
            },
            'caretreeLink': {
                method: 'GET',
                url: 'api/license/caretreeLink'
            },
            'checkLicense': {
                method: 'GET',
                url: 'api/license/checkLicense'
            }
        });

        return service;
    }

})();
