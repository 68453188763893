(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('LibraryEditController', LibraryEditController);

    LibraryEditController.$inject = ['entity', '$uibModalInstance', '$scope', '$sce', 'Library', 'MessageService', 'Group', '$rootScope', '$timeout'];

    function LibraryEditController(entity, $uibModalInstance, $scope, $sce, Library, MessageService, Group, $rootScope, $timeout) {

        var vm = this;

        $scope.sce = $sce.trustAsResourceUrl;

        vm.library = entity;
        vm.groups = [];

        vm.close = close;
        vm.save = save;
        vm.loadAllGroups = loadAllGroups;

        vm.loadAllGroups();

        function close() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            Library.save(vm.library, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            MessageService.success("library.message.saveSuccess");
            $uibModalInstance.close(result);
            $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
        }

        function onSaveError() {
            MessageService.error("library.message.saveError");
        }

        function loadAllGroups() {
            Group.queryUserGroups(function(result){
                vm.groups = result;
                // for (var i = 0; i < vm.groups.length; i ++) {
                //     vm.groups[i].groupId = parseInt(vm.groups[i].groupId, 10);
                // }
            });

        }
    }

})();
