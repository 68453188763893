(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Comment', Comment);

    Comment.$inject = ['$resource'];

    function Comment ($resource) {
        var service = $resource('api/comment', {}, {
           'save': {
                method: 'POST',
                isArray: true,
            },
            'commentList': {
                method: 'GET',
                isArray: true,
                url: 'api/commentlist/:riyoushaId/:jigyoushoId/:jisshiDt/:jisshiDtBefore'
            },
            'commentlistShow': {
                method: 'GET',
                isArray: true,
                url: 'api/commentlistShow/:riyoushaId/:jigyoushoId/:jisshiDt/:jisshiDtBefore'
            },
            'user': {
                method: 'GET',
                url: 'api/user'
            },
            "del": {
                method: "DELETE",
                url: "library/api/upload/id/:id"
            },
            "getPhotoList": {
                method: 'GET',
                isArray: true,
                url: 'library/api/getPhotoList/:riyoushaId/:startDt/:endDt/:groupId'
            },
            "getFileList": {
                method: 'GET',
                isArray: true,
                url: 'library/api/getFileList/:riyoushaId/:startDt/:endDt/:groupId'
            },
            "getFileListPublic": {
                method: 'GET',
                isArray: true,
                url: 'library/api/getFileListPublic/:riyoushaId/:startDt/:endDt/:groupId'
            },
            "getPhotoListPublic": {
                method: 'GET',
                isArray: true,
                url: 'library/api/getPhotoListPublic/:riyoushaId/:startDt/:endDt/:groupId'
            },
            "photoList": {
                method: 'GET',
                isArray: true,
                url: 'library/api/photoList/:riyoushaId/:uploadDate/:jisshiDtBefore'
            },
            'delete': {
                method:'DELETE',
                url: 'api/deletecomment/:id'
            },
            'get': {
                method: 'GET',
                url: 'api/editcomment/:id'
            },
            'update': {
                method:'PUT'
            },
            'updatePublishType': {
                method:'PUT',
                url: 'api/publiccomment'
            }
        });
        return service;
    }
})();
