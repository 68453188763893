(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .directive('timeFormatHanToZen', ['$filter',timeFormatHanToZen]);

    var convert = function(val) {
        if (val === undefined || val === null) {
            return val;
        }
        var hanKatakanaList = new Array("0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ":");
        var zenKatakanaList = new Array("０", "１", "２", "３", "４", "５", "６", "７", "８", "９", "：");
        //半角数字和冒号转全角
        for (var i = 0; i < val.length; i++) {
            for (var j = 0; j < hanKatakanaList.length; j++) {
                val = val.replace(hanKatakanaList[j], zenKatakanaList[j]);
            }
        }
        return val;
     }

    function timeFormatHanToZen ($filter) {
        var directive = {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, elem, attrs, ctrl) {
                    elem.bind("blur",function(){
                        var viewValue = ctrl.$viewValue;
                        viewValue = convert(viewValue);
                        ctrl.$viewValue = viewValue;
                        ctrl.$render();
                        return viewValue;
                    });
            }
        };

        return directive;
    }
})();
