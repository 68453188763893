(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('LicenseController', LicenseController);

    LicenseController.$inject = ['paginationConstants', 'Principal', 'LicenseService', 'MessageService', '$rootScope', '$state'];

    function LicenseController (paginationConstants, Principal, LicenseService, MessageService, $rootScope, $state) {

        var vm = this;

        // 翻页所需
        vm.page = 1;
        vm.links = null;
        vm.totalItems = null;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        // 製品列表
        vm.productList = [];
        // 查询条件
        vm.searchData = {id: "", name: "", productId: "", productNm: "", shopId: "", shopNm: ""};
        vm.defaultSearchData = {id: "", name: "", productId: "", productNm: "", shopId: "", shopNm: ""};

        vm.idText = "法人ID";
        vm.nmText = "法人名";
        vm.idTextTitle = "法人ID";
        vm.nmTextTitle = "法人名";
        vm.addButtonText = "ライセンス(1.0)追加";

        // license列表
        vm.licenseList = [];

        // 初始加载数据
        vm.loadData = loadData;
        vm.changeSearchVersion = changeSearchVersion;
        vm.changeSearchVersionByClickText = changeSearchVersionByClickText;

        // 删除指定license
        vm.remove = remove;
        // 点击查询
        vm.search = search;
        // 清空搜索条件
        vm.clear = clear;
        vm.version = 1;

        loadData();
        vm.goEdit = goEdit;
        vm.goDel = goDel;
        vm.isAdmin = false;
        vm.isPartner = false;
        vm.partnerId = 0;

        function loadData() {
            Principal.identity().then(function(account) {
                if (account != null) {
                    vm.partnerId = account.id;
                    if (account.authorities != null) {
                        if (account.authorities.indexOf("ROLE_ADMIN") != -1) {
                            vm.isAdmin = true;
                        }
                        if (account.authorities.indexOf("ROLE_PARTNER") != -1) {
                            vm.isPartner = true;
                        }
                    }
                }
                if($rootScope.searchData != undefined){
                    vm.searchData = $rootScope.searchData;
                    $rootScope.searchData = undefined;
                }
                if($rootScope.version != undefined){
                    vm.version = $rootScope.version;
                    $rootScope.version = undefined;
                }
                if($rootScope.page != undefined){
                    vm.page = $rootScope.page;
                    $rootScope.page = undefined;
                }
                LicenseService.query({
                    page: vm.page - 1,
                    version: vm.version,
                    id: vm.searchData.id,
                    name: vm.searchData.name,
                    productId: vm.searchData.productId,
                    productNm: vm.searchData.productNm,
                    shopId: vm.searchData.shopId,
                    shopNm: vm.searchData.shopNm,
                    isPartner: vm.isPartner,
                    partnerId: vm.partnerId}, function(result, headers) {

                    if (vm.version == 1) {
                        vm.idTextTitle = "法人ID";
                        vm.nmTextTitle = "法人名";
                        vm.idText = "法人ID";
                        vm.nmText = "法人名";
                        vm.addButtonText = "ライセンス(1.0)追加";
                    } else if (vm.version == 2) {
                        vm.idTextTitle = "グループID";
                        vm.nmTextTitle = "グループ名";
                        vm.idText = "グループID";
                        vm.nmText = "グループ名";
                        vm.addButtonText = "ライセンス(2.0)追加";
                    }

                    vm.totalItems = headers('X-Total-Count');
                    vm.licenseList = result;
                    vm.queryCount = vm.totalItems;
                    setTimeout(function() {
                        //屏幕高度
                        var height = document.documentElement.clientHeight;
                        //导航部分的高度
                        var navbarHeight = $("#navbarHeight").height();//20
                        //检索部分的高度
                        var searchHeight = $("#searchHeight").height();//15
                        //表头高度
                        var headerHeight = $("#headerHeight").height();//
                        //分页部分高度
                        var pageHeight = $("#pageHeight").height();
                        //copyRight
                        var copyRight = $("#copyRight").height();
                        var tableDivHeight = height-navbarHeight-19-searchHeight-headerHeight-20-pageHeight-10-copyRight-10-40;
                        $("#tableDiv").css("max-height",tableDivHeight);
                    }, 20);
                });
            });
        }

        function remove(id) {
            if(confirm('削除してもよろしいですか?')) {
                LicenseService.delete({id: id}, function(){
                    loadData();
                    MessageService.success("license.message.deleted");
                    $rootScope.$broadcast('messageChanged');
                });
            }
        }

        // 改变查询条件中的版本
        function changeSearchVersion() {
            $rootScope.version = vm.version;
            vm.searchData = angular.copy(vm.defaultSearchData);
            if (vm.version == 2) {
                vm.idText = "グループID";
                vm.nmText = "グループ名";
                vm.addButtonText = "ライセンス(2.0)追加";
            } else {
                vm.idText = "法人ID";
                vm.nmText = "法人名";
                vm.addButtonText = "ライセンス(1.0)追加";
            }
            search();
        }

        function search() {
            vm.page = 1;
            loadData();
        }

        function clear() {
            vm.searchData = angular.copy(vm.defaultSearchData);
            vm.page = 1;
            loadData();
        }

        function changeSearchVersionByClickText(version) {
            vm.version = version;
            vm.changeSearchVersion();
        }

        function goEdit(id, version) {
            $rootScope.page = vm.page;
            $rootScope.searchData = vm.searchData;
            $rootScope.version = vm.version;
            $state.go('license.edit', {id: id, version: version});
        }
        function goDel(id, version, gid, productId, shopId) {
            $rootScope.page = vm.page;
            $rootScope.searchData = vm.searchData;
            $rootScope.version = vm.version;
            $state.go('license.delete', {id: id, version: version, gid:gid, productId:productId, shopId:shopId});
        }
    }
})();
