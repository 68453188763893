(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('password', {
            parent: 'account',
            url: '/account/password',
            data: {
                pageTitle: 'global.menu.account.password'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/password/password.html',
                    controller: 'PasswordController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('password');
                    return $translate.refresh();
                }]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
            	var top = $uibModalStack.getTop();
                if (top) {
                    $uibModalStack.dismiss(top.key);
                }
                Principal.identity().then(function(account) {
                	if (account != null) {
                		if (account.login.indexOf(";") != -1) {
                    		$state.go('home');
                            $uibModal.open({
                                templateUrl: 'app/home/dialog/license.html',
                                controller: 'HomeLicenseController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg'
                            }).result.then(function() {
                                $state.go('home', null, { reload: true });
                            }, function() {
                                $state.go('home');
                            });
                        }
                	} else {
                		LoginService.open();
                	}

                });
            }]
        }).state('passwordToken', {
            parent: 'account',
            url: '/account/password/{token}',
            data: {
                pageTitle: 'global.menu.account.password'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/password/password.html',
                    controller: 'PasswordController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('password');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
