(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('AuthResource', AuthResource);

    AuthResource.$inject = ['$resource'];

    function AuthResource ($resource) {
        var service = $resource('api/auth', {}, {
            'hasCaretreeAccount': {
                method: 'GET',
                url: 'api/auth/hasCaretreeAccount'
            }
        });

        return service;
    }

})();
