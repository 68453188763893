(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('qrcode', {
                parent: 'riyousha',
                url: '/robot/qrcode',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_TIMECARD'],
                    pageTitle: 'riyousha.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/system/riyousha/qrcode/qrcode.html',
                        controller: 'QrCodeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('riyousha');
                        return $translate.refresh();
                    }]
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
                	var top = $uibModalStack.getTop();
                	if (top) {
                	    $uibModalStack.dismiss(top.key);
                	}
                    Principal.identity().then(function(account) {
                    	if (account != null) {
                    		var gtc_caretree2_robot_allfunction_fullaccess = false;
                        	if (account.functions != null) {
                            	for (var i in account.functions) {
                            		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                            			gtc_caretree2_robot_allfunction_fullaccess = true;
                            		}
                            	}
                            }
                        	if (gtc_caretree2_robot_allfunction_fullaccess == false) {
                        		$state.go('home');
                            	$uibModal.open({
                                    templateUrl: 'app/home/dialog/license.html',
                                    controller: 'HomeLicenseController',
                                    controllerAs: 'vm',
                                    backdrop: 'static',
                                    size: 'lg'
                                }).result.then(function() {
                                    $state.go('home', null, { reload: true });
                                }, function() {
                                    $state.go('home');
                                });
                            }
                    	} else {
                    		LoginService.open();
                    	}
                    	
                    });
                }]
            })
            .state('qrcode-detail', {
                parent: 'qrcode',
                url: '/{groupId}/qrcode-detail',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_TIMECARD'],
                    pageTitle: 'riyousha.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/system/riyousha/qrcode/qrcode-detail.html',
                        controller: 'QrCodeDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('riyousha');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
