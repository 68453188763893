(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('ShiftOccupation', ShiftOccupation);

    ShiftOccupation.$inject = ['$resource'];

    function ShiftOccupation ($resource) {
        var service = $resource('timecard/api/shift', {}, {
            'getAll': {
                method: 'GET',
                url:'timecard/api/shiftOccupation/all',
                isArray: true
            },
            'getAllByGroupId': {
                method: 'GET',
                url:'timecard/api/shiftOccupation/getAllByGroupId',
                isArray: true
            },
            'save': {
                method: 'POST',
                url:'timecard/api/shiftOccupation/save'
            }
            ,
            'delete': {
                method: 'DELETE',
                url:'timecard/api/shiftOccupation/delete/:id'
            }
        });

        return service;
    }

})();
