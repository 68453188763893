(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .directive('durationFormat', ['moment',durationFormat]);

    function durationFormat (moment) {
        var directive = {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$formatters.push(function(value){
                    if (angular.isUndefined(value) || value === "" || value === null) {
                        return value;
                    }
                    var duration = moment.duration(value);
                    return moment.utc(duration.as('milliseconds')).format("HH:mm");
                });
            }
        };

        return directive;
    }
})();
