(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ProductTypeEditDialogController', ProductTypeEditDialogController);

    ProductTypeEditDialogController.$inject = ['$uibModalInstance', 'Role', 'ProductType', 'param', 'MessageService', '$timeout', '$rootScope'];

    function ProductTypeEditDialogController ($uibModalInstance, Role, ProductType, param, MessageService, $timeout, $rootScope) {

        var vm = this;

        // 商品贩卖类型
        vm.types = [
            {"id": 1, "value": "アカウントライセンス"},
            {"id": 2, "value": "プロダクトライセンス"}
        ];

        // 製品种类
        vm.productType = {id: '', name: '', licenseType: 1, kinouList: "", resultList: ""};

        vm.searchAuthority = searchAuthority;

        vm.functionCheck = functionCheck;

        // 初始加载数据
        vm.loadData = loadData;

        loadData();

        // 关闭dialog
        vm.close = close;

        // 保存数据
        vm.save = save;

        function loadData() {
            if (param.id != null && param.id != '') {
                ProductType.get({id: param.id}, function(result){
                    vm.productType= result;
                   /* vm.productType.name = result.name;
                    vm.productType.licenseType = result.licenseType;
                    vm.productType.kinouList = result.kinouList;*/
                    vm.productType.resultList = result.kinouList;
                    //functionCheck();
                });
            }else {
                getFunction();
                vm.productType.licenseType = 2;
            }
        }

        function close () {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.productType.licenseType = 2;
            ProductType.save(vm.productType, function(result){
                MessageService.success("productType.message.saved");
                $uibModalInstance.close(result);
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            });
        }

        function searchAuthority(){
            /*var container = $('#aDiv');
            var scrollTo = $("input[name='" + vm.authority+ "']");
            if(vm.authority == ""){
                container.animate({
                    scrollTop: 0
                });
            }else{
                container.animate({
                    scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() - 10
                });
            }*/
            vm.productType.kinouList = vm.productType.resultList;
            console.log(vm.productType.kinouList);
            console.log(vm.productType.resultList);
            if(vm.authority != "" && vm.authority != null){
                angular.forEach(vm.productType.kinouList,function(data,index,array){
                    if(!data.name.match(vm.authority)){
                        data.showFlag = false;
                    }else{
                        data.showFlag = true;
                    }
                });
            }else{
                angular.forEach(vm.productType.kinouList,function(data,index,array){
                    data.showFlag = true;
                });
            }
        }

        function getFunction() {
            Role.getFunction({}, function (data){
                vm.productType.kinouList = data;
                vm.productType.resultList = data;
            });
        }

        function functionCheck (){
            vm.productType.kinouList.sort(function (a,b){
                if(b.checked == a.checked){
                    return a.id - b.id;
                }
                return b.checked - a.checked;
            });
            var container = $('#aDiv');
            var scrollTo = $("input[name='" + vm.authority+ "']");
            container.animate({
                scrollTop: 0
            });
        }
    }

})();
