(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('productType', {
            parent: 'app',
            url: '/settings/productType',
            data: {
                pageTitle: 'productType.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/master/productType/productType.html',
                    controller: 'ProductTypeController',
                    controllerAs: 'vm'
                }
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', 'Role', 'Kinou',
                function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService, Role, Kinou) {
                    var top = $uibModalStack.getTop();
                    if (top) {
                        $uibModalStack.dismiss(top.key);
                    }
                    Principal.identity().then(function(account) {
                        if (account != null) {
                            Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_License_Product_FullAccess"}, function(result){
	                        	if (!result.flag) {
	                        		$state.go('accessdenied');
	                        	}
	                        });
                        } else {
                            LoginService.open();
                        }
                    });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productType');
                    return $translate.refresh();
                }]
            }
        }).state('productType.edit', {
            parent: 'productType',
            url: '/edit/{id}',
            data: {

            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/master/productType/dialog/productType-edit-dialog.html',
                    controller: 'ProductTypeEditDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        param: {id: $stateParams.id}
                    }
                }).result.then(function() {
                    $state.go('productType', null, { reload: true });
                }, function() {
                    $state.go('productType');
                });
            }]
        }).state('productType.delete', {
            parent: 'productType',
            url: '/delete/{id}',
            data: {

            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/master/productType/dialog/productType-delete-dialog.html',
                    controller: 'ProductTypeDeleteDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ProductType', function(ProductType) {
                            return ProductType.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('productType', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
