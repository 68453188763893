(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('StorageManagementRuleController', StorageManagementRuleController);

    StorageManagementRuleController.$inject = ['$uibModalInstance', 'entity', '$state', 'StroageService', 'MessageService', '$timeout', '$rootScope'];

    function StorageManagementRuleController ($uibModalInstance, entity, $state, StroageService, MessageService, $timeout, $rootScope) {
        var vm = this;
        vm.goConfirm = goConfirm;
        vm.clear = clear;
        vm.checked = false;
        vm.houjinId = entity.houjinId;
        vm.oldTotalSize = entity.totalSize;
        vm.totalSize = 0;
        vm.oldPlanType = 0;

        function clear(){
            $uibModalInstance.close('cancel');
        }

        function goConfirm(){
            $uibModalInstance.close('cancel');
            if(entity.planType == 0){
                vm.totalSize = 500;
            }
            if(entity.planType == 1){
                vm.totalSize = 5000;
            }
            if(entity.planType == 2){
                vm.totalSize = 25000;
            }
            if(entity.planType == 3){
                vm.totalSize = 125000;
            }
            if(entity.planType == 4){
                vm.totalSize = 625000;
            }
            if(vm.oldTotalSize == 0.5){
                vm.oldPlanType = 0;
            }
            if(vm.oldTotalSize == 5){
                vm.oldPlanType = 1;
            }
            if(vm.oldTotalSize == 25){
                vm.oldPlanType = 2;
            }
            if(vm.oldTotalSize == 125){
                vm.oldPlanType = 3;
            }
            if(vm.oldTotalSize == 625){
                vm.oldPlanType = 4;
            }
            StroageService.updateStorageTotalSize({houjinId: vm.houjinId, totalSize: vm.totalSize},function(){
                StroageService.sendMail({ planType:entity.planType,houjinId:vm.houjinId,oldPlanType:vm.oldPlanType},
                    function () {
                        $state.go('storage-management.confirmStorage');
                    }, function () {
                        MessageService.error("storageManagement.home.occurredError");
                        $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
                    });
            });
        }
    }
})();
