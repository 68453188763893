(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$uibModal', '$rootScope', '$state', 'Auth', 'Principal', 'ProfileService', 'LoginService','Pepper', 'SelectedPepper', '$scope', 'MessageService', '$timeout', 'Group', 'SelectedGroup', 'Kinou', 'User'];

    function NavbarController ($uibModal, $rootScope, $state, Auth, Principal, ProfileService, LoginService, Pepper, SelectedPepper,$scope,MessageService, $timeout, Group, SelectedGroup, Kinou, User) {
        var vm = this;
        vm.currentYear = new Date().getFullYear();
        vm.currentMonth = new Date().getMonth()+1;
        vm.pepperList = [];
        vm.pepper = {};
        vm.groupList = [];
        vm.group = {};
        vm.messages = [];

        // account 管理
        vm.showAccount = false;
        // GROUP管理
        vm.showGroup = false;
        //製品管理
        vm.showProduct = false;
        //ライセンス管理
        vm.showLicense = false;
        //ストレージ管理
        vm.showStorage = false;
        //Swagger
        vm.showSwagger = false;
        //ケア樹タイムカード
        vm.showTimecard = false;
        //シフト管理利用者
        vm.showShift = false;
        //訪問打刻
        vm.houmonTimeCard = false;
        //システム
        vm.showSystem = false;

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });
        getAccount();

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.showAccount = false;
                //GROUP管理
                vm.showGroup = false;
                //製品管理
                vm.showProduct = false;
                //ライセンス管理
                vm.showLicense = false;
                //ストレージ管理;
                vm.showStorage = false;
                //Swagger
                vm.showSwagger = false;
                //ケア樹タイムカード
                vm.showTimecard = false;
                //シフト管理
                vm.showShift = false;
                //システム
                vm.showSystem = false;
                
                if (account != null) {
                    if (account.authorities != null) {
                    	// account管理
                    	Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_Authentication_access_FullAccess"}, function(result){
                        	if (result.flag) {
                        		vm.showAccount = true;
                        	}
                        });
                        // group管理
                        Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_Authentication_Group_FullAccess"}, function(result){
                        	if (result.flag) {
                        		vm.showGroup = true;
                        	}
                        });
                        // 製品管理
                        Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_License_Product_FullAccess"}, function(result){
                        	if (result.flag) {
                        		vm.showProduct = true;
                        	}
                        });
                        //ライセンス管理
                        Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_License_License_FullAccess"}, function(result){
                        	if (result.flag) {
                        		vm.showLicense = true;
                        	}
                        });
                        //ストレージ管理
                        Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_License_Storage_FullAccess"}, function(result){
                        	if (result.flag) {
                        		vm.showStorage = true;
                        	}
                        });
                        //Swagger
                        Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_API_Swagger_FullAccess"}, function(result){
                        	if (result.flag) {
                                vm.showSwagger = true;
                        		vm.showSystem = true;
                        	}
                        });
                        //システム
                        Kinou.isLoginRole({role: "ROLE_ADMIN"}, function(result){
                            if (result.flag) {
                                vm.showSystem = true;
                            }
                        });
                        //ケア樹タイムカード
                        /*Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_TimeCard_AllFunction_FullAccess"}, function(result){
                        	if (result.flag) {
                        		vm.showTimecard = true;
                        	}
                        });*/
                        Kinou.isLoginRole({role: "ROLE_TIMECARD"}, function(result){
                            if (result.flag) {
                                vm.showTimecard = true;
                            }
                        });
                        //シフト管理
                        /*Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_Shift_AllFunction_FullAccess"}, function(result){
                            if (result.flag) {
                                vm.showShift = true;
                            }
                        });*/
                        Kinou.isLoginRole({role: "ROLE_SHIFT"}, function(result){
                            if (result.flag) {
                                vm.showShift = true;
                            }
                        });
                        User.queryUserOption({userId: account.id}, function (result) {
                            vm.houmonTimeCard = false;
                            if (result.houmonTimeCard == 1) {
                                vm.houmonTimeCard = true;
                            }
                        });
                    }

                    if (account.login.indexOf(";") != -1) {
                        vm.isOneUser = true;
                        vm.isTwoUser = false;
                    } else {
                        vm.isOneUser = false;
                        vm.isTwoUser = true;
                    }
                }
            });
        }

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.loadPepperList = loadPepperList;
        vm.loadGroupList = loadGroupList;
        vm.changePepper = changePepper;
        vm.changeGroup = changeGroup;
        vm.clearPepper = clearPepper;
        vm.messageShow = messageShow;
        vm.messageAllwaysShow = messageAllwaysShow;

        vm.loadPepperList();
        vm.loadGroupList();

        $scope.$on('pepperUpdate', function() {
            Pepper.query(function(result){
                SelectedPepper.setPepperList(result);
                loadPepperList();
                if (result.length === 0) {
                    SelectedPepper.setCurrent(null);
                } else {
                    if (!checkPepper()) {
                        SelectedPepper.setCurrent(result[0]);
                    }
                }
            });
        });

        $scope.$on('groupUpdate', function() {
            Group.parentGroup({}, function(result){
                SelectedGroup.setGroupList(result);
                loadGroupList();
                if (result.length === 0) {
                    SelectedGroup.setCurrent(null);
                } else {
                    if (!checkGroup()) {
                        SelectedGroup.setCurrent(result[0]);
                    }
                }
            });
        });

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            clearPepper();
            clearGroup();
            vm.messages = [];
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function loadPepperList() {
            //console.log("get pepper list of current login user");
            vm.pepperList = SelectedPepper.pepperList();
            vm.pepper = SelectedPepper.current();
        }

        function changePepper(pepper) {
            if (pepper.id > 0) {
                SelectedPepper.setCurrent(pepper);
                vm.pepper = pepper;
                $rootScope.$broadcast('pepperChanged');
                MessageService.success("global.messages.info.pepperchanged");
            } else {
                MessageService.error("global.error.pepperchangerror");
            }

        }

        function checkPepper() {
            var result = false;
            for (var i = 0; i < vm.pepperList; i ++) {
                if (vm.pepperList[i].id === vm.pepper.id) {
                    result = true;
                }
            }
            return result;
        }

        function clearPepper() {
            vm.pepperList = [];
            vm.pepper = {};
        }

        function loadGroupList() {
            vm.groupList = SelectedGroup.groupList();
            vm.group = SelectedGroup.current();
        }

        function changeGroup(group) {
            SelectedGroup.setCurrent(group);
            //vm.group = group;
            $rootScope.$broadcast('groupChanged');
        }

        $scope.$on('groupChanged', function() {
            vm.group = SelectedGroup.current();
        })

        function checkGroup() {
            var result = false;
            for (var i = 0; i < vm.groupList; i ++) {
                if (vm.groupList[i].id === vm.group.id) {
                    result = true;
                }
            }
            return result;
        }

        function clearGroup() {
            vm.groupList = [];
            vm.group = {};
        }

        $scope.$on('messageChanged', function() {
            messageShow();
            $timeout(function(){$('.message').animate({right: '-260px'})}, 2000);
        });

        $scope.$on('messageShowWithTime', function(second) {
            messageShow();
            if (second > 0) {
                $timeout(function(){$('.message').animate({right: '-260px'})}, second);
            }
        });

        function messageAllwaysShow() {
            messageShow();
        }

        function messageShow() {
            $('.message').animate({right: '0px'});
            if ($rootScope.msgs) {
                vm.messages = JSON.parse($rootScope.msgs);
                $timeout(function(){$('.message_info').scrollTop(9999999999)}, 500);
            } else {
                vm.messages = [];
            }
        }
    }
})();
