(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('LicenseDeleteDialogController', LicenseDeleteDialogController);

    LicenseDeleteDialogController.$inject = ['$uibModalInstance', 'entity', 'groupEntity', 'productEntity', 'shopEntity', 'LicenseService', 'MessageService', '$timeout', '$rootScope', '$stateParams'];

    function LicenseDeleteDialogController ($uibModalInstance, entity, groupEntity, productEntity, shopEntity, LicenseService, MessageService, $timeout, $rootScope, $stateParams) {
        var vm = this;

        vm.version = $stateParams.version;
        vm.license = entity;
        vm.groupEntity = groupEntity;
        vm.productEntity = productEntity;
        vm.shopEntity = shopEntity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.error = false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            LicenseService.delete({id: id}, function() {
                MessageService.success("license.message.deleted");
                $uibModalInstance.close(true);
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            }, function () {
                vm.error = true;
            });
        }
    }
})();
