(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Shokushu', Shokushu);

    Shokushu.$inject = ['$resource'];

    function Shokushu ($resource) {
        var service = $resource('timecard/api/shokushus', {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get':{
                method: 'GET',
                url: 'timecard/api/shokushus/:id'
            },
            'load': {
                method: 'POST',
                url: 'timecard/api/shokushus/caretree',
                isArray: true
            },
            'import': {
                method: 'POST',
                url: 'timecard/api/shokushus/import',
            },
            'save': {
                method: 'POST'
            },
            'update': {
                method:'PUT'
            },
            'enum':{
                url: 'timecard/api/enum/:name',
                method: 'GET',
                isArray: true
            },
            'delete': {
                method:'DELETE',
                url:'timecard/api/shokushus/:id'
            }
        });

        return service;
    }

})();
