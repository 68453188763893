(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('GroupOption', GroupOption);

    GroupOption.$inject = ['$resource'];

    function GroupOption ($resource) {
        var service = $resource('timecard/api/options/group', {}, {
            'get': {
                method: 'GET',
                url:'timecard/api/options/group'
            },
            'getAll': {
                method: 'POST',
                url:'timecard/api/options/group/all'
            },
            'save': {
                method: 'POST',
                url:'timecard/api/options/group'
            }
        });

        return service;
    }

})();
