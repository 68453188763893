(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ShokushuManagementDetailController', ShokushuManagementDetailController);

    ShokushuManagementDetailController.$inject = ['$stateParams', 'Shokushu','Group'];

    function ShokushuManagementDetailController($stateParams, Shokushu, Group) {
        var vm = this;

        vm.load = load;
        vm.shokushu = {};
        vm.groups = [];
        vm.load($stateParams.id);

        function load(id) {
            Shokushu.get({id: id}, function(result) {
                vm.shokushu = result;
                Group.get({"id":vm.shokushu.groupId}, function(result){
                    vm.groups = result;
                    console.log(vm.groups);
                });
            });
        }
    }
})();
