(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('group-management', {
                parent: 'app',
                url: '/settings/group',
                data: {
                    pageTitle: 'group-management.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/system/group-management/group-management.html',
                        controller: 'GroupManagementController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('group-management');
                        return $translate.refresh();
                    }]
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', 'Kinou', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService, Kinou) {
                    Principal.identity().then(function(account) {
                    	if (account != null) {
	                        var isGroupAdmin = false;

	                        if (account.authorities
	                            && (account.authorities.indexOf("ROLE_ADMIN") !== -1
	                                || account.authorities.indexOf("ROLE_DEVELOPER") !== -1)) {
	                            isGroupAdmin = true;
	                        } else {
	                            account.groupAuthes.forEach(function (element) {
	                                if (element.groupAuth === 'admin') {
	                                    isGroupAdmin = true;
	                                }
	                            });
	                        }
	                        if (!isGroupAdmin) {
	                            $state.go('accessdenied');
	                        }
	                        Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_Authentication_Group_FullAccess"}, function(result){
	                        	if (!result.flag) {
	                        		$state.go('accessdenied');
	                        	}
	                        });
                    	} else {
                            LoginService.open();
                    	}
                    });
                }]
            })
            .state('group-management.new', {
                parent: 'group-management',
                url: '/new',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
                    Principal.identity().then(function(account) {
                    	if (account != null) {
	                        var isGroupAdmin = false;
	                        if (account.authorities
	                            && (account.authorities.indexOf("ROLE_ADMIN") !== -1
	                                || account.authorities.indexOf("ROLE_DEVELOPER") !== -1)) {
	                            isGroupAdmin = true;
	                        } else {
	                            account.groupAuthes.forEach(function (element) {
	                                if (element.groupAuth === 'admin') {
	                                    isGroupAdmin = true;
	                                }
	                            });
	                        }
	                        if (isGroupAdmin) {
	                            $uibModal.open({
	                                templateUrl: 'app/system/group-management/group-management-dialog.html',
	                                controller: 'GroupManagementDialogController',
	                                controllerAs: 'vm',
	                                backdrop: 'static',
	                                size: 'lg',
	                                resolve: {
	                                    entity: function () {
	                                        return {
	                                            id: null, userId:0, caretreeId: null, caretreePw: null, jigyoushoCd: null, serviceType: null,
	                                            groupName: null, parentId: ""
	                                        };
	                                    }
	                                }
	                            }).result.then(function() {
	                                $state.go('group-management', null, { reload: true });
	                            }, function() {
	                                $state.go('group-management');
	                            });
	                        }
                    	} else {
                            LoginService.open();
                    	}
                    });
                }]
            })
            .state('group-management.edit', {
                parent: 'group-management',
                url: '/{id}/edit',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
                    Principal.identity().then(function(account) {
                    	if (account != null) {
                    		var isGroupAdmin = false;
                            if (account.authorities
                                && (account.authorities.indexOf("ROLE_ADMIN") !== -1
                                    || account.authorities.indexOf("ROLE_DEVELOPER") !== -1)) {
                                isGroupAdmin = true;
                            } else {
                                account.groupAuthes.forEach(function (element) {
                                    if (element.groupAuth === 'admin') {
                                        isGroupAdmin = true;
                                    }
                                });
                            }
                            if (isGroupAdmin) {
                                $uibModal.open({
                                    templateUrl: 'app/system/group-management/group-management-dialog.html',
                                    controller: 'GroupManagementDialogController',
                                    controllerAs: 'vm',
                                    backdrop: 'static',
                                    size: 'lg',
                                    resolve: {
                                        entity: {id: $stateParams.id}
                                    }
                                }).result.then(function() {
                                    $state.go('group-management', null, { reload: true });
                                }, function() {
                                    $state.go('^');
                                });
                            }
                    	} else {
                    		LoginService.open();
                    	}
                        
                    });
                }]
            })
            .state('group-management.delete', {
                parent: 'group-management',
                url: '/{id}/delete',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
                    Principal.identity().then(function(account) {
                    	if (account != null) {
                    		var isGroupAdmin = false;
                            if (account.authorities
                                && (account.authorities.indexOf("ROLE_ADMIN") !== -1
                                    || account.authorities.indexOf("ROLE_DEVELOPER") !== -1)) {
                                isGroupAdmin = true;
                            } else {
                                account.groupAuthes.forEach(function (element) {
                                    if (element.groupAuth === 'admin') {
                                        isGroupAdmin = true;
                                    }
                                });
                            }
                            if (isGroupAdmin) {
                                $uibModal.open({
                                    templateUrl: 'app/system/group-management/group-management-delete-dialog.html',
                                    controller: 'GroupManagementDeleteController',
                                    controllerAs: 'vm',
                                    size: 'md',
                                    resolve: {
                                        entity: ['Group', function(Group) {
                                            return Group.get({id : $stateParams.id});
                                        }]
                                    }
                                }).result.then(function() {
                                    $state.go('group-management', null, { reload: true });
                                }, function() {
                                    $state.go('^');
                                });
                            }
                    	} else {
                    		LoginService.open();
                    	}
                        
                    });
                }]
            });
    }
})();
