(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('FunctionManagementCreateController',FunctionManagementCreateController);

    FunctionManagementCreateController.$inject = ['$stateParams', 'param', 'AlertService', '$uibModalInstance', 'Kinou', 'JhiLanguageService','Group', 'MessageService', '$timeout', '$rootScope','OptionUser','Principal', 'Outlets'];

    function FunctionManagementCreateController ($stateParams, param, AlertService, $uibModalInstance, Kinou, JhiLanguageService, Group, MessageService, $timeout, $rootScope, OptionUser, Principal, Outlets) {
        var vm = this;
        vm.clear = clear;
        vm.authority;
        vm.searchAuthority = searchAuthority;
        vm.kinou = {id: "", code: "", name: ""};
        vm.save = save;
        vm.loadData = loadData;
        loadData();

        function loadData() {
            if (param.kinouId != null && param.kinouId != '') {
                Kinou.getKinou({kinouId: param.kinouId}, function (result) {
                    vm.kinou.id = result.id;
                    vm.kinou.code = result.code;
                    vm.kinou.name = result.name;
                });
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function searchAuthority(){
            var container = $('#aDiv');
            var scrollTo = $("input[name='" + vm.authority+ "']");
            container.animate({
                scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
            });
        }

        function save(){
            var entity = angular.copy(vm.kinou);
            if(entity.id == ''){
                Kinou.createKinou(entity, function(result){
                    MessageService.success("functionManagement.kinou.kinouSaved");
                    $uibModalInstance.close(result);
                    $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
                },function(error){
                    AlertService.error(error.data.message);
                    MessageService.error("global.messages.error.save");
                    $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
                });
            }else{
                Kinou.updateKinou(entity, function(result){
                    MessageService.success("functionManagement.kinou.kinouSaved");
                    $uibModalInstance.close(result);
                    $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
                },function(error){
                    AlertService.error(error.data.message);
                    MessageService.error("global.messages.error.save");
                    $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
                });
            }
        }
    }
})();
