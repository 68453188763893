(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('LicenseEditDialogController', LicenseEditDialogController);

    LicenseEditDialogController.$inject = ['$uibModalInstance', '$uibModal', 'Kinou', 'MessageService', 'LicenseService', 'param', '$timeout', '$rootScope', 'Price'];

    function LicenseEditDialogController ($uibModalInstance, $uibModal, Kinou, MessageService, LicenseService, param, $timeout, $rootScope, Price) {

        var vm = this;

        // ライセンス
        vm.license = {dbId: '', version: 1, id: '', name: '', productId: '', productNm: '', shopId: '', shopNm: "", startDate: null, endDate: null, licenseCount: 1, price: null, planId: 1, stopFlag: false};
        // 参考价格
        vm.referencePrice = null;
        // 页面表示
        vm.idText = "法人ID";
        vm.nmText = "法人名";

        vm.endDateCheckShow = false;

        vm.isOpen = false;
        vm.openCalendar = openCalendar;

        // 初始加载数据
        vm.loadData = loadData;

        // 关闭dialog
        vm.clear = clear;

        // 根据version改变显示文字
        vm.setVersionShow = setVersionShow;

        // 选择不同的版本时候，页面字段不同名称
        vm.changeVersion = changeVersion;

        // 选择group
        vm.selectGroup = selectGroup;

        // 选择法人
        vm.selectHoujin = selectHoujin;

        // 选择制品
        vm.selectProduct = selectProduct;

        // 选择贩卖店
        vm.selectShop = selectShop;

        // 保存数据
        vm.save = save;

        // 关闭dialog
        vm.close = close;

        vm.editFlag = false;

        vm.keiyakuplanList = [];

        loadData();

        function loadData() {
            if (param.id != null && param.id != '') {
                vm.editFlag = true;
                LicenseService.get({id: param.id}, function (result) {
                    vm.license.dbId = result.dbId;
                    vm.license.version = result.version;
                    vm.license.id = result.id;
                    vm.license.name = result.name;
                    vm.license.productId = result.productId;
                    vm.license.productNm = result.productNm;
                    vm.license.shopId = result.shopId;
                    vm.license.shopNm = result.shopNm;
                    vm.license.startDate = result.startDate != null ? new Date(result.startDate) : null;
                    vm.license.endDate = result.endDate != null ? new Date(result.endDate) : null;
                    vm.license.licenseCount = result.licenseCount;
                    vm.license.price = result.price;
                    vm.license.planId = result.planId;
                    vm.license.stopFlag = result.stopFlag;
                    // 根据产品id获取当前时间内的参考价格
                    Price.getByProductId({productId: result.productId}, function (priceResult) {
                        if (priceResult != null) {
                            vm.referencePrice = priceResult.price;
                        }
                    });
                    setVersionShow();
                });
            } else {
                vm.license.version = param.version;
                setVersionShow();
            }
            Kinou.getKeiyakuplanByLicense({}, function (result) {
                vm.keiyakuplanList = result;
            });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function setVersionShow() {
            if (vm.license.version == 2) {
                vm.idText = "グループID";
                vm.nmText = "グループ名";
            } else {
                vm.idText = "法人ID";
                vm.nmText = "法人名";
            }
        }

        function changeVersion() {
            setVersionShow();
            vm.license.id = "";
            vm.license.name = "";
        }

        function selectGroup() {
            var modalInstance = $uibModal.open({
                templateUrl : 'app/master/group/dialog/group-select-dialog.html',
                controller : 'GroupSelectDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size : 'lg',
                resolve : {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('group');
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(function(result) {
                if (result != '') {
                    vm.license.id = result.id;
                    vm.license.name = result.name;
                }
            },function(){
                //MessageService.error("global.error.internalServerError");
            })
        }

        function selectHoujin() {
            var modalInstance = $uibModal.open({
                templateUrl : 'app/master/houjin/dialog/houjin-select-dialog.html',
                controller : 'HoujinSelectDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size : 'lg',
                resolve : {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('houjin');
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(function(result) {
                if (result != '') {
                    vm.license.id = result.id;
                    vm.license.name = result.name;
                }
            },function(){
                //MessageService.error("global.error.internalServerError");
            })
        }

        function selectProduct() {
            var modalInstance = $uibModal.open({
                templateUrl : 'app/master/product/dialog/product-select-dialog.html',
                controller : 'ProductSelectDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size : 'lg',
                resolve : {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(function(result) {
                if (result != '') {
                    vm.license.productId = result.id;
                    vm.license.productNm = result.name;
                    // 根据产品id获取当前时间内的参考价格
                    Price.getByProductId({productId: result.id}, function (priceResult) {
                        if (priceResult != null) {
                            vm.referencePrice = priceResult.price;
                        }
                    });
                }
            },function(){
                //MessageService.error("global.error.internalServerError");
            })
        }

        function selectShop() {
            var modalInstance = $uibModal.open({
                templateUrl : 'app/master/shop/dialog/shop-select-dialog.html',
                controller : 'ShopSelectDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size : 'lg',
                resolve : {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('shop');
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(function(result) {
                if (result != '') {
                    vm.license.shopId = result.id;
                    vm.license.shopNm = result.name;
                }
            },function(){
                //MessageService.error("global.error.internalServerError");
            })
        }

        function save() {

            vm.endDateCheckShow = false;

            if ($('#startDateCheck').is(':hidden') && $('#endDateCheck').is(':hidden')) {
                if ((new Date($('#startDate').val())).getTime() > (new Date($('#endDate').val())).getTime()) {
                    vm.endDateCheckShow = true;
                    return false;
                } else {
                    vm.endDateCheckShow = false;
                }
            }

            var entity = angular.copy(vm.license);
            entity.startDate = $('#startDate').val() == '' ? null : $('#startDate').val();
            entity.endDate = $('#endDate').val() == '' ? null : $('#endDate').val();
            LicenseService.save(entity, function(result){
                MessageService.success("license.message.saved");
                $uibModalInstance.close(result);
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            });
        }

        function close () {
            $uibModalInstance.dismiss('cancel');
        }

        function openCalendar(i) {
            if (i == 1) {
                vm.isOpenStartDate = true;
            } else if (i == 2) {
                vm.isOpenEndDate = true;
            }
        }

    }
})();
