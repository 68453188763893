(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ChooseController', ChooseController);

    ChooseController.$inject = ['params', '$uibModalInstance', 'Principal', 'Enum', 'Library', 'ParseLinks', 'paginationConstants', '$scope', '$sce'];

    function ChooseController(params, $uibModalInstance, Principal, Enum, Library, ParseLinks, paginationConstants, $scope, $sce) {

        var vm = this;

        $scope.sce = $sce.trustAsResourceUrl;

        vm.belong = {};
        vm.page = 1;
        vm.links = null;
        vm.totalItems = null;
        vm.libraryList = [];
        vm.selectedBelong = 2;
        vm.selectedType = params.fileType;
        vm.formUrlFlg = false;
        vm.url = "";

        vm.close = close;
        vm.loadAll = loadAll;
        vm.loadBelong = loadBelong;
        vm.loadLibraries = loadLibraries;

        vm.changeBelong = changeBelong;
        vm.selectedLibrary = selectedLibrary;
        vm.setFromUrl = setFromUrl;

        loadAll();

        Principal.hasAuthority('ROLE_ADMIN').then(function(result){
            if(result) {
                vm.isAdmin = true;
            } else {
                vm.isAdmin = false;
            }
        });

        function close() {
            $uibModalInstance.close("");
        }

        function loadAll() {
            loadBelong();
            loadLibraries();
        }

        function loadBelong() {
            Enum.getLibrary({name: "LibraryBelongType"}, function(result) {
                vm.belong = result;
            });
        }

        function loadLibraries() {
            Library.queryPublic({page: vm.page - 1, size: paginationConstants.itemsPerPage, belong: vm.selectedBelong, type: vm.selectedType}, function(result, headers){
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.libraryList = result;
            });
        }

        function changeBelong(selectedBelong) {
            vm.formUrlFlg = false;
            vm.selectedBelong = selectedBelong;
            vm.page = 1;
            loadLibraries();
        }

        function selectedLibrary(value) {
            $uibModalInstance.close(value);
        }

        function setFromUrl() {
            vm.formUrlFlg = true;
        }
    }
})();
