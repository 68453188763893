(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('vital', {
            parent: 'kiroku',
            url: '/vital',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'global.menu.kiroku.vital'
            },
            views: {
                'content@': {
                    templateUrl: 'app/kiroku/vital/vital.html',
                    controller: 'VitalKirokuController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function() {
                    return {riyoushaId:null,jisshiDt:null,jisshiTm:null,taion:null,yuunetsuKbn:null,ketsuatsuUe:null,
                        ketsuatsuShita:null,myakuhaku:null,kokyuu:null,spo:null,huseimyakuKbn:null,taijuu:null,bikou:null};
                },
                // 文字
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('vital');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
