(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('LicenseShopController', LicenseShopController);

    LicenseShopController.$inject = ['paginationConstants', 'LicenseService'];

    function LicenseShopController (paginationConstants, LicenseService) {

        var vm = this;

        // 翻页所需
        vm.page = 1;
        vm.links = null;
        vm.totalItems = null;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        // 结果列表
        vm.licenseList = [];
        // 统计列表（待完善，计划用来统计贩卖店自己商品的贩卖情况，但是目前贩卖店和商品的信息没有绑定）
        vm.licenseCountList = [];
        // 查询条件
        vm.searchData = {productName: ""};
        vm.defaultSearchData = {productName: ""};

        // 初始加载数据
        vm.loadData = loadData;
        // 点击查询
        vm.search = search;
        // 清空搜索条件
        vm.clear = clear;

        // 加载完成之后自动执行
        loadData();

        function loadData() {
            LicenseService.queryShopLicense({
                page: vm.page - 1,
                productName: vm.searchData.productName
            }, function (result, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.licenseList = result;
            });
        }

        function search() {
            vm.page = 1;
            loadData();
        }

        function clear() {
            vm.searchData = angular.copy(vm.defaultSearchData);
            vm.page = 1;
            loadData();
        }
    }
})();
