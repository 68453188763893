(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('shokushu-management', {
                parent: 'app',
                url: '/timeca/Jobtype',
                data: {
                    pageTitle: 'shokushuManagement.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/timecard/shokushu-management/shokushu-management.html',
                        controller: 'ShokushuManagementController',
                        controllerAs: 'vm'
                    }
            	},
            	params: {
            		groupId: null,
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('shokushu-management');
                        return $translate.refresh();
                    }]

                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', 'Kinou',
                    function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService, Kinou) {
                	var top = $uibModalStack.getTop();
                	if (top) {
                	    $uibModalStack.dismiss(top.key);
                	}
                    Principal.identity().then(function(account) {
                    	if (account != null) {
                            var gtc_caretree2_timecard_allfunction_fullaccess = false;
                            var isAdmin = false;
                            var isDeveloper = false;
                            Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_TimeCard_AllFunction_FullAccess"}, function(result){
                            	if (!result.flag) {
                            		$state.go('accessdenied');
                            	} else {
                            		plan($state, $uibModal, account, isAdmin, isDeveloper, gtc_caretree2_timecard_allfunction_fullaccess);
                            	}
                            });
                    	} else {
                    		LoginService.open();
                    	}
                    	
                    });
                }]
            })
            .state('shokushu-management-detail', {
                parent: 'shokushu-management',
                url: '/shokushu/:id',
                data: {
                    pageTitle: 'shokushu-management.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/timecard/shokushu-management/shokushu-management-detail.html',
                        controller: 'ShokushuManagementDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('shokushu-management');
                        return $translate.refresh();
                    }]
                }
            })
            .state('shokushu-management.new', {
                parent: 'shokushu-management',
                url: '/new',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
                	$uibModal.open({
                        templateUrl: 'app/timecard/shokushu-management/shokushu-management-dialog.html',
                        controller: 'ShokushuManagementDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null, groupId: null, shokushuCd: null, shokushuName: null, type: null,
                                    createdBy: null, createdDate: null,
                                    lastModifiedBy: null, lastModifiedDate: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('shokushu-management', null, { reload: true });
                    }, function() {
                        $state.go('shokushu-management');
                    });
                }]
            })
            .state('shokushu-management.import', {
	            parent: 'shokushu-management',
	            url: '/import/{id}',
	            data: {
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
	            	$uibModal.open({
                        templateUrl: 'app/timecard/shokushu-management/shokushu-management-import.html',
                        controller: 'ShokushuManagementImportController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg'
                    }).result.then(function() {
                        $state.go('shokushu-management', null, { reload: true });
                    }, function() {
                        $state.go('shokushu-management');
                    });
	            }]
	        })
            .state('shokushu-management.edit', {
                parent: 'shokushu-management',
                url: '/edit/{id}',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
                	$uibModal.open({
                        templateUrl: 'app/timecard/shokushu-management/shokushu-management-dialog.html',
                        controller: 'ShokushuManagementDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Shokushu', function(Shokushu) {
                                return Shokushu.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function() {
                        $state.go('shokushu-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('shokushu-management.delete', {
                parent: 'shokushu-management',
                url: '/{id}/delete',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
                	$uibModal.open({
                        templateUrl: 'app/timecard/shokushu-management/shokushu-management-delete-dialog.html',
                        controller: 'ShokushuManagementDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Shokushu', function(Shokushu) {
                                return Shokushu.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function() {
                        $state.go('shokushu-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }
    function plan($state, $uibModal, account, isAdmin, isDeveloper, gtc_caretree2_timecard_allfunction_fullaccess){
        if (account.authorities.indexOf("ROLE_ADMIN") != -1) {
            isAdmin = true;
        }
        if (account.authorities.indexOf("ROLE_DEVELOPER") != -1) {
            isDeveloper = true;
        }
        if (account.functions != null) {
            for (var i in account.functions) {
                if (account.functions[i] == 'GTC_CareTree2_TimeCard_AllFunction_FullAccess') {
                    gtc_caretree2_timecard_allfunction_fullaccess = true;
                }
            }
        }
        if (!gtc_caretree2_timecard_allfunction_fullaccess && !isAdmin && !isDeveloper) {
            $state.go('home');
            $uibModal.open({
                templateUrl: 'app/home/dialog/license.html',
                controller: 'HomeLicenseController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg'
            }).result.then(function() {
                $state.go('home', null, { reload: true });
            }, function() {
                $state.go('home');
            });
        }
    }
})();
