(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CategoryDeleteController', CategoryDeleteController);

    CategoryDeleteController.$inject = ['$uibModalInstance', 'entity', 'Category', 'MessageService', '$timeout', '$rootScope'];

    function CategoryDeleteController ($uibModalInstance, entity, Category, MessageService, $timeout, $rootScope) {
        var vm = this;

        vm.category = entity;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            Category.delete({id: id}, function () {
                MessageService.success("global.messages.info.delete");
                $uibModalInstance.close(true);
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            });
        }
    }
})();
