(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ShowMediaController', ShowMediaController);

    ShowMediaController.$inject = ['$stateParams', '$scope', 'Category', 'Script', '$state', 'Enum', 'Library', 'Variable', '$uibModal', 'MessageService', '$sce', '$translate', 'Principal'];

    function ShowMediaController ($stateParams, $scope, Category, Script, $state, Enum, Library, Variable, $uibModal, MessageService, $sce, $translate, Principal) {

        var vm = this;

        $scope.sce = $sce.trustAsResourceUrl;

        vm.categories = [];
        vm.script = {id: null, pepperId: null, scriptType: null, scriptName: null, title: null,
            libraryId: null, libraryUrl: null, library: {},
            rhythm: null, rhythmValue: null, rhythmObj: {},
            publishType: true, plainScript: null, comment: null,
            scriptItems: [],
            categories: []};
        vm.detailTypes = [];
        vm.scriptDetailType = [];
        vm.libraries = [];
        vm.variables = [];
        vm.type = $stateParams.type;
        vm.url = null;
        vm.movieLit = [];
        vm.libraryUrl = null;
        vm.loadCount = 0;

        var audio = document.getElementById('audio');
        var video = document.getElementById('video');

        Principal.hasAuthority('ROLE_ADMIN').then(function(result){
            if(result) {
                vm.isAdmin = true;
            } else {
                vm.isAdmin = false;
            }
        });

        vm.loadAll = loadAll;
        vm.loadCategories = loadCategories;
        vm.loadScript = loadScript;
        vm.loadScriptDetailType = loadScriptDetailType;
        vm.loadLibraries = loadLibraries;
        vm.loadVariables = loadVariables;
        vm.loadUrl = loadUrl;
        vm.addLabel = addLabel;
        vm.save = save;
        vm.delLabel = delLabel;
        vm.addDetail = addDetail;
        vm.delDetail = delDetail;
        vm.back = back;
        vm.choose = choose;

        vm.setMedia = setMedia;
        vm.record = record;
        vm.transformSecondToMinute = transformSecondToMinute;
        vm.transformMinuteToSecond = transformMinuteToSecond;

        vm.checkLibrary = checkLibrary;

        vm.preViewShow = preViewShow;

        vm.loadAll();

        function loadAll() {
            vm.loadCategories();
            vm.loadScriptDetailType();
            vm.loadLibraries();
            vm.loadVariables();
            vm.loadUrl();
            //vm.loadScript();
        }

        function loadCategories() {
            Category.list(function(result) {
                for (var i = 0; i < result.length; i ++) {
                    if (vm.isAdmin || (!vm.isAdmin && result[i].categoryType < 9)) {
                        vm.categories.push(result[i]);
                    }
                }
                vm.loadScript();
            });
        }

        function loadScript() {
            vm.loadCount ++;

            if (vm.loadCount != 5) {
                return false;
            }
            if ($stateParams.id != 0) {
                Script.get({id: $stateParams.id}, function(result) {
                    vm.script = result;

                    var categories = vm.script.categories;
                    vm.script.categories = [];
                    vm.script.rhythmObj = {};

                    for (var j = 0; j < vm.categories.length; j ++) {
                        for (var i = 0; i < categories.length; i ++) {
                            if (categories[i].id == vm.categories[j].id) {
                                vm.script.categories.push(vm.categories[j]);
                            }
                        }
                    }

                    for (var i = 0; i < vm.script.scriptItems.length; i ++) {
                        var scriptItemDetails = vm.script.scriptItems[i].scriptItemDetails;
                        for (var j = 0; j < scriptItemDetails.length; j ++) {
                            scriptItemDetails[j].detailType = scriptItemDetails[j].detailType.toString();
                            preViewShow(i, j, scriptItemDetails[j].detailType);
                        }
                    }

                    for (var i = 0; i < vm.variables.length; i ++) {
                        if (vm.variables[i].id == vm.script.rhythm) {
                            vm.script.rhythmObj = vm.variables[i];
                        }
                    }

                    for (var i = 0; i < vm.libraries.length; i ++) {
                        if (vm.script.libraryId == vm.libraries[i].id) {
                            vm.script.library = vm.libraries[i];
                        }
                    }

                    vm.setMedia();

                    vm.script.publishType = vm.script.publishType == 1 ? true : false;

                    vm.labelCount = vm.script.scriptItems.length;

                });
                vm.media = 1;
            } else {
                vm.labelCount = 0;
            }
        }

        function loadScriptDetailType() {
            Enum.get({name: 'ScriptMediaDetailType'}, function(result) {
                vm.scriptDetailType = result;
                vm.loadScript();
            });
        }

        function loadLibraries() {
            Library.list({}, function(result) {
                vm.libraries = result;
                for (var i = 0; i < vm.libraries.length; i ++) {
                    if (vm.libraries[i].fileType == '4' || vm.libraries[i].fileType == '5')
                    vm.movieLit.push(vm.libraries[i]);
                }
                vm.loadScript();
            });
        }

        function loadVariables() {
            Variable.list({}, function(result) {
                vm.variables = result;
                vm.loadScript();
            });
        }

        // ラベルを追加
        function addLabel(labelName) {
            vm.script.scriptItems.push({label: labelName, labelType: 3, scriptItemDetails: []});
            vm.script.scriptItems[vm.script.scriptItems.length - 1].scriptItemDetails.push({detailType: '5', contentId: ""});
        }

        function save(saveDirect) {

            preData(saveDirect);

            if (saveDirect) {
                Script.make(vm.script, function(result) {
                    vm.script.plainScript = result.plainScript;
                });
            } else {
                Script.save(vm.script, onSaveSuccess, onSaveError);
            }
        }

        function preData(saveDirect) {
            vm.success = null;
            vm.isSaving = true;
            vm.script.scriptType = vm.type;
            vm.script.saveDirect = saveDirect;

            if (vm.script.library.id != undefined) {
                vm.script.libraryId = vm.script.library.id;
                vm.script.libraryUrl = vm.script.library.url;
                if (vm.script.library.uploadFlg) {
                    vm.script.libraryUrl = vm.url + "/library/api/library/file/" + vm.script.library.id;
                } else {
                    vm.script.libraryUrl = vm.script.library.url;
                }
            }
            if (vm.script.rhythmObj != null && vm.script.rhythmObj.id != undefined) {
                vm.script.rhythm = vm.script.rhythmObj.id;
                vm.script.rhythmValue = vm.script.rhythmObj.itemCode;
            } else {
                vm.script.rhythm = "";
                vm.script.rhythmValue = "";
            }

            // contentValue
            for (var i = 0; i < vm.script.scriptItems.length; i ++) {
                var item = vm.script.scriptItems[i];

                if (item.label && item.label != '') {
                    item.labelShow = transformMinuteToSecond(item.label);
                } else {
                    item.label = 0;
                    item.labelShow = 0;
                }

                var delDetailArr = [];
                for (var j = 0; j < item.scriptItemDetails.length; j ++) {
                    var detail = item.scriptItemDetails[j];
                    if (detail.detailType == '10') {
                        for (var k = 0; k < vm.libraries.length; k ++) {
                            var o = vm.libraries[k];
                            if (detail.contentId != undefined && detail.contentId == o.id) {
                                var li = checkLibrary(detail.contentId);
                                if (li.uploadFlg) {
                                    if (detail.detailType == '10') {
                                        detail.contentValue = vm.url + "/library/api/library/image/" + o.id;
                                    } else {
                                        detail.contentValue = vm.url + "/library/api/library/file/" + o.id;
                                    }
                                } else {
                                    detail.contentValue = o.url
                                }
                            }
                        }
                    } else if (detail.detailType == '15') {
                        for (var k = 0; k < vm.variables.length; k ++) {
                            var o = vm.variables[k];
                            if (detail.contentId != undefined && detail.contentId == o.id) {
                                detail.contentValue = o.itemCode;
                            }
                        }
                    } else if (detail.detailType == '') {
                        delDetailArr.push(j);
                    }
                }
                for (var l = delDetailArr.length;l > 0; l --) {
                    delDetail(i, l);
                }
            }
        }

        function onSaveSuccess() {
            vm.isSaving = false;
            vm.success = true;
            //loadScript();
            $state.go('script');
            MessageService.success("global.messages.info.save");
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("global.messages.error.save");
            vm.success = null;
        }

        // ラベルを削除
        function delLabel(index) {
            vm.script.scriptItems.splice(index, 1);
        }

        function addDetail(parentIndex, type) {
            vm.script.scriptItems[parentIndex].scriptItemDetails.push({detailType: type});
        }

        function delDetail(parentIndex, index) {
            if (vm.script.scriptItems[parentIndex].scriptItemDetails[index].detailType == '10') {
                vm.script.scriptItems[parentIndex].background = "";
            }
            vm.script.scriptItems[parentIndex].scriptItemDetails.splice(index, 1);
        }

        function back() {
            $state.go('script');
        }

        // media
        function setMedia() {
            if (vm.script.library != null) {
                if (vm.script.library.uploadFlg == 0) {
                    vm.libraryUrl = vm.script.library.url;
                } else {
                    vm.libraryUrl = "/library/api/library/file/" + vm.script.library.id;
                }
                vm.media = 1;
            } else {
                vm.media = null;
                vm.libraryUrl = null;
            }
        }

        function record() {
            var currentTime = 0;
            if (vm.type == 2) {
                var audio = document.getElementById('audio');
                currentTime = parseInt(audio.currentTime, 10);
            } else if(vm.type == 3 && vm.script.library.fileType == 4) {
                var video = document.getElementById('video');
                currentTime = parseInt(video.currentTime, 10);
            } else if(vm.type == 3 && vm.script.library.fileType == 5) {
                return false;
            }
            var labelName = transformSecondToMinute(currentTime);
            addLabel(labelName);
        }

        function transformSecondToMinute(second) {
            var minuteTime = parseInt(second / 60, 10);
            var secondTime = parseInt(second % 60, 10) > 9 ? parseInt(second % 60, 10) : "0" + parseInt(second % 60, 10);
            var result = minuteTime + ":" + secondTime;
            return result;
        }

        function transformMinuteToSecond(minute) {
            var minuteArr = minute.split(":");
            return parseInt(minuteArr[0], 10) * 60 + parseInt(minuteArr[1], 10);
        }

        function preViewShow(parent, index, type) {
            if (type == '10') {
                if (vm.script.scriptItems[parent].scriptItemDetails[index].contentId != null
                    && vm.script.scriptItems[parent].scriptItemDetails[index].contentId != "") {
                    var li = checkLibrary(vm.script.scriptItems[parent].scriptItemDetails[index].contentId);
                    if (li != null) {
                        if (li.uploadFlg) {
                            vm.script.scriptItems[parent].background = "/library/api/library/image/"+ vm.script.scriptItems[parent].scriptItemDetails[index].contentId;
                        } else {
                            vm.script.scriptItems[parent].background = li.url;
                        }
                    } else {
                        vm.script.scriptItems[parent].background = null;
                    }
                } else {
                    vm.script.scriptItems[parent].background = vm.script.scriptItems[parent].scriptItemDetails[index].text;
                }
            } else if (type == '15') {
                vm.script.scriptItems[parent].scriptItemDetails[index].contentValue = getVariableDetail(vm.script.scriptItems[parent].scriptItemDetails[index].contentId, 15)
            }
        }

        function checkLibrary(id) {
            var result = null;
            for (var i = 0; i < vm.libraries.length; i ++) {
                if (vm.libraries[i].id == id) {
                    result = angular.copy(vm.libraries[i]);
                }
            }
            return result;
        }

        function getVariableDetail(id, type) {
            var result = "";
            if (type == '15') {
                for (var k = 0; k < vm.variables.length; k ++) {
                    var o = vm.variables[k];
                    if (id == o.id) {
                        result = o.itemCode;
                    }
                }
            }
            return result;
        }

        function choose(item, detail, fileType, type) {
            var modalInstance = $uibModal.open({
                templateUrl : 'app/system/script/library/choose/choose.html',
                controller : 'ChooseController',
                controllerAs: 'vm',
                size : 'lg',
                resolve : {
                    params : {fileType: fileType},
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('library');
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(function(result) {
                if (result != '') {
                    vm.script.scriptItems[item].scriptItemDetails[detail].contentId = "";
                    vm.script.scriptItems[item].scriptItemDetails[detail].text = "";
                    if (result.type == 1) {
                        vm.script.scriptItems[item].scriptItemDetails[detail].contentId = result.value;
                    } else {
                        vm.script.scriptItems[item].scriptItemDetails[detail].text = result.value;
                    }
                    preViewShow(item, detail, type);
                }
            },function(){
                MessageService.error("global.error.internalServerError");
            })
        }

        function loadUrl() {
            Library.url(function(result){
                vm.url = result.url;
                vm.loadScript();
            })
        }
    }
})();
