(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ShopEditDialogController', ShopEditDialogController);

    ShopEditDialogController.$inject = ['$uibModalInstance'];

    function ShopEditDialogController ($uibModalInstance) {

        var vm = this;

        // 贩卖店
        vm.shop = {};

        // 初始加载数据
        vm.loadData = loadData();

        // 关闭dialog
        vm.clear = clear;

        function loadData() {
            vm.product = {
                shopId: '',
                shopNm: '',
                tel: '',
                fax: '',
                managerDuty: '',
                managerNm: ''
            };
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
