(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Product', Product);

    Product.$inject = ['$resource'];

    function Product ($resource) {
        var service = $resource('api/product', {}, {
            'query': {
                method: 'GET',
                url: 'api/product/list',
                isArray: true
            },
            'queryDialog': {
                method: 'GET',
                url: 'api/product/listDialog',
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'save': {
                method: 'POST'
            },
            'delete': {
                method: 'DELETE'
            },
            'getProductType': {
                method: 'GET',
                url: 'api/product/productType'
            }
        });

        return service;
    }

})();
