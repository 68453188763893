(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('LibraryUploadController', LibraryUploadController);

    LibraryUploadController.$inject = ['$uibModalInstance', '$scope', 'Upload', 'Principal', 'Group', 'MessageService'];

    function LibraryUploadController($uibModalInstance, $scope, Upload, Principal, Group, MessageService) {

        var vm = this;

        vm.files = [];

        vm.totalFiles = [];
        vm.index = 0;

        vm.groupId = null;

        vm.close = close;
        vm.loadAllGroups = loadAllGroups;

        vm.loadAllGroups();

        Principal.hasAuthority('ROLE_ADMIN').then(function(result){
            if(result) {
                vm.isAdmin = true;
            } else {
                vm.isAdmin = false;
            }
        });

        function close() {
            $uibModalInstance.close();
        }

        function upload(files) {
            if (files && files.length) {
                for (var i = 0; i < files.length; i ++) {
                    vm.totalFiles.push({fileName: files[i].name, progress: 0, file: files[i]});
                }
                startUpload();
            }
        }

        function startUpload() {
            var file = vm.totalFiles[vm.index].file;
            var data = vm.totalFiles[vm.index];
            data.photoFlg = 0;
            data.adminFlg = vm.isAdmin;
            data.groupId = vm.groupId;
            if (!file.$error) {
                Upload.upload({
                    url: 'library/api/upload',
                    data: data
                }).progress(function (evt) {
                    if (vm.index < vm.totalFiles.length) {
                        vm.totalFiles[vm.index].progress = isNaN(parseInt(100.0 * evt.loaded / evt.total)) ? 0 : parseInt(100.0 * evt.loaded / evt.total);
                    }
                }).success(function (result) {
                    uploadFinish();
                    onUploadSuccess();
                }).error(function (result) {
                    //alert("上限が50M、アップロードできませんでした。");
                    MessageService.error("library.message.uploadError");
                    uploadFinish();
                });
            } else {
                uploadFinish();
                //alert("ファイルエラーが発生しました。");
                MessageService.error("library.message.uploadError");
            }
        }

        function uploadFinish() {
            vm.index = vm.index + 1;
            if (vm.totalFiles.length > vm.index) {
                startUpload();
            }
        }

        function onUploadSuccess() {
            //console.log("success");
        }

        function onUploadError(error) {
            //console.log("error");
        }

        $scope.$watch('vm.files', function () {
            upload(vm.files);
        });

        function loadAllGroups() {
            Group.queryUserGroups(function(result){
                vm.groups = result;
                if (result.length > 0) {
                    vm.groupId = result[0].id;
                }
            });

        }

    }
})();
