(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('keiyakuplan-management', {
                parent: 'app',
                url: '/settings/keiyakuplan?page&sort',
                data: {
                	authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'keiyakuplanManagement.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/system/keiyakuplan-management/keiyakuplan-management.html',
                        controller: 'KeiyakuplanManagementController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('keiyakuplan-management');
                        return $translate.refresh();
                    }]

                }
            })
        .state('keiyakuplan-management.keiyakuplan', {
            parent: 'keiyakuplan-management',
            url: '/keiyakuplan/{kinouId}',
            data: {
            	authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/system/keiyakuplan-management/keiyakuplan-management-planset.html',
                    controller: 'KeiyakuplanManagementPlansetController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        param: {kinouId: $stateParams.kinouId}
                    }
                }).result.then(function() {
                    $state.go('keiyakuplan-management', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
