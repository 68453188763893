(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('variable', {
            parent: 'system',
            url: '/robot/variable',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'global.menu.setting.variable'
            },
            views: {
                'content@': {
                    templateUrl: 'app/system/variable/variable.html',
                    controller: 'VariableController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                // 文字
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('variable');
                    return $translate.refresh();
                }]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
            	var top = $uibModalStack.getTop();
            	if (top) {
            	    $uibModalStack.dismiss(top.key);
            	}
                Principal.identity().then(function(account) {
                	if (account != null) {
                		var gtc_caretree2_robot_allfunction_fullaccess = false;
                    	if (account.functions != null) {
                        	for (var i in account.functions) {
                        		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                        			gtc_caretree2_robot_allfunction_fullaccess = true;
                        		}
                        	}
                        }
                    	if (gtc_caretree2_robot_allfunction_fullaccess == false) {
                    		$state.go('home');
                        	$uibModal.open({
                                templateUrl: 'app/home/dialog/license.html',
                                controller: 'HomeLicenseController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg'
                            }).result.then(function() {
                                $state.go('home', null, { reload: true });
                            }, function() {
                                $state.go('home');
                            });
                        }
                	} else {
                		LoginService.open();
                	}
                	
                });
            }]
        }).state('variable.new', {
            parent: 'variable',
            url: '/{itemType}/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
            	Principal.identity().then(function(account) {
            		if (account != null) {
            			var gtc_caretree2_robot_allfunction_fullaccess = false;
                    	if (account.functions != null) {
                        	for (var i in account.functions) {
                        		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                        			gtc_caretree2_robot_allfunction_fullaccess = true;
                        		}
                        	}
                        }
                    	if (gtc_caretree2_robot_allfunction_fullaccess != false) {
                        	$uibModal.open({
                                templateUrl: 'app/system/variable/detail/variable-edit-dialog.html',
                                controller: 'VariableEditDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    entity: function () {
                                        return {id: null, itemType: $stateParams.itemType, itemCode: null, itemValue: null};
                                    }
                                }
                            }).result.then(function() {
                                $state.go('variable', null, { reload: true });
                            }, function() {
                                $state.go('variable');
                            });
                        }
            		} else {
            			LoginService.open();
            		}
                	
                });
            }]
        }).state('variable.edit', {
            parent: 'variable',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
            	Principal.identity().then(function(account) {
            		if (account != null) {
            			var gtc_caretree2_robot_allfunction_fullaccess = false;
                    	if (account.functions != null) {
                        	for (var i in account.functions) {
                        		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                        			gtc_caretree2_robot_allfunction_fullaccess = true;
                        		}
                        	}
                        }
                    	if (gtc_caretree2_robot_allfunction_fullaccess != false) {
                        	$uibModal.open({
                                templateUrl: 'app/system/variable/detail/variable-edit-dialog.html',
                                controller: 'VariableEditDialogController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                size: 'lg',
                                resolve: {
                                    entity: ['Variable', function(Variable) {
                                        return Variable.get({id : $stateParams.id});
                                    }]
                                }
                            }).result.then(function() {
                                $state.go('variable', null, { reload: true });
                            }, function() {
                                $state.go('variable');
                            });
                        }
            		} else {
            			LoginService.open();
            		}
                	
                });
            }]
        }).state('variable.delete', {
            parent: 'variable',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
            	Principal.identity().then(function(account) {
            		if (account != null) {
            			var gtc_caretree2_robot_allfunction_fullaccess = false;
                    	if (account.functions != null) {
                        	for (var i in account.functions) {
                        		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                        			gtc_caretree2_robot_allfunction_fullaccess = true;
                        		}
                        	}
                        }
                    	if (gtc_caretree2_robot_allfunction_fullaccess != false) {
                        	$uibModal.open({
                                templateUrl: 'app/system/variable/detail/variable-delete-dialog.html',
                                controller: 'VariableDeleteController',
                                controllerAs: 'vm',
                                size: 'md',
                                resolve: {
                                    entity: ['Variable', function(Variable) {
                                        return Variable.get({id: $stateParams.id});
                                    }]
                                }
                            }).result.then(function() {
                                $state.go('variable', null, { reload: true });
                            }, function() {
                                $state.go('^');
                            });
                        }
            		} else {
            			LoginService.open();
            		}
                	
                });
            }]
        });
    }
})();
