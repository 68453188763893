(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'Auth', '$rootScope', 'MessageService', 'User', 'Kinou', 'AuthResource', '$uibModal'];

    function HomeController ($scope, Principal, LoginService, $state, Auth, $rootScope, MessageService, User, Kinou, AuthResource, $uibModal) {
        var vm = this;

        vm.currentYear = new Date().getFullYear();
        vm.currentMonth = new Date().getMonth()+1;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.requestResetPassword = requestResetPassword;
        vm.login = login;
        vm.isAdmin = false;
        vm.isUser = false;
        vm.isCaretree = false;
        vm.isPartner = false;
        vm.isDeveloper = false;
        vm.isOneUser = false;
        vm.isTwoUser = false;
        //ケア樹Free
        vm.showCaretreeFree = false;
        //ケア樹あそぶ
        vm.showAsobu = false;
        //ケア樹タイムカード
        vm.showTimecard = false;
        // あそぶ表示かどうか
        vm.showAnalyze = false;
        //訪問打刻
        vm.houmonTimeCard = false;
        
        vm.isOneUser = false;
        vm.isTwoUser = false;
        vm.gtc_caretree2_robot_allfunction_fullaccess = false;
        vm.gtc_caretree2_analyze_function_fullaccess = false;
        vm.gtc_caretree_web_allfunction_fullaccess = false;

        vm.hasCaretreeAccount = false;
        
        vm.checkAccount = checkAccount;
        
        checkAccount();

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        
        vm.gotoRobot = gotoRobot;
        vm.gotoAnalyze = gotoAnalyze;
        vm.gotoCaretreeFree = gotoCaretreeFree;
        vm.timecardClick = timecardClick;
        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.isAdmin = false;
                vm.isUser = false;
                vm.isCaretree = false;
                vm.isPartner = false;
                vm.isDeveloper = false;
                vm.isOneUser = false;
                vm.isTwoUser = false;
                //ケア樹Free
                vm.showCaretreeFree = false;
                //ケア樹わかる
                vm.showAnalyze = false;
                //ケア樹あそぶ
                vm.showAsobu = false;
                //ケア樹タイムカード
                vm.showTimecard = false;
                
                vm.gtc_caretree2_robot_allfunction_fullaccess = false;
                vm.gtc_caretree2_analyze_function_fullaccess = false;
                vm.gtc_caretree_web_allfunction_fullaccess = false;

                if (account != null) {
                    if (account.authorities != null) {
                        if (account.authorities.indexOf("ROLE_ADMIN") != -1) {
                            vm.isAdmin = true;
                        }
                        if (account.authorities.indexOf("ROLE_USER") != -1) {
                            vm.isUser = true;
                        }
                        if (account.authorities.indexOf("ROLE_CARETREE") != -1) {
                            vm.isCaretree = true;
                        }
                        if (account.authorities.indexOf("ROLE_PARTNER") != -1) {
                            vm.isPartner = true;
                        }
                        if (account.authorities.indexOf("ROLE_DEVELOPER") != -1) {
                            vm.isDeveloper = true;
                        }
                        //ケア樹Free
                        Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree_WEB_AllFunction_FullAccess"}, function(result){
                        	if (result.flag) {
                        		vm.showCaretreeFree = true;
                        	}
                        });
                        //ケア樹あそぶ
                        Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_Robot_AllFunction_FullAccess"}, function(result){
                        	if (result.flag) {
                        		vm.showAsobu = true;
                        	}
                        });
                        //ケア樹わかる
                        Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_Analyze_Function_FullAccess"}, function(result){
                        	if (result.flag) {
                        		vm.showAnalyze = true;
                        	}
                        });
                        //ケア樹タイムカード
                        Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_TimeCard_AllFunction_FullAccess"}, function(result){
                        	if (result.flag) {
                        		vm.showTimecard = true;
                        	}
                        });
                    }
                    if (account.functions != null) {
                        for (var i in account.functions) {
                            if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                                vm.gtc_caretree2_robot_allfunction_fullaccess = true;
                            }
                            if (account.functions[i] == 'GTC_CareTree2_Analyze_Function_FullAccess') {
                                vm.gtc_caretree2_analyze_function_fullaccess = true;
                            }
                            //ケア樹Free
                            if (account.functions[i] == 'GTC_CareTree_WEB_AllFunction_FullAccess') {
                                vm.gtc_caretree_web_allfunction_fullaccess = true;
                            }
                        }
                    }

                    if (account.login.indexOf(";") != -1) {
                        vm.isOneUser = true;
                        vm.isTwoUser = false;
                    } else {
                        vm.isOneUser = false;
                        vm.isTwoUser = true;
                    }
                    User.queryUserOption({userId: account.id}, function (result) {
                        vm.houmonTimeCard = false;
                        if (result.houmonTimeCard == 1) {
                            vm.houmonTimeCard = true;
                        }
                    });
                }
            });
        }
        function register () {
            $state.go('register');
        }
        function requestResetPassword () {
            $state.go('requestReset');
        }

        function login () {
            var userName = "";
            var password = "";
            var rememberMe = "";
            if($("#login li.active").attr("value") == 1){
                userName = vm.jigyoushoCd + ";" + vm.usernameOne;
                password = vm.passwordOne;
                rememberMe = vm.rememberMeOne;
            }else{
                userName = vm.username;
                password = vm.password
                rememberMe = vm.rememberMe;
            };
            Auth.login({
                username: userName,
                password: password,
                rememberMe: rememberMe
            }).then(function () {
                vm.authenticationError = false;
                //$uibModalInstance.close();
                $state.go('home');
                
                checkAccount();

                $rootScope.$broadcast('authenticationSuccess');
                $rootScope.$broadcast('pepperUpdate');
                $rootScope.$broadcast('groupUpdate');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function () {
                MessageService.errorWithSecond("login.messages.error.authentication", 0);
            });
        }
        
        function checkAccount() {
        	AuthResource.hasCaretreeAccount({}, function(data){
        		vm.hasCaretreeAccount = data.result;
        	});
        }
        
        function gotoRobot() {
        	if (vm.gtc_caretree2_robot_allfunction_fullaccess || vm.isAdmin || vm.isDeveloper) {
        		window.open('/#/robot/contents');
        	} else {
        		$uibModal.open({
                    templateUrl: 'app/home/dialog/license.html',
                    controller: 'HomeLicenseController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function() {
                    $state.go('home', null, { reload: true });
                }, function() {
                    $state.go('home');
                });
        	}
        }

        function gotoAnalyze() {
            if (vm.gtc_caretree2_analyze_function_fullaccess || vm.isAdmin || vm.isDeveloper) {
                window.open('/#/analyze');
            } else {
                $uibModal.open({
                    templateUrl: 'app/home/dialog/license.html',
                    controller: 'HomeLicenseController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function() {
                    $state.go('home', null, { reload: true });
                }, function() {
                    $state.go('home');
                });
            }
        }

        function gotoCaretreeFree(type){
            if (vm.gtc_caretree_web_allfunction_fullaccess || vm.isAdmin || vm.isDeveloper) {
                if(type == 0){
                    window.open('https://goodleaf.jp/');
                }else if(type == 1){
                    window.open('https://caretreesys.net/');
                }
            } else {
                $uibModal.open({
                    templateUrl: 'app/home/dialog/license.html',
                    controller: 'HomeLicenseController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result.then(function() {
                    $state.go('home', null, { reload: true });
                }, function() {
                    $state.go('home');
                });
            }
        }

        function timecardClick() {
            $state.go('houmonTimecard');
        }
    }
})();
