(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TokenDialogController', TokenDialogController);

    TokenDialogController.$inject = ['$uibModalInstance', 'MessageService', 'TokenService', 'param', '$timeout', '$rootScope', '$uibModal'];

    function TokenDialogController ($uibModalInstance, MessageService, TokenService, param, $timeout, $rootScope, $uibModal) {

        var vm = this;

        // Token
        vm.token = {clientId: "", appName: "", endDate: ""};
        vm.tokenList = [];
        vm.copyTextShow = false;
        // 日期选择控件显示控制
        vm.isOpen = false;

        // 初始加载数据
        vm.loadData = loadData;
        // 关闭dialog
        vm.close = close;
        // 清除选择的token
        vm.del = del;
        // 保存数据
        vm.save = save;
        // 复制到剪贴板
        vm.copy = copy;
        vm.edit = edit;
        // 显示日期控件
        vm.openCalendar = openCalendar;

        loadData();

        function loadData() {
            // 获取信息
            if (param.id != null && param.id != '') {
                vm.token.clientId = param.id;
                loadTokenList(param.id);
            } else {
                TokenService.loginShop(function(result){
                    vm.token.clientId = result.id;
                    loadTokenList(result.id);
                })
            }
        }

        function loadTokenList(shopId) {
            TokenService.tokenList({shopId: shopId}, function(result){
                vm.tokenList = result;
            });
        }

        function close () {
            $uibModalInstance.dismiss('cancel');
        }

        function del(index) {

            var modalInstance = $uibModal.open({
                templateUrl : 'app/system/token-management/dialog/token-delete-dialog.html',
                controller : 'TokenDeleteDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size : 'md',
                resolve: {
                    entity: ['TokenService', function(TokenService) {
                        return TokenService.get({id : vm.tokenList[index].id});
                    }]
                }
            });
            modalInstance.result.then(function(result) {
                loadData();
            },function(){
                //MessageService.error("global.error.internalServerError");
            })


            // if(confirm('削除してもよろしいですか?')) {
            //     TokenService.del({id: id}, function(){
            //         MessageService.success("token.message.deleted");
            //         loadData();
            //     });
            // }
        }

        function save() {
            var entity = angular.copy(vm.token);
            entity.endDate = $('#endDate').val() == '' ? null : $('#endDate').val();
            TokenService.save(entity, function(result){
                MessageService.success("token.message.saved");
                loadData();
                vm.token.appName = "";
                vm.token.endDate = "";
            });
        }

        function copy(text) {
            $('#copyText').val(text).show().focus().select();
            document.execCommand("copy");
            $('#copyText').hide();
        }

        function openCalendar(){
            vm.isOpen = true;
        }

        function edit(index) {
            var modalInstance = $uibModal.open({
                templateUrl : 'app/system/token-management/dialog/token-edit-dialog.html',
                controller : 'TokenEditDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size : 'md',
                resolve : {
                    param: {token: vm.tokenList[index]},
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('token');
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(function(result) {
                vm.tokenList[index] = result;
            },function(){
                //MessageService.error("global.error.internalServerError");
            })
        }
    }
})();
