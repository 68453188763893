(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('robot-management', {
                parent: 'app',
                url: '/robot/robot',
                data: {
                    pageTitle: 'robot-management.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/system/robot-management/robot-management.html',
                        controller: 'RobotManagementController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('robot-management');
                        return $translate.refresh();
                    }]
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
                	var top = $uibModalStack.getTop();
                	if (top) {
                	    $uibModalStack.dismiss(top.key);
                	}
                    Principal.identity().then(function(account) {
                    	if (account != null) {
                    		var gtc_caretree2_robot_allfunction_fullaccess = false;
                        	if (account.functions != null) {
                            	for (var i in account.functions) {
                            		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                            			gtc_caretree2_robot_allfunction_fullaccess = true;
                            		}
                            	}
                            }
                        	if (gtc_caretree2_robot_allfunction_fullaccess == false) {
                        		$state.go('home');
                            	$uibModal.open({
                                    templateUrl: 'app/home/dialog/license.html',
                                    controller: 'HomeLicenseController',
                                    controllerAs: 'vm',
                                    backdrop: 'static',
                                    size: 'lg'
                                }).result.then(function() {
                                    $state.go('home', null, { reload: true });
                                }, function() {
                                    $state.go('home');
                                });
                            }
                    	} else {
                    		LoginService.open();
                    	}
                    	
                    });
                }]
            })
            .state('robot-management.new', {
                parent: 'robot-management',
                url: '/new',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
                	Principal.identity().then(function(account) {
                		if (account != null) {
                			var gtc_caretree2_robot_allfunction_fullaccess = false;
                        	if (account.functions != null) {
                            	for (var i in account.functions) {
                            		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                            			gtc_caretree2_robot_allfunction_fullaccess = true;
                            		}
                            	}
                            }
                        	if (gtc_caretree2_robot_allfunction_fullaccess != false) {
                            	$uibModal.open({
                                    templateUrl: 'app/system/robot-management/robot-management-dialog.html',
                                    controller: 'RobotManagementDialogController',
                                    controllerAs: 'vm',
                                    backdrop: 'static',
                                    size: 'md'
                                }).result.then(function() {
                                    $state.go('robot-management', null, { reload: true });
                                }, function() {
                                    $state.go('robot-management');
                                });
                            }
                		} else {
                			LoginService.open();
                		}
                    	
                    });
                }]
            })
            .state('robot-management.edit', {
                parent: 'robot-management',
                url: '/{id}/edit',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
                	Principal.identity().then(function(account) {
                		if (account != null) {
                			var gtc_caretree2_robot_allfunction_fullaccess = false;
                        	if (account.functions != null) {
                            	for (var i in account.functions) {
                            		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                            			gtc_caretree2_robot_allfunction_fullaccess = true;
                            		}
                            	}
                            }
                        	if (gtc_caretree2_robot_allfunction_fullaccess != false) {
                            	$uibModal.open({
                                    templateUrl: 'app/system/robot-management/robot-management-dialog.html',
                                    controller: 'RobotManagementDialogController',
                                    controllerAs: 'vm',
                                    backdrop: 'static',
                                    size: 'md'
                                }).result.then(function() {
                                    $state.go('robot-management', null, { reload: true });
                                }, function() {
                                    $state.go('^');
                                });
                            }
                		} else {
                			LoginService.open();
                		}
                    	
                    });
                }]
            })
            .state('robot-management.delete', {
                parent: 'robot-management',
                url: '/{id}/delete/{groupNm}',
                data: {
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
                	Principal.identity().then(function(account) {
                		if (account != null) {
                			var gtc_caretree2_robot_allfunction_fullaccess = false;
                        	if (account.functions != null) {
                            	for (var i in account.functions) {
                            		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                            			gtc_caretree2_robot_allfunction_fullaccess = true;
                            		}
                            	}
                            }
                        	if (gtc_caretree2_robot_allfunction_fullaccess != false) {
                            	$uibModal.open({
                                    templateUrl: 'app/system/robot-management/robot-management-delete-dialog.html',
                                    controller: 'RobotManagementDeleteController',
                                    controllerAs: 'vm',
                                    size: 'md',
                                    resolve: {
                                        entity: ['Pepper', function(Pepper) {
                                            return Pepper.get({id : $stateParams.id});
                                        }],
                                        group: {groupName:$stateParams.groupNm}
                                    }
                                }).result.then(function() {
                                    $state.go('robot-management', null, { reload: true });
                                }, function() {
                                    $state.go('^');
                                });
                            }
                		} else {
                			LoginService.open();
                		}
                    	
                    });
                }]
            });
    }
})();
