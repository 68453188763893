(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('PhotoController', PhotoController);

    PhotoController.$inject = ['paginationConstants', 'Upload', 'MessageService', 'Uploader', 'ParseLinks', 'Group'];

    function PhotoController(paginationConstants, Upload, MessageService, Uploader, ParseLinks, Group) {

        var vm = this;

        vm.links = null;
        vm.totalItems = null;
        vm.page = 1;

        vm.picFile = null;
        vm.photoList = [];

        vm.groupId = null;

        vm.uploadPic = uploadPic;
        vm.loadPhotos = loadPhotos;
        vm.changePublishType = changePublishType;
        vm.del = del;
        vm.loadAllGroups = loadAllGroups;

        vm.loadPhotos();
        vm.loadAllGroups();

        function uploadPic() {
            Upload.upload({
                url: 'library/api/upload',
                data: {fileName: vm.picFile.name, file: vm.picFile, photoFlg: 1, adminFlg: false, groupId: vm.groupId}
            }).success(function () {
                MessageService.success("photo.message.upload.success");
                vm.picFile = null;
                vm.loadPhotos();
            }).error(function () {
                MessageService.error("photo.message.upload.error");
            });
        }

        function loadPhotos() {
            Uploader.query({page: vm.page - 1, size: paginationConstants.itemsPerPage}, function(result, headers){
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.photoList = result;
            });
        }

        function changePublishType(id, type) {
            var data = {};
            data.id = id;
            data.type = type;
            Uploader.changePublishType(data, onChangeSuccess, onChangeError);
        }

        function onChangeSuccess() {
            MessageService.success("photo.message.change.success");
        }

        function onChangeError() {
            MessageService.error("photo.message.change.error");
        }

        function del(id) {
            Uploader.del({id: id}, onDeleteSuccess, onDeleteError);
        }

        function onDeleteSuccess() {
            MessageService.success("photo.message.del.success");
            vm.loadPhotos();
        }

        function onDeleteError() {
            MessageService.error("photo.message.del.error");
        }

        function loadAllGroups() {
            Group.queryUserGroups(function(result){
                vm.groups = result;
                if (result.length > 0) {
                    vm.groupId = result[0].id;
                }
            });
        }
    }

})();
