(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TokenEditDialogController', TokenEditDialogController);

    TokenEditDialogController.$inject = ['param', '$uibModalInstance', 'TokenService', 'MessageService'];

    function TokenEditDialogController (param, $uibModalInstance, TokenService, MessageService) {

        var vm = this;

        vm.token = angular.copy(param.token);
        vm.isOpen = false;

        vm.close = close;
        vm.save = save;
        vm.openCalendar = openCalendar;

        function close() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.token.endDate = $('#endEditDate').val() == '' ? null : $('#endEditDate').val();
            TokenService.save(vm.token, function() {
                MessageService.success("token.message.saved");
                $uibModalInstance.close(vm.token);
            });
        }

        function openCalendar(){
            vm.isOpen = true;
        }

    }
})();
