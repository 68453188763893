(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .directive('timeFormat', ['$filter',timeFormat]);

    var convert = function(val) {
        if (val === undefined || val === null) {
            return val;
        }
        if (val.length === 3) {
            if (val.match(/^[0-9]:[0-9]$/)) {
                // 「9:0」 →「09:00」
                val = "0" + val.substring(0, 1) + ":0" + val.substring(2, 3);
            } else if (val.match(/^[0-9][0-9][0-9]$/)) {
                // 「915」 →「09:15」
                val = "0" + val.substring(0, 1) + ":" + val.substring(1, 3);
            }

        } else if (val.length === 4) {
            if (val.match(/^[0-9][0-9][0-9][0-9]$/)) {
                // 「0915」 →「09:15」
                val = val.substring(0, 2) + ":" + val.substring(2, 4);
            } else if (val.match(/^[0-9]:[0-9][0-9]$/)) {
                // 「9:15」 →「09:15」
                val = "0" + val;
            } else if (val.match(/^[0-9][0-9]:[0-9]$/)) {
                // 「09:5」 →「09:05」
                val = val.substring(0, 2) + ":0" + val.substring(3, 4);
            }
        }

        if (val.match(/^[0-2][0-9]:([0-5][0-9])$/)) {
            // （「26:00」→「02:00」）
            var hh = parseInt(val.substring(0, 2), 10);
            if (hh >= 24) {
                hh -= 24;
                if (hh < 10) {
                    hh = "0" + hh;
                }
                val = hh + val.substring(2, 5);
            }
        }
        console.log(val);
        return val;
    }

    function timeFormat ($filter) {
        var directive = {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, elem, attrs, ctrl) {
                    elem.bind("blur",function(){
                        var viewValue = ctrl.$viewValue;
                        viewValue = convert(viewValue);
                        ctrl.$viewValue = viewValue;
                        ctrl.$render();
                        return viewValue;
                    });
            }
        };

        return directive;
    }
})();
