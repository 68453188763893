(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('BaseController', BaseController);

    BaseController.$inject = ['Setting', '$scope','SelectedPepper', '$state', 'MessageService'];

    function BaseController (Setting, $scope, SelectedPepper, $state, MessageService) {
        var vm = this;

        vm.datas = {pepperId: null, pepperSettingList: []};

        vm.success = null;
        vm.pepper = SelectedPepper.current();
        vm.systemSetting = [];

        vm.loadPepperSettings = loadPepperSettings;
        vm.loadSystemPepperSettings = loadSystemPepperSettings;
        vm.add = add;
        vm.del = del;
        vm.save = save;
        vm.cancel = cancel;
        vm.copy = copy;


        vm.loadPepperSettings();
        vm.loadSystemPepperSettings();

        $scope.$on('pepperChanged', function() {
            vm.pepper = SelectedPepper.current();
            loadPepperSettings();
        });


        function loadPepperSettings() {
            Setting.query({pepperId: vm.pepper.id, type: 1}, function(result) {
                vm.datas.pepperSettingList = result;
                // if (vm.datas.pepperSettingList.length == 0) {
                //     vm.add();
                // }
            });
        }

        function add() {
            var pepperSetting = {id: null, pepperId: null, variableType: 2, itemName: null, variableName: null, variableValue: null, repeatType: 1, sampleText: null};
            vm.datas.pepperSettingList.push(pepperSetting);
        }

        function del(index) {
            vm.datas.pepperSettingList.splice(index, 1);
            // if (vm.datas.pepperSettingList.length == 0) {
            //     vm.add();
            // }
        }

        function save() {
            vm.isSaving = true;
            vm.datas.pepperId = vm.pepper.id;
            vm.datas.type = 1;
            Setting.save(vm.datas, onSaveSuccess, onSaveError);
        }

        function cancel() {
            vm.loadPepperSettings();
        }

        function onSaveSuccess() {
            vm.isSaving = false;
            MessageService.success("base.home.success");
            $state.go('script');
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("base.home.error");
        }

        function copy(index) {
            if (confirm("既にデフォルト項目が上書きしますか。")) {
                for (var i = vm.datas.pepperSettingList.length - 1; i >= 0; i --) {
                    if (vm.datas.pepperSettingList[i].variableType == 1) {
                        vm.datas.pepperSettingList.splice(i, 1);
                    }
                }
                for (var i = 0; i < vm.systemSetting[index].pepperSettingList.length; i ++) {
                    var setting = angular.copy(vm.systemSetting[index].pepperSettingList[i]);
                    setting.id = null;
                    setting.variableType = 1;
                    vm.datas.pepperSettingList.push(setting);
                }
            }
        }

        function loadSystemPepperSettings() {
            Setting.system({type: 5}, function(result) {
                vm.systemSetting = result;
            });
        }

    }
})();
