(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('GroupSelectDialogController', GroupSelectDialogController);

    GroupSelectDialogController.$inject = ['paginationConstants', 'Group', '$uibModalInstance'];

    function GroupSelectDialogController (paginationConstants, Group, $uibModalInstance) {

        var vm = this;

        // 翻页所需
        vm.page = 1;
        vm.links = null;
        vm.totalItems = null;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        // group列表
        vm.groupList = [];

        // 查询条件
        vm.searchData = {id: "", name: ""};
        vm.defaultSearchData = {id: "", name: ""};

        // 初始加载数据
        vm.loadData = loadData;
        // 点击查询
        vm.search = search;
        // 清空搜索条件
        vm.clear = clear;
        // 选择指定
        vm.select = select;
        // 关闭dialog
        vm.close = close;

        loadData();

        function loadData() {
            Group.queryDialog({
                page: vm.page - 1,
                id: vm.searchData.id,
                name: vm.searchData.name}, function(result, headers){
                vm.totalItems = headers('X-Total-Count');
                vm.groupList = result;
                vm.queryCount = vm.totalItems;
            });
        }

        function search() {
            vm.page = 1;
            loadData();
        }

        function clear() {
            vm.searchData = angular.copy(vm.defaultSearchData);
            vm.page = 1;
            loadData();
        }

        function select(selected) {
            $uibModalInstance.close(selected);
        }

        function close() {
            $uibModalInstance.close("");
        }
    }
})();
