(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('UserManagementAddAllDialogController',UserManagementAddAllDialogController);

    UserManagementAddAllDialogController.$inject = ['staffList', 'User', '$stateParams', '$uibModalInstance', 'MessageService', '$timeout', '$rootScope'];

    function UserManagementAddAllDialogController (staffList, User, $stateParams, $uibModalInstance, MessageService, $timeout, $rootScope) {
        var vm = this;
        vm.noChecked = false;
        vm.checkAll = true;
        vm.staffList = staffList;
        vm.errorList = [];
        vm.errorShow = false;

        vm.cancel = cancel;
        vm.save = save;
        vm.check = check;
        vm.checkAllFun = checkAllFun;

        function cancel () {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            var selectList = [];
            for (var i = 0; i < vm.staffList.length; i ++) {
                if (vm.staffList[i].checked) {
                    selectList.push(vm.staffList[i]);
                }
            }
            User.saveAll({groupId: $stateParams.groupId, staffList: selectList}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            if (result != null && result.length > 0) {
                vm.errorList = result;
                vm.errorShow = true;
            } else {
                MessageService.success("global.messages.info.save");
                $uibModalInstance.close(result);
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            }
        }

        function onSaveError () {
            MessageService.error("global.messages.error.save");
        }

        function check() {
            var allChecked = true;
            vm.noChecked = true;
            for (var i = 0; i < vm.staffList.length; i ++) {
                if (!vm.staffList[i].checked) {
                    allChecked = false;
                } else {
                    vm.noChecked = false;
                }
            }
            if (allChecked) {
                vm.checkAll = true;
            } else {
                vm.checkAll = false;
            }
        }

        function checkAllFun() {
            if (vm.checkAll) {
                for (var i = 0; i < vm.staffList.length; i ++) {
                    vm.staffList[i].checked = true;
                }
                vm.noChecked = false;
            } else {
                for (var i = 0; i < vm.staffList.length; i ++) {
                    vm.staffList[i].checked = false;
                }
                vm.noChecked = true;
            }
        }
    }
})();
