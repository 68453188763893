(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('TokenService', TokenService);

    TokenService.$inject = ['$resource'];

    function TokenService ($resource) {
        var service = $resource('api/apitoken', {}, {
            'shopList': {
                method: 'GET',
                isArray: true,
                url: 'api/apitoken/shopList'
            },
            'loginShop': {
                method: 'GET',
                url: 'api/apitoken/loginShop'
            },
            'tokenList': {
                method: 'GET',
                isArray: true,
                url: 'api/apitoken/tokenList'
            },
            'save': {
                method: 'POST'
            },
            'del': {
                method: 'DELETE'
            },
            'get': {
                method: 'GET'
            },
            'createToken': {
                method: 'POST',
                url: 'api/token'
            },
            'updateToken': {
                method: 'PUT',
                url: 'api/token'
            },
            'deleteToken': {
                method: 'DELETE',
                url: 'api/deleteToken'
            }
        });

        return service;
    }

})();
