(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ContentValidationController', ContentValidationController);

    ContentValidationController.$inject = ['$uibModalInstance', '$uibModal', 'MessageService', '$timeout', '$rootScope'];

    function ContentValidationController ($uibModalInstance, $uibModal, MessageService, $timeout, $rootScope) {

        var vm = this;

        vm.inquiry = {}

        // 初始加载数据
        vm.loadData = loadData;
        vm.close = close;
        vm.checkList = "";

        loadData();

        function loadData() {
            vm.inquiry = $rootScope.inquiry;
            if($rootScope.inquiry.checkbox1){
                vm.checkList += "居宅介護支援, "
            }
            if($rootScope.inquiry.checkbox2) {
                vm.checkList += "訪問介護, "
            }
            if($rootScope.inquiry.checkbox3) {
                vm.checkList += "訪問入浴介護, "
            }
            if($rootScope.inquiry.checkbox4) {
                vm.checkList += "訪問リハビリ, "
            }
            if($rootScope.inquiry.checkbox5) {
                vm.checkList += "夜間対応型訪問介護, "
            }
            if($rootScope.inquiry.checkbox6) {
                vm.checkList += "通所介護, "
            }
            if($rootScope.inquiry.checkbox7) {
                vm.checkList += "地域密着型通所介護, "
            }
            if($rootScope.inquiry.checkbox8) {
                vm.checkList += "通所リハビリ, "
            }
            if($rootScope.inquiry.checkbox9) {
                vm.checkList += "総合事業, "
            }
            if($rootScope.inquiry.checkbox10) {
                vm.checkList += "認知症対応型通所介護, "
            }
            if($rootScope.inquiry.checkbox11) {
                vm.checkList += "ショートステイ, "
            }
            if($rootScope.inquiry.checkbox12) {
                vm.checkList += "短期入所療養介護, "
            }
            if($rootScope.inquiry.checkbox13) {
                vm.checkList += "小規模多機能型居宅介護, "
            }
            if($rootScope.inquiry.checkbox14) {
                vm.checkList += "看護小規模多機能型居宅介護, "
            }
            if($rootScope.inquiry.checkbox15) {
                vm.checkList += "グループホーム, "
            }
            if($rootScope.inquiry.checkbox16) {
                vm.checkList += "特養, "
            }
            if($rootScope.inquiry.checkbox17) {
                vm.checkList += "老健, "
            }
            if($rootScope.inquiry.checkbox18) {
                vm.checkList += "特定施設入居者生活介護, "
            }
            if($rootScope.inquiry.checkbox19) {
                vm.checkList += "地域密着型特定施設入居者生活介護, "
            }
            if($rootScope.inquiry.checkbox20) {
                vm.checkList += "サ高住（住宅型有料老人ホーム）, "
            }
            if($rootScope.inquiry.checkbox21) {
                vm.checkList += "介護記録（iPadアプリ/ケア樹きろく）, "
            }
            if($rootScope.inquiry.checkbox22) {
                vm.checkList += "ファクタリング, "
            }
            if($rootScope.inquiry.checkbox24) {
                vm.checkList += "ケア樹みえる, "
            }
            if($rootScope.inquiry.checkbox25) {
                vm.checkList += "ケア樹はかる, "
            }
            if($rootScope.inquiry.checkbox26) {
                vm.checkList += "ケア樹あそぶ, "
            }

            if(vm.checkList != ''){
                vm.checkList = vm.checkList.substring(0, vm.checkList.length-2);
            }
        }

        function close(){
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
