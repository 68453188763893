(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('VariableController', VariableController);

    VariableController.$inject = ['ParseLinks', 'paginationConstants', 'Variable', 'Enum', '$rootScope', 'Library'];

    function VariableController (ParseLinks, paginationConstants, Variable, Enum, $rootScope, Library) {
        var vm = this;

        vm.itemType = {};
        vm.variables = [];
        vm.variableList = [];
        vm.url = null;

        vm.loadAll = loadAll;
        vm.loadVariableType = loadVariableType;
        vm.loadVariableList = loadVariableList;
        vm.changeType = changeType;

        vm.loadAll();

        function loadAll() {
            loadVariableType();
            loadUrl();
        }

        function loadVariableType() {
            Enum.get({name: 'VariableType'}, function (result) {
                vm.variables = result;
                if (vm.variables.length > 0) {
                    if ($rootScope.variableType && $rootScope.variableType != "" && $rootScope.variableType != null) {
                        for (var i = 0; i < vm.variables.length; i ++) {
                            if ($rootScope.variableType == vm.variables[i].code) {
                                vm.itemType = vm.variables[i];
                                loadVariableList();
                            }
                        }
                    } else {
                        vm.itemType = vm.variables[0];
                        loadVariableList();
                    }
                }
            });
        }

        function loadVariableList() {
            Variable.query({page: vm.page - 1, size: paginationConstants.itemsPerPage, type: vm.itemType.code}, function(result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.variableList = result;
            });
        }

        function changeType(code) {
            for (var i = 0; i < vm.variables.length; i ++) {
                if (code == vm.variables[i].code) {
                    vm.itemType = vm.variables[i];
                    loadVariableList();
                }
            }
        }

        function loadUrl() {
            Library.url(function(result){
                vm.url = result.url;
            })
        }
    }
})();
