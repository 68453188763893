(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('PriceEditDialogController', PriceEditDialogController);

    PriceEditDialogController.$inject = ['$uibModalInstance', '$uibModal', 'MessageService', 'Price', 'param', '$timeout', '$rootScope', 'Product'];

    function PriceEditDialogController ($uibModalInstance, $uibModal, MessageService, Price, param, $timeout, $rootScope, Product) {

        var vm = this;

        // 単価
        vm.price = {id: "", productId: "", productNm: "", price: 0,  startDate: null, endDate: null, stopFlag: false};

        vm.isOpen = false;
        vm.openCalendar = openCalendar;

        // 初始加载数据
        vm.loadData = loadData;
        // 关闭dialog
        vm.close = close;
        // 选择制品
        vm.select = select;
        // 清除选择的制品
        vm.clear = clear;
        // 保存数据
        vm.save = save;

        loadData();

        function loadData() {
            // 获取单价信息
            if (param.id != null && param.id != '') {
                Price.get({id: param.id}, function (result) {
                    vm.price.id = result.id;
                    vm.price.productId = result.productId;
                    Product.get({id: result.productId}, function(product) {
                        vm.price.productNm = product.productName;
                    });
                    vm.price.price = result.price;
                    vm.price.startDate = new Date(result.startDate);
                    vm.price.endDate = result.endDate;
                    vm.price.stopFlag = result.stopFlag;
                });
            }
        }

        function close () {
            $uibModalInstance.dismiss('cancel');
        }

        function select() {
            var modalInstance = $uibModal.open({
                templateUrl : 'app/master/product/dialog/product-select-dialog.html',
                controller : 'ProductSelectDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size : 'lg',
                resolve : {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(function(result) {
                if (result != '') {
                    vm.price.productId = result.id;
                    vm.price.productNm = result.name;
                }
            },function(){
                //MessageService.error("global.error.internalServerError");
            })
        }

        function clear() {
            vm.price.productId = "";
            vm.price.productNm = "";
        }

        function save() {
            var entity = angular.copy(vm.price);
            entity.startDate = $('#startDate').val();
            Price.save(entity, function(result){
                MessageService.success("price.message.saved");
                $uibModalInstance.close(result);
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            });
        }

        function openCalendar() {
            vm.isOpen = true;
        }
    }
})();
