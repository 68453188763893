(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('license', {
            parent: 'app',
            url: '/settings/license',
            data: {
                pageTitle: 'license.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/master/license/license.html',
                    controller: 'LicenseController',
                    controllerAs: 'vm'
                }
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', 'Role', 'Kinou',
                function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService, Role, Kinou) {
                    var top = $uibModalStack.getTop();
                    if (top) {
                        $uibModalStack.dismiss(top.key);
                    }
                    Principal.identity().then(function(account) {
                        if (account != null) {
                            Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_License_License_FullAccess"}, function(result){
                            	if (!result.flag) {
                            		$state.go('accessdenied');
                            	}
                            });
                        } else {
                            LoginService.open();
                        }

                    });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('license');
                    return $translate.refresh();
                }]
            }
        }).state('license.edit', {
            parent: 'license',
            url: '/edit/{id}/{version}',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal','Principal', function($stateParams, $state, $uibModal, Principal) {
                $uibModal.open({
                    templateUrl: 'app/master/license/dialog/license-edit-dialog.html',
                    controller: 'LicenseEditDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        param: {id: $stateParams.id, version: $stateParams.version}
                    }
                }).result.then(function() {
                    $state.go('license', null, { reload: true });
                }, function() {
                    $state.go('license');
                });
            }]
        }).state('license.delete', {
            parent: 'license',
            url: '/delete/{id}/{version}/{gid}/{productId}/{shopId}',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', function($stateParams, $state, $uibModal, Principal) {
                $uibModal.open({
                    templateUrl: 'app/master/license/dialog/license-delete-dialog.html',
                    controller: 'LicenseDeleteDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['LicenseService', function(LicenseService) {
                            return LicenseService.get({id : $stateParams.id});
                        }],
                        groupEntity: ['Houjin', 'Group', function(Houjin, Group) {
                            if ($stateParams.version == 1) {
                                return Houjin.get({id: $stateParams.gid});
                            } else {
                                return Group.get({id: $stateParams.gid});
                            }
                        }],
                        productEntity: ['Product', function(Product) {
                            return Product.get({id: $stateParams.productId});
                        }],
                        shopEntity: ['Outlets', function(Outlets) {
                            return Outlets.get({id: $stateParams.productId});
                        }]
                    }
                }).result.then(function() {
                    $state.go('license', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
