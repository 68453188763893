(function () {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User ($resource) {
        var service = $resource('api/users/:login', {}, {
            'query': {method: 'GET', url: 'api/users', isArray: true},
            'queryAllByGroupId': {method: 'GET', url: 'api/usersAllByGroupId', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'uploadPhoto': {
                method:'PUT',
                url: 'api/users/photo'
            },
            'delete':{ method:'DELETE'},
            'all': {
                method: 'GET',
                url: 'api/users/all',
                isArray: true
            },
            'check': {
                method: 'POST',
                url: 'api/users/check'
            },
            'auth': {
                method: 'GET',
                url: 'api/users/auth',
                isArray: true
            },
            'getStaffs': {
                method: 'GET',
                url: 'api/users/staffs',
                isArray: true
            },
            'saveAll': {
                method: 'POST',
                url: 'api/users/saveAll',
                isArray: true
            },
            'getPhoto': {
                method:'GET',
                url: 'api/users/photo',
                isArray: true
            },
            'queryAll':{
                method:'GET',
                url: 'api/users/updateAll',
                isArray: true
            },
            'updateAll':{
                method: 'POST',
                url: 'api/users/updateAll'
            },
            'printAll': {
                method: 'POST',
                url: 'api/users/printAll'
            },
            'getRole': {
                method: 'GET',
                url: 'api/users/roleList',
                isArray: true
            },
            'getSelectRole': {
                method: 'GET',
                url: 'api/users/selectRoleList',
                isArray: true
            },
            'queryLogin': {method: 'GET', url: 'api/:login/existLogin'},
            'queryEmail': {method: 'GET', url: 'api/:email/existEmail'},
            'queryUserOne': {method: 'GET', url: 'api/:id/queryUser'},
            'queryUserGroup': {
                method: 'GET',
                url: 'api/users/:userId/userGroup'
            },
            'queryUserOption': {
                method: 'GET',
                url: 'timecard/api/userOption/:userId/houmonTimeCard'
            }
        });

        return service;
    }
})();
