(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .directive('checkProductTypeRepeat', checkProductTypeRepeat);

    checkProductTypeRepeat.$inject = ['ProductType', '$q'];

    function checkProductTypeRepeat (ProductType, $q) {

        var directive = {
            restrict: 'AE',
            require: 'ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc (scope, element, attrs, ctrl) {
            ctrl.$asyncValidators.productTypeRepeat = function(modelValue) {
                var d = $q.defer();
                ProductType.checkRepeat({name: modelValue}, function(result){
                    if (result.id == scope.$eval(attrs.checkProductTypeRepeat) || result.id == 0) {
                        d.resolve();
                    } else {
                        d.reject();
                    }
                });
                return d.promise;
            }
        }
    }
})();
