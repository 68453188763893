(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('MonthShiftTime', MonthShiftTime);

    MonthShiftTime.$inject = ['$resource'];

    function MonthShiftTime ($resource) {
        var service = $resource('timecard/api/shift', {}, {
            'getMonthAll': {
                method: 'GET',
                url:'timecard/api/monthshiftTime/all',
                isArray: true
            },
            'getMonthParentAll': {
                method: 'GET',
                url:'timecard/api/monthshiftTimeParent/all',
                isArray: true
            },
            'save': {
                method: 'POST',
                url:'timecard/api/monthshiftTime/save'
            },
            'delete': {
                method: 'DELETE',
                url:'timecard/api/shiftTime/delete/:id'
            }
        });

        return service;
    }

})();
