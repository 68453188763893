(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('token-shop', {
            parent: 'app',
            url: '/settings/outlet',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/system/token-management/token-shop.html',
                    controller: 'TokenShopController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('token');
                    return $translate.refresh();
                }]
            }
        }).state('token-shop.edit', {
            parent: 'token-shop',
            url: '/edit/{id}',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('token');
                    return $translate.refresh();
                }]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/system/token-management/dialog/token-dialog.html',
                    controller: 'TokenDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        param: {id: $stateParams.id}
                    }
                }).result.then(function() {
                    $state.go('token-shop', null, { reload: true });
                }, function() {
                    $state.go('token-shop');
                });
            }]
        }).state('token-edit', {
            parent: 'app',
            url: '/settings/outlet/token',
            data: {
                //authorities: ['ROLE_PARTNER']
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/system/token-management/token-edit.html',
                    controller: 'TokenEditController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('token');
                    return $translate.refresh();
                }]
            }
        }).state('token-delete', {
            parent: 'token-edit',
            url: '/delete/{id}',
            data: {
                //authorities: ['ROLE_ADMIN', 'ROLE_PARTNER']
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/system/token-management/dialog/token-delete-dialog.html',
                    controller: 'TokenDeleteDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['TokenService', function(TokenService) {
                            return TokenService.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('token-edit', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
