(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('product', {
            parent: 'app',
            url: '/settings/product',
            data: {
                pageTitle: 'product.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/master/product/product.html',
                    controller: 'ProductController',
                    controllerAs: 'vm'
                }
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', 'Role', 'Kinou',
                function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService, Role, Kinou) {
                    var top = $uibModalStack.getTop();
                    if (top) {
                        $uibModalStack.dismiss(top.key);
                    }
                    Principal.identity().then(function(account) {
                        if (account != null) {
                            Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_License_Product_FullAccess"}, function(result){
	                        	if (!result.flag) {
	                        		$state.go('accessdenied');
	                        	}
	                        });
                        } else {
                            LoginService.open();
                        }
                    });
            }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product');
                    return $translate.refresh();
                }]
            }
        }).state('product.edit', {
            parent: 'product',
            url: '/edit/{id}',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal','Principal', '$uibModalStack', function($stateParams, $state, $uibModal, Principal, $uibModalStack) {
                $uibModal.open({
                    templateUrl: 'app/master/product/dialog/product-edit-dialog.html',
                    controller: 'ProductEditDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        param: {id: $stateParams.id}
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: true });
                }, function() {
                    $state.go('product');
                });
            }]
        }).state('product.delete', {
            parent: 'product',
            url: '/delete/{id}',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal','Principal', '$uibModalStack', function($stateParams, $state, $uibModal,Principal,$uibModalStack) {
                $uibModal.open({
                    templateUrl: 'app/master/product/dialog/product-delete-dialog.html',
                    controller: 'ProductDeleteDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.id});
                        }],
                        productTypeEntity: ['Product', function(Product) {
                            return Product.getProductType({productId: $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
