(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Setting', Setting);

    Setting.$inject = ['$resource'];

    function Setting ($resource) {
        var service = $resource('robot/api/setting', {}, {
            "query": {
                method: 'GET',
                isArray: true,
                url: 'robot/api/settings/pepperId/:pepperId/type/:type'
            },
            "save": {
                method: "POST"
            },
            "system": {
                method: 'GET',
                isArray: true,
                url: 'robot/api/settings/type/:type'
            }
        });

        return service;
    }

})();
