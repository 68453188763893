(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ShowConversationController', ShowConversationController);

    ShowConversationController.$inject = ['$stateParams', '$scope', 'Category', 'Script', '$state', 'Enum', 'Library', 'Variable', '$sce', '$uibModal', 'MessageService', '$translate', 'Principal'];

    function ShowConversationController ($stateParams, $scope, Category, Script, $state, Enum, Library, Variable, $sce, $uibModal, MessageService, $translate, Principal) {
        var vm = this;

        vm.categories = [];
        vm.script = {id: null, scriptType: null, scriptName: null, title: null,
            libraryId: null, publishType: true, plainScript: null, comment: null,
            scriptItems: [],
            categories: []};
        vm.detailTypes = [];
        vm.scriptDetailType = [];
        vm.libraries = [];
        vm.variables = [];
        vm.labelCount = 1;
        vm.url = null;
        vm.type = $stateParams.type;
        vm.loadCount = 0;

        Principal.hasAuthority('ROLE_ADMIN').then(function(result){
            if(result) {
                vm.isAdmin = true;
            } else {
                vm.isAdmin = false;
            }
        });

        vm.loadAll = loadAll;
        vm.loadCategories = loadCategories;
        vm.loadScript = loadScript;
        vm.loadScriptDetailType = loadScriptDetailType;
        vm.loadLibraries = loadLibraries;
        vm.loadVariables = loadVariables;
        vm.loadUrl = loadUrl;
        vm.addLabel = addLabel;
        vm.save = save;
        vm.delLabel = delLabel;
        vm.addDetail = addDetail;
        vm.delDetail = delDetail;
        vm.back = back;
        vm.choose = choose;
        vm.checkLibrary = checkLibrary;

        vm.preViewShow = preViewShow;

        vm.loadAll();

        $scope.sce = $sce.trustAsResourceUrl;

        function loadAll() {
            vm.loadCategories();
            vm.loadScriptDetailType();
            vm.loadLibraries();
            vm.loadVariables();
            vm.loadUrl();
        }

        function loadCategories() {
            Category.list(function(result) {
                for (var i = 0; i < result.length; i ++) {
                    if (vm.isAdmin || (!vm.isAdmin && result[i].categoryType < 9)) {
                        vm.categories.push(result[i]);
                    }
                }
                vm.loadScript();
            });
        }

        function loadScript() {
            vm.loadCount ++;
            if (vm.loadCount != 5) {
                return false;
            }
            if ($stateParams.id != 0) {
                Script.get({id: $stateParams.id}, function(result) {
                    vm.script = result;

                    var categories = vm.script.categories;
                    vm.script.categories = [];

                    for (var i = 0; i < categories.length; i ++) {
                        for (var j = 0; j < vm.categories.length; j ++) {
                            if (categories[i].id == vm.categories[j].id) {
                                vm.script.categories.push(vm.categories[j]);
                            }
                        }
                    }

                    for (var i = 0; i < vm.script.scriptItems.length; i ++) {
                        var scriptItemDetails = vm.script.scriptItems[i].scriptItemDetails;
                        for (var j = 0; j < scriptItemDetails.length; j ++) {
                            scriptItemDetails[j].detailType = scriptItemDetails[j].detailType.toString();
                            preViewShow(i, j, scriptItemDetails[j].detailType);
                        }
                    }

                    vm.script.publishType = vm.script.publishType == 1 ? true : false;

                    if (vm.script.scriptItems.length > 0) {
                        vm.labelCount = parseInt(vm.script.scriptItems[vm.script.scriptItems.length - 1].label.substring(1), 10)
                    } else {
                        vm.labelCount = 0;
                    }
                });
            } else {
                vm.labelCount = 0;
            }
        }

        function loadScriptDetailType() {
            Enum.get({name: 'ScriptConversationDetailType'}, function(result) {
                vm.scriptDetailType = result;
                vm.loadScript();
            });
        }

        function loadLibraries() {
            Library.list({}, function(result) {
                vm.libraries = result;
                vm.loadScript();
            });
        }

        function loadVariables() {
            Variable.list({}, function(result) {
                vm.variables = result;
                vm.loadScript();
            });
        }

        // ラベルを追加
        function addLabel(labelType) {
            vm.labelCount ++;
            var labelName = vm.labelCount;
            if (labelType == 1) {
                labelName = "s" + vm.labelCount;
            } else {
                labelName = "q" + vm.labelCount;
            }
            vm.script.scriptItems.push({label: labelName, labelType: labelType, scriptItemDetails: []});
            vm.script.scriptItems[vm.script.scriptItems.length - 1].scriptItemDetails.push({detailType: '1', contentId: ""});
        }

        function save(saveDirect) {

            preData(saveDirect);

            if (saveDirect) {
                Script.make(vm.script, function(result) {
                    vm.script.plainScript = result.plainScript;
                });
            } else {
                Script.save(vm.script, onSaveSuccess, onSaveError);
            }
        }

        function preData(saveDirect) {

            vm.success = null;
            vm.isSaving = true;
            vm.script.scriptType = $stateParams.type;
            vm.script.saveDirect = saveDirect;

            // contentValue
            for (var i = 0; i < vm.script.scriptItems.length; i ++) {
                var item = vm.script.scriptItems[i];
                var delDetailArr = [];
                for (var j = 0; j < item.scriptItemDetails.length; j ++) {
                    var detail = item.scriptItemDetails[j];
                    if (detail.detailType == '10' || detail.detailType == '20') {
                        for (var k = 0; k < vm.libraries.length; k ++) {
                            var o = vm.libraries[k];
                            if (detail.contentId != undefined && detail.contentId == o.id) {
                                var li = checkLibrary(detail.contentId);
                                if (li.uploadFlg) {
                                    if (detail.detailType == '10') {
                                        detail.contentValue = vm.url + "/library/api/library/image/" + o.id;
                                    } else {
                                        detail.contentValue = vm.url + "/library/api/library/file/" + o.id;
                                    }
                                } else {
                                    detail.contentValue = o.url
                                }
                            }
                        }
                    } else if (detail.detailType == '15' || detail.detailType == '25') {
                        for (var k = 0; k < vm.variables.length; k ++) {
                            var o = vm.variables[k];
                            if (detail.contentId != undefined && detail.contentId == o.id) {
                                detail.contentValue = o.itemCode;
                            }
                        }
                    } else if (detail.detailType == '') {
                        delDetailArr.push(j);
                    }
                }
                for (var l = delDetailArr.length;l > 0; l --) {
                    delDetail(i, l);
                }
            }
        }

        function onSaveSuccess() {
            vm.isSaving = false;
            vm.success = true;
            //loadScript();
            $state.go('script');
            MessageService.success("global.messages.info.save");
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("global.messages.error.save");
            vm.success = null;
        }

        // ラベルを削除
        function delLabel(index) {
            vm.script.scriptItems.splice(index, 1);
        }

        function addDetail(parentIndex, type) {
            vm.script.scriptItems[parentIndex].scriptItemDetails.push({detailType: type});
        }

        function delDetail(parentIndex, index) {
            if (vm.script.scriptItems[parentIndex].scriptItemDetails[index].detailType == '10') {
                vm.script.scriptItems[parentIndex].background = "";
            } else if (vm.script.scriptItems[parentIndex].scriptItemDetails[index].detailType == '20') {
                vm.script.scriptItems[parentIndex].music = "";
            }
            vm.script.scriptItems[parentIndex].scriptItemDetails.splice(index, 1);
        }

        function back() {
            $state.go('script');
        }

        function preViewShow(parent, index, type) {
            if (type == '10') {
                if (vm.script.scriptItems[parent].scriptItemDetails[index].contentId != null
                    && vm.script.scriptItems[parent].scriptItemDetails[index].contentId != "") {
                    var li = checkLibrary(vm.script.scriptItems[parent].scriptItemDetails[index].contentId);
                    if (li != null) {
                        if (li.uploadFlg) {
                            vm.script.scriptItems[parent].background = "/library/api/library/image/"+ vm.script.scriptItems[parent].scriptItemDetails[index].contentId;
                        } else {
                            vm.script.scriptItems[parent].background = li.url;
                        }
                    } else {
                        vm.script.scriptItems[parent].background = null;
                    }
                } else {
                    vm.script.scriptItems[parent].background = vm.script.scriptItems[parent].scriptItemDetails[index].text;
                }
            } else if (type == '15') {
                vm.script.scriptItems[parent].scriptItemDetails[index].contentValue = getVariableDetail(vm.script.scriptItems[parent].scriptItemDetails[index].contentId, 15)
            } else if (type == '20') {
                if (vm.script.scriptItems[parent].scriptItemDetails[index].contentId != null
                    && vm.script.scriptItems[parent].scriptItemDetails[index].contentId != "") {
                    var li = checkLibrary(vm.script.scriptItems[parent].scriptItemDetails[index].contentId);
                    if (li != null) {
                        if (li.uploadFlg) {
                            vm.script.scriptItems[parent].music = "/library/api/library/file/" + vm.script.scriptItems[parent].scriptItemDetails[index].contentId;
                        } else {
                            vm.script.scriptItems[parent].music = li.url;
                        }
                    } else {
                        vm.script.scriptItems[parent].music = null;
                    }
                } else {
                    vm.script.scriptItems[parent].music = vm.script.scriptItems[parent].scriptItemDetails[index].text;
                }
            }
        }

        function checkLibrary(id) {
            var result = null;
            for (var i = 0; i < vm.libraries.length; i ++) {
                if (vm.libraries[i].id == id) {
                    result = angular.copy(vm.libraries[i]);
                }
            }
            return result;
        }

        function getVariableDetail(id, type) {
            var result = "";
            if (type == '15') {
                for (var k = 0; k < vm.variables.length; k ++) {
                    var o = vm.variables[k];
                    if (id == o.id) {
                        result = o.itemCode;
                    }
                }
            }
            return result;
        }

        function choose(item, detail, fileType, type) {
            var modalInstance = $uibModal.open({
                templateUrl : 'app/system/script/library/choose/choose.html',
                controller : 'ChooseController',
                controllerAs: 'vm',
                size : 'lg',
                resolve : {
                    params : {fileType: fileType},
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('library');
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(function(result) {
                if (result != '') {
                    vm.script.scriptItems[item].scriptItemDetails[detail].contentId = "";
                    vm.script.scriptItems[item].scriptItemDetails[detail].text = "";
                    if (result.type == 1) {
                        vm.script.scriptItems[item].scriptItemDetails[detail].contentId = result.value;
                    } else {
                        vm.script.scriptItems[item].scriptItemDetails[detail].text = result.value;
                    }
                    preViewShow(item, detail, type);
                }
            },function(){
                MessageService.error("global.error.internalServerError");
            })
        }

        function loadUrl() {
            Library.url(function(result){
                vm.url = result.url;
                vm.loadScript();
            })
        }
    }
})();
