(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ProductDeleteDialogController', ProductDeleteDialogController);

    ProductDeleteDialogController.$inject = ['$uibModalInstance', 'entity', 'productTypeEntity', 'Product', 'MessageService', '$timeout', '$rootScope'];

    function ProductDeleteDialogController ($uibModalInstance, entity, productTypeEntity, Product, MessageService, $timeout, $rootScope) {
        var vm = this;

        vm.product = entity;
        vm.productType = productTypeEntity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.error = false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Product.delete({id: id}, function() {
                MessageService.success("product.message.deleted");
                $uibModalInstance.close(true);
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            }, function () {
                vm.error = true;
            });
        }
    }
})();
