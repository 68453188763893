(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Timecard', Timecard);

    Timecard.$inject = ['$resource'];

    function Timecard ($resource) {
        var service = $resource('timecard/api/timecards', {}, {
            'query': {
                method: 'GET'
            },
            'calendar': {//指定年月的日历信息取得
                method: 'GET',
                url: 'timecard/api/timecards/calendar'
            },
            'queryDay': {//指定天的所有打卡信息取得
                method: 'POST',
                url: 'timecard/api/timecards/day',
            },
            'queryMonth': {//指定月的所有打卡信息取得
                method: 'POST',
                url: 'timecard/api/timecards/month'
            },
            'queryHistory':{
                method: 'GET',
                url: 'timecard/api/timecards/history',
                isArray:true
            },
            'queryChart':{
                method: 'GET',
                url: 'timecard/api/timecards/chart',
            },
            'save': {
                method: 'POST'
            },
            "saveHistory": {
                method: 'POST',
                url: 'timecard/api/timecards/history'
            },
            'update': {
                method:'PUT'
            },
            'yesterday': {
                method: 'POST',
                url: 'timecard/api/timecards/yesterday'
            },
            'beOut': {
                method: 'POST',
                url: 'timecard/api/timecards/out'
            },
            'reEntry': {
                method:'PUT',
                url: 'timecard/api/timecards/out'
            },
            'delete': {
                method:'DELETE'
            }
        });

        return service;
    }

})();
