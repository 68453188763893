(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];
    function stateConfig($stateProvider) {
        $stateProvider.state('monthshift-time', {
            parent: 'app',
            url: '/timecard/monthshift-time',
            data: {
                pageTitle: 'global.menu.timecard.management'
            },
            views: {
                'content@': {
                    templateUrl: 'app/timecard/monthshift-time/monthshift-time-detail.html',
                    controller: 'MonthShiftTimeDetailController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                groupId: null,
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('shift-time');
                    return $translate.refresh();
                }]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService',  'Kinou',
                function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService, Kinou) {
                    var top = $uibModalStack.getTop();
                    if (top) {
                        $uibModalStack.dismiss(top.key);
                    }
                    Principal.identity().then(function(account) {
                        if (account != null) {
                            var gtc_caretree2_timecard_allfunction_fullaccess = false;
                            var isAdmin = false;
                            var isDeveloper = false;
                            Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_Shift_AllFunction_FullAccess"}, function(result){
                                if (!result.flag) {
                                    $state.go('accessdenied');
                                } else {
                                    //plan($state, $uibModal, account, isAdmin, isDeveloper, gtc_caretree2_timecard_allfunction_fullaccess);
                                }
                            });
                        } else {
                            LoginService.open();
                        }

                    });
                }]
        });
    }
})();
