(function() {
    'use strict';
    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];
    function stateConfig($stateProvider) {
        $stateProvider.state('staff-occupation', {
            parent: 'app',
            url: '/timecard/staff-occupation/:currentYear/:currentMonth',
            data: {
                pageTitle: 'global.menu.timecard.management'
            },
            views: {
                'content@': {
                    templateUrl: 'app/timecard/staff-occupation/staff-occupation-detail.html',
                    controller: 'StaffOccupationDetailController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                groupId: null,
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),

                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('staff-occupation');
                    return $translate.refresh();
                }]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService',  'Kinou',
                function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService, Kinou) {
                    var top = $uibModalStack.getTop();
                    if (top) {
                        $uibModalStack.dismiss(top.key);
                    }
                    Principal.identity().then(function(account) {
                        if (account != null) {
                            var gtc_caretree2_timecard_allfunction_fullaccess = false;
                            var isAdmin = false;
                            var isDeveloper = false;
                            Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_Shift_AllFunction_FullAccess"}, function(result){
                                if (!result.flag) {
                                    $state.go('accessdenied');
                                } else {
                                    //plan($state, $uibModal, account, isAdmin, isDeveloper, gtc_caretree2_timecard_allfunction_fullaccess);
                                }
                            });
                        } else {
                            LoginService.open();
                        }

                    });
                }]
        })
        .state('staff-occupation.selectuser', {
            parent: 'staff-occupation',
            url: '/selectuser',
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', function($stateParams, $state, $uibModal, Principal, $uibModalStack, LoginService) {
                Principal.identity().then(function(account) {
                    $uibModal.open({
                        templateUrl: 'app/timecard/staff-occupation/staff-single-add.html',
                        controller: 'StaffSingleAddController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: {}
                        }
                    }).result.then(function() {
                        $state.go('staff-occupation', null, { reload: true });
                    }, function() {
                        $state.go('staff-occupation');
                    });
                });
            }]
        });
    }
})();
