(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('photo', {
            parent: 'script',
            url: '/photo',
            data: {
                pageTitle: 'global.menu.system.photo'
            },
            views: {
                'content@': {
                    templateUrl: 'app/system/script/photo/photo.html',
                    controller: 'PhotoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                // 文字
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('photo');
                    return $translate.refresh();
                }]
            }
        }).state('photo.show', {
            parent: 'photo',
            url: '/photo/{id}/show',
            data: {
                pageTitle: 'global.menu.system.photo'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'Principal', 'LoginService', function($stateParams, $state, $uibModal, Principal, LoginService) {
        		Principal.identity().then(function(account) {
        			if (account != null) {
        				var gtc_caretree2_robot_allfunction_fullaccess = false;
                      	if (account.functions != null) {
                          	for (var i in account.functions) {
                          		if (account.functions[i] == 'GTC_CareTree2_Robot_AllFunction_FullAccess') {
                          			gtc_caretree2_robot_allfunction_fullaccess = true;
                          		}
                          	}
                      	}
                      	if (gtc_caretree2_robot_allfunction_fullaccess != false) {
                        	  $uibModal.open({
                                  templateUrl: 'app/system/script/photo/photo-show-dialog.html',
                                  controller: 'PhotoShowDialogController',
                                  controllerAs: 'vm',
                                  backdrop: 'static',
                                  size: 'lg',
                                  resolve: {
                                      entity: ['Uploader', function(Uploader) {
                                          return Uploader.get({id : $stateParams.id});
                                      }]
                                  }
                              }).result.then(function() {
                                  $state.go('photo', null, { reload: true });
                              }, function() {
                                  $state.go('^');
                              });
                          }
        			} else {
        				LoginService.open();
        			}
                  	
        		});
            }]
        });
    }
})();
