(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('CorporationController', CorporationController);

    CorporationController.$inject = [];

    function CorporationController () {

        var vm = this;

        // 法人列表
        vm.corporationList = [];

        // 初始加载数据
        vm.loadData = loadData();

        function loadData() {
            var corporation1 = {jigyoushoId: "14ad254f", houjinId: "300030", jigyoushoCd: "4580012", jigyoushoNm: "グッドツリー介護事業所"};
            var corporation2 = {jigyoushoId: "19ad254p", houjinId: "400120", jigyoushoCd: "4580012", jigyoushoNm: "ディケアーグッドツリー"};
            vm.corporationList.push(corporation1);
            vm.corporationList.push(corporation2);
        }
    }
})();
