(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('analyze', {
            parent: 'app',
            url: '/analyze',
            data: {
                pageTitle: 'global.menu.analyze.main'
            },
            views: {
                'content@': {
                    templateUrl: 'app/analyze/riyousha-analyze.html',
                    controller: 'RiyoushaAnalyzeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('riyousha-analyze');
                    return $translate.refresh();
                }]
            },
            onEnter: ['$state', 'LicenseService', '$uibModal', 'Principal', '$uibModalStack', 'LoginService', 'Kinou',
                function($state, LicenseService, $uibModal, Principal, $uibModalStack, LoginService, Kinou){
                var top = $uibModalStack.getTop();
                if (top) {
                    $uibModalStack.dismiss(top.key);
                }
                Principal.identity().then(function(account) {
                	if (account != null) {
                        var gtc_caretree2_analyze_function_fullaccess = false;
                        var isAdmin = false;
                        var isDeveloper = false;
                        Kinou.isLoginRoleHasFunctions({functions: "GTC_CareTree2_Analyze_Function_FullAccess"}, function(result){
                        	if (!result.flag) {
                        		$state.go('accessdenied');
                        	} else {
                        		plan($state, $uibModal, account, isAdmin, isDeveloper, gtc_caretree2_analyze_function_fullaccess);
                        	}
                        });
                	} else {
                		LoginService.open();
                	}
                    
                });
            }]
        })
        .state('analyze.edit',{
            parent: 'analyze',
            url: '/{id}/edit',
            data: {
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/analyze/riyousha-analyze-dialog.html',
                    controller: 'CommentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sd',
                    resolve: {
                        entity: {id: $stateParams.id}
                    }
                }).result.then(function() {
                    $state.go('analyze', null, { reload: false});
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('analyze.loginredash', {
            parent: 'analyze',
            url: '/loginredash"',
            data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/analyze/login.html',
                    controller: 'RiyoushaAnalyzeController',
                    controllerAs: 'vm'
                }
            },
            onEnter: function () {
            }
        }).state('analyze.redash', {
            parent: 'analyze',
            url: '/redash/{jigyoushoId}/{riyoushaId}/{jisshiDt}/{jisshiDtBefore}',
            data: {
                pageTitle: 'global.menu.analyze.main'
            },
            views: {
                'content@': {
                    templateUrl: 'app/analyze/login.html',
                    controller: 'LoginRedashController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('riyousha-analyze');
                    return $translate.refresh();
                }]
            }
        }).state('analyze.show', {
            parent: 'analyze',
            url: '/photo/{id}/show',
            data: {
                pageTitle: 'global.menu.system.photo'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/analyze/photo-show-dialog.html',
                    controller: 'AnalyzePhotoShowDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Uploader', function(Uploader) {
                            return Uploader.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('photo', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        }).state('analyzeshow',{
            parent: '',
            url: '/analyzeshow/{riyoushaId}/{jigyoushoId}/{jisshiDt}',
            data: {
                pageTitle: ''
            },
            views: {
                'content@': {
                    templateUrl: 'app/analyze/analyzeshow.html',
                    controller: 'AnalyzeshowController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                analyzeParams: ['$stateParams', function ($stateParams){
                    return $stateParams;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('riyousha-analyze');
                    return $translate.refresh();
                }]
            }
        });
    }
    function plan($state, $uibModal, account, isAdmin, isDeveloper, gtc_caretree2_analyze_function_fullaccess){
        if (account.authorities.indexOf("ROLE_ADMIN") != -1) {
            isAdmin = true;
        }
        if (account.authorities.indexOf("ROLE_DEVELOPER") != -1) {
            isDeveloper = true;
        }
        if (account.functions != null) {
            for (var i in account.functions) {
                if (account.functions[i] == 'GTC_CareTree2_Analyze_Function_FullAccess') {
                    gtc_caretree2_analyze_function_fullaccess = true;
                }
            }
        }
        if (!gtc_caretree2_analyze_function_fullaccess && !isAdmin && !isDeveloper) {
            $state.go('home');
            $uibModal.open({
                templateUrl: 'app/home/dialog/license.html',
                controller: 'HomeLicenseController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg'
            }).result.then(function() {
                $state.go('home', null, { reload: true });
            }, function() {
                $state.go('home');
            });
        }
    }
})();
