(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('Houjin', Houjin);

    Houjin.$inject = ['$resource'];

    function Houjin ($resource) {

        var service = $resource('api/houjin', {}, {
            'query': {
                method: 'GET',
                url: 'api/houjin/list',
                isArray: true
            },
            'get': {
                method: 'GET',
                url: 'api/houjin/one'
            },
            'getList': {
            	method: 'GET',
            	isArray: true
            }
        });

        return service;
    }

})();
