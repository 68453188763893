(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('StorageManagementController', StorageManagementController);

    StorageManagementController.$inject = ['ParseLinks', 'paginationConstants','StroageService','$rootScope', '$state', 'Houjin'];

    function StorageManagementController(ParseLinks, paginationConstants,StroageService, $rootScope, $state, Houjin) {
        var vm = this;
        vm.houjinId = '';
        vm.jigyoushoStorageList=[];
        vm.totalSize;//总容量
        vm.used = 0;//使用容量
        vm.surplus = 0;//剩余容量
        vm.percent;//使用百分比
        vm.alertMailFlg = false;
        vm.doDeleteOneYear = doDeleteOneYear;
        vm.storageCheck = storageCheck;
        vm.goRule = goRule;
        vm.getHoujin = getHoujin;
        vm.getStroage = getStroage;
        vm.serviceTerms = serviceTerms;
        getHoujin();
        function getHoujin() {
        	Houjin.getList({},function(result){
        		vm.houjinList = result;
        		if (vm.houjinList.length > 0) {
        			vm.houjinId = vm.houjinList[0].id;
        			getStroage();
        		}
        	});
        }
        function getStroage() {
        	StroageService.houjinList({id:vm.houjinId},function(result){
                vm.jigyoushoStorageList = result.jigyoushoStorageList;
                vm.totalSize = result.houjinStorage.totalSize;
                vm.used = result.houjinStorage.used;
                vm.surplus = result.houjinStorage.leaved;
                vm.percent = result.houjinStorage.percent;
                vm.alertMailFlg = result.houjinStorage.alertMailFlg;
                var storageChart = echarts.init(document.getElementById('storage'));
                var storageOption = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    series: [{
                        color :['#DDDDDD','#337ab7'],
                        data: [
                            {value:vm.surplus,name:'使用可能'},
                            {value:vm.used,name:'使用済'}
                        ],
                        type: 'pie',
                        center:['70%', '50%']
                    }]
                }
                storageChart.setOption(storageOption);
                window.onresize = function (){
                    storageChart.resize();
                }
            });
        }

        function doDeleteOneYear(id){
            $state.go('storage-management.delete', {houjinId: vm.houjinId, id: id});
        }
        function storageCheck(){
            $state.go('storage-management.sendMail', {houjinId: vm.houjinId, alertMailFlg: vm.alertMailFlg});
        }
        function goRule(planType){
        	if ((vm.totalSize == 0.5 && planType == 0) 
        			|| (vm.totalSize == 5 && planType == 1) 
        			|| (vm.totalSize == 25 && planType == 2) 
        			|| (vm.totalSize == 125 && planType == 3) 
        			|| (vm.totalSize == 625 && planType == 4)) {
        		$state.go('storage-management.same');
        	} else {
        		StroageService.storageUpdateTime({id:vm.houjinId},
                    function (result){
                        if(result.flag){
                            $state.go('storage-management.plan');
                        }else{
                            $state.go('storage-management.rule',{houjinId: vm.houjinId, planType: planType, totalSize: vm.totalSize});
                        }
                    }
                )
        	}
        }
        function serviceTerms(){
            window.open('https://caretree.jp/mobile/wp-content/uploads/2019/10/caretree_licence_20191024.pdf');
        }
    }
})();
