(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('ScriptController', ScriptController);

    ScriptController.$inject = ['$scope','Principal','ParseLinks', 'pagingParams', 'paginationConstants','Category','Enum','Script','Collection', 'Pepper', 'SelectedPepper', '$state', 'MessageService', '$stateParams', 'User', '$translate','$rootScope'];

    function ScriptController ($scope, Principal, ParseLinks, pagingParams, paginationConstants, Category, Enum, Script, Collection, Pepper, SelectedPepper, $state, MessageService, $stateParams, User, $translate,$rootScope) {
        var vm = this;
        vm.scriptTypes = [];
        vm.categories = [];
        vm.scriptList = [];
        vm.collections = [];
        vm.links = null;
        vm.totalItems = null;
        vm.page = $stateParams.page;

        vm.pepper = SelectedPepper.current();
        vm.selectedType = $stateParams.scriptSelectType;
        vm.selectedCategory = $stateParams.scriptSelectCategory;
        vm.selectedScriptId = [];
        vm.copyData = {};
        vm.isSaving = false;
        vm.userList = [];
        vm.pepperList = [];
        vm.pepper = {};

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.loadAll = loadAll;
        vm.loadScriptList = loadScriptList;
        vm.loadAllCategory = loadAllCategory;
        vm.loadScriptTypes  = loadFavoriteTypes;
        vm.loadPage = loadPage;
        vm.loadAllCollections = loadAllCollections;
        vm.loadUsers = loadUsers;
        vm.addFavorite = addFavorite;
        vm.delFavorite = delFavorite;
        vm.changeType = changeType;
        vm.changeCategory = changeCategory;
        vm.selectScript = selectScript;
        vm.edit = edit;
        vm.goEdit = goEdit;
        vm.goShow = goShow;
        vm.copy = copy;
        vm.del = del;
        vm.changeSort = changeSort;
        vm.number;
        vm.CountList = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
        vm.transition = transition;
        vm.changePepper = changePepper;
        vm.loadPepperList = loadPepperList;

        function checkPepper() {
            var result = false;
            for (var i = 0; i < vm.pepperList; i ++) {
                if (vm.pepperList[i].id === vm.pepper.id) {
                    result = true;
                }
            }
            return result;
        }

        function loadPepperList() {
            //console.log("get pepper list of current login user");
            vm.pepperList = SelectedPepper.pepperList();
            if(vm.selectPepper == undefined && vm.pepperList.length > 0){
               vm.selectPepper = vm.pepperList[0].id;
            }
            vm.pepper = SelectedPepper.current();
        }
        Principal.hasAuthority('ROLE_ADMIN').then(function(result){
            if(result) {
                vm.isAdmin = true;
            } else {
                vm.isAdmin = false;
            }
        });

        vm.loadAllCategory();
        vm.loadScriptTypes();
        vm.loadAll();

        $scope.$on('pepperChanged', function() {
            vm.page = 1;
            vm.pepper = SelectedPepper.current();
            loadAll();
        });

        function loadAll() {
            vm.pepperList = SelectedPepper.pepperList();
            if($rootScope.page != undefined){
                vm.page = $rootScope.page;
                $rootScope.page = undefined;
            }
            if($rootScope.selectedCategory != undefined){
                vm.selectedCategory = $rootScope.selectedCategory;
            }
            if($rootScope.selectedType != undefined){
                vm.selectedType = $rootScope.selectedType;
            }
            loadUsers();
            loadScriptList();
            loadAllCollections();
            Pepper.query(function(result){
                SelectedPepper.setPepperList(result);
                loadPepperList();
                if (result.length === 0) {
                    SelectedPepper.setCurrent(null);
                } else {
                    if (!checkPepper()) {
                        SelectedPepper.setCurrent(result[0]);
                    }
                }
            });

            // clear selected script
            vm.selectedScriptId = [];
        }

        function loadScriptList() {
            Script.query({page: vm.page - 1, size: paginationConstants.itemsPerPage, type: vm.selectedType, category: vm.selectedCategory, sort: sort()},function(result,headers){
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.scriptList = result;
                for (var i=0;i<vm.scriptList.length;i++){
                    vm.scriptList[i].sort = String(vm.scriptList[i].sort);
                    if(vm.scriptList[i].sort == "null"){
                        vm.scriptList[i].sort = "";
                    }
                }
            });
        }

        function loadAllCategory() {
            Category.allList(function(result){
                vm.categories = result;
            });
        }

        function loadFavoriteTypes() {
            Enum.get({name: "FavoriteType"},function(result){
                vm.scriptTypes = result;
            });
        }

        function loadPage (page) {
            vm.page = page;
            vm.loadAll();
        }

        function loadAllCollections() {
            if (vm.pepper == null || vm.pepperList.length == 0) {
                return false;
            }
            if(vm.pepper.id == undefined){
                vm.pepper.id = vm.pepperList[0].id
            }
            Collection.query({pepperId: vm.pepper.id}, function(result){
                vm.collections = result;
            });
        }

        function addFavorite(collection) {
            vm.isSaving = true;
            Collection.save({pepperId: vm.pepper.id, scriptId: collection.scriptId},onSaveSuccess,onSaveError);
        }

        function delFavorite(collection) {
            vm.isSaving = true;
            Collection.delete({pepperId: vm.pepper.id, scriptId: collection.scriptId},onSaveSuccess,onSaveError);
        }


        function onSaveSuccess () {
            vm.isSaving = false;
            MessageService.success("script.message.collectionChange");
            vm.loadAllCollections();
        }

        function onSaveError () {
            MessageService.error("global.messages.error.save");
            vm.isSaving = false;
        }

        function changeType(selectedType) {
            vm.selectedType = selectedType;
            $rootScope.selectedType = selectedType;
            vm.page = 1;
            loadAll();
        }

        function changeCategory(selectedCategory) {
            vm.selectedCategory = selectedCategory;
            vm.page = 1;
            $rootScope.selectedCategory = selectedCategory;
            loadAll();
        }

        function selectScript(id) {
            var exitIndex = null;
            for (var i = 0; i < vm.selectedScriptId.length; i ++) {
                if (vm.selectedScriptId[i] == id) {
                    exitIndex = i;
                }
            }
            if (exitIndex == null) {
                vm.selectedScriptId.push(id);
            } else {
                vm.selectedScriptId.splice(exitIndex, 1);
            }
        }

        function edit(id, type) {
            $state.go('media', {id: id, type: type});
        }

        function show(id, type) {
            $state.go('showMedia', {id: id, type: type});
        }

        function goShow(id,type){
            $rootScope.page = vm.page;
            if (type == 2 || type == 3) {
                show(id, type);
            } else {
                $state.go('showConversation', {id: id, type: type});
            }
        }
        function goEdit() {
            $rootScope.page = vm.page;
            var id = vm.selectedScriptId[0];
            var type = 1;
            for (var i = 0; i < vm.scriptList.length; i ++) {
                var script = vm.scriptList[i];
                if (script.id == id) {
                    type = script.scriptType;
                }
            }
            if (type == 2 || type == 3) {
                edit(id, type);
            } else {
                $state.go('conversation', {id: id, type: type});
            }
        }

        function copy() {
            vm.copyData.ids = vm.selectedScriptId;
            Script.copy(vm.copyData, onCopySuccess, onCopyError);
        }

        function onCopySuccess() {
            vm.copySuccess = true;
            MessageService.success("script.message.copySuccess");
            loadAll();
        }

        function onCopyError() {
            vm.copySuccess = false;
            MessageService.error("script.message.copyError");
            loadAll();
        }

        function del() {
            if (confirm($translate.instant("script.message.delConfirm"))) {
                vm.copyData.ids = vm.selectedScriptId;
                Script.del(vm.copyData, onDelSuccess, onDelError);
            }
        }

        function onDelSuccess() {
            MessageService.success("global.messages.info.del");
            loadAll();
        }

        function onDelError() {
            MessageService.error("global.messages.error.del");
            loadAll();
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate != 'id') {
                result.push('id');
            }
            return result;
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: 1,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                scriptSelectType: vm.selectedType,
                scriptSelectCategory: vm.selectedCategory,
                search: vm.loadScriptList
            });
        }

        function loadUsers() {
            User.all(function(result){
                vm.userList = result;
            });
        }

        function changeSort(sort,id){
            var data = {};
            data.id = id;
            data.sort = sort;
            Script.sort(data, onChangeSuccess, onChangeError);
        }

        function onChangeSuccess() {
            MessageService.success("script.sort.changesuccess");
        }
        function onChangeError() {
            MessageService.error("script.sort.changeerror");
        }

        function changePepper(pepperList) {
            var pepper =  pepperList.find(function (obj){
                return obj.id = vm.selectPepper;
            });
            if (pepper.id > 0) {
                SelectedPepper.setCurrent(pepper);
                vm.pepper = pepper;
                $rootScope.$broadcast('pepperChanged');
                MessageService.success("global.messages.info.pepperchanged");
            } else {
                MessageService.error("global.error.pepperchangerror");
            }
        }
    }
})();
