(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('QrCodeController', QrCodeController);

    QrCodeController.$inject = ['Group', 'Enum', 'paginationConstants', 'ParseLinks', '$rootScope','$state'];

    function QrCodeController(Group, Enum, paginationConstants, ParseLinks, $rootScope,$state) {
        var vm = this;

        vm.loadAll = loadAll;
        vm.loadJigyoushoServiceTypeList = loadJigyoushoServiceTypeList;
        vm.groups = [];
        vm.serviceTypeList = [];

        vm.totalItems = null;
        vm.page = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.qrCodeShow = qrCodeShow;
        vm.loadAll();
        vm.loadJigyoushoServiceTypeList();

        function loadAll () {
            if($rootScope.groupPage != undefined){
                vm.page = $rootScope.groupPage;
                $rootScope.groupPage = undefined;
            }
            Group.query({page: vm.page - 1, size: vm.itemsPerPage, all: false}, function(result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.groups = result;
                vm.queryCount = vm.totalItems;
            });
        }

        function loadJigyoushoServiceTypeList() {
            Enum.get({name: "JigyoushoServiceTypeFull"},function(result){
                vm.serviceTypeList = result;
            });
        }

        function qrCodeShow(groupId){
            $rootScope.groupPage = vm.page;
            $state.go('qrcode-detail', {groupId: groupId});
        }

    }

})();
