(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TokenShopController', TokenShopController);

    TokenShopController.$inject = ['TokenService', 'paginationConstants'];

    function TokenShopController (TokenService, paginationConstants) {

        var vm = this;

        // 翻页所需
        vm.page = 1;
        vm.links = null;
        vm.totalItems = null;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        // 查询条件
        vm.searchData = {shopNm: "", tel: ""};
        vm.defaultSearchData = {shopNm: "", tel: ""};

        // 贩卖店列表
        vm.shopList = [];

        // 初始加载数据
        vm.loadData = loadData;
        // 点击查询
        vm.search = search;
        // 清空搜索条件
        vm.clear = clear;

        loadData();

        function loadData() {
            TokenService.shopList(vm.searchData, function(result, headers){
                vm.totalItems = headers('X-Total-Count');
                vm.shopList = result;
                vm.queryCount = vm.totalItems;
            });
        }

        function search() {
            vm.page = 1;
            loadData();
        }

        function clear() {
            vm.searchData = angular.copy(vm.defaultSearchData);
            vm.page = 1;
            loadData();
        }
    }
})();
