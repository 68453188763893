(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('AdminBaseController', AdminBaseController);

    AdminBaseController.$inject = ['Setting', '$scope', 'SelectedPepper', 'MessageService'];

    function AdminBaseController (Setting, $scope, SelectedPepper, MessageService) {
        var vm = this;

        vm.datas = {pepperId: null, pepperSettingList: []};

        vm.pepper = SelectedPepper.current();
        vm.success = null;

        vm.loadPepperSettings = loadPepperSettings;
        vm.add = add;
        vm.del = del;
        vm.save = save;
        vm.cancel = cancel;

        vm.loadPepperSettings();

        $scope.$on('pepperChanged', function() {
            vm.pepper = SelectedPepper.current();
            loadPepperSettings();
        });

        function loadPepperSettings() {
            Setting.query({pepperId: vm.pepper.id, type: 3}, function(result) {
                vm.datas.pepperSettingList = result;
                // if (vm.datas.pepperSettingList.length == 0) {
                //     vm.add();
                // }
            });
        }

        function add() {
            var pepperSetting = {id: null, pepperId: null, variableType: 5, itemName: null, variableName: null, variableValue: null, repeatType: 1, sampleText: null};
            vm.datas.pepperSettingList.push(pepperSetting);
        }

        function del(index) {
            vm.datas.pepperSettingList.splice(index, 1);
            // if (vm.datas.pepperSettingList.length == 0) {
            //     vm.add();
            // }
        }

        function save() {
            vm.isSaving = true;
            vm.datas.pepperId = vm.pepper.id;
            vm.datas.type = 3;
            Setting.save(vm.datas, onSaveSuccess, onSaveError);
        }

        function cancel() {
            vm.loadPepperSettings();
        }

        function onSaveSuccess() {
            vm.isSaving = false;
            vm.loadPepperSettings();
            MessageService.success("base.home.success");
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("base.home.error");
        }
    }
})();
