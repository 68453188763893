(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('RobotManagementDeleteController', RobotManagementDeleteController);

    RobotManagementDeleteController.$inject = ['$uibModalInstance', 'entity', 'group', 'Pepper', 'MessageService', '$timeout', '$rootScope'];

    function RobotManagementDeleteController ($uibModalInstance, entity, group, Pepper, MessageService, $timeout, $rootScope) {
        var vm = this;

        vm.pepper = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.groupName = group.groupName;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (pepperId) {
            Pepper.delete({id: pepperId}, onDelSuccess, onDelError);
        }

        function onDelSuccess() {
            MessageService.success("robotManagement.message.delSuccess");
            $uibModalInstance.close(true);
            $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
        }

        function onDelError() {
            MessageService.error("robotManagement.message.delError");
        }
    }
})();
