(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .controller('TokenDeleteDialogController', TokenDeleteDialogController);

    TokenDeleteDialogController.$inject = ['$uibModalInstance', 'entity', 'TokenService', 'MessageService', '$timeout', '$rootScope'];

    function TokenDeleteDialogController ($uibModalInstance, entity, TokenService, MessageService, $timeout, $rootScope) {
        var vm = this;

        vm.token = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.error = false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            TokenService.del({id: id}, function() {
                MessageService.success("token.message.deleted");
                $uibModalInstance.close(true);
                $timeout(function(){$rootScope.$broadcast('messageChanged');}, 500);
            }, function () {
                vm.error = true;
            });
        }
    }
})();
