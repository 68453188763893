(function() {
    'use strict';

    angular
        .module('gatewayApp')
        .factory('StaffOccupation', StaffOccupation);

    StaffOccupation.$inject = ['$resource'];

    function StaffOccupation ($resource) {
        var service = $resource('timecard/api/staffOccupation', {}, {
            'queryDuty': {
                method: 'POST',
                url: 'timecard/api/staffOccupation/getDutyByUserId',
                isArray: true
            },
            'queryDutyOccupation': {
                method: 'POST',
                url: 'timecard/api/staffOccupation/getDutyOccupationByUserId',
                isArray: true
            },
            'deletedStaffOccupation': {
                method: 'POST',
                url: 'timecard/api/staffOccupation/deletedStaffOccupation'
            },
            'queryStaffOccupation': {
                method: 'POST',
                url: 'timecard/api/staffOccupation/getStaffOccupation',
                isArray: true
            },
            'queryStaffOccupationByGroupId': {
                method: 'GET',
                url: 'timecard/api/staffOccupation/getStaffOccupationByGroupId',
                isArray: true
            },
            'queryUserByGroupId': {
                method: 'GET',
                url: 'timecard/api/staffOccupation/getUserByGroupId',
                isArray: true
            },
            'queryCount': {
                method: 'GET',
                url: 'timecard/api/staffOccupation/getCount'
            },
            'queryStaffDuty': {
                method: 'GET',
                url: 'timecard/api/staffOccupation/getStaffDuty',
                isArray: true
            },
            'save': {
                method: 'POST',
                url:'timecard/api/staffOccupation/save'
            },
            'delete': {
                method: 'DELETE',
                url:'timecard/api/staffOccupation/delete/:id'
            }
        });

        return service;
    }

})();
